import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { observable, computed, action, runInAction, when, makeObservable } from 'mobx'
import {
  path,
  head,
  prop,
  pluck,
  curry,
  flip,
  includes,
  compose,
  propEq,
  propOr,
  isEmpty,
  pathOr,
  filter,
  last,
  take,
  split,
  isNil
} from 'ramda'
import { compact } from 'ramda-adjunct'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  flippedIncludes, safeFormatToDate, getFragment, getPublicationSubPathFromPathname
} from 'Lib/purefunctions'
import parse from 'html-react-parser'

import Spinner from 'Components/spinner2'
import {
  getArticleBySlug, getPagePostTypeBySlug, getPageBySlug, getPaidPageBySlug
} from 'Api/endpoints/article'
import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import TwoColumnView from 'Containers/Sites/Base/Templates/twoColumn'
import { getPortfolio } from 'Api/endpoints/portfolio'
import injectPlayerScript from 'Lib/injectPlayer'
import jwHack from './jwhack'
import domProcPortfolio from './domProcPortfolio'
import handleFragment from './handleFragment'
import { getAd } from '../../../../../Api/endpoints/auth'
import isAfter from 'date-fns/isAfter'
import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'

@inject('store')
@observer
class PageDynamic extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    publication: MobxPropTypes.objectOrObservableObject,
    type: PropTypes.string,
    redirectIfPageNotFound: PropTypes.bool,
    forceSlug: PropTypes.string,
    paidPage: PropTypes.bool
  };

  constructor (props) {
    super(props)

    makeObservable(this)

    const portfolioId = path(['publication', 'portfolio_id'], this.props)
    when(
      () => this.shouldShowPortfolio,
      () => {
        if (!portfolioId) {
          return
        }

        this.fetchPortfolio(portfolioId)
      }
    )

    const articleTemplate = path(['publication', 'settings', 'articleTemplate'], this.props)
    if (articleTemplate === 'two-column') {
      this.fetchAd()
    }

    this.hydrate()
  }

  componentDidMount () {
    window.addEventListener('scroll', this.listenToScroll)
    handleFragment()
  }

  componentDidUpdate (prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.article = null
      this.pageRaw = null
      this.hydrate()
    }

    jwHack()
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.listenToScroll)

    if (this.isPage) {
      return
    }

    this.trackScroll()
  }

  @computed get articleExists () {
    return !!prop('_id', this.article)
  }

  @computed get newsletterTypes () {
    return !this.articleExists ? [] : this.article.newslettertype
  }

  @computed get shouldShowPortfolio () {
    if (isEmpty(this.newsletterTypes) || isNil(this.newsletterTypes)) {
      return false
    }

    return compose(
      propEq('code', 'issues'),
      this.props.store.mst.newsletterType.getById
    )(this.newsletterTypes)
  }

  @computed get author () {
    if (!this.articleExists) {
      return {}
    }

    const { person } = this.article

    if (!isEmpty(head(person))) {
      return this.props.store.mst.author.getById(head(person)) || {}
    }

    return {}
  }

  @computed get specialReportSidebar () {
    if (this.props.type !== 'special-report') {
      return null
    }

    return (
      <div className='specialReportSidebarWrapper'>
        <img src={this.article.promo_image_url} alt='promo' />
        <a href={this.article.media_links} target='_blank noopener noreferer' onClick={this.handleTrackSpecialReportDownload}>
          <button>
            <FontAwesomeIcon icon={faFilePdf} size='sm' /> DOWNLOAD PDF
          </button>
        </a>
      </div>
    )
  }

  @computed get content () {
    if (!this.articleExists) {
      return <div />
    }

    const elem = domProcPortfolio(this.portfolioRaw)
    const dynamicContentSection = path(['publication', 'settings', 'dynamicContentSection'], this.props)
    const articleData = this.article || this.pageRaw
    const showDynamicContent = !(articleData.newsletter_type === 'resources') &&
      !isEmpty(dynamicContentSection.content) &&
      this.props.type === 'content' && !(articleData.post_type === 'special-report')
    const dynamicPortfolioSectionTop = path(['settings', 'dynamicPortfolioSections', 'top'], this.props.publication)
    const showDynamicPortfolioSectionTop = (dynamicPortfolioSectionTop.enabled && !isEmpty(dynamicPortfolioSectionTop.content))
    const dynamicPortfolioSectionBottom = path(['settings', 'dynamicPortfolioSections', 'bottom'], this.props.publication)
    const showDynamicPortfolioSectionBottom = (dynamicPortfolioSectionBottom.enabled && !isEmpty(dynamicPortfolioSectionBottom.content))
    const { dynamicSidebarSection } = this.props.publication.settings
    const hideDynamicSidebarSection = this.props.type === 'special-report'

    const code = prop('code', this.props.publication)
    const adjustedContent = code ? this.article.content.replaceAll('__SLUG', code) : this.article.content

    return (
      <>
        {this.article.content && (<div>{parse(adjustedContent)}</div>)}

        {dynamicContentSection.enabled && showDynamicContent && (
          <div className='dynamicContentSection'>
            {parse(dynamicContentSection.content)}
          </div>
        )}

        {this.shouldShowPortfolio && this.portfolioRaw && this.props.type === 'content' && (
          <div className='postfixPortfolio'>
            {showDynamicPortfolioSectionTop && (
              <div className='dynamicPortfolioSectionTop'>
                {parse(dynamicPortfolioSectionTop.content)}
              </div>
            )}
            {parse(elem.innerHTML)}
            {showDynamicPortfolioSectionBottom && (
              <div className='dynamicPortfolioSectionBottom'>
                {parse(dynamicPortfolioSectionBottom.content)}
              </div>
            )}
          </div>
        )}
        {!hideDynamicSidebarSection && dynamicSidebarSection && (
          <div className='d-xl-none dynamicContent mt-5 mb-3'>
            {parse(dynamicSidebarSection)}
          </div>
        )}
      </>
    )
  }

  @computed get customMessage () {
    const code = prop('code', this.props.publication)
    const { siteDetails } = this.props.store.site

    const messageData = compose(
      head,
      pluck(code),
      pathOr({}, ['messages', 'publicationMsg'])
    )(siteDetails)

    if ((messageData == null) || (messageData.until == null) || (messageData.enabled == null)) {
      return false
    }

    if (messageData.content && messageData.enabled && isAfter(toDate(parseISO(messageData.until)), new Date())) {
      return messageData.content
    }
  }

  @computed get pageContent () {
    const content = prop('content', this.pageRaw)
    if (!content) {
      return <div />
    }

    return <div>{parse(content)}</div>
  }

  @computed get mediaLink () {
    return prop('media_links', this.article)
  }

  @computed get readingTimeMin () {
    return this.article.reading_time_min
  }

  @computed get publication () {
    const { location: { pathname } } = this.props
    const fragment = compose(
      last,
      take(2),
      split('/')
    )(pathname)
    return this.props.store.mst.publication.getByCode(fragment)
  }

  @computed get availablePublications () {
    const { userPublications } = this.props.store.mst.userPublication

    const userPubIds = pluck('publicationId', userPublications)
    return filter(
      compose(
        flippedIncludes(userPubIds),
        prop('_id')
      )
    )(this.props.store.mst.publication.paid)
  }

  @observable articleId;

  @observable article;

  @observable articleSlug;

  @observable pageSlug;

  @observable portfolioRaw = '';

  @observable pageRaw = '';

  @observable isPage = false;

  @observable isArticle = false;

  @observable ad;

  @observable scrollingPosition = 0;

  @observable trackArticleSent = false;

  @observable timestamp = new Date();

  @action.bound
  listenToScroll () {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    const height = document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    this.scrollingPosition = Math.max(this.scrollingPosition, Math.round((100 * winScroll) / height))
  }

  @action.bound
  setArticleId (id) {
    this.articleId = id
  }

  @action hydrate () {
    this.articleSlug = path(['match', 'params', 'item'], this.props)
    this.pageSlug = path(['match', 'params', 'slug'], this.props) || this.props.forceSlug
    this.postType = path(['match', 'params', 'posttype'], this.props)
    const { publication } = this.props

    this.trackArticleSent = false

    if (this.pageSlug && !this.postType && !this.props.publication) {
      // noinspection JSIgnoredPromiseFromCall
      this.fetchPage(this.props.paidPage)

      this.isPage = true
    }

    if (this.postType && this.pageSlug && !this.props.publication) {
      // noinspection JSIgnoredPromiseFromCall
      this.fetchPostTypePage()
    }

    if (publication) {
      const pid = prop('_id', publication)
      const isPaid = propEq('publication_type', 'paid', publication)

      if (isPaid && !this.props.store.mst.userPublication.containsPublication(pid)) {
        this.props.history.push(`/unavailable?pub=${publication.code}`)
        return
      }
      // noinspection JSIgnoredPromiseFromCall
      this.fetchArticleId()
      this.isArticle = true
    }
  }

  @action.bound
  async fetchArticleId () {
    try {
      const id = prop('_id', this.props.publication)
      let resp = {}

      if (this.props.type === 'author') { // Deprecated, we don't display authors any more
        const getPage = (flip(curry(getPageBySlug)))(this.articleSlug)
        const siteCodes = pluck('wordpressSiteName', this.props.store.mst.author.getByCode(this.articleSlug))

        const promises = siteCodes.map(getPage)
        const results = compact(await Promise.all(promises))

        resp = head(results)
      } else {
        const sitecode = path(['publication', 'wordpressSiteName'], this.props)
        resp = await getArticleBySlug(sitecode, id, this.articleSlug)
        runInAction(() => {
          if (!prop('_id', resp) && this.props.redirectIfPageNotFound) {
            this.props.history.push('/pageNotFound')
            return
          }
          this.article = resp
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  @action.bound
  async fetchPortfolio (id) {
    const res = await getPortfolio(id)

    this.portfolioRaw = res.value
  }

  @action.bound
  async fetchPage (paid = false) {
    const { sitecode } = this.props.store.site
    const slug = this.pageSlug

    if (!slug) {
      return
    }

    if (!paid) {
      this.pageRaw = await getPageBySlug(sitecode, slug)
    } else {
      this.pageRaw = await getPaidPageBySlug(sitecode, slug)
    }

    if (!prop('_id', this.pageRaw) && this.props.redirectIfPageNotFound) {
      this.props.history.push('/pageNotFound')
    }
  }

  @action.bound
  async fetchPostTypePage () {
    const { pageSlug, postType } = this

    if (!pageSlug && !postType) {
      return
    }

    this.pageRaw = await getPagePostTypeBySlug('all', postType, pageSlug)

    if (!prop('_id', this.pageRaw) && this.props.redirectIfPageNotFound) {
      this.props.history.push('/pageNotFound')
    }
  }

  @action.bound
  async fetchAd () {
    const { sitecode, siteDetails: { adZones } } = this.props.store.site
    const { userAdKeywords } = this.props.store.user
    const adZoneType = 'keyword'

    if (adZones && adZones.sidebar) {
      this.ad = await getAd(sitecode, adZones.sidebar, adZoneType, userAdKeywords)
    }
  }

  @action.bound
  trackArticle () {
    if (this.isPage || !this.article) {
      return
    }

    const { session } = this.props.store.user
    const { pathname } = this.props.location

    const fragment = getFragment(pathname)
    const publication = this.props.store.mst.publication.getByCode(fragment)

    const sessionId = session ? session.id : null

    const publicationSubpath = publication ? getPublicationSubPathFromPathname(pathname) : null

    const title = propOr('', 'title')(this.article)
    const wpKey = propOr(0, 'wpKey')(this.article)
    const newsletterType = prop('newsletter_type')(this.article)
    const isArticle = (newsletterType === 'issues' || newsletterType === 'updates')
    const isReport = publicationSubpath && publicationSubpath.toLowerCase().includes('special-reports')
    const custom3 = isArticle ? 'article' : isReport ? 'report' : 'other'

    const obj = {
      session: sessionId,
      event: 'articleView',
      url: window.location.href,
      wp_key: wpKey,
      status: 1,
      scroll_depth: 100,
      publication_code: prop('code', publication),
      product_code: path(['settings', 'product_code'], publication),
      publication_subpath: publicationSubpath,
      custom1: title,
      custom3
    }

    this.props.store.user.analyticsAdd(obj)
    this.trackArticleSent = true
  }

  @action.bound
  trackCustomPage () {
    const { session } = this.props.store.user
    const sessionId = session ? session.id : null

    const obj = {
      session: sessionId,
      event: 'other',
      url: window.location.href,
      status: 1,
      scroll_depth: 100
    }

    this.props.store.user.analyticsAdd(obj)
    this.trackArticleSent = true
  }

  @action.bound
  trackScroll () {
    const { session } = this.props.store.user
    const { pathname } = this.props.location

    const fragment = getFragment(pathname)
    const publication = this.props.store.mst.publication.getByCode(fragment)

    const sessionId = session ? session.id : null

    const publicationSubpath = publication ? getPublicationSubPathFromPathname(pathname) : null

    const title = propOr('', 'title')(this.article)
    const wpKey = propOr(0, 'wpKey')(this.article)
    const newsletterType = prop('newsletter_type')(this.article)
    const isArticle = (newsletterType === 'issues' || newsletterType === 'updates')
    const isReport = publicationSubpath && publicationSubpath.toLowerCase().includes('special-reports')
    const custom3 = isArticle ? 'article' : isReport ? 'report' : 'other'

    const obj = {
      session: sessionId,
      event: 'scrollPosition',
      url: window.location.href,
      wp_key: wpKey,
      status: 1,
      scroll_depth: this.scrollingPosition,
      publication_code: prop('code', publication),
      product_code: path(['settings', 'product_code'], publication),
      publication_subpath: publicationSubpath,
      custom1: title,
      custom2: Math.ceil(Math.abs(new Date() - this.timestamp) / 1000).toString(),
      custom3
    }

    this.props.store.user.analyticsAdd(obj)
  }

  @action.bound
  handleTrackSpecialReportDownload () {
    const { session } = this.props.store.user
    const { pathname } = this.props.location

    const fragment = getFragment(pathname)
    const publication = this.props.store.mst.publication.getByCode(fragment)

    const sessionId = session ? session.id : null

    const publicationSubpath = publication ? getPublicationSubPathFromPathname(pathname) : null

    const title = propOr('', 'title')(this.article)

    const obj = {
      session: sessionId,
      event: 'reportDownload',
      url: window.location.href,
      status: 1,
      scroll_depth: 100,
      publication_code: prop('code', publication),
      product_code: path(['settings', 'product_code'], publication),
      publication_subpath: publicationSubpath,
      custom1: title
    }

    this.props.store.user.analyticsAdd(obj)
  }

  render () {
    const { type } = this.props
    const { withTimestamp } = this.props.store.site
    const timeFormat = withTimestamp ? 'MMMM dd, yyyy hh:mm aaa' : 'MMMM dd, yyyy'

    if (!this.articleExists && !this.pageRaw) {
      return <TemplateSingle content={<Spinner />} />
    }

    const data = this.article || this.pageRaw
    const { title, content, createdAtGMT } = data
    const isResources = compose(
      includes('resources'),
      propOr([], 'newsletter_type')
    )(data)

    const isIssues = compose(
      includes('issues'),
      propOr([], 'newsletter_type')
    )(data)

    const isUpdates = compose(
      includes('updates'),
      propOr([], 'newsletter_type')
    )(data)

    const date = safeFormatToDate(createdAtGMT, timeFormat)
    const withMd9Wrapper = (type === 'special-report')
    const extraClass = this.portfolioRaw ? 'withPortfolio' : ''
    const authorTitle = !isResources ? path(['author', 'title'], this) : ''
    const authorAvatar = !isResources ? path(['author', 'avatar'], this) : ''
    const bio = !isResources ? path(['author', 'bio'], this) : ''
    const authorCode = path(['author', 'code'], this)
    const publicationCode = path(['props', 'publication', 'code'], this)
    const publicationTitle = path(['props', 'publication', 'title'], this)
    const articleTemplate = path(['publication', 'settings', 'articleTemplate'], this.props)

    injectPlayerScript()

    if (this.article && !this.trackArticleSent) {
      this.trackArticle()
    }

    if (includes(type, ['page', 'custom'])) {
      const forceMd12 = this.pageSlug === 'publication-schedule'
      const md8Title = this.pageSlug !== 'publication-schedule'

      this.trackCustomPage()
      return (
        <TemplateSingle
          title={title}
          content={this.pageContent}
          forceMd12={forceMd12}
          md8Title={md8Title}
        />
      )
    }

    if (type === 'author') {
      return (
        <TemplateSingle title={title} content={<div>{parse(content)}</div>} />
      )
    }

    if (type === 'special-report') {
      if (this.mediaLink) {
        return (
          <TemplateSingle
            type={type}
            message={this.customMessage}
            title={title}
            content={this.content}
            author={authorTitle}
            avatar={authorAvatar}
            authorBio={bio}
            authorLink={`/team-members/${authorCode}`}
            date={date}
            publicationCode={publicationCode}
            publicationTitle={publicationTitle}
            sidebar={this.specialReportSidebar}
            wideSidebar
            md9Wrapper={withMd9Wrapper}
            extraClass={extraClass}
            mediaLink={this.mediaLink}
          />
        )
      }

      return (
        <TemplateSingle
          message={this.customMessage}
          title={title}
          content={this.content}
          author={authorTitle}
          avatar={authorAvatar}
          authorBio={bio}
          authorLink={`/team-members/${authorCode}`}
          date={date}
          publicationCode={publicationCode}
          publicationTitle={publicationTitle}
          extraClass={extraClass}
        />
      )
    }

    if (isIssues || isUpdates) {
      if (articleTemplate === 'two-column') {
        return (
          <TwoColumnView
            message={this.customMessage}
            title={title}
            content={this.content}
            author={this.author}
            ad={this.ad}
            date={date}
            publicationCode={publicationCode}
            publicationTitle={publicationTitle}
            extraClass={extraClass}
            mediaLink={this.mediaLink}
            readingTimeMin={this.readingTimeMin}
            article={this.article}
          />
        )
      }

      if (articleTemplate === 'single-column') {
        return (
          <TemplateSingle
            message={this.customMessage}
            title={title}
            content={this.content}
            author={authorTitle}
            avatar={authorAvatar}
            publicationCode={publicationCode}
            publicationTitle={publicationTitle}
            authorLink={`/team-members/${authorCode}`}
            mediaLink={this.mediaLink}
            date={date}
            singlePage
            readingTimeMin={this.readingTimeMin}
          />
        )
      }
    }

    return (
      <TemplateSingle
        message={this.customMessage}
        title={title}
        content={this.content}
        author={authorTitle}
        avatar={authorAvatar}
        publicationCode={publicationCode}
        publicationTitle={publicationTitle}
        authorLink={`/team-members/${authorCode}`}
        date={date}
        singlePage
        readingTimeMin={this.readingTimeMin}
        publication={this.publication}
      />
    )
  }
}

export default PageDynamic
