import React from 'react'
import PT from 'prop-types'
import { isEmpty } from 'ramda'

import { html2jsx } from 'Lib/parser'

const DynamicContent = ({ content }) => {
  if (!content || isEmpty(content)) {
    return null
  }

  return (
    <div className='dynamicContent d-none d-xl-block'>
      {html2jsx(content)}
    </div>
  )
}

DynamicContent.propTypes = {
  content: PT.string
}

export default DynamicContent
