import React, { useState } from 'react'
import Spinner from 'Components/spinner'
import PT from 'prop-types'

const PasswordEditor = ({ onSubmit, onCancel, isLoading }) => {
  const [password, setPassword] = useState('')
  const [passwordVerification, setPasswordVerification] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const submit = () => {
    if (!password) {
      setErrorMsg('Password cannot be empty')
      return
    }

    if (password !== passwordVerification) {
      setErrorMsg('Password fields must match')
      return
    }

    onSubmit(password)
  }

  return (
    <form className='passwordEditor' onSubmit={submit}>
      <h5>PASSWORD</h5>

      <div className='form-group'>
        <input
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='New Password'
        />
      </div>

      <div className='form-group'>
        <input
          type='password'
          value={passwordVerification}
          onChange={(e) => setPasswordVerification(e.target.value)}
          placeholder='Confirm New Password'
        />
      </div>

      {errorMsg && (
        <div className='form-group'>
          <div className='error'>{errorMsg}</div>
        </div>
      )}

      <button type='button' className='btn btn-success' onClick={submit} disabled={isLoading}>
        {!isLoading && <span>Save</span>}
        {isLoading && <Spinner />}
      </button>

      <button type='button' className='btn btn-light' onClick={onCancel} disabled={isLoading}>
        <span>Cancel</span>
      </button>
    </form>
  )
}

PasswordEditor.propTypes = {
  onSubmit: PT.func,
  onCancel: PT.func,
  isLoading: PT.bool
}

export default PasswordEditor
