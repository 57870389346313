import React, { useState, useEffect, useRef } from 'react'
import PT from 'prop-types'
import {
  ascend,
  compose,
  concat,
  converge,
  filter,
  isEmpty,
  map,
  not,
  prop,
  sortWith,
  addIndex,
  pluck,
  isNil,
  equals,
  includes,
  find,
  propEq
} from 'ramda'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { useStore } from 'App'
import { reorder } from 'Lib/purefunctions'
import PubRowView from './pubRowView'

const mapIndexed = addIndex(map)
const sortByNum = sortWith([ascend(prop('productDisplayOrder'))])
const takeUnordered = filter(compose(
  isEmpty,
  prop('productDisplayOrder')
))
const takeOrdered = filter(compose(
  not,
  isEmpty,
  prop('productDisplayOrder')
))
const sortPubViewList = converge(concat, [
  compose(
    sortByNum,
    takeOrdered
  ),
  takeUnordered
])
const generateOrderList = (pubViewList) => map((item) => ({
  id: item.publicationCode,
  item
}))(pubViewList)
const mergeOrder = (stored, calculated) => {
  if (isEmpty(stored) || isNil(stored) || isEmpty(calculated)) {
    return calculated
  }

  // Calculated holds source of truth as far as the availability of publications is concerned
  // Store holds the previously stored order - may not necessarily reflect all publications

  const calculatedCodes = pluck('id', calculated)
  if (equals(stored, calculatedCodes)) {
    return calculated
  }

  // Get the list of new items from calculated
  // old line
  // is missing map
  // const newFromCalculated = reject((c) => compose(includes(c, stored), prop('id')))(calculated)
  const newFromCalculated = map((s) => find(propEq('id', s), calculated), filter(c => not(includes(c, stored)), calculatedCodes))
  // Create a new list based on the cleaned stored
  const reorderedByStored = compose(
    map((s) => find(propEq('id', s), calculated)),
    filter((s) => includes(s, calculatedCodes)) // Throw away no-longer valid stored codes
  )(stored)

  // Concat the two list, new at the top
  return concat(newFromCalculated, reorderedByStored)
}

function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const PubRowViewList = ({
  value, rawPaidTopN, freeTopN, newItems
}) => {
  const store = useStore()
  const [order, setOrder] = useState([])
  const prevValue = usePrevious(value)
  const wpSiteName = store.mst.publication.getWordpressSiteNameByCode(value[0].publicationCode)

  const updatePrefs = action(async (code, list) => {
    if (!wpSiteName) {
      return
    }
    await store.user.updateLrgPubOrder(wpSiteName, list)
  })

  // noinspection JSIgnoredPromiseFromCall
  const onDragEnd = (result) => {
    const { destination, source } = result

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const res = reorder(order, source.index, destination.index)
    const after = pluck('id', res)
    // noinspection JSIgnoredPromiseFromCall
    updatePrefs('test', after)

    setOrder(res)
  }

  const getDroppableStyle = (isDraggingOver) => ({
    border: isDraggingOver ? '2px dashed gray' : 'inherit',
    padding: isDraggingOver ? '20px' : 'inherit'
  })

  useDeepCompareEffect(() => {
    if (!store.user.lrgPubOrder || !wpSiteName || !value) {
      return
    }

    const calculated = generateOrderList(sortPubViewList(value))
    const stored = prop(wpSiteName.toUpperCase(), store.user.lrgPubOrder)
    const final = mergeOrder(stored, calculated)

    setOrder(final)
  }, [store.user.lrgPubOrder, value, prevValue, wpSiteName])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`pub-${wpSiteName}`} type='PUB'>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} style={getDroppableStyle(snapshot.isDraggingOver)}>
            {mapIndexed(({ id, item }, idx) => (
              <PubRowView
                key={id}
                publication={item}
                rawPaidTopN={rawPaidTopN}
                freeTopN={freeTopN}
                newItems={newItems}
                idx={idx}
              />
            ))(order)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

PubRowViewList.propTypes = {
  value: PT.array,
  rawPaidTopN: PT.array,
  freeTopN: PT.array,
  newItems: PT.array
}

export default observer(PubRowViewList)
