/* This is a hack to get the audio embedded in the content to play */

import { last, match, test } from 'ramda'

export default function jwHack () {
  const scripts = document.getElementsByTagName('script')
  let audioFile

  Array.prototype.forEach.call(scripts, (script) => {
    if (test(/playerInstance/, script.innerText) && test(/jwplayer/, script.innerText)) {
      audioFile = last(match(/(https.*)"/, script.innerText))
    }
  })

  setTimeout(() => {
    if (window.jwplayer && audioFile) {
      window.jwplayer.key = 'VoB/xbsJxu9Ev/skQDribd3X7KHSEMI1bOUfvH/I6kw='

      const playerInstance = window.jwplayer('vidBox')
      playerInstance.setup({
        file: audioFile,
        width: 550,
        height: 40
      })
    }
  }, 2000)
}
