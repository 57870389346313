import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  head, last, splitAt
} from 'ramda'
import { Link } from 'react-router-dom'
import uniqueString from 'Lib/unique-string'
import { isNew } from 'Lib/purefunctions'
import classnames from 'classnames'

import Spinner from 'Components/spinner'
import Indicator from 'Components/newArticleIndicator'

export default class SectionalIssues extends Component {
  static propTypes = {
    title: PropTypes.string,
    issues: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      date: PropTypes.string,
      snippet: PropTypes.string,
      url: PropTypes.string,
      readingTimeMin: PropTypes.number
    })),
    isChild: PropTypes.bool,
    alternativeMode: PropTypes.bool,
    newItems: PropTypes.array
  };

  render () {
    const {
      title,
      issues,
      isChild,
      alternativeMode,
      newItems
    } = this.props

    const allItems = splitAt(4, issues)
    const leftItems = head(allItems)
    const rightItems = last(allItems)
    const isInNew = isNew(newItems || [])

    return (
      <div
        data-name='pubTour-step2'
        className={classnames('subscriber-issues',
          { isChildPublication: isChild, isAlternativeMode: alternativeMode }, 'sectional-issues')}
      >
        {!alternativeMode && !isChild && (
          <div>
            <h5 className='text-muted'>Issues & Updates</h5>
            <hr />
          </div>
        )}
        {!alternativeMode && isChild && (
          <div className='title'>
            <h4>{title}</h4>
            <hr />
          </div>
        )}

        <div className='row itemgroup'>
          <div className='col-md-7 col-sm-7 leftside'>
            {!leftItems && <Spinner />}
            {leftItems.map((item, index) => (
              <div className='issue' key={uniqueString()}>
                <h5>
                  {isInNew(item.slug) && <Indicator />}
                  <Link to={item.url}>
                    {item.title}
                  </Link>
                </h5>
                <div className='entry-meta text-muted'>
                  {item.readingTimeMin && (
                    <>
                      <span> {item.readingTimeMin} min read</span>
                    </>
                  )}
                  {(index !== leftItems.length - 1) &&
                    <hr />}
                </div>
              </div>
            ))}
          </div>

          <div className='col-md-5 col-sm-5 rightside'>
            {rightItems.map((item, index) => (
              <div className='issue' key={uniqueString()}>
                <h5>
                  {isInNew(item.slug) && <Indicator />}
                  <Link to={item.url}>
                    {item.title}
                  </Link>
                </h5>
                <div className='entry-meta text-muted'>
                  {item.readingTimeMin && (
                    <>
                      <span> {item.readingTimeMin} min read</span>
                    </>
                  )}
                  {(index !== rightItems.length - 1) &&
                    <hr />}
                </div>
              </div>
            ))}
          </div>

        </div>

        <div className='actionWrapper' />
      </div>
    )
  }
}
