import React, { useState, useEffect } from 'react'
import PT from 'prop-types'

import { useStore } from 'App'
// import { prop } from 'ramda';

import Spinner from 'Components/spinner'
import { getPublicationSchedule } from 'Api/endpoints/user'

import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import { equals } from 'ramda'

const Loading = (
  <div className='loading'>
    <Spinner />
  </div>
)

const Row = ({ item }) => (
  <tr>
    <td className='title'>{item.title}</td>
    <td className='caption'>{item.caption}</td>
    <td className='current'>{item.currentIssue}</td>
    <td className='next'>{item.nextIssue}</td>
  </tr>
)

Row.propTypes = {
  item: PT.shape({
    title: PT.string,
    caption: PT.string,
    currentIssue: PT.string,
    nextIssue: PT.string
  })
}

const formatSchedule = (schedule) => (
  <section>
    <table className='scheduleTable'>
      <thead>
        <tr>
          <th className='title'>Publication</th>
          <th className='caption'>Schedule</th>
          <th className='current'>Current Issue</th>
          <th className='next'>Next Issue</th>
        </tr>
      </thead>

      <tbody>
        {schedule.map((item) => <Row item={item} key={item.title} />)}
      </tbody>
    </table>
  </section>
)

const PublicationSchedule = () => {
  const [content, setContent] = useState(Loading)
  const store = useStore()
  const { sitecode } = store.site

  useEffect(() => {
    if (!sitecode) {
      return
    }

    const param = sitecode.toUpperCase() === 'LG' ? 'ALL' : sitecode.toUpperCase()

    getPublicationSchedule(param)
      .then(formatSchedule)
      .then(setContent)
  }, [sitecode])

  const contentJsx = (
    <>
      <section className='schedule'>
        {content}
      </section>
      {equals('JC', sitecode) && (
        <aside className='note'>
          <p>
            <strong>Note</strong>
            : Every month, we commit to providing the same number of issues and trade recommendations as what appears
            on the calendar. But keep in mind, trade recommendations
            <strong> may be issued early if the opportunity cannot wait</strong>,
            or later if conditions aren't yet in the right place. Rest assured, this approach is based on
            providing you the best possible entry points for each of our trade recommendations.
          </p>
        </aside>
      )}
    </>
  )

  return (
    <TemplateSingle
      title='Publication Schedule'
      content={contentJsx}
      extraClass='__page_publication_schedule'
    />
  )
}

export default PublicationSchedule
