/* eslint-disable @typescript-eslint/no-unused-vars */
/* This is an issue with the linter not recognizing the use within the decorator */

import { computed, action, observable, makeObservable } from 'mobx'
import * as Sentry from '@sentry/browser'
import * as RNEA from 'fp-ts/ReadonlyNonEmptyArray'
import * as ROA from 'fp-ts/ReadonlyArray'
import * as O from 'fp-ts/Option'
import * as S from 'fp-ts/string'
import { pipe, constant } from 'fp-ts/lib/function'
import { function as SF } from 'fp-ts-std'

import { timeout } from 'Lib/purefunctions'
import { settings } from 'Api/endpoints/auth'
import Store from './index'

const { guard } = SF

// TODO Update once the Auth api has been typed.
interface IRemoteSettingsResp {
  value?: {
    siteData: IRemoteSettings
  }
  error?: string
}

interface IActive {
  enabled?: boolean
}

interface IMsgArea extends IActive {
  until?: string
}

interface IPopup {
  id: string
}

interface ICaptcha {
  beta: string
  live: string
}

export interface IPublicationMsgArea extends IMsgArea {
  code: string
  content: string
}

interface IAdZone {
  mySub: string
}

interface IMyAccount {
  externalUrl: string
}

interface IRemoteSettings {
  siteName?: string
  brandId?: string
  withTimestamp?: boolean
  messages?: {
    popup?: IPopup
    headMsg?: IMsgArea
    mySubsMsg?: IMsgArea
    loginMsg?: IMsgArea
    contactMsg?: IMsgArea
    maintenance?: IActive,
    publicationMsg?: IPublicationMsgArea[]
  }
  captchaKey?: ICaptcha
  adZones?: IAdZone
  myAccount?: IMyAccount
}

export default class Site {
  private readonly root: Store

  constructor (rootStore: Store) {
    makeObservable(this)

    this.root = rootStore
  }

  @observable remoteSettings: IRemoteSettings = {}

  @observable hasLoaded = false

  @computed get site (): string {
    return pipe(
      window.location.hostname,
      S.split('.'),
      RNEA.init,
      ROA.last,
      O.map(S.toLowerCase),
      O.getOrElse(() => '')
    )
  }

  @computed get isDev (): boolean {
    return pipe(
      window.location.hostname,
      S.split('.'),
      RNEA.last,
      S.toLowerCase,
      (s) => S.Eq.equals(s, 'local')
    )
  }

  @computed get siteId (): string {
    return `site-${this.site}`
  }

  @computed get sitecode (): string {
    const matchHostname = guard<string, string>([
      [S.includes('legacy'), constant('LG')],
      [S.includes('palm'), constant('PB')],
      [S.includes('rogueeconomics'), constant('RE')],
      [S.includes('brownstone'), constant('BR')],
      [S.includes('jeff'), constant('JC')],
      [S.includes('opportunistic'), constant('OT')],
      [S.includes('newparadigm'), constant('NP')],
      [S.includes('widemoatresearch'), constant('WM')],
      [S.includes('gulfportanalytics'), constant('GP')],
    ])(constant(''))

    console.log(pipe(
      window.location.hostname,
      matchHostname
    ))

    return pipe(
      window.location.hostname,
      matchHostname
    )
  }

  @computed get freeSitecode (): string {
    return `${this.sitecode}F`
  }

  @computed get searchSitecode (): string {
    if (this.sitecode === 'LG') {
      return 'ALL'
    }

    return this.sitecode
  }

  @computed get siteLocalPath (): string {
    switch (this.sitecode) {
      case 'LG':
        return 'Legacyresearch'
      case 'PB':
        return 'Palmbeachgroup'
      case 'RE':
        return 'Rogueeconomics'
      case 'BR':
        return 'Brownstone'
      case 'JC':
        return 'Jeffclarktrader'
      case 'OT':
        return 'Opportunistictrader'
      case 'NP':
        return 'Newparadigmresearch'
      case 'WM':
        return 'Widemoatresearch'
      case 'GP':
        return 'Gulfportanalytics'
      default:
        return ''
    }
  }

  @computed get siteName (): string {
    return this.siteDetails.siteName ?? ''
  }

  @computed get brandId (): string {
    return this.siteDetails.brandId ?? ''
  }

  @computed get siteDetails (): IRemoteSettings {
    return this.remoteSettings
  }

  @computed get isMaintenance (): boolean {
    return this.siteDetails.messages?.maintenance?.enabled === true
  }

  @computed get isBeta (): boolean {
    const urlParts = /^(?:\w+:\/\/)?([^/]+)(.*)$/.exec(document.location.href)

    if (urlParts === null || urlParts.length < 1) {
      return false
    }

    return (
      urlParts[1].includes('beta') ||
      urlParts[1].includes('localhost')
    )
  }

  @computed get captchaKey (): string {
    const {
      siteDetails,
      isBeta
    } = this

    if (isBeta) {
      return siteDetails?.captchaKey?.beta ?? ''
    }

    return siteDetails?.captchaKey?.live ?? ''
  }

  @action.bound
  getSiteNameByCode (code: string): string {
    switch (code) {
      case 'LG':
      case 'LGF':
        return 'Legacy Research'
      case 'PB':
      case 'PBF':
        return 'Palm Beach Research Group'
      case 'RE':
      case 'REF':
        return 'Rogue Economics'
      case 'BR':
      case 'BRF':
        return 'Brownstone Research'
      case 'JC':
      case 'JCF':
        return 'Jeff Clark\'s Delta Report'
      case 'OT':
      case 'OTF':
        return 'The Opportunistic Trader'
      case 'NP':
      case 'NPF':
        return 'New Paradigm Research'
      case 'WM':
      case 'WMF':
        return 'Wide Moat Research'
      case 'GP':
      case 'GPF':
        return 'Gulfport Analytics'
      default:
        return 'N/A'
    }
  }

  @computed get withTimestamp (): boolean {
    return this.siteDetails?.withTimestamp === true
  }

  @action.bound
  async fetchSettings (): Promise<void> {
    const handleResponse = (resp: IRemoteSettingsResp): IRemoteSettingsResp => {
      const {
        value,
        error
      } = resp

      if (value?.siteData != null) {
        this.remoteSettings = value.siteData
      } else {
        console.error(error)
      }

      return resp
    }

    // First try
    const result1: IRemoteSettingsResp = await settings(this.sitecode)
      .then(handleResponse)

    if (result1.error != null) {
      // Sentry.captureMessage('Settings call failed on 1st try');

      await timeout(4 * 1000)
    } else {
      return
    }

    // Second try
    const result2: IRemoteSettingsResp = await settings(this.sitecode)
      .then(handleResponse)

    if (result2.error != null) {
      Sentry.captureMessage('Settings call failed on 2nd try')

      window.location.href = '/maintenance.html'
    }
  }

  @action.bound
  getFaviconName (withNotification = false): string {
    switch (this.sitecode) {
      case 'LG':
        return withNotification ? 'favicon-lg-red' : 'favicon-lg'
      case 'PB':
        return withNotification ? 'favicon-pb-red' : 'favicon-pb'
      case 'RE':
        return withNotification ? 'favicon-re-red' : 'favicon-re'
      case 'BR':
        return withNotification ? 'favicon-br-red' : 'favicon-br'
      case 'JC':
        return withNotification ? 'favicon-jc-red' : 'favicon-jc'
      case 'OT':
        return withNotification ? 'favicon-ot-red' : 'favicon-ot'
      case 'NP':
        return withNotification ? 'favicon-np-red' : 'favicon-np'
      case 'WM':
        return withNotification ? 'favicon-wm-red' : 'favicon-wm'
      default:
        return ''
    }
  }
}
