/* eslint-disable react/no-unescaped-entities */
import React, {
  useState, useEffect, useMemo, useCallback
} from 'react'
import PT from 'prop-types'
import Spinner from 'Components/spinner'
import {
  map,
  isEmpty,
  propOr,
  propEq,
  filter,
  compose,
  not,
  prop,
  isNil,
  evolve,
  length,
  always,
  propSatisfies,
  pick,
  assoc,
  head,
  pluck, uniq, sortBy, find
} from 'ramda'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { observer } from 'mobx-react-lite'

import Link from 'Components/link'
import { useStore } from 'App'
import Labeler from './labeler'
import Toggler from './toggler'
import MsgModal from './msgModal'

const getPublicationsWithParent = compose(
  filter(
    compose(
      not,
      isEmpty,
      prop('bundleParentPublication')
    )
  ),
  propOr([], 'childSubscriptions')
)

const getPublicationsWithoutParent = compose(
  filter(
    compose(
      isEmpty,
      prop('bundleParentPublication')
    )
  ),
  propOr([], 'childSubscriptions')
)

const countSelected = compose(
  length,
  filter(propSatisfies((v) => v === true, 'selected'))
)

const useBundle = (bundle) => {
  const [state, setState] = useState(bundle)

  const updateStatus = (code, status) => {
    if (isNil(code) || isNil(status)) {
      return
    }

    const update = evolve({
      childSubscriptions: map((pub) => {
        if (propEq('publicationCode', code, pub)) {
          return evolve({
            selected: always(status)
          })(pub)
        }

        return pub
      })
    })
    setState((s) => update(s))
  }

  return [state, setState, updateStatus]
}

const formatBundleStateForSubmission = (bs) => {
  const trimmedBundle = compose(
    evolve({
      childSubscriptions: map(pick(['publicationCode', 'selected']))
    }),
    pick(['status', 'selected', 'publicationCode', 'level', 'childSubscriptions'])
  )(bs)

  return assoc('source', 'Web', trimmedBundle)
}

const SelectManager = ({
  bundle, validate, update, refresh
}) => {
  const [infomessage, setInfomessage] = useState(null)
  const [validationMessage, setValidationMessage] = useState(null)
  const [validationAllowConfirm, setValidationAllowConfirm] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState(null)
  const [bundleState, setBundleState, updateBundleState] = useBundle(bundle)
  const [userToggle, setUserToggle] = useState([])
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [readyToValidate, setReadyToValidate] = useState(false)
  const [readyToUpdate, setReadyToUpdate] = useState(false)
  const store = useStore()

  const bonusPublications = useMemo(() => getPublicationsWithParent(bundle), [bundle])
  const onToggle = useCallback((code, status) => setUserToggle({
    code,
    status
  }), [])
  const handleSubmitStep1 = useCallback(() => setReadyToValidate(true), [])
  const firstLevelBundleExtract = useMemo(() => getPublicationsWithoutParent(bundle), [bundle])
  const firstLevelBundleExtractSorted = sortBy(prop('product_display_order'), firstLevelBundleExtract)
  const siteCodes = compose(uniq, map((sub) => prop('wordpressSiteName', sub)))(firstLevelBundleExtract)

  // Reset bundleState when the props change
  useEffect(() => {
    setBundleState(bundle)
  }, [bundle, setBundleState])

  // Update bundleState on user toggle
  useDeepCompareEffect(() => {
    const publication = head(filter(propEq('publicationCode', userToggle.code), getPublicationsWithoutParent(bundle)))

    if (!publication) {
      return
    }

    // Update the main publication.
    updateBundleState(userToggle.code, userToggle.status)

    // Get the child pubs and update their status as well.
    const bundled = compose(
      pluck('pubCode'),
      propOr([], 'bundledPublications'),
      find(propEq('publicationCode', userToggle.code)),
      propOr([], 'childSubscriptions')
    )(bundleState)
    bundled.map((code) => updateBundleState(code, userToggle.status))
  }, [userToggle, bundle])

  // Enforce selection limits if necessary
  useEffect(() => {
    if (bundle.babypubSwappingChargeable &&
      countSelected(getPublicationsWithoutParent(bundleState)) > bundle.maxChildPubsAllowed) {
      // eslint-disable-next-line max-len
      setInfomessage(`You have selected more than ${bundle.maxChildPubsAllowed} subscriptions. Please adjust your selections`)
      setDisableSubmit(true)
    } else {
      setInfomessage(false)
      setDisableSubmit(false)
    }
  }, [bundle.babypubSwappingChargeable, bundle.maxChildPubsAllowed, bundleState])

  // Perform validation when user asks
  useEffect(() => {
    if (!readyToValidate) {
      return
    }

    const performAction = async () => {
      const sendingObject = formatBundleStateForSubmission(bundleState)

      setDisableSubmit(true)

      const { value, error } = await validate(sendingObject)
      setReadyToValidate(false)

      if (error) {
        setValidationMessage(error.response.data.message)
        setDisableSubmit(false)
        return
      }

      const {
        numberOfBabyPubChanges, babypubSwappingChargeable, babypubSwappingServiceFeePerChange, statusCode, message
      } = value
      const amount = babypubSwappingChargeable ? babypubSwappingServiceFeePerChange * numberOfBabyPubChanges : 0

      setValidationAllowConfirm(false)

      if (statusCode === '200') {
        setValidationAllowConfirm(true)
        setValidationMessage(`For these changes you will be charged $${amount}. Do you want to continue?`)
      } else if (Math.floor(parseInt(statusCode, 10) / 1000) === 4) {
        const msg = (
          <div>
            <p>
              <b>An error has occurred:</b>   <br />{message} <br /> You have not been charged. <br />
            </p>
            <p>
              To contact support, please click <Link to='/contact/'>here</Link>
            </p>
          </div>
        )
        setValidationMessage(msg)
      } else if (Math.floor(parseInt(statusCode, 10) / 1000) === 5) {
        const msg = (
          <div>
            <p><b>An error has occurred:</b>   <br />{message}</p>
            <p>
              A support case has been created, our support team will contact
              you. <br />
            </p>
            <p>
              To contact support, please click <Link to='/contact/'>here</Link>
            </p>
          </div>
        )
        setValidationMessage(msg)
      } else {
        const msg = (
          <div>
            <p>
              <b>An error has occurred:</b>   <br />${message}
            </p>
            <p>
              To contact support, please click <Link to='/contact/'>here</Link>
            </p>
          </div>
        )
        setValidationMessage(msg)
      }

      setDisableSubmit(false)
    }

    performAction()
  }, [readyToValidate, bundleState, validate])

  // Perform update when conditions are met
  useEffect(() => {
    if (!readyToUpdate) {
      return
    }

    const performAction = async () => {
      const sendingObject = formatBundleStateForSubmission(bundleState)

      setDisableSubmit(true)

      const { value, error } = await update(sendingObject)

      setReadyToUpdate(false)

      if (error) {
        setConfirmationMessage(error.response.data.message)
        setDisableSubmit(false)
        return
      }

      const {
        numberOfBabyPubChanges, babypubSwappingChargeable, babypubSwappingServiceFeePerChange, statusCode, message
      } = value
      const amount = babypubSwappingChargeable ? babypubSwappingServiceFeePerChange * numberOfBabyPubChanges : 0

      setValidationAllowConfirm(false)
      if (statusCode === '200') {
        if (babypubSwappingChargeable === false) {
          setConfirmationMessage('Your selections have been updated. You will not be charged for the updates.')
        } else {
          setValidationMessage(`Your selections have been updated. You will see a charge for $${amount}.`)
        }
        refresh()
      } else if (Math.floor(parseInt(statusCode, 10) / 1000) === 4) {
        const msg = (
          <div>
            <p>
              <b> An error has occurred: </b> <br /> {message} <br /> You have not been
              charged.
            </p>
            <p>
              To contact support, please click <Link to='/contact/'>here</Link>
            </p>
          </div>
        )
        setConfirmationMessage(msg)
      } else if (Math.floor(parseInt(statusCode, 10) / 1000) === 5) {
        const msg = (
          <div>
            <p>
              <b> An error has occurred: </b> <br /> {message}
            </p>
            <p>
              A support case has been created, our support team will contact
              you.
            </p>
            <p>
              To contact support, please click <Link to='/contact/'>here</Link>
            </p>
          </div>
        )
        setConfirmationMessage(msg)
      } else {
        const msg = (
          <div>
            <p>
              <b> An error has occurred: </b> <br /> {message}
            </p>
            <p>
              To contact support, please click <Link to='/contact/'>here</Link>
            </p>
          </div>
        )
        setValidationMessage(msg)
      }

      setDisableSubmit(false)
    }

    performAction()
  }, [bundleState, confirmationMessage, readyToUpdate, refresh, update])

  if (isEmpty(bundle)) {
    return <Spinner />
  }

  return (
    <div id='selectManager'>
      <header>
        <h1>
          Manage Select Subscriptions
        </h1>
        <hr />
        <p>As a <i>Legacy Select</i> subscriber, you get access to up to five Legacy Select subscriptions at a time.</p>
        <p>Choose your <i>Select</i> subscriptions below. Please note: You can only access five subscriptions at one time. Once you make your selections, click <b> "Update Select Subscriptions."</b></p>
      </header>

      <table id='subscriptions'>
        <thead>
          <tr>
            <th>Subscriptions</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='subHeader' colSpan={2}>
              {bundle.name}
            </td>
          </tr>
          {map((site) => {
            const siteName = site === 'BP' ? 'Additional MySubscriptions' : store.site.getSiteNameByCode(site)
            return (
              <tr key={site}>
                <td className='babySubSection' colSpan={2}>
                  <span className='sectionTitle'>{siteName}</span>
                  {map((sub) => {
                    if (sub.wordpressSiteName === site) {
                      return (
                        <div key={sub.code} className='subItem'>
                          <div className='subTitle'>
                            <Labeler publication={sub} list={bonusPublications} />
                          </div>
                          <div className='subToggle'>
                            <Toggler publication={sub} onChange={onToggle} />
                          </div>
                        </div>
                      )
                    }
                    return ('')
                  })(firstLevelBundleExtractSorted)}
                </td>
              </tr>
            )
          })(siteCodes)}
        </tbody>
      </table>

      <section id='actions'>
        {infomessage && <div className='message'>{infomessage}</div>}

        <div className='buttons'>
          <Link className='btn btn-light go-back' to='/my-account'>
            Back to your Subscriptions
          </Link>
          <button
            className='btn btn-success'
            disabled={disableSubmit}
            onClick={handleSubmitStep1}
          >
            {disableSubmit && <Spinner />}
            {!disableSubmit && <span>Update Select Subscriptions</span>}
          </button>
        </div>
      </section>

      {validationMessage && validationAllowConfirm && (
        <MsgModal
          content={validationMessage}
          onClose={() => setValidationMessage(null)}
          onConfirm={() => {
            setReadyToUpdate(true)
            setValidationMessage(null)
          }}
        />
      )}
      {validationMessage && !validationAllowConfirm && (
        <MsgModal
          content={validationMessage}
          onClose={() => setValidationMessage(null)}
        />
      )}
      {confirmationMessage && (
        <MsgModal
          content={confirmationMessage}
          onClose={() => setConfirmationMessage(null)}
        />
      )}
    </div>
  )
}

const publicationShape = PT.shape({
  selected: PT.bool,
  name: PT.string,
  publicationCode: PT.string,
  bundledPublications: PT.arrayOf(PT.shape({
    publicationCode: PT.string
  }))
})

const bundleShape = PT.shape({
  childSubscriptions: PT.arrayOf(publicationShape),
  level: PT.string,
  maxChildPubsAllowed: PT.number,
  babypubSwappingChargeable: PT.bool,
  name: PT.string
})

Toggler.propTypes = {
  publication: publicationShape.isRequired,
  onChange: PT.func.isRequired
}

SelectManager.propTypes = {
  bundle: bundleShape,
  validate: PT.func,
  update: PT.func,
  refresh: PT.func
}

export default observer(SelectManager)
