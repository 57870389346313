import { apply, curry, flatten, map } from 'ramda'
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import React from 'react'

const generateProductRedirects = curry((original, redirect) => [
  /* Publication Page */
  <Route
    key={`/${redirect}`}
    exact
    path={`/${redirect}`}
    render={() => (
      <Redirect to={`/${original}`} />
    )}
  />,
  /* Publication Issues & Updates Archive */
  <Route
    key={`/${redirect}/archives`}
    exact
    path={`/${redirect}/archives`}
    render={() => (
      <Redirect to={`/${original}/archives`} />
    )}
  />,
  /* Publication Archive Single Newsletter Type */
  <Route
    key={`/${redirect}/archives/:newslettertype`}
    exact
    path={`/${redirect}/archives/:newslettertype`}
    render={(props) => (
      <Redirect to={`/${original}/archives/${props.match.params.newslettertype}`} />
    )}
  />,
  /* Publication Issues & Updates Archive Filtered By Year/Month */
  <Route
    key={`/${redirect}/archives/:year/:month`}
    exact
    path={`/${redirect}/archives/:year/:month`}
    render={(props) => (
      <Redirect to={`/${original}/archives/${props.match.params.year}/${props.match.params.month}`} />
    )}
  />,
  /* Publication Archive Single Newsletter Type Filtered By Year/Month */
  <Route
    key={`/${redirect}/archives/:newslettertype/:year/:month`}
    exact
    path={`/${redirect}/archives/:newslettertype/:year/:month`}
    render={(props) => (
      <Redirect to={`/${original}/archives/${props.match.params.year}/${props.match.params.month}`} />
    )}
  />,
  /* Publication Special Reports Archive */
  <Route
    key={`/${redirect}/special-reports`}
    exact
    path={`/${redirect}/special-reports`}
    render={() => (
      <Redirect to={`/${original}/special-reports`} />
    )}
  />,
  /* Publication Special Reports Archive Filtered By Year/Month */
  <Route
    key={`/${redirect}/special-reports/:year/:month`}
    exact
    path={`/${redirect}/special-reports/:year/:month`}
    render={(props) => (
      <Redirect to={`/${original}/special-reports/${props.match.params.year}/${props.match.params.month}`} />
    )}
  />,
  /* Portfolio */
  <Route
    key={`/${redirect}/portfolio`}
    exact
    path={`/${redirect}/portfolio`}
    render={() => (
      <Redirect to={`/${original}/portfolio`} />
    )}
  />,
  /* Dynamic Single Issue/Update Posts */
  <Route
    key={`/${redirect}/:item`}
    exact
    path={`/${redirect}/:item`}
    render={(props) => (
      <Redirect to={`/${original}/${props.match.params.item}`} />
    )}
  />,
  /* Dynamic Single Special Report Posts */
  <Route
    key={`/${redirect}/special-reports/:item`}
    exact
    path={`/${redirect}/special-reports/:item`}
    render={(props) => (
      <Redirect to={`/${original}/special-reports/${props.match.params.item}`} />
    )}
  />
])

const redirectMap = ([
  ['dealbook', 'palm-beach-infinity']
])
const productRedirects = flatten(map(apply(generateProductRedirects))(redirectMap))

export default productRedirects
