import { curry } from 'ramda'

import { upstreamAccount as API } from '../index'

async function subscription (siteId) {
  try {
    const res = await API.get(`/auth/${siteId}/account/subscription`)

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function getUserInfo (siteId) {
  try {
    const res = await API.get(`/auth/${siteId}/account/sfdcaddress `)

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function updateUserInfo (siteId, {
  firstName, lastName, phoneNumber, street, state, city, postalCode, country
}) {
  try {
    const res = await API.post(`/auth/${siteId}/account/update/sfdcaddress `, {
      firstName,
      lastName,
      phoneNumber,
      street,
      state,
      city,
      postalCode,
      country
    })

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function updatePassword (siteId, username, password) {
  try {
    const res = await API.post(`/auth/${siteId}/account/update/password`, {
      username,
      password,
      appcode: 'webapp'
    })

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function getSelectData (siteId, pubcode, level) {
  try {
    const res = await API.get(`/auth/${siteId}/account/select?pubcode=${pubcode}&level=${level}`)

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

const validateSelectData = curry(async (siteId, updateObject) => {
  try {
    const res = await API.put(`/auth/${siteId}/account/verify/select`, updateObject)

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: e
    }
  }
})

const updateSelectData = curry(async (siteId, updateObject) => {
  try {
    const res = await API.put(`/auth/${siteId}/account/update/select`, updateObject)

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: e
    }
  }
})

export {
  subscription,
  getUserInfo,
  updateUserInfo,
  updatePassword,
  getSelectData,
  validateSelectData,
  updateSelectData
}
