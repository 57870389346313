// import * as E from 'fp-ts/lib/Either'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

import { upstreamAlert as API } from '../index'
import { handleError, IError } from '../error'
import { AxiosError, AxiosResponse } from 'axios'

export interface NotificationsResponse {
  processedTime: string
  refreshRequired: boolean
  notificationData: NotificationData
}

export interface NotificationData {
  customerKey: number
  lastModified: string
  readNotifications: string[]
  notifications: EditorialNotification[]
}

export interface EditorialNotification {
  _id: string
  publicationId: string
  wpKey: number
  createdAt: string
  newsletterType: string
  newsletterTypeId: string
  notificationType: string
  postType: string
  siteCode: string
  slug: string
  status: string
  wordpressSiteName?: string
  isRead: boolean
}

interface StatusResponse {
  status: string
}

const convertNotificationData = (data: NotificationsResponse): EditorialNotification[] => {
  return data.notificationData.notifications.map((notification => {
    notification.isRead = data.notificationData.readNotifications.includes(notification._id);

    return notification
  }))
}

export function getNotifications (sitecode: string): TE.TaskEither<IError, EditorialNotification[]> {
  return pipe(
    TE.tryCatch(
      async () => await API.get(`/notification/${sitecode}/all`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp: AxiosResponse<NotificationsResponse>) => resp.data),
    TE.chain(data => TE.right(convertNotificationData(data)))
  )
}

export function markNotificationRead(sitecode: string, id: string): TE.TaskEither<IError, string> {
  return pipe(
    TE.tryCatch(
      async () => await API.patch(`/notification/${sitecode}/isread/${id}`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp: AxiosResponse<StatusResponse>) => resp.data.status)
  )
}

export function markAllNotificationsRead(sitecode: string): TE.TaskEither<IError, string> {
  return pipe(
    TE.tryCatch(
      async () => await API.patch(`/notification/${sitecode}/allread`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp: AxiosResponse<StatusResponse>) => resp.data.status)
  )
}
