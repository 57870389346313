import React, { Component } from 'react'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { computed, makeObservable } from 'mobx'
import {
  map, toPairs, head, values, replace
} from 'ramda'
import { Link } from 'react-router-dom'

import uniqueString from 'Lib/unique-string'
import TemplateSingle from 'Containers/Sites/Base/Templates/single'

@inject('store')
@observer
class Archive extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject
  };

  constructor (props) {
    super(props)
    makeObservable(this)
  }

  @computed get content () {
    const seed = toPairs(map((group) => (
      <div key={uniqueString()}>
        {
          map(
            (item) => (
              <li className='boxedItem' key={uniqueString()}>
                <Link to={`/${item.code}/portfolio`}>{replace('&amp;', '&', item.title)}</Link>
              </li>
            )
          )(values(group))
        }
      </div>
    ))(this.props.store.mst.publication.portfoliosBySite))

    return (
      <section className='portfolioArchiveContent'>
        {seed.map((item) => (
          <div key={uniqueString()}>
            <h4>{head(item)}</h4>
            <ul className='boxedItemsList'>
              {item[1]}
            </ul>
          </div>
        ))}
      </section>
    )
  }

  render () {
    return (
      <TemplateSingle
        title='Portfolios'
        content={this.content}
        extraClass='__portfolios'
      />
    )
  }
}

export default Archive
