import React, { useEffect, useState, createContext, useContext, FC } from 'react'
import { withRouter, useHistory } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import classnames from 'classnames'
import { usePrevious } from 'ahooks';
import * as O from 'fp-ts/lib/Option'

import { useStore } from 'App'
import Accordion from './Accordion'
import LessonCard from './LessonCard'
import CourseHeader from './CourseHeader'
import Spinner from 'Components/spinner2'
import { ILesson, IPublicationStore, ISection, ICourse } from 'Stores/mst'
import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import { NextInCourse } from '../../../../../Stores/mst/Views/course'

const emptyF = (): void => {
  // Do nothing
}

const useLoadCourses = (): [boolean] => {
  const store = useStore()
  const [courseListUpdated, setCourseListUpdated] = useState(false)

  // If there is no course info, update once
  useEffect(() => {
    if (store.mst.course.courses.length === 0 && !courseListUpdated) {
      store.mst.course.fetchCourses()()
        .then(() => setCourseListUpdated(true))
    } else {
      setCourseListUpdated(true)
    }
  }, [store.mst.course.courses, courseListUpdated])

  return [courseListUpdated]
}

type UseCourse = [O.Option<ICourse>, boolean, boolean]
const useCourse = (code: string, listReady: boolean): UseCourse => {
  const store = useStore()
  const [course, setCourse] = useState<O.Option<ICourse>>(O.none)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)

    if (!listReady) {
      setCourse(O.none)
      setLoading(true)
      setError(false)

      return
    }

    const course: O.Option<ICourse> = O.fromNullable(store.mst.course.findByCode(code))
    setCourse(course)

    O.match(
      () => {
          setLoading(false)
          setError(true)
      },
      (c: ICourse) => {
        store.mst.course.fetchCourseSections(c)
          .then(() => {
            setCourse(course)
            setLoading(false)
            setError(false)
          })
      }
    )(course)
  }, [listReady])

  return [course, loading, error]
}

interface UseActivePair {
  activeSection: ISection | undefined
  activeLesson: ILesson | undefined
  setActivePair: (section: ISection, lesson: ILesson) => void,
  setNextActive: () => void,
  setPrevActive: () => void
  checkNext: (section: ISection, lesson: ILesson) => boolean,
}

const useActivePair = (course: O.Option<ICourse>): UseActivePair => {
  const store = useStore()
  const [activeSection, setActiveSection] = useState<ISection>()
  const [activeLesson, setActiveLesson] = useState<ILesson>()

  const checkNext = (section: ISection, lesson: ILesson): boolean => {
    return O.match(
      () => {
        return false
      },
      (c: ICourse) => {
        const maybeNext = store.mst.course.nextInCourse(c.id, section.id, lesson.id)
        // console.log(maybeNext)

        return O.match(
          () => {
            return false
          },
          () => {
            return true
          }
        )(maybeNext)
      }
    )(course)
  }

  const setActivePair = (section: ISection, lesson: ILesson): void => {
    setActiveSection(section)
    setActiveLesson(lesson)
  }

  const setNextActive = (): void => {
    return O.match(
      emptyF,
      (c: ICourse) => {
        if (activeSection === undefined || activeLesson === undefined) {
          return
        }

        const maybeNext = store.mst.course.nextInCourse(c.id, activeSection.id, activeLesson.id)

        O.match(
          emptyF,
          (next: NextInCourse) => {
            const {
              section,
              lesson
            } = next

            setActiveSection(section)
            setActiveLesson(lesson)
          }
        )(maybeNext)
      }
    )(course)
  }

  const setPrevActive = (): void => {
    if (course === undefined || activeSection === undefined) {
      return
    }

    return O.match(
      emptyF,
      (c: ICourse) => {
        if (activeSection === undefined || activeLesson === undefined) {
          return
        }
        const {
          section,
          lesson
        } = store.mst.course.prevInCourse(c.id, activeSection.id, activeLesson.id)

        setActiveSection(section)
        setActiveLesson(lesson)
      }
    )(course)
  }

  return {
    activeSection,
    activeLesson,
    setActivePair,
    setNextActive,
    setPrevActive,
    checkNext
  }
}

const CourseContext = createContext<UseActivePair>({
  activeSection: undefined,
  activeLesson: undefined,
  setNextActive: emptyF,
  setPrevActive: emptyF,
  setActivePair: emptyF,
  checkNext: () => false
})

export const useCourseContext = (): UseActivePair => useContext(CourseContext)

interface RouteParams {
  code: string
}

interface ICourseComponent extends RouteComponentProps<RouteParams> {
  publication: IPublicationStore
}

const CourseComponent: FC<ICourseComponent> = ({
  match,
  publication
}): JSX.Element => {
  const { params: { code } } = match
  const [courseListLoaded] = useLoadCourses()
  const [mobileAccordionVisible, setMobileAccordionVisible] = useState(false)
  const [course, courseLoading, courseError] = useCourse(code, courseListLoaded)
  const {
    activeSection,
    activeLesson,
    setActivePair,
    setNextActive,
    setPrevActive,
    checkNext
  } = useActivePair(course)
  const previousLesson = usePrevious(activeLesson)
  const history = useHistory()

  useEffect(() => {
    if (activeLesson == null) {
      return
    }

    if (activeLesson !== previousLesson) {
      setMobileAccordionVisible(false)
    }
  }, [previousLesson, activeLesson])

  const contextValue: UseActivePair = {
    activeSection,
    activeLesson,
    setActivePair,
    setNextActive,
    setPrevActive,
    checkNext
  }

  const handleViewAccordionIntention = (): void => {
    setMobileAccordionVisible(true)
  }

  const handleHideAccordionIntention = (): void => {
    setMobileAccordionVisible(false)
  }

  if (!courseListLoaded || courseLoading) {
    return (
      <BaseLayout>
        <div className="course-container">

          <Spinner/>
        </div>
      </BaseLayout>
    )
  }

  if (courseError && !courseLoading) {
    history.push('/pageNotFound')

    return (
      <BaseLayout>
        <div className="course-container">

          <div>Course not found.</div>
        </div>
      </BaseLayout>
    )
  }

  return O.match(
    () => (
      <BaseLayout>
      <div className="course-container">

        <div>Unknown course.</div>
      </div>
    </BaseLayout>),
    (courseV: ICourse) => (
      <BaseLayout>
        <CourseContext.Provider value={contextValue}>
          <div className="course-container">
            <CourseHeader publication={publication} course={courseV}/>

            <div className="container page">
              <div className="row">
                <div className="course-lesson-bar d-none d-lg-block col-lg-3">
                  {courseV.sections.map(section => <Accordion section={section} key={section.id}/>)}
                </div>
                <div className="course-selector-utility mobile d-lg-none">
                  <header>
                    <div className={classnames('', { offstage: mobileAccordionVisible })}>
                      <button className="btn btn-light" onClick={handleViewAccordionIntention}>View Course Lessons</button>
                    </div>
                    <div className={classnames('', { offstage: !mobileAccordionVisible })}>
                      <button className="btn btn-light" onClick={handleHideAccordionIntention}>Hide Lessons List</button>
                    </div>
                  </header>
                  <main className={classnames('', { offstage: !mobileAccordionVisible })}>
                    <div className={classnames('course-lesson-bar mobile d-lg-none', { offstage: !mobileAccordionVisible })}>
                      {courseV.sections.map(section => <Accordion section={section} key={section.id}/>)}
                    </div>
                  </main>
                </div>

                <div className="course-window col-12 col-lg-9">
                  <LessonCard lesson={activeLesson} course={courseV} publication={publication}/>
                </div>
              </div>
            </div>
          </div>
        </CourseContext.Provider>
      </BaseLayout>
    )
  )(course)
}

export default withRouter(CourseComponent)
