/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react'
import PT from 'prop-types'
import { observer } from 'mobx-react-lite'
import {
  path, pathOr, map, pick, prop
} from 'ramda'
import { useStore } from 'App'

import Bio from './sidebarComponents/bio'
import Meta from './sidebarComponents/meta'
import DynamicContent from './sidebarComponents/dynamicContent'
import Ad from './sidebarComponents/ad'

const keyComponentMap = (values) => ({
  meta: <Meta key='meta' {...values.meta} />,
  bio: <Bio key='bio' bio={values.bio} />,
  dynamic: <DynamicContent key='dynamic' content={values.content} />,
  ad: <Ad key='ad' ad={values.ad} />
})

const SideBar = (props) => {
  const store = useStore()
  const [stack, setStack] = useState([])
  const [valueObj, setValueObj] = useState({})

  useEffect(() => {
    if (!store) {
      return
    }

    const publication = store.mst.publication.getByCode(props.publicationCode)
    console.log(pathOr([], ['settings', 'article-sidebar-stack'], publication))

    setStack(pathOr([], ['settings', 'article-sidebar-stack'], publication))
    setValueObj({
      meta: pick(['author', 'date', 'readingTimeMin'], props),
      bio: prop('bio', props.author),
      content: path(['settings', 'dynamicSidebarSection'], publication),
      ad: prop('ad', props)
    })
  }, [store, props])

  const components = keyComponentMap(valueObj)
  console.log(components)

  return (
    <div className='sidebar'>
      {map((key) => components[key])(stack)}
    </div>
  )
}

SideBar.propTypes = {
  author: PT.shape({
    title: PT.string,
    avatar: PT.string
  }),
  date: PT.string,
  readingTimeMin: PT.number,
  ad: PT.shape({
    imageURL: PT.string,
    redirectURL: PT.string,
    altText: PT.string
  }),
  publicationCode: PT.string
}

export default observer(SideBar)
