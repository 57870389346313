import React, { useState } from 'react'
import PT from 'prop-types'
import {
  isEmpty, compose, pluck, flatten, map, prop, sortBy, propOr, take, reverse, filter, includes, not
} from 'ramda'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { observer } from 'mobx-react'

import { useStore } from 'App'
import Spinner from 'Components/spinner'
import { safeFormatToDate, isNew } from 'Lib/purefunctions'
import Indicator from 'Components/newArticleIndicator'

const useItems = (raw, count) => {
  const store = useStore()

  const getPublicationTitle = (pubId) => propOr('', 'title', store.mst.publication.getById(pubId))
  const getPublicationCode = (pubId) => propOr('', 'code', store.mst.publication.getById(pubId))
  const getPublicationId = (code) => propOr('', '_id', store.mst.publication.getByCode(code))

  const { withTimestamp } = store.site
  const timeFormat = withTimestamp ? 'EEE, MMM dd hh:mm aaa' : 'EEE, MMM dd'

  const feedFilter = propOr([], 'feedFilter')(store.site.siteDetails)
  const rejectionMap = map(getPublicationId)(feedFilter)
  const unmatchedWithPubFilter = compose(not, isEmpty, prop('pubTitle'))

  return compose(
    take(count),
    reverse,
    filter(unmatchedWithPubFilter),
    sortBy(prop('createdAtGMT')),
    map((article) => ({
      link: `/${getPublicationCode(article.publication)}/${article.slug}`,
      title: article.title,
      pubTitle: getPublicationTitle(article.publication),
      date: safeFormatToDate(article.createdAtGMT, timeFormat),
      createdAtGMT: article.createdAtGMT,
      slug: article.slug,
      readingTimeMin: article.reading_time_min
    })),
    filter((article) => !includes(article.publication, rejectionMap)),
    flatten,
    pluck('days')
  )(raw)
}

const Table = ({ items, newItems }) => (
  <div className='contentArea'>
    {items && items.map((item) => (
      <div className='d-flex justify-content-between' key={`${item.slug}_${item.createdAtGMT}`}>
        <div className='pr-4'>
          <span className='inlineIndicatorWrapper'>
            {isNew(newItems || [])(item.slug) && <Indicator label='New' />}
          </span>
          <span>{item.pubTitle}: </span>
          <Link to={item.link}>{item.title}</Link>
        </div>
        <div className='d-none d-sm-block text-nowrap'>
          {item.date}
          {item.readingTimeMin && (
            <span className='text-muted'> • {item.readingTimeMin} min read</span>
          )}
        </div>
      </div>
    ))}
  </div>
)

Table.propTypes = {
  items: PT.arrayOf(PT.shape({
    title: PT.string,
    link: PT.string,
    pubTitle: PT.string,
    date: PT.string,
    slug: PT.string,
    readingTimeMin: PT.number
  })),
  newItems: PT.array
}

const Latest = ({ raw, newItems }) => {
  const [days, setDays] = useState(7)
  const items = useItems(raw, days)

  const handleChangeDays = (value) => {
    setDays(value)
  }

  if (isEmpty(raw)) {
    return <Spinner />
  }

  return (
    <section id='latestResearchFeed'>
      <header className='row'>
        <div className='col-6'>
          <h5 className='title'>Latest Research Feed</h5>
        </div>

        <div className='col-6 latestToggleWrapper'>
          <span>Results for </span>
          <button
            type='button'
            className={classnames('daysBtn', {
              active: days === 7
            })}
            onClick={() => handleChangeDays(7)}
          >
            7 days
          </button>
          <button
            type='button'
            className={classnames('daysBtn', {
              active: days === 14
            })}
            onClick={() => handleChangeDays(14)}
          >
            14 days
          </button>
        </div>

        <div className='col-12'>
          <div className='separator' />
        </div>
      </header>

      <main className='row'>
        <div className='col-12'>
          <Table items={items} newItems={newItems} />
        </div>
      </main>
    </section>

  )
}

Latest.propTypes = {
  raw: PT.arrayOf(PT.shape({
    title: PT.string,
    slug: PT.string,
    publication: PT.string,
    createdAtGMT: PT.string,
    reading_time_min: PT.number
  })),
  newItems: PT.array
}

export default observer(Latest)
