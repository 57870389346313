import React, { Component } from 'react'
import classnames from 'classnames'
import { test } from 'ramda'
import { Helmet } from 'react-helmet'
import PT from 'prop-types'

import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import injectPlayerScript from 'Lib/injectPlayer'
import BackNav from './components/backNav'
import ContentHeader from './components/contentHeader'
import Message from './components/message'
import MobileAd from './components/mobileAd'
import SideBar from './components/sideBar'
import Masthead from '../Components/masthead'

class TwoColumnView extends Component {
  constructor (props) {
    super(props)

    if (test(/brightcove/, props.content)) {
      this.shouldInjectPlayer = true
    }
  }

  componentDidMount () {
    injectPlayerScript()
  }

  render () {
    const {
      message,
      title,
      metaTitle,
      content,
      author,
      ad,
      date,
      extraClass,
      publicationCode,
      publicationTitle,
      mediaLink,
      readingTimeMin,
      article
    } = this.props

    const cssPubCode = `pr-${publicationCode}`
    const classNamesObj = {}

    classNamesObj[`${extraClass}`] = extraClass

    const common = (
      <div
        id='__template_two_column'
        className={classnames(cssPubCode, classNamesObj)}
      >
        <Helmet>
          <title>{metaTitle || title}</title>
        </Helmet>

        <Masthead />

        <div className='container mt-3'>
          <Message html={message} />

          <ContentHeader
            title={title}
            mediaLink={mediaLink}
            article={article}
          />

          <BackNav link={`/${publicationCode}/`} title={publicationTitle} />

          <div className='twoColumns_container centered'>
            <SideBar
              author={author}
              date={date}
              readingTimeMin={readingTimeMin}
              ad={ad !== 'no ad' ? ad : null}
              publicationCode={publicationCode}
            />

            <div className='content-styles col-12 col-xl-8 col-md-12'>
              {content}

              <MobileAd ad={ad} />
            </div>
          </div>

          <div className='contentEndWrapper'>
            <hr className='separator' />

            <BackNav link={`/${publicationCode}/`} title={publicationTitle} />
          </div>
        </div>
      </div>
    )

    return (
      <BaseLayout>
        {common}
      </BaseLayout>
    )
  }
}

TwoColumnView.propTypes = {
  message: PT.oneOfType([
    PT.string,
    PT.bool
  ]),
  title: PT.string,
  metaTitle: PT.string,
  content: PT.node,
  author: PT.shape({
    title: PT.string,
    avatar: PT.string
  }),
  ad: PT.oneOfType([
    PT.string,
    PT.object
  ]),
  date: PT.string,
  publicationCode: PT.string,
  publicationTitle: PT.string,
  extraClass: PT.string,
  mediaLink: PT.string,
  readingTimeMin: PT.number,
  article: PT.object
}

export default TwoColumnView
