import { Predicate } from 'fp-ts/lib/Predicate'
import { getParent } from 'mobx-state-tree'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/lib/function'

import { IBookmark, IBookmarkStore } from '../index'

const bookmark = (self: IBookmarkStore): IBookmarkStore => ({
  itemExists (slug: string, publication: string): boolean {
    const { items } = getParent<IBookmarkStore>(self)

    const matchSlug: Predicate<IBookmark> = (p) => p.slug === slug
    const matchPublication: Predicate<IBookmark> = (p) => p.publication === publication

    return pipe(
      items,
      A.filter(matchSlug),
      A.filter(matchPublication),
      A.isEmpty
    )
  }
})

export default bookmark
