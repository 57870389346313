import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ScrollBackToTop = () => {
  const [show, handleShow] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 1200) {
        handleShow(true)
      } else handleShow(false)
    })
    return () => {
      window.removeEventListener('scroll', {})
    }
  }, [])

  const backToTop = () => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }

  return (
    <div>
      {show && (
        <div className='backToTop text-center'>
          <button className='backToTop-btn k-button ' onClick={() => backToTop()}>
            <div className='d-none d-xl-block mr-1'>Top</div>
            <FontAwesomeIcon icon='chevron-up' />
          </button>
        </div>
      )}
    </div>
  )
}

export default ScrollBackToTop
