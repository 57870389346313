import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { computed, makeObservable } from 'mobx'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons'
import { far, faBookmark } from '@fortawesome/free-regular-svg-icons'

import { test } from 'ramda'
import { Helmet } from 'react-helmet'
import { html2jsx } from 'Lib/parser'

import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import injectPlayerScript from 'Lib/injectPlayer'
import Masthead from '../Components/masthead'

library.add(fas, far)

const StructWrapper = ({ children, md9 }) => {
  if (md9) {
    return (
      <div className='row'>
        <div className='col-md-9' style={{ margin: 'auto' }}>
          {children}
        </div>
      </div>
    )
  }

  return children
}

@inject('store')
@observer
class Single extends Component {
  image;

  static propTypes = {
    noLayout: PropTypes.bool,
    message: PropTypes.string,
    title: PropTypes.string,
    metaTitle: PropTypes.string,
    content: PropTypes.node,
    sidebar: PropTypes.node,
    author: PropTypes.string,
    avatar: PropTypes.string,
    // authorLink: PropTypes.string,
    date: PropTypes.string,
    nextPost: PropTypes.string,
    prevPost: PropTypes.string,
    publicationCode: PropTypes.string,
    publicationTitle: PropTypes.string,
    wideSidebar: PropTypes.bool,
    md9Wrapper: PropTypes.bool,
    forceMd12: PropTypes.bool,
    md8Title: PropTypes.bool,
    isPortfolio: PropTypes.bool,
    isEditors: PropTypes.bool,
    isProducts: PropTypes.bool,
    extraClass: PropTypes.string,
    mediaLink: PropTypes.string,
    readingTimeMin: PropTypes.number,
    isBookmarked: PropTypes.bool,
    onBookmarkClicked: PropTypes.func
  };

  // eslint-disable-next-line class-methods-use-this
  handlePrint () {
    window.print()

    return false
  }

  constructor (props) {
    super(props)
    makeObservable(this)
    this.state = {
    }

    if (test(/brightcove/, props.content)) {
      this.shouldInjectPlayer = true
    }
  }

  componentDidMount () {
    injectPlayerScript()
  }

  @computed get backComponent () {
    if (this.props.publicationCode && this.props.publicationTitle) {
      return (
        <div className='navigationWrapper'>
          <Link to={`/${this.props.publicationCode}/`}>
            <img
              alt='Back Arrow'
              src='//d2z5ewoj022g8u.cloudfront.net/site/img/icons/arrow.png'
            />
            {`Back to ${this.props.publicationTitle}`}
          </Link>
        </div>
      )
    }

    return <div />
  }

  render () {
    const {
      message,
      title,
      metaTitle,
      content,
      sidebar,
      author,
      avatar,
      mediaLink,
      date,
      nextPost,
      prevPost,
      noLayout,
      wideSidebar,
      md9Wrapper,
      forceMd12,
      md8Title,
      extraClass,
      isPortfolio,
      isEditors,
      isProducts,
      publicationCode,
      readingTimeMin,
      onBookmarkClicked,
      isBookmarked
    } = this.props

    const showPagination = nextPost || prevPost
    const cssPubCode = `pr-${publicationCode}`
    const classNamesObj = {}

    classNamesObj[`${extraClass}`] = extraClass

    const common = (
      <div
        id='__template_single'
        className={classnames(cssPubCode, classNamesObj)}
      >
        <Helmet>
          <title>{metaTitle || title}</title>
        </Helmet>

        <Masthead />

        <div className='container'>
          {message &&
          (
            <aside className='publicationMessage'>
              <div className='mt-4 mb-0 alert alert-warning text-center'>
                <p className='mb-0'>{html2jsx(message)}</p>
              </div>
            </aside>
          )}
          <div className='row headerWrapper'>
            <div
              className={classnames({
                'col-12': !md8Title,
                'col-lg-8': md8Title,
                centered: md8Title
              })}
            >
              {title && (<h1>{title}</h1>)}

              {author && (
                <div className={classnames('byline', { 'd-none': sidebar })}>
                  <div className='byline-meta'>
                    {avatar && <img className='avatar k-avatar-circle' src={avatar} alt={author} />}
                    <div className='ml-2'>
                      <span className='bold'> {author}</span>
                      <br />
                      <span className='text-muted'>
                        <span>{date} </span>
                        {readingTimeMin && (
                          <span> • {readingTimeMin} min read</span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='d-none d-md-block print'>
                    {onBookmarkClicked && (
                      <div style={{ display: 'inline-block' }}>
                        <button className='bookmarkBtn' onClick={onBookmarkClicked} style={{ background: 'none', border: 'none' }}> {/* CSS quirk fix */}
                          {!isBookmarked && <FontAwesomeIcon icon={faBookmark} size='lg' />}
                          {isBookmarked && <FontAwesomeIcon icon={faBookmarkSolid} size='lg' />}
                        </button>
                      </div>
                    )}
                    {mediaLink && (
                      <>
                        <button className='k-button ml-2'>
                          <Link to={mediaLink} target='_blank' className='no-format' download>Download
                            <FontAwesomeIcon icon='file-alt' className='ml-2' />
                          </Link>
                        </button>
                      </>
                    )}
                    <button className='k-button ml-2' rel='noopener noreferrer' onClick={this.handlePrint}> Print
                      <FontAwesomeIcon icon='print' className='ml-2' />
                    </button>
                  </div>
                </div>
              )}

              <hr className='separator' />
            </div>
          </div>

          <StructWrapper md9={md9Wrapper} md8>
            <div className='row'>
              <div
                className={classnames('contentWrapper centered', {
                  'col-md-12': sidebar || forceMd12,
                  'col-lg-8':
                    (sidebar && !wideSidebar) || (!sidebar && !isPortfolio),
                  'col-xl-9': sidebar && !wideSidebar,
                  'col-lg-6': sidebar && wideSidebar,
                  'col-xl-7': sidebar && wideSidebar,
                  'col-lg-10': isEditors || isProducts,
                  'col-12': isPortfolio
                })}
              >

                {content}

                {showPagination && (
                  <div className='pagination row'>
                    <div className='col-6'>
                      «<Link to={prevPost}>Previous Article</Link>
                    </div>
                    <div
                      className={classnames('col-6', 'rightSide', {
                        'd-none': !sidebar
                      })}
                    >
                      <Link to={nextPost}>Next Article »</Link>
                    </div>
                  </div>
                )}
              </div>

              {!wideSidebar && sidebar && (
                <div className='col-md-12 col-lg-4 col-xl-3'>
                  <div className='rightSidebar'>{sidebar}</div>
                </div>
              )}
              {wideSidebar && (
                <div className='col-md-12 col-lg-6 col-xl-5 rightSidebar wide'>
                  <div className='specialReport'>{sidebar}</div>
                </div>
              )}
            </div>
          </StructWrapper>

          <div className='row contentEndWrapper'>
            <div className='col-12'>
              <hr className='separator' />
              {this.backComponent}
              {/* <span>{this.backComponent}</span> */}
            </div>
          </div>
        </div>
      </div>
    )

    if (noLayout) {
      return common
    }

    return (
      <BaseLayout>
        {common}
      </BaseLayout>
    )
  }
}

export default Single
