import { equals, pathOr } from 'ramda'

import { AxiosError } from 'axios'

export interface IError {
  error: string
  statusCode?: number
}

export const handleError = (e: AxiosError): IError => {
  const code = pathOr<number>(-1, ['response', 'status'], e)

  if (equals(code, 401)) {
    localStorage.removeItem('lrg_user')
    window.location.href = '/'
  }

  return {
    error: JSON.stringify(e),
    statusCode: code
  }
}
