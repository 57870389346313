export default function injectPlayerScript () {
  const script1 = document.createElement('script')

  script1.src = '//players.brightcove.net/5102072647001/BJxYHdft0_default/index.min.js'
  script1.async = true
  document.body.appendChild(script1)

  if (window.jwplayer) {
    return
  }

  const script2 = document.createElement('script')

  script2.src = '//duk61ht4mw10c.cloudfront.net/shared/site/assets/jwplayer-7.0.3/jwplayer.js'
  script2.async = true
  document.body.appendChild(script2)
}
