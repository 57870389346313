import { types, getParent } from 'mobx-state-tree'

export const Realtime = types
  .model('Article', {
    _id: types.string,
    wordpressSiteName: types.string,
    slug: types.string,
    status: types.string,
    post_type: types.string,
    iotDataSentOn: types.maybe(types.string),
    publication: types.string,
    newslettertype: types.maybe(types.string),
    newsletter_type: types.maybe(types.string),
    person: types.optional(types.array(types.optional(types.string, '')), []),
    parsedDate: types.Date,
    consumed: types.boolean
  })
  .actions((self) => ({
    remove () {
      getParent(self)
        .remove(self)
    },
    updateProperty (key, value) {
      self[key] = value
    }
  }))
