import React, { Component } from 'react'
import Link from 'Components/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { inject, PropTypes as MobxPropTypes } from 'mobx-react'

@inject('store')
class ContactInfo extends Component {
  render () {
    const { siteDetails } = this.props.store.site

    return (
      <div>
        <div className='col rightSide'>
          <header>
            <h4>
              <strong>Our Contact Information</strong>
            </h4>
            <hr />
            <div className='row rightSide__contact-info'>
              <FontAwesomeIcon icon='phone' />{' '}
              <span> {siteDetails.phone.value}</span>
            </div>
            <div className='row rightSide__contact-info'>
              <FontAwesomeIcon icon='calendar' />{' '}
              <span>Mon-Fri, 9am-7pm ET</span>
            </div>
            <div className='row rightSide__contact-info'>
              <FontAwesomeIcon icon='envelope' />{' '}
              <span>{siteDetails.mail.services}</span>
            </div>
            <div className='row rightSide__contact-info'>
              <FontAwesomeIcon icon='map-marker' />
              <br />{' '}
              <span>
                {siteDetails.address.addr1}
                <br />
                {siteDetails.address.addr2}
              </span>
            </div>
          </header>
          <footer>
            <h4>
              {' '}
              <strong>Service Quick Links</strong>{' '}
            </h4>
            <hr />
            <Link to='/reset' className='ml-4'>
              Lost / Forgot Password
            </Link>
            {!window.location.href.includes('feedback') &&
              (
                <div>
                  <h4 className='mt-5'>
                    <strong>Help Center</strong>
                  </h4>
                  <hr />
                  <div className='row ml-2'>
                    <a href='#generalFaqs'>
                      <div className='faq-btn'>
                        <FontAwesomeIcon icon='question-circle' />
                        General
                      </div>
                    </a>
                    <a href='#technicalFaqs'>
                      <div className='faq-btn'>
                        <FontAwesomeIcon icon='cogs' />
                        Technical
                      </div>
                    </a>
                    <a href='#billingFaqs'>
                      <div className='faq-btn'>
                        <FontAwesomeIcon icon='file-invoice' />
                        Billing
                      </div>
                    </a>
                  </div>
                </div>
              )}
          </footer>
        </div>
      </div>
    )
  }
}

ContactInfo.propTypes = {
  store: MobxPropTypes.objectOrObservableObject
}

export default ContactInfo
