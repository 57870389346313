import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'
import * as TE from 'fp-ts/lib/TaskEither'
import * as T from 'fp-ts/lib/Task'
import { pipe } from 'fp-ts/lib/function'
import * as E from 'fp-ts/Either'

import {
  getArticlesArchiveTask,
  getArticlesByYearMonthTask
} from 'Api/endpoints/article'
import {
  getFreeArticlesArchive
} from 'Api/endpoints/freearticle'
import { Article } from 'Stores/mst/Models/article'
import { IArticle, IArticleStore } from 'Stores/mst'
import { IArticleResponse, IArticlesArchiveParams, IArticlesByYearMonthParams } from 'Api/endpoints/types/article'
import { Nullable } from 'Lib/Types/base'
import { IError } from 'Api/error'

interface ILocalArticle extends IArticleResponse {
  parsedCreated?: Date
}

const logAndNullify = (e: IError): null => {
  console.log(e)

  return null
}

const article = (self: IArticleStore): IArticleStore => ({
  createObject (item: ILocalArticle): IArticle {
    // Add the parsed creation date
    if (item.createdAtGMT != null) {
      item.parsedCreated = toDate(parseISO(item.createdAtGMT))
    }

    return Article.create(item)
  },

  pushArticle (item: IArticle, skipCache = false): void {
    const existingItem = self.articles.filter((a) => a._id === item._id)[0]

    if (existingItem == null) {
      self.articles.push(item)

      return
    }

    if (skipCache) {
      existingItem.update(item)
    }
  },

  pushArticles (items: IArticle[]): void {
    items.forEach((item) => this.pushArticle(item))
  },

  createAndPush (item: ILocalArticle): IArticle {
    const object = this.createObject(item)
    this.pushArticle(object)

    return object
  },

  setState (state) {
    self.state = state
  },

  async fetch (sitecode, publicationCode, type, offset = 0, limit = 10, newsletterType = undefined, fieldType = 'min', cb): Promise<Nullable<number>> {
    // eslint-disable-next-line no-nested-ternary
    const postType = type === 'issues' ? 'content' : type === 'bulletin' ? 'bulletin' : 'special-report'

    const args: IArticlesArchiveParams = {
      sitecode,
      publicationCode,
      postType,
      newsletterType,
      offset,
      limit,
      fieldType,
      cb
    }

    return await pipe(
      args,
      getArticlesArchiveTask,
      TE.map((data) => {
        const objects = data.map(this.createObject)
        self.pushArticles(objects)

        return objects.length
      }),
      T.map(E.getOrElseW(logAndNullify))
    )()
  },

  async fetchByDate (props: IArticlesByYearMonthParams) {
    return await pipe(
      props,
      getArticlesByYearMonthTask,
      TE.map((data) => {
        const objects = data.map(this.createObject)
        self.pushArticles(objects)

        return objects.length
      }),
      T.map(E.getOrElseW(logAndNullify))
    )()
  },

  async fetchFree (sitecode, publication, offset, limit) {
    try {
      const data = await getFreeArticlesArchive(sitecode, publication, offset, limit)
      const objects = data.map(this.createObject)
      self.pushArticles(objects)

      return objects.length
    } catch (e) {
      console.error(e)
      return null
    }
  },

  addBookmarkToRecord (articleId) {
    const record = self.articles.valueOf()
      .filter((a) => a._id === articleId)[0]

    if (record == null) {
      return
    }

    record.updateProperty('bookmark', {
      wordpressSiteName: record.wordpressSiteName,
      wordpressId: record.wordpressId,
      createdAt: (new Date()).toISOString()
    })
  },

  removeBookmarkFromRecord (articleId) {
    const record = self.articles.valueOf()
      .filter((a) => a._id === articleId)[0]

    if (record == null) {
      return
    }

    record.updateProperty('bookmark', null)
  }
})

export default article
