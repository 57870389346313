import {
  propEq,
  head,
  compose,
  prop,
  filter
} from 'ramda'

const newsletterType = (self) => ({
  getById (id) {
    return head(self.newsletterTypes.filter(propEq('_id', id)))
  },

  getByCode (code) {
    return self.newsletterTypes.filter(propEq('code', code))
  },

  getTitleByCode (code) {
    return compose(
      prop('title'),
      head,
      filter(propEq('code', code))
    )(self.newsletterTypes)
  }
}
)

export default newsletterType
