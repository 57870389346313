import { getRoot } from 'mobx-state-tree'
import { getMonth, getYear } from 'date-fns'
import { Predicate } from 'fp-ts/Predicate'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { pipe, constant } from 'fp-ts/lib/function'
import { function as F } from 'fp-ts-std'

import { innerText, safeFormatToDate, withTrailingSlash } from 'Lib/purefunctions'
import { IArticle, IArticleStore, IAuthor, IPublication } from '../index'
import { rootStore } from '../../index'
import { Maybe } from 'Lib/Types/base'
import { ArticleI } from '../Models/article'
import { IArticleResponse, IArticleTopNResponse } from '../../../Api/endpoints/types/article'

const { unless } = F

interface ITypeMap {
  type: string
  code: string
}

export interface ISnippet extends IArticle {
  date: string
  snippet: string
  image: string
  id: string
  author: string
  authorLink: string
}

interface ITypeMap {
  type: string,
  code: string
}

interface IDate {
  month: number
  year: number
}

interface IFormattedIssuesByTopN extends IArticleResponse {
  url?: string
  snippet?: string
  date?: string
}

const matchesDate = (d?: IDate): Predicate<IArticle> => (a: IArticle) => {
  if (d == null) {
    return true
  }

  const month = getMonth(a.parsedCreated) + 1
  const year = getYear(a.parsedCreated)

  return month === d.month && year === d.year
}

const withSort = (list: IArticle[]): IArticle[] => pipe(
  list,
  A.sort(ArticleI.ord),
  A.reverse
)

const maybeExcludeNewsletterTypes = (list: string[]): Predicate<IArticle> => (a) => {
  if (list.length === 0 || a.newsletter_type == null) {
    return true
  }

  return !list.includes(a.newsletter_type)
}

const article = (self: IArticleStore): IArticleStore => {
  const getFormattedIssuesFromTopNByPublication = (list: IArticleTopNResponse[]) => (pub: IPublication): IFormattedIssuesByTopN[]  => {
    const siteStore = getRoot<typeof rootStore>(self)._root!.site
    const { withTimestamp } = siteStore.siteDetails
    const timeFormat = withTimestamp !== undefined ? 'EEE, MMM dd hh:mm aaa' : 'MMM dd, yyyy'

    const hasPublication: Predicate<IArticleTopNResponse> = (a) => a.publication === pub._id
    const issues = pipe(
      list,
      A.filter(hasPublication),
      A.takeLeft(4),
      A.map((a) => a.top_n ?? []),
      A.flatten
    )

    const addDate = (v: IFormattedIssuesByTopN): IFormattedIssuesByTopN => {
      if (v.createdAtGMT != null) {
        v.date =  safeFormatToDate(v.createdAtGMT, timeFormat)
      }

      return v
    }

    return pipe(
      issues,
      A.map((a: IArticleResponse): IFormattedIssuesByTopN => ({
        url: withTrailingSlash(`/${pub.code}/${a.slug}`),
        snippet: innerText(a.excerpt ?? ''),
        ...a
      })),
      A.map(addDate)
    )
  }

  return {
    getFree (publicationCode): ISnippet[] {
      return self.snippetForm(publicationCode, 'free')
    },

    getIssues (publicationCode, date, sortByDate = false, skip = []): ISnippet[] {
      return self.snippetForm(publicationCode, 'issues', date, sortByDate, skip)
    },

    getReports (publicationCode, date, sortByDate = false): ISnippet[] {
      return self.snippetForm(publicationCode, 'reports', date, sortByDate)
    },

    getStickyReports (publicationCode): ISnippet[] {
      const isSticky: Predicate<ISnippet> = (s) => s.sticky_report === 'yes'

      return pipe(
        self.snippetForm(publicationCode, 'reports', null, false, []),
        A.filter(isSticky)
      )
    },

    // TODO Check that we actually use this
    getBulletins (publicationCode, date, sortByDate = false): ISnippet[] {
      const hasRecommendation: Predicate<ISnippet> = (s) => s.recommendation_line !== ''
      return pipe(
        self.snippetForm(publicationCode, 'bulletin', date, sortByDate),
        A.filter(hasRecommendation)
      )
    },

    getFormattedIssuesFromTopNByPublication,

    toSnippet (item: IArticle): ISnippet {
      const getAuthorTitle = (id: string): string => {
        const author: IAuthor = getRoot<typeof rootStore>(self).author.getById(id)

        return author?.title ?? ''
      }

      const getAuthorCode = (id): string => {
        const author = getRoot<typeof rootStore>(self).author.getById(id)

        return `/team-members/${author?.code}`
      }

      const siteStore = getRoot<typeof rootStore>(self)._root!.site
      const { withTimestamp } = siteStore
      const timeFormat = withTimestamp ? 'EEE, MMM dd hh:mm aaa' : 'EEE, MMM dd'

      return {
        date: safeFormatToDate(item.createdAtGMT, timeFormat) as string,
        snippet: innerText(item.excerpt),
        image: (item.promo_image_url ?? item.featured_image) ?? '',
        id: item._id,
        author: getAuthorTitle(item.person[0]),
        authorLink: getAuthorCode(item.person[0]),
        ...item
      }
    },



    snippetForm (publication, type, date?: IDate, shouldSortByDate = false, skip: string[] = []): ISnippet[] {
      const typeMap: ITypeMap[] = [
        {
          type: 'issues',
          code: 'content'
        },
        {
          type: 'reports',
          code: 'special-report'
        },
        {
          type: 'bulletin',
          code: 'content'
        },
        {
          type: 'free',
          code: 'post'
        }
      ]

      const articleCode = pipe(
        typeMap,
        A.filter((entry: ITypeMap) => entry.type === type),
        A.head,
        O.map((s: ITypeMap) => s.code),
        O.getOrElse(constant(''))
      )

      const publicationStore = getRoot<typeof rootStore>(self).publication

      const publicationId = pipe(
        publicationStore.publications,
        A.filter((entry: IPublication) => entry.code === publication),
        A.head,
        O.map((s: IPublication) => s._id),
        O.getOrElse(constant(''))
      )

      const matchesPostType: Predicate<IArticle> = (a) => a.post_type === articleCode
      const matchesPublication: Predicate<IArticle> = (a) => a.publication.includes(publicationId)
      const matches: IArticle[] = pipe(
        self.articles,
        A.filter(matchesPostType),
        A.filter(matchesPublication)
      )

      return pipe(
        matches,
        A.filter(maybeExcludeNewsletterTypes(skip)),
        A.filter(matchesDate(date)),
        unless<IArticle[]>(() => !shouldSortByDate)(withSort),
        A.map(self.toSnippet)
      )
    },

    getById (id): Maybe<IArticle> {
      const matchesId: Predicate<IArticle> = (v) => v._id === id

      return self.articles.filter(matchesId)[0]
    },

    getBySlugAndPublicationId (slug, publicationId): O.Option<IArticle> {
      if (publicationId == null) {
        return O.none
      }

      const matchesSlug: Predicate<IArticle> = (a) => a.slug === slug
      const matchesPublication: Predicate<IArticle> = (a) => a.publication.includes(publicationId)

      return pipe(
        self.articles,
        A.filter(matchesPublication),
        A.filter(matchesSlug),
        A.head
      )
    }
  }
}

export default article
