import React from 'react'
import PT from 'prop-types'
import Spinner from 'Components/spinner'
import {
  compose, map, isEmpty, prop, propOr, pathOr, uniq, sortBy
} from 'ramda'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'

import Link from 'Components/link'
// import { Tooltip } from '@progress/kendo-react-tooltip'

import { useStore } from 'App'

const LocalListing = ({ list }) => (
  <tbody>
    {map((sub) => (
      <tr className='kv' key={sub.title}>
        <td>{sub.title}</td>
        <td>Active</td>
      </tr>
    ))(list)}
  </tbody>
)

const ManageBtn = ({ product }) => {
  const manageable = pathOr(false, ['manageChoice'], product)

  if (!manageable) {
    return null
  }

  const { level } = product
  const pubcode = product.itemNumber

  return (
    <Tooltip
      placement='top'
      overlay='Change your subscriptions'
    >
      <Link to={`/my-account/manage/${pubcode}/${level}`} className='ml-2'>
        <button className='k-button'>Manage LRS</button>
      </Link>
    </Tooltip>
  )
}

const ProductListing = ({ details, isLoading, userPaid }) => {
  const store = useStore()
  const { sitecode, getSiteNameByCode } = store.site

  if (isEmpty(details) && isLoading) {
    return <tbody><tr><td><Spinner /></td></tr></tbody>
  }

  if (!isLoading && !isEmpty(details)) {
    return map((product) => {
      if ((product.itemNumber === 'LGM') || (product.itemNumber === 'LRS')) {
        // eslint-disable-next-line max-len
        const siteCodes = compose(uniq, map((babySub) => prop('wordpressSiteName', babySub)))(product.babySubs)
        let babySubs = propOr([], 'babySubs', product)
        babySubs = sortBy(prop('product_display_order'), babySubs)
        return (
          <tbody key={product.code}>
            <tr className='subSection'>
              <td className='subHeader'>
                {product.title}
                {sitecode === 'LG' &&
                  <ManageBtn product={product} />}
              </td>
              <td className='subStatus'>Active</td>
            </tr>
            {map((site) => {
              // remove BP once brownstone is live
              const siteName = site === 'BP' ? 'Additional MySubscriptions' : getSiteNameByCode(site)
              return (
                <tr key={site}>
                  <td className='babySubSection'>
                    {sitecode === 'LG' &&
                      <span className='sectionTitle'>{siteName}</span>}
                    {map((sub) => {
                      if (sub.wordpressSiteName === site) {
                        return (
                          <span key={sub.code} className='subItem'>{sub.title}</span>
                        )
                      }
                      return ('')
                    })(babySubs)}
                  </td>
                </tr>
              )
            })(siteCodes)}
          </tbody>
        )
      }
      if ((product.wordpressSiteName === sitecode) && !(sitecode === 'LG')) {
        return (
          <tbody key={product.code}>
            <tr className='subSection'>
              <td className='subHeader'>
                <span>
                  {product.title}
                </span>
              </td>
              <td className='subStatus'>Active</td>
            </tr>
            {map((sub) => (
              <tr key={sub.code}>
                <td className='subItem'>{sub.title}</td>
              </tr>
            ))(propOr([], 'babySubs', product))}
          </tbody>
        )
      }
      if ((sitecode === 'LG')) {
        return (
          <tbody key={product.code}>
            <tr className='subSection'>
              <td className='subHeader'>
                <span>
                  {product.title}
                </span>
              </td>
              <td className='subStatus'>Active</td>
            </tr>
            {map((sub) => (
              <tr key={sub.code}>
                <td className='subItem'>{sub.title}</td>
              </tr>
            ))(propOr([], 'babySubs', product))}
          </tbody>
        )
      }

      return ('')
    })(details)
  }

  return <LocalListing list={userPaid} />
}

const productShape = PT.shape({
  babySubs: PT.arrayOf(PT.shape({
    itemDescription: PT.string
  })),
  id: PT.shape({
    item: PT.shape({
      itemNumber: PT.string,
      itemDescription: PT.string,
      manageChoice: PT.bool
    })
  }),
  level: PT.string,
  itemNumber: PT.oneOfType([
    PT.string,
    PT.number
  ])
})

ManageBtn.propTypes = {
  product: productShape
}

LocalListing.propTypes = {
  list: PT.array
}

ProductListing.propTypes = {
  details: PT.arrayOf(productShape),
  isLoading: PT.bool,
  userPaid: PT.array
}

export default ProductListing
