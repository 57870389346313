/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

import articleViews from './Views/article'
import publicationViews from './Views/publication'
import authorViews from './Views/author'
import nstViews from './Views/newsletterType'
import rltViews from './Views/realtime'
import notificationViews from './Views/notification'
import courseViews from './Views/course'
import bookmarkViews from './Views/bookmark'
import articleActions from './Actions/article'
import publicationActions from './Actions/publication'
import userPublicationActions from './Actions/userPublication'
import authorActions from './Actions/author'
import nstActions from './Actions/newsletterType'
import rtlActions from './Actions/realtime'
import notificationActions from './Actions/notification'
import courseActions from './Actions/course'
import bookmarkActions from './Actions/bookmark'
import { Category } from './Models/category'
import { NewsletterType } from './Models/newsletterType'
import { PubCode } from './Models/pubCode'
import { Publication } from './Models/publication'
import { UserPublication } from './Models/userPublication'
import { Author } from './Models/author'
import { Article } from './Models/article'
import { Realtime } from './Models/realtime'
import { Bookmark } from './Models/bookmark'
import { Notification } from './Models/notification'
import { Course, Section, Lesson } from './Models/course'

const articleStore = types
  .model('ArticleStore', {
    articles: types.array(Article),
    state: types.enumeration('State', ['pending', 'done', 'error'])
  })
  .views(articleViews)
  .actions(articleActions)

export interface IArticle extends Instance<typeof Article> {}
export interface IArticleSnapshotIn extends SnapshotIn<typeof Article> {}
export interface IArticleSnapshotOut extends SnapshotOut<typeof Article> {}
// @ts-expect-error Circular
export type IArticleStore = Instance<typeof articleStore> & {
  articles: IArticle[]
}

const siteCodeStore = types
  .model('siteCodeStore', {
    siteCode: types.array(types.string),
    state: types.enumeration('State', ['pending', 'done', 'error'])
  })

const categoryStore = types
  .model('CategoryStore', {
    categories: types.array(Category),
    state: types.enumeration('State', ['pending', 'done', 'error'])
  })

const newsletterTypeStore = types
  .model('newsletterTypeStore', {
    newsletterTypes: types.array(NewsletterType),
    state: types.enumeration('State', ['pending', 'done', 'error'])
  })
  .views(nstViews)
  .actions(nstActions)

const pubCodeStore = types
  .model('pubCodeStore', {
    pubCodes: types.array(PubCode),
    state: types.enumeration('State', ['pending', 'done', 'error'])
  })

const authorStore = types
  .model('authorStore', {
    authors: types.array(Author),
    state: types.enumeration('State', ['pending', 'done', 'error'])
  })
  .views(authorViews)
  .actions(authorActions)

export interface IAuthor extends Instance<typeof Author> {}
export interface IAuthorSnapshotIn extends SnapshotIn<typeof Author> {}
export interface IAuthorSnapshotOut extends SnapshotOut<typeof Author> {}
// @ts-expect-error Circular
export type IAuthorStore = Instance<typeof authorStore> & {
  publications: IAuthor[]
}

const publicationStore = types
  .model('publicationStore', {
    publications: types.array(Publication),
    state: types.enumeration('State', ['pending', 'done', 'error'])
  })
  .views(publicationViews)
  .actions(publicationActions)

export interface IPublication extends Instance<typeof Publication> {}
export interface IPublicationSnapshotIn extends SnapshotIn<typeof Publication> {}
export interface IPublicationSnapshotOut extends SnapshotOut<typeof Publication> {}
export type IPublicationStore = Instance<typeof publicationStore> & {
  publications: IPublication[]
}

const userPublicationStore = types
  .model('userPublicationStore', {
    userPublications: types.array(UserPublication),
    state: types.enumeration('State', ['pending', 'done', 'error'])
  })
  .actions(userPublicationActions)

export interface IUserPublication extends Instance<typeof UserPublication> {}
export interface IUserPublicationSnapshotIn extends SnapshotIn<typeof UserPublication> {}
export interface IUserPublicationSnapshotOut extends SnapshotOut<typeof UserPublication> {}
export type IUserPublicationStore = Instance<typeof userPublicationStore> & {
  publications: IUserPublication[]
}

const realtimeStore = types
  .model('realtimeStore', {
    messages: types.array(Realtime)
  })
  .actions(rtlActions)
  .views(rltViews)

const notificationStore = types
  .model('notificationStore', {
    notifications: types.array(Notification)
  })
  .actions(notificationActions)
  .views(notificationViews)

export interface INotification extends Instance<typeof Notification> {}
export interface INotificationSnapshotIn extends SnapshotIn<typeof Notification> {}
export interface INotificationSnapshotOut extends SnapshotOut<typeof Notification> {}
// @ts-expect-error Circular
export type INotificationStore = Instance<typeof notificationStore> & {
  notifications: INotification[]
}

const courseStore = types
  .model('courseStore', {
    courses: types.array(Course),
    sections: types.array(Section),
    lessons: types.array(Lesson),
  })
  .actions(courseActions)
  .views(courseViews)

export interface ISection extends Instance<typeof Section> {}
export interface ISectionSnapshotIn extends SnapshotIn<typeof Section> {}
export interface ISectionSnapshotOut extends SnapshotOut<typeof Section> {}
export interface ILesson extends Instance<typeof Lesson> {}
export interface ILessonSnapshotIn extends SnapshotIn<typeof Lesson> {}
export interface ILessonSnapshotOut extends SnapshotOut<typeof Lesson> {}
export interface ICourse extends Instance<typeof Course> {}
export interface ICourseSnapshotIn extends SnapshotIn<typeof Course> {}
export interface ICourseSnapshotOut extends SnapshotOut<typeof Course> {}

// @ts-expect-error Circular
export type ICourseStore = Instance<typeof courseStore> & {
  courses?: ICourse[],
  sections?: ISection[]
  lessons?: ILesson[]
} // Don't use an interface here or all hell will break loose.
// https://github.com/microsoft/TypeScript/issues/15300

const bookmarkStore = types
  .model('bookmarkStore', {
    items: types.array(Bookmark)
  })
  .actions(bookmarkActions)
  .views(bookmarkViews)

export interface IBookmark extends Instance<typeof Bookmark> {}
export interface IBookmarkSnapshotIn extends SnapshotIn<typeof Bookmark> {}
export interface IBookmarkSnapshotOut extends SnapshotOut<typeof Bookmark> {}
// @ts-expect-error Circular
export type IBookmarkStore = Instance<typeof bookmarkStore> & {
  publications: IBookmark[]
}

export {
  articleStore,
  siteCodeStore,
  categoryStore,
  newsletterTypeStore,
  pubCodeStore,
  authorStore,
  publicationStore,
  userPublicationStore,
  realtimeStore,
  bookmarkStore,
  notificationStore,
  courseStore
}
