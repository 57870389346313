import { makeAutoObservable } from 'mobx'

import { getPortfolio } from 'Api/endpoints/portfolio'
import Store from './index'

interface IPortfolios {
  [key: string]: string
}

export default class Portfolio {
  private readonly root: Store

  constructor (rootStore: Store) {
    makeAutoObservable(this)

    this.root = rootStore
  }

  portfolios: IPortfolios = {}

  async fetchPortfolio (id: string): Promise<void> {
    const data = await getPortfolio(id)

    if ('value' in data) {
      this.portfolios[`_${id}`] = data.value
    }
  }
}
