import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'
import {
  compose, head, prop, assoc, is, filter, map, curry, propEq, reject
} from 'ramda'
import Spinner from 'Components/spinner'

import { useStore } from 'App'
import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import { getBookmarkedArticles } from 'Api/endpoints/article'
import { removeArticleBookmark } from 'Api/endpoints/user'
import Item from './item'

const isRecordBookmarked = compose(
  is(Object),
  prop('bookmark')
)

const Favorites = () => {
  const store = useStore()
  const { sitecode } = store.site
  const { getById } = store.mst.publication
  const [favorites, setFavorites] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    getBookmarkedArticles(sitecode).then((res) => {
      const enh = compose(
        map((item) => {
          const publication = compose(head, prop('publication'))(item)
          const publicationObj = getById(publication)
          return assoc('publicationObj', publicationObj, item)
        }),
        filter(isRecordBookmarked)
      )(res)
      setFavorites(enh)
      setLoading(false)
    })
  }, [sitecode, getById])

  const handleBookmarkClicked = curry((list, item) => {
    store.mst.article.removeBookmarkFromRecord(item.id)
    removeArticleBookmark(sitecode, item.wpKey)
    setFavorites(reject(propEq('_id', item._id), list))
  })

  return (
    <BaseLayout>
      <div id='__page_favorites' className='container'>
        <Helmet>
          <title>Bookmarks</title>
        </Helmet>

        <div className='row'>
          <div className='col'>
            <header className='headerWrapper'>
              <h1>Bookmarks</h1>
              <p>You can view all saved articles on this page.</p>
            </header>

            {loading && <Spinner />}

            <main>
              <div className='row'>
                <div className='col-sm-12 col-xs-12 col-md-12 favorite-items'>
                  {favorites.map((item) => (
                    <Item
                      key={item.slug}
                      item={item}
                      handleBookmarkClicked={handleBookmarkClicked(favorites)}
                    />
                  ))}

                  {favorites.length === 0 && loading === false && (
                    <aside>
                      <h4>You do not have anything on your list yet.</h4>
                    </aside>
                  )}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default observer(Favorites)
