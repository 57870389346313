import { getParent, types } from 'mobx-state-tree'
import { includes } from 'ramda'
import * as EQ from 'fp-ts/Eq'
import * as ORD from 'fp-ts/Ord'
import * as S from 'fp-ts/string'
import { pipe } from 'fp-ts/lib/function'

import { IArticle, IArticleStore } from '..'

const immutableOnUpdateKeys = ['category', 'pubcode', 'publication', 'person', 'newslettertype']

export const Article = types
  .model('Article', {
    _id: types.optional(types.string, ''),
    wordpressId: types.optional(types.number, 0),
    wpKey: types.maybe(types.number),
    wordpressSiteName: types.optional(types.string, ''),
    content: types.optional(types.string, ''),
    contentText: types.optional(types.string, ''),
    excerpt: types.optional(types.string, ''),
    slug: types.optional(types.string, ''),
    title: types.optional(types.string, ''),
    order: types.optional(types.number, 0),
    status: types.optional(types.string, ''),
    old_url: types.optional(types.string, ''),
    new_url: types.optional(types.string, ''),
    post_type: types.optional(types.string, ''),
    featured_image: types.union(types.null, types.optional(types.string, '')),
    promo_image_url: types.union(types.null, types.optional(types.string, '')),
    createdAt: types.optional(types.string, ''),
    createdAtGMT: types.optional(types.string, ''),
    modifiedAt: types.optional(types.string, ''),
    createdAtWeekday: types.optional(types.number, 0),
    category: types.optional(types.array(types.optional(types.string, '')), []),
    pubcode: types.optional(types.array(types.optional(types.string, '')), []),
    publication: types.optional(types.array(types.optional(types.string, '')), []),
    newslettertype: types.optional(types.string, ''),
    newsletter_type: types.optional(types.string, ''),
    person: types.optional(types.array(types.optional(types.string, '')), []),
    paid_content: types.optional(types.boolean, false),
    page_subheading: types.optional(types.string, ''),
    media_links: types.optional(types.string, ''),
    external_url: types.optional(types.string, ''),
    sticky_report: types.optional(types.string, ''),
    recommendation_line: types.optional(types.string, ''),
    parsedCreated: types.optional(types.Date, 0),
    reading_time_min: types.maybe(types.number),
    bookmark: types.maybe(types.frozen()),
    history: types.maybe(types.frozen())
  })
  .actions((self) => ({
    update (item) {
      Object.keys(item).forEach((key) => { this.updateProperty(key, item[key]) })
    },
    updateProperty (key, value) {
      if (includes(key, immutableOnUpdateKeys)) {
        return
      }

      self[key] = value
    },
    remove () {
      getParent<IArticleStore>(self).remove(self)
    }
  }))

/* Typeclass Instances */

// export const articleEq: EQ.Eq<IArticle> = {
//   equals(a: IArticle, b: IArticle) {
//     return a._id === b._id
//   }
// }

const articleEq = pipe(
  S.Eq,
  EQ.contramap((f: IArticle) => f._id)
)

const articleOrd = pipe(
  S.Ord,
  ORD.contramap((f: IArticle) => f.createdAtGMT)
)

export const ArticleI = {
  eq: articleEq,
  ord: articleOrd
}
