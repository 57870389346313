import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react'

@inject('store')
@observer
class ProtectedRoute extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    publication: MobxPropTypes.objectOrObservableObject,
    failCondition: PropTypes.bool,
    failLocation: PropTypes.string,
    external: PropTypes.bool
  };

  render () {
    const { failCondition, external, failLocation } = this.props

    if (failCondition) {
      if (!external) {
        return (<Redirect to={{ pathname: failLocation }} />)
      }

      window.location.href = failLocation

      return null
    }

    return <Route exact {...this.props} />
  }
}

export default withRouter(ProtectedRoute)
