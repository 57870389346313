import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  compose, defaultTo, propOr
} from 'ramda'
import { Link } from 'react-router-dom'
import uniqueString from 'Lib/unique-string'
import { isNew } from 'Lib/purefunctions'
import classnames from 'classnames'

import Spinner from 'Components/spinner'
import Indicator from 'Components/newArticleIndicator'

export default class IssuesAndBulletins extends Component {
  static propTypes = {
    issues: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      date: PropTypes.string,
      url: PropTypes.string,
      readingTimeMin: PropTypes.number
    })),
    bulletin: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      dateByMinute: PropTypes.string,
      recommendationLine: PropTypes.string
    })),
    publicationCode: PropTypes.string.isRequired,
    isChild: PropTypes.bool,
    newItems: PropTypes.array
  };

  render () {
    const {
      issues,
      bulletin,
      publicationCode,
      isChild,
      newItems
    } = this.props

    const getTypeFromArticle = compose(
      defaultTo(''),
      propOr([], 'newsletter_type')
    )
    const issuesItems = issues
    const bulletinItems = bulletin
    const isInNew = isNew(newItems || [])

    return (
      <div className={classnames('subscriber-bulletin',
        { isChildPublication: isChild })}
      >
        <div className='row itemgroup'>
          <div className='col-md-7 issues'>
            {!isChild && (
              <div className='section-title'>
                <h5 className='text-muted'>Issues & Updates</h5>
                <hr />
              </div>
            )}
            {!issuesItems && <Spinner />}
            {issuesItems.map((item, index) => (
              <div className='issue' key={uniqueString()}>
                <h5>
                  {isInNew(item.slug) && <Indicator />}
                  <Link to={item.url}>
                    {item.title}
                  </Link>
                </h5>
                <div className='entry-meta text-muted'>
                  <span>{item.date}</span>
                  {getTypeFromArticle(item) && !isChild && (
                    <>
                      <span className='splitter'>|</span>
                      <span className='articleType'>
                        {getTypeFromArticle(item)}
                      </span>
                    </>
                  )}
                  {item.readingTimeMin && (
                    <>
                      <span className='splitter'>|</span>
                      <span> {item.readingTimeMin} min read</span>
                    </>
                  )}
                  {(index !== issuesItems.length - 1) &&
                    <hr />}
                </div>
              </div>
            ))}
            <div className='actionWrapper'>
              <div>
                <Link to={`/${publicationCode}/archives`}>
                  View All Issues & Updates
                </Link>
              </div>
            </div>
          </div>

          <div className='col-md-5 actions'>
            {!isChild && (
              <div className='section-title'>
                <h5 className='text-muted'>Recent Recommendations</h5>
                <hr />
              </div>
            )}
            {bulletinItems.map((item, index) => (
              <div className='action' key={uniqueString(index)}>
                <sup>{item.dateByMinute}</sup>
                <div>
                  {isInNew(item.slug) && <Indicator />}
                  <span>{item.recommendationLine}</span>
                </div>
              </div>
            ))}
            <div className='disclaimer'>
              <sub className='text-muted'><b>IMPORTANT NOTE:</b> Some of the above trades may no longer be actionable. Please review the portfolio page for the most up-to-date trade information and guidance.</sub>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
