import React from 'react'
import {
  compose, filter, head, isEmpty, join, map, pluck, prop, propEq
} from 'ramda'
import PT from 'prop-types'
import Link from 'Components/link'

const getBundledPubByCode = (code, list) => compose(
  head,
  filter(propEq('publicationCode', code))
)(list)

const Labeler = ({ publication, list = [] }) => {
  const bundled = compose(
    map((code) => getBundledPubByCode(code, list)),
    pluck('pubCode'),
    prop('bundledPublications')
  )(publication)
  return (
    <>
      <div>
        <Link to={publication.product_page}>
          {publication.name}
        </Link>
      </div>
      {!isEmpty(bundled) && (
        <div>
          <b>Bonus: </b>
          {compose(
            join(', '),
            pluck('name')
          )(bundled)}
        </div>
      )}
    </>
  )
}

const publicationShape = PT.shape({
  selected: PT.bool,
  name: PT.string,
  product_page: PT.string,
  publicationCode: PT.string,
  bundledPublications: PT.arrayOf(PT.shape({
    publicationCode: PT.string
  }))
})

Labeler.propTypes = {
  publication: publicationShape.isRequired,
  list: PT.arrayOf(publicationShape)
}

export default Labeler
