/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import { types } from 'mobx-state-tree'
import * as O from 'fp-ts/lib/Option'

import { ICourseStore, ILesson, ISection } from '..'
import { Nullable } from 'Lib/Types/base'

export const Lesson = types.model('Lesson', {
  id: types.identifier,
  title: types.string,
  ctaContent: types.optional(types.string, ''), // TODO Remove optional after API update in prod
  slug: types.string,
  description: types.string,
  displayOrder: types.number,
  titlePrefix: types.string,
  secondaryTitle: types.string,
  videoCode: types.maybe(types.string),
  videoDuration: types.maybe(types.string),
  downloadLabel: types.maybe(types.string),
  downloadResourcesUrl: types.maybe(types.string),
}).actions((self) => ({
    updateProperty (key, value) {
      self[key] = value
    },
    update (item) {
      Object.keys(item)
        .forEach((key) => { this.updateProperty(key, item[key]) })
    },
  })
)

export const Section = types.model('Section', {
  id: types.identifier,
  title: types.maybe(types.string),
  ctaContent: types.optional(types.string, ''), // TODO Remove optional after API update in prod
  isLocked: types.optional(types.boolean, false), // TODO Remove optional after API update in prod
  code: types.string,
  label: types.string,
  displayOrder: types.number,
  lessons: types.maybe(types.array(types.reference(Lesson)))
})
  .views((self) => {
    return {
      findLesson (id: string): Nullable<ILesson> {
        if (self.lessons == null) {
          return null
        }

        return self.lessons.find(lesson => lesson.id === id) ?? null
      },

      hasNextLesson (idx: number): boolean {
        if (self.lessons == null) {
          return false
        }

        return idx + 1 < self.lessons.length
      },

      getNextLesson (idx: number): O.Option<ILesson> {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
        if (this.hasNextLesson(idx) === true && self.lessons != null) {
          return O.some(self.lessons[idx + 1] as ILesson)
        }

        return O.none
      },

      getNextLessonById (currentId: string): O.Option<ILesson> {
        if (self.lessons == null) {
          return O.none
        }

        // Find the idx
        const idx = self.lessons.findIndex(lesson => lesson.id === currentId)

        if (idx === -1) { // Not found
          return O.none
        }

        return this.getNextLesson(idx)
      },

      getPrevLesson (idx: number): Nullable<ILesson> {
        if (self.lessons == null) {
          return null
        }

        if (idx > 0) {
          return self.lessons[idx - 1]
        }

        return null
      },

      getPrevLessonById (currentId): Nullable<ILesson> {
        if (self.lessons == null) {
          return null
        }

        const idx = self.lessons.findIndex(lesson => lesson.id === currentId)

        return this.getPrevLesson(idx)
      },
    }
  })
  .actions((self) => ({
      updateProperty (key, value) {
        self[key] = value
      },
      update (item) {
        Object.keys(item)
          .forEach((key) => { this.updateProperty(key, item[key]) })
      },
    })
  )

export const Course = types
  .model('Course', {
    id: types.identifier,
    code: types.string,
    title: types.string,
    type: types.optional(types.string, 'course'),
    description: types.string,
    publications: types.array(types.string),
    sections: types.maybe(types.array(types.reference(Section))),
    sectionIds: types.array(types.string)
  })
  .views((self) => {
    return {
      findSection (id: string): Nullable<ISection> {
        if (self.sections == null) {
          return null
        }

        return self.sections.find(section => section.id === id) ?? null
      },

      hasNextSection (idx: number): boolean {
        if (self.sections == null) {
          return false
        }

        return idx + 1 < self.sections.length
      },

      getNextSection (idx: number): Nullable<ICourseStore> {
        if (self.sections != null && this.hasNextSection(idx) === true) {
          return self.sections[idx + 1]
        }

        return null
      },

      getNextSectionById (currentId): Nullable<ICourseStore> {
        if (self.sections == null) {
          return null
        }

        // Find the idx
        const idx = self.sections.findIndex(section => section.id === currentId)

        return this.getNextSection(idx)
      },

      getPrevSection (idx: number): Nullable<ISection> {
        if (self.sections == null) {
          return null
        }

        if (idx > 0) {
          return self.sections[idx - 1]
        }

        return null
      },

      getPrevSectionById (currentId): Nullable<ICourseStore> {
        if (self.sections == null) {
          return null
        }

        const idx = self.sections.findIndex(lesson => lesson.id === currentId)

        return this.getPrevSection(idx)
      },
    }
  })
  .actions((self) => ({
    updateProperty (key, value) {
      self[key] = value
    },
    update (item) {
      Object.keys(item)
        .forEach((key) => { this.updateProperty(key, item[key]) })
    },
  }))
