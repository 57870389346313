import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'
import { AxiosError, AxiosResponse } from 'axios'
import { join } from 'ramda'

import { upstreamCourse as API } from '../index'
import { handleError, IError } from '../error'

export interface ILessonAPI {
  _id: string
  wordpressSiteName: string
  content: string
  ctaContent: string
  slug: string
  status: string
  title: string
  displayOrder: number
  title_prefix: string
  lesson_title: string
  lesson_video_duration: string
  download_label: string
  download_resources_url: string
  lesson_video_code: string
}

export interface ISectionAPI {
  _id: string
  code: string
  title: string
  label: string
  displayOrder: number
  ctaContent: string
  isLocked: boolean
  lessons: ILessonAPI[]
}

interface SectionsResponse {
  processedTime: string
  refreshRequired: boolean
  sectionData: ISectionAPI[]
}

export interface ICourseAPI {
  _id: string
  code: string
  title: string
  description: string
  type: 'course' | 'product'
  publications: string[],
  sections: Array<Omit<ISectionAPI, 'lessons'>>
}

interface CoursesResponse {
  processedTime: string
  refreshRequired: boolean
  courseData: ICourseAPI[]
}

export function getSections (sitecode: string, sectionIds: string[]): TE.TaskEither<IError, ISectionAPI[]> {
  const sectionsStr = join(',', sectionIds)
  return pipe(
    TE.tryCatch(
      async () => await API.get(`/courses/${sitecode}/section/${sectionsStr}`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp: AxiosResponse<SectionsResponse>) => resp.data.sectionData),
  )
}

export function getCourses (sitecode: string): TE.TaskEither<IError, ICourseAPI[]> {
  return pipe(
    TE.tryCatch(
      async () => await API.get(`/courses/${sitecode}/data`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp: AxiosResponse<CoursesResponse>) => resp.data.courseData),
  )
}
