import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  compose, defaultTo, hasPath, head, isEmpty, isNil, propOr
} from 'ramda'
import { Link } from 'react-router-dom'
import uniqueString from 'Lib/unique-string'
import { html2jsx } from 'Lib/parser'
import { isNew } from 'Lib/purefunctions'

import Spinner from 'Components/spinner'
import Indicator from 'Components/newArticleIndicator'

export default class IssuesAndReports extends Component {
  static propTypes = {
    issues: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      date: PropTypes.string,
      snippet: PropTypes.string,
      url: PropTypes.string,
      readingTimeMin: PropTypes.number
    })),
    reports: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      snippet: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.string
    })),
    publicationCode: PropTypes.string.isRequired,
    publicationTitle: PropTypes.string,
    campaignData: PropTypes.array,
    newItems: PropTypes.array
  };

  render () {
    const {
      issues, reports, publicationCode, publicationTitle, campaignData, newItems
    } = this.props

    const getTypeFromArticle = compose(
      defaultTo(''),
      propOr([], 'newsletter_type')
    )
    const issuesItems = issues
    const reportsItems = reports
    const isInNew = isNew(newItems || [])
    const noReportsItems = isEmpty(reportsItems)
    const campaign = head(campaignData)
    const campaignStatus = (isNil(campaign)) ? false : campaign.enabled
    const campaignTitle = (hasPath(['campaignTitle'], campaign)) ? campaign.campaignTitle : 'Special Reports'

    return (
      <div className='subscriber-mixed'>
        <div className='row itemgroup'>
          <div className='col-md-7 col-sm-12 issues leftside'>
            <h6 className='title'>Issues and Updates</h6>
            <hr />
            {!issuesItems && <Spinner />}
            {issuesItems.map((item, index) => (
              <div className='issue' key={uniqueString()}>
                <h5>
                  {isInNew(item.slug) && <Indicator />}
                  <Link to={item.url}>
                    {item.title}
                  </Link>
                </h5>
                <div className='text-muted entry-meta'>
                  <span>{item.date}</span>
                  {item.pubTitle && item.pubTitle !== 'Issues' && (
                    <>
                      <span className='splitter'>|</span>
                      <span className='articleType'>
                        {item.pubTitle}
                      </span>
                    </>
                  )}
                  {getTypeFromArticle(item) && (!item.pubTitle || item.pubTitle === 'Issues') && (
                    <>
                      <span className='splitter'>|</span>
                      <span className='articleType'>
                        {getTypeFromArticle(item)}
                      </span>
                    </>
                  )}
                  {item.reading_time_min && (
                    <>
                      <span className='splitter'>|</span>
                      <span> {item.reading_time_min} min read</span>
                    </>
                  )}
                  {(index !== issuesItems.length) &&
                    <hr />}
                </div>
              </div>
            ))}
            <h6 className='more'>
              <Link to={`/${publicationCode}`}>
                View More Issues & Updates...
              </Link>
            </h6>
          </div>

          <div className='col-md-5 col-sm-12 rightside'>
            {!campaignStatus && (
              <div className='reports'>
                <h6 className='title'>Special Reports</h6>
                <hr />
                {reportsItems.map((item, index) => (
                  <div className='report' key={uniqueString()}>
                    <div className='wrapper'>
                      <div className='image'>
                        <Link to={item.url}>
                          <img src={item.image} alt={item.title} />
                        </Link>
                      </div>
                      <h5>
                        <Link to={item.url}>
                          {item.title}
                        </Link>
                      </h5>
                    </div>
                    {(index !== reportsItems.length) &&
                      <hr />}
                  </div>
                ))}
                {noReportsItems && <p>There are no special reports published at this time.</p>}
                {!noReportsItems && (
                  <h6 className='more'>
                    <Link to={`/${publicationCode}`}>
                      View More Special Reports...
                    </Link>
                  </h6>
                )}
              </div>
            )}
            {campaignStatus && (
              <div id={campaign.campaignId} className='campaign'>
                <h6 className='title'>{campaignTitle}</h6>
                <hr />
                <div className='campaignContent'>
                  {html2jsx(campaign.campaignContent)}
                </div>
              </div>
            )}
          </div>

        </div>

        <div className='actionWrapper' data-name='mySubsWalkthroughStep-5'>
          <div>
            <Link to={`/${publicationCode}`}>
              Access {publicationTitle} Homepage
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
