import React, { Component } from 'react'
import PT from 'prop-types'
import { action, computed, observable, makeObservable } from 'mobx'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import { Helmet } from 'react-helmet'
import { isEmpty, path } from 'ramda'
import { differenceInSeconds } from 'date-fns'
import { store as notifications } from 'react-notifications-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { secondaryLogin } from 'Api/endpoints/auth'
import {
  getUserInfo,
  updatePassword,
  updateUserInfo
} from 'Api/endpoints/account'
import Login from 'Containers/Sites/Base/Pages/AccountPanel/login'
import PasswordEditor from 'Containers/Sites/Base/Pages/AccountPanel/passwordEditor'
import AddressEditor from 'Containers/Sites/Base/Pages/AccountPanel/addressEditor'
import MsgModal from 'Containers/Sites/Base/Pages/AccountPanel/msgModal'
import { timeout } from 'Lib/purefunctions'

const PublicationStatus = ({ status }) => {
  const stValue = status === true ? 'Active' : 'Not Subscribed'

  return <div className='publicationStatus'>{stValue}</div>
}

@inject('store')
@observer
class AccountPanel extends Component {
  @observable validSession = true;

  @observable editAddress = false;

  @observable editPassword = false;

  @observable editorIsLoading = false;

  @observable modalIsOpen = false;

  @observable modalContent = '';

  @observable userInfo = {};

  constructor (props) {
    super(props)

    makeObservable(this)

    if (!this.shouldDoAccountLogin) {
      this.props.afterAuth()

      this.fetchUserInfo()
    }
  }

  @computed get shouldDoAccountLogin () {
    const exp = parseFloat(this.props.store.user.decodedAccountToken.exp) * 1000
    const diff = differenceInSeconds(new Date(exp), new Date())

    return diff <= 0
  }

  @computed get verifyAccount () {
    const { usernameDuringLogin } = this.props.store.user
    const { sitecode } = this.props.store.site

    return secondaryLogin(sitecode, usernameDuringLogin)
  }

  componentDidUpdate () {
    if (isEmpty(this.userInfo)) {
      this.fetchUserInfo()
    }
  }

  @action.bound
  async fetchUserInfo () {
    const { sitecode } = this.props.store.site

    const { value } = await getUserInfo(sitecode)

    if (value) {
      this.userInfo = value
    }
  }

  @action.bound
  async handleSubmitAddressChanges (info) {
    const { sitecode } = this.props.store.site

    this.editorIsLoading = true

    const { error } = await updateUserInfo(sitecode, info)

    if (error) {
      this.modalContent = 'error'
      this.modalIsOpen = true
    } else {
      await timeout(2 * 1000)
      await this.fetchUserInfo()

      notifications.addNotification({
        title: 'Success!',
        message: 'Your information has been updated.',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      })
    }

    this.editorIsLoading = false
    this.editAddress = false
  }

  @action.bound
  async handleSubmitPasswordChange (password) {
    const { sitecode } = this.props.store.site
    const { usernameDuringLogin } = this.props.store.user

    this.editorIsLoading = true

    const { error } = await updatePassword(
      sitecode,
      usernameDuringLogin,
      password
    )

    if (error) {
      this.modalContent = 'Something went wrong, password has not been changed.'
      this.modalIsOpen = true
    } else {
      notifications.addNotification({
        title: 'Success!',
        message: 'Your password has been updated.',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      })
    }
    this.editorIsLoading = false
    this.editPassword = false
  }

  @action.bound
  async handleAfterSuccessfulLogin (value) {
    const { afterLogin } = this.props.store.user

    if (value) {
      afterLogin(value)
      this.props.afterAuth()
    }
  }

  render () {
    const { customerNumber } = this.props.store.user.user
    const supportPhone = path(
      ['siteDetails', 'phone', 'value'],
      this.props.store.site
    )
    const { captchaKey } = this.props.store.site
    const { metaTitle } = this.props
    const {
      street,
      city,
      country,
      emailAddress,
      firstName,
      lastName,
      phoneNumber,
      postalCode,
      state
    } = this.userInfo
    const { usernameDuringLogin } = this.props.store.user

    return (
      <BaseLayout>
        <Helmet>
          <title>{metaTitle}</title>
        </Helmet>
        <div className='container' id='__subscription_account'>
          {this.shouldDoAccountLogin && (
            <div className='row'>
              <div className='col-12'>
                <Login
                  onSuccess={this.handleAfterSuccessfulLogin}
                  verify={this.verifyAccount}
                  captchaKey={captchaKey}
                  username={usernameDuringLogin}
                />
              </div>
            </div>
          )}

          {!this.shouldDoAccountLogin && (
            <>
              <div className='row'>
                <div className='col-12 col-md-7 col-lg-8'>
                  <section id='subscriptionsWrapper'>
                    {/* <notifications>
                      <h1>My Account</h1>
                      <hr />
                      <p>Your current {brand} subscriptions are listed below</p>
                    </notifications> */}

                    <main>{this.props.children}</main>
                  </section>
                </div>

                <div className='col-12 col-md-5 col-lg-4'>
                  <section id='accountWrapper'>
                    <div className='myaccount-labels justify-content-between align-items-baseline'>
                      <h2>My Login</h2>
                    </div>

                    <h5>USERNAME</h5>
                    <div id='userna meValue' className='sub-text'>
                      {usernameDuringLogin}
                    </div>

                    {!this.editPassword && (
                      <>
                        <h5>PASSWORD</h5>

                        <div className='passwordGroup'>
                          <div id='passwordMask'>
                            <span className='stars sub-text'>******** </span>
                          </div>

                          <button
                            type='button'
                            className='btn btn-secondary edit-button align-items-baseline'
                            onClick={() => {
                              this.editPassword = true
                            }}
                          >
                            <div>
                              <FontAwesomeIcon icon='edit' className='mr-1' />
                              UPDATE PASSWORD
                            </div>
                          </button>
                        </div>
                      </>
                    )}

                    {this.editPassword && (
                      <PasswordEditor
                        onSubmit={this.handleSubmitPasswordChange}
                        onCancel={() => {
                          this.editPassword = false
                        }}
                        isLoading={this.editorIsLoading}
                      />
                    )}

                    <h5>SUBSCRIBER ID</h5>
                    <div id='subscriberId' className='sub-text'>
                      {customerNumber}
                    </div>

                    <hr />
                    <div className='myaccount-labels'>
                      <h2>My Details</h2>

                      {!this.editAddress && (
                        <aside className='editBtnWrapper'>
                          <button
                            type='button'
                            className='btn btn-secondary edit-button align-items-baseline'
                            onClick={() => {
                              this.editAddress = true
                            }}
                          >
                            <FontAwesomeIcon icon='edit' className='mr-1' />
                            EDIT
                          </button>
                        </aside>
                      )}
                    </div>

                    {!this.editAddress && (
                      <>
                        <h5>NAME</h5>
                        <div id='nameValue' className='sub-text'>
                          {`${firstName} ${lastName}` || 'N/A'}
                        </div>
                        <h5>PHONE</h5>
                        <div id='numberValue' className='sub-text'>
                          {phoneNumber || 'N/A'}
                        </div>
                        <h5>EMAIL</h5>
                        <div id='emai lValue' className='sub-text email'>
                          {emailAddress}
                        </div>

                        <h5>POSTAL ADDRESS</h5>
                        <div id='addressValue' className='sub-text'>
                          <div>{street}</div>
                          {city && street && state && postalCode && country && (
                            <div>
                              {city}, {state} {postalCode} <p>{country}</p>
                            </div>
                          )}

                          {/* {street && <div>{street} </div>}
                          {city && <div>{city}</div>}
                          {postalCode && <div>{postalCode}</div>}
                          {state && <div>{state}</div>}
                          {country && <div>{country}</div>} */}
                        </div>
                      </>
                    )}

                    {this.editAddress && (
                      <AddressEditor
                        initialValues={this.userInfo}
                        onSubmit={this.handleSubmitAddressChanges}
                        isLoading={this.editorIsLoading}
                        onCancel={() => {
                          this.editAddress = false
                        }}
                      />
                    )}

                    <hr />

                    <h2>Need Help?</h2>

                    <h5>PHONE</h5>
                    <div id='supportPhoneValue' className='sub-text'>
                      {supportPhone}
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}
        </div>
        {this.modalIsOpen && (
          <MsgModal
            content={this.modalContent}
            onClose={() => {
              this.modalIsOpen = false
            }}
          />
        )}
      </BaseLayout>
    )
  }
}

PublicationStatus.propTypes = {
  status: PT.bool
}

AccountPanel.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  title: PT.string,
  metaTitle: PT.string,
  afterAuth: PT.func.isRequired,
  children: PT.node
}

export default AccountPanel
