import React, { useState } from 'react'
import PT from 'prop-types'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'Components/link'
import {
  ascend, compose, filter, isEmpty, prop, propEq, sortWith
} from 'ramda'

import ClickOut from 'Lib/clickOut'

const DropDownMenu = (props) => {
  const [menuState, handleMenuState] = useState(false)

  const toggleMenu = () => {
    handleMenuState((prevMenuState) => !prevMenuState)
  }

  let pubTitle
  if (props.currentPublication) pubTitle = props.currentPublication.title

  const sortByNum = sortWith([ascend(prop('product_display_order'))])
  const takeShown = filter(propEq('show_on_subscription_page', 'yes'))
  const removeChildPubs = filter(compose(
    isEmpty,
    prop('parent_publication')
  ))
  const sortedPublications = compose(
    sortByNum,
    takeShown,
    removeChildPubs
  )(props.availablePublications)

  return (
    <>
      <div className='dropdown'>
        <button
          tabIndex="0"
          aria-haspopup="true"
          aria-controls="publicationMenu"
          aria-expanded={menuState ? 'true' : 'false'}
          type='button'
          className='btn text-white'
          onClick={() => toggleMenu()}
        >
          <span className='dropdownLabel'> Publication: </span>
          <span className='dropdownBrands'>  {pubTitle || 'Select Publication'} </span>
          &nbsp;
          {menuState ? <FontAwesomeIcon icon={faChevronUp} size='sm' /> : <FontAwesomeIcon icon={faChevronDown} size='sm' />}
        </button>
        {menuState && (
          <ClickOut onClickOut={() => toggleMenu()}>
            <div
              aria-labelledby="publicationMenu"
              style={{ fontSize: '12px' }}
              className={menuState ? 'dropdown-menu d-inline-block' : 'dropdown-menu'}
            >
              <>
                {sortedPublications.map((pub) => (
                  <Link
                    role='menuitem'
                    key={pub._id}
                    className={pubTitle === pub.title ? 'dropdown-item bg-secondary text-white' : 'dropdown-item'}
                    to={isEmpty(pub.archive_url) ? `/${pub.code}` : pub.archive_url}
                  >{pub.title}
                  </Link>
                ))}
              </>
            </div>
          </ClickOut>
        )}
      </div>
    </>
  )
}

DropDownMenu.propTypes = {
  availablePublications: PT.array,
  currentPublication: PT.object
}

export default DropDownMenu
