import React from 'react'
import PT from 'prop-types'
import { isEmpty } from 'ramda'

const MobileAd = ({ ad }) => {
  if (!ad || isEmpty(ad)) {
    return null
  }

  const {
    redirectURL, imageURL, altText, width, height
  } = ad

  return (
    <div className='sidebarAdContainer d-xl-none mt-5 text-center mb-3'>
      <a href={redirectURL} target='_blank' rel='noopener noreferrer'>
        <img src={imageURL} alt={altText} width={width} height={height} />
      </a>
    </div>
  )
}

const stringOrNumber = PT.oneOfType([PT.string, PT.number])

MobileAd.propTypes = {
  ad: PT.oneOfType([
    PT.string,
    PT.shape({
      imageURL: PT.string,
      redirectURL: PT.string,
      altText: PT.string,
      width: stringOrNumber,
      height: stringOrNumber
    })
  ])
}

MobileAd.defaultProps = { ad: null }

export default MobileAd
