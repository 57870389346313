import { forEach, replace } from 'ramda'

const changeFavicon = (src) => {
  const link = document.createElement('link')
  const oldLink = document.getElementById('dynamic-favicon')

  link.id = 'dynamic-favicon'
  link.name = 'favicon'
  link.rel = 'shortcut icon'
  link.type = 'image/png'
  link.href = `${window.location.origin}/${src}?=${Math.random()}`

  document.head.appendChild(link)
  if (oldLink) {
    document.head.removeChild(oldLink)
  }
}

export const adjustPageTitle = (store) => {
  let count = 0

  forEach((p) => {
    // eslint-disable-next-line no-shadow
    const publication = store.mst.publication.getById(p.publication)

    if (!store.mst.userPublication.containsPublication(p.publication)) {
      p.updateProperty('consumed', true)
      return
    }

    if (p.consumed || p.post_type !== 'content') {
      return
    }

    if (publication && publication.title) {
      count += 1
    }
  }, store.mst.realtime.messages)

  const title = replace(/\([^)]*\)/, '', document.title)

  if (count === 0) {
    changeFavicon(`${store.site.getFaviconName(false)}.png`)
  }

  if (count > 0) {
    document.title = `( New Updates ) ${title}`
    changeFavicon(`${store.site.getFaviconName(true)}.png`)
  }
}
