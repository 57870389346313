import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import classnames from 'classnames'

import uniqueString from 'Lib/unique-string'
import Link from 'Components/link'

@observer
class Header extends Component {
  static propTypes = {
    title: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string
    })),
    // noBackTo: PropTypes.bool,
    // color: PropTypes.string,
    publicationCode: PropTypes.string
  };

  render () {
    const {
      title,
      links,
      publicationCode
    } = this.props

    return (
      <>
        <div className={classnames('subscriber-heading')} data-name='mySubsWalkthroughStep-3'>
          <div className='row'>
            <div className='col-lg-6 '>
              <h1><Link to={`/${publicationCode}`}>{title}</Link></h1>
            </div>
            <div className='col-lg-6'>
              <div className='subscriber-heading-list'>
                {links.map((item) => <p key={uniqueString()}><Link to={item.url}>{item.title}</Link></p>)}
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='borderline' />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
