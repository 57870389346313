import React, { MouseEvent } from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

import { ILesson, ISection } from 'Stores/mst'
import { useCourseContext } from './index'

interface IAccordionItem {
  section: ISection
  lesson: ILesson
}

const AccordionItem = ({ section, lesson }: IAccordionItem): JSX.Element => {
  const { activeLesson, setActivePair } = useCourseContext()

  const handleClick = (e: MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()
    setActivePair(section, lesson)
  }

  const isActive = activeLesson?.id === lesson.id

  return (
    <div className={classnames('accordion-item', {'focused': isActive})}
         onClick={handleClick}
         id={lesson.id}>
      <div className='row'>
        <div className='title col-lg-9'>
          {lesson.titlePrefix} {lesson.secondaryTitle}
          <span className='infoSign mobile d-md-none'>
          {section.isLocked && (<FontAwesomeIcon icon={faCircleInfo} size='lg' />)}
          </span>
        </div>

        {!section.isLocked && (
          <>
            <div className='duration d-none d-lg-block col-md-3'><span>{lesson.videoDuration}</span></div>
            <div className='duration mobile d-md-none'>Duration: {lesson.videoDuration}</div>
          </>
        )}

        {section.isLocked && (
          <>
            <div className='infoSign d-none d-lg-block col-md-3'>
              <span><FontAwesomeIcon icon={faCircleInfo} size='lg' /></span>
            </div>
          </>
        )}

      </div>
    </div>
  )
}

export default AccordionItem
