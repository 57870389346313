import {
  prop, sortBy, take, compose, filter, propEq
} from 'ramda'

const message = (self) => ({
  get latest5 () {
    return take(5, sortBy(prop('id'), self.messages))
  },

  get nonConsumed () {
    return compose(
      sortBy(prop('id')),
      filter(propEq('consumed', false))
    )(self.messages)
  }
})

export default message
