import { types, getParent } from 'mobx-state-tree'

// TODO Investigate if it can be removed

export const PubCode = types
  .model('PubCode', {
    _id: types.identifier,
    wordpressId: types.string,
    code: types.string,
    title: types.string,
    description: types.string,
    wordpressSiteName: types.string
  })
  .actions((self) => ({
    remove () {
      getParent(self).remove(self)
    }
  }))
