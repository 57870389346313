import React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router'

import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import Link from 'Components/link'

const PageNotFound = (props: RouteComponentProps): JSX.Element => {
  const handleGoBack = (): void => {
    props.history.go(-2)
  }

  return (
    <TemplateSingle
      title='Page Not Found'
      content={(
        <section className='error-404 not-found'>
          <header className='page-header'>
            <h1 className='page-title'>Oops! That page can’t be found.</h1>
          </header>
          <div className='page-content'>
            <p>It looks like nothing was found at this location.
              Go to <Link to='/my-subscriptions' samepage>My Subscriptions</Link> or
              <button className='btnAsLink' onClick={handleGoBack}>
                {/* eslint-disable-next-line */}
                <a>Go Back.</a>
              </button>
            </p>
          </div>
        </section>
      )}
    />
  )
}

export default withRouter(PageNotFound)
