import React, { useState, useRef, useEffect } from 'react'
import PT from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import { pathOr } from 'ramda'

import Spinner from 'Components/spinner'

const Login = ({
  captchaKey, verify, onSuccess, username = ''
}) => {
  const [password, setPassword] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const recaptchaRef = useRef(null)

  useEffect(() => {
    if (!loading) {
      return
    }

    const doSubmit = async () => {
      const { error, value } = await verify(password, captcha)

      if (error) {
        try {
          const msg = pathOr('Authentication failed', ['response', 'data', 'message'])(error)

          setErrorMsg(msg)
        } catch (err) {
          console.error(err)
        }

        setLoading(false)
        setCaptcha('')

        if (recaptchaRef && recaptchaRef.current) {
          recaptchaRef.current.reset()
        }

        return false
      }

      return value
    }

    doSubmit().then((value) => {
      if (value) {
        onSuccess(value)
      }
    })
  // eslint-disable-next-line
  }, [loading]);

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)
  }

  return (
    <section className='secondaryLogin'>
      <h5>Verification</h5>
      <p>
        For security reasons, please re-enter your password to verify your access before proceeding to your account
        page.
      </p>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <input
            type='text'
            value={username}
            disabled
          />
        </div>

        <div className='form-group'>
          <input
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Password'
          />
        </div>
        <div className='form-group recaptchaWrapper'>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={captchaKey}
            onChange={setCaptcha}
          />
        </div>
        <div className='form-group'>
          <div className='error'>{errorMsg}</div>
        </div>
        {!loading && (
          <button
            type='submit'
            className='btn btn-dark'
          >
            Log In
          </button>
        )}
        {loading && (
          <button
            type='submit'
            className='btn btn-dark'
            disabled
          >
            <Spinner />
          </button>
        )}
      </form>
    </section>
  )
}

Login.propTypes = {
  captchaKey: PT.string.isRequired,
  verify: PT.func.isRequired,
  onSuccess: PT.func.isRequired,
  username: PT.string
}

export default Login
