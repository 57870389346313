import React from 'react'
import PT from 'prop-types'
import { isEmpty, propOr } from 'ramda'

const Meta = ({
  author, date, readingTimeMin
}) => {
  if (!author || isEmpty(author)) {
    return null
  }

  const authorTitle = propOr('', 'title', author)
  const authorAvatar = propOr('', 'avatar', author)

  return (
    <div className='byline-meta'>
      {authorAvatar && <img className='avatar k-avatar-circle' src={authorAvatar} alt={authorTitle} />}
      <div className='ml-2'>
        <span className='bold author-class'> {authorTitle}</span>
        <br />
        <span className='text-muted'>
          <span>{date} </span>
          <br />
          {readingTimeMin && (
            <span>{readingTimeMin} min read</span>
          )}
        </span>
      </div>
    </div>
  )
}

Meta.propTypes = {
  author: PT.shape({
    title: PT.string,
    avatar: PT.string
  }),
  date: PT.string,
  readingTimeMin: PT.number
}

export default Meta
