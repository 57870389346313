import { getRoot } from 'mobx-state-tree'
import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

import { Notification } from 'Stores/mst/Models/notification'
import { INotification, INotificationStore } from 'Stores/mst'
import { getNotifications, markNotificationRead, EditorialNotification } from 'Api/endpoints/alert'

interface LocalEditorialNotification extends EditorialNotification {
  parsedDate: Date
}

const notification = (self: INotificationStore): INotificationStore => ({
  createObject (input: EditorialNotification) {
    const item: LocalEditorialNotification = {...input, parsedDate: toDate(parseISO(input.createdAt))}

    return Notification.create(item)
  },

  pushMessage (item, skipCache = false) {
    const existingItem = self.notifications.valueOf()
      .filter((a) => a._id === item._id)[0]

    if (existingItem != null && !skipCache) {
      return
    }
    if (existingItem != null && skipCache) {
      existingItem.update(item)
      return
    }

    self.notifications.push(item)
  },

  upsert (item: EditorialNotification, skipCache = false): void {
    const obj = this.createObject(item)

    this.pushMessage(obj, skipCache)
  },

  // Used by fetchAll
  upsertAll (items: EditorialNotification[], skipCache = false): void {
    items.forEach(item => self.upsert(item, skipCache))
  },

  async fetchAll (): Promise<void> {
    const { sitecode } = getRoot<INotificationStore>(self)._root.site

    await pipe(
      getNotifications(sitecode),
      TE.map(self.upsertAll)
    )()
  },

  async markRead (id: string): Promise<void> {
    const entry: INotification = self.find(id)
    const { sitecode } = getRoot<INotificationStore>(self)._root.site

    if (entry == null) {
      console.log(`Entry not found: ${id}`)
      return
    }

    void await markNotificationRead(sitecode, id)()
    entry.updateProperty('isRead', true)
  }
})

export default notification
