import axios from 'axios'
import { test, path, curry } from 'ramda'
import { differenceInSeconds } from 'date-fns'

import { handleError as _handleError } from 'Api/error'
import { readableRandomStringMaker } from 'Lib/unique-string'
import type { Nullable, Maybe } from 'Lib/Types/base'
import { IDecodedToken } from 'Lib/Types/payload'
import { decodeJwtPayload } from 'Lib/payload'

const isBeta = test(/-beta/, document.location.hostname) || test(/beta-/, document.location.hostname)

export const updateCacheBustValue = (): string => {
  const str = readableRandomStringMaker(8)

  localStorage.setItem('cachebust', str)

  return str
}

const decodeUserToken = (tokenType: string): Nullable<IDecodedToken> => {
  const lrgStr = localStorage.getItem('lrg_user')

  if (lrgStr === null) {
    return null
  }

  try {
    const lrg = JSON.parse(lrgStr)
    const token: Maybe<string> = path([tokenType], lrg)

    if (token == null) {
      return null
    }

    const decodedToken = decodeJwtPayload(token)

    if (decodedToken === null) {
      return null
    }

    const exp = decodedToken.exp * 1000
    const diff = differenceInSeconds(new Date(exp), new Date())
    const encryptedSnaid = decodedToken.customerEncryptedSnaid

    return {
      token,
      diff,
      encryptedSnaid
    }
  } catch (e) {
    console.error(e)
    return null
  }
}

const interceptor = curry((tokenType, config) => {
  const decodedToken = decodeUserToken(tokenType)

  if (decodedToken == null) {
    return config
  }

  const {
    token,
    diff,
    encryptedSnaid
  } = decodedToken

  if (token.length === 0) {
    return config
  }

  if (diff <= 0) {
    // Remove the token from the storage
    localStorage.removeItem('lrg_user')

    // Reload app, to login
    window.location.href = '/login'

    return {
      headers: {},
      method: config.method,
      url: ''
    }
  }

  config.headers.Authorization = `Bearer ${token}`
  config.headers['encrypted-snaid'] = encryptedSnaid

  return config
})

const articleInterceptor = curry((tokenType, config) => {

  const _config = interceptor(tokenType, config)

  let cacheStr = localStorage.getItem('cachebust')

  if (cacheStr == null) {
    cacheStr = updateCacheBustValue()
  }

  _config.headers.cachebust = cacheStr

  return _config
})

const interceptorAuthOnFulfilled = interceptor('token')
const articleInterceptorAuthOnFulfilled = articleInterceptor('token')
const interceptorAccountAuthOnFulfilled = interceptor('accountToken')
const interceptorAuthOnError = async (error): Promise<string> => await Promise.reject(error)

const dev = {
  auth: {
    baseURL: 'https://dev-auth.cloudlgr.com',
    key: 'WWJMUgGoKG6hx8swvdZnWYkpVpEHziN9PY12cDFh'
  },
  portfolio: {
    baseURL: 'https://dev-portfolio.cloudlgr.com',
    key: 'e16bgOXbhp6YD3jttF80o4Qpbn0DI8I39ZqQLOrU'
  },
  article: {
    baseURL: 'https://dev-article.cloudlgr.com',
    key: 'zRf7maHonl91tYB7caKaC19lzZeiTw7B7ZV896Xy'
  },
  eletter: {
    baseURL: 'https://dev-eletter.cloudlgr.com',
    key: '4nMQDDaWgR5SuW96tEVzv4Hm6Yvykwx643pJj8br'
  },
  user: {
    baseURL: 'https://dev-user.cloudlgr.com',
    key: 'ClzgTD6GHO5YO1QEPt8OX8Pw6Dk7bktv4AqU1Ma3'
  },
  util: {
    baseURL: 'https://dev-util.cloudlgr.com',
    key: 'EKuDQBJ2EU6QdeuaNgwPx9MOEOnAB5Yc2wT190Iu'
  },
  iot: {
    baseURL: 'https://dev-iot.cloudlgr.com',
    key: '0FEoJFStHT9Zsb3zJZCukaaHi9d4cUynK8w2s5Yd'
  },
  alert: {
    baseURL: 'https://dev-alert.cloudlgr.com',
    key: 'm0WiZg5I2Q5V6i679cVQ567RyKrW7R1JefL6QfTb'
  },
  course: {
    baseURL: 'https://dev-courses.cloudlgr.com',
    key: 'iFrY56r2Nm2XlpSMGEyq58StvmSZg1ry6tXlE1nr'
  }
}

const prod = {
  auth: {
    baseURL: 'https://auth.cloudlgr.com',
    key: 'zAhsJRx2G8a6BuSvuOXDX9nqNvTMOPtW8ebO9clT'
  },
  portfolio: {
    baseURL: 'https://portfolio.cloudlgr.com',
    key: 'BErjdM9o4X7NwDdrt31Zx5lj1ETb6wNPaHFHeAKa'
  },
  article: {
    baseURL: 'https://article.cloudlgr.com',
    key: 'xwAzMa08Ea3TilnxNvPlg4NaYxHm70Mo1LQ2gFIi'
  },
  eletter: {
    baseURL: 'https://eletter.cloudlgr.com',
    key: 'tuTaNrCWAV3lYbxwOicvr4mjzUuG9Fea17dfhz0G'
  },
  user: {
    baseURL: 'https://user.cloudlgr.com',
    key: 'YPirQqJLxa9uOGfueW9uU8zolmzADoD8aBpWxlGk'
  },
  util: {
    baseURL: 'https://util.cloudlgr.com',
    key: ''
  },
  iot: {
    baseURL: 'https://iot.cloudlgr.com',
    key: 'UByDWWwdsl6gYkGNiTnaN4qIhB9H23502edpPxzN'
  },
  alert: {
    baseURL: 'https://alert.cloudlgr.com',
    key: 'uGrd8XCkr37Rt2H7rRxk45B1PCh3N3999jnTCod2'
  },
  course: {
    baseURL: 'https://courses.cloudlgr.com',
    key: 'jfOpvOum5p9wlkuunFauk4jk4xUKbhQVAFwLcvwh'
  }
}

const config = isBeta ? dev : prod

export const upstreamAuth = axios.create({
  baseURL: config.auth.baseURL,
  headers: {
    'x-api-key': config.auth.key
  }
})

upstreamAuth.interceptors.request.use(
  interceptorAuthOnFulfilled,
  interceptorAuthOnError
)

export const upstreamPortfolio = axios.create({
  baseURL: config.portfolio.baseURL,
  headers: {
    'x-api-key': config.portfolio.key
  }
})

upstreamPortfolio.interceptors.request.use(
  interceptorAuthOnFulfilled,
  interceptorAuthOnError
)

export const upstreamArticle = axios.create({
  baseURL: config.article.baseURL,
  headers: {
    'x-api-key': config.article.key
  }
})

upstreamArticle.interceptors.request.use(
  articleInterceptorAuthOnFulfilled,
  interceptorAuthOnError
)

export const upstreamEletter = axios.create({
  baseURL: config.eletter.baseURL,
  headers: {
    'x-api-key': config.eletter.key
  }
})

// upstreamEletter.interceptors.request.use(
//   interceptorAuthOnFulfilled,
//   interceptorAuthOnError
// )

export const upstreamAccount = axios.create({
  baseURL: config.auth.baseURL,
  headers: {
    'x-api-key': config.auth.key
  }
})

upstreamAccount.interceptors.request.use(
  interceptorAccountAuthOnFulfilled,
  interceptorAuthOnError
)

export const upstreamUser = axios.create({
  baseURL: config.user.baseURL,
  headers: {
    'x-api-key': config.user.key
  }
})

upstreamUser.interceptors.request.use(
  interceptorAuthOnFulfilled,
  interceptorAuthOnError
)

export const upstreamUtil = axios.create({
  baseURL: config.util.baseURL,
  headers: {
    'x-api-key': config.util.key
  }
})

upstreamUtil.interceptors.request.use(
  interceptorAuthOnFulfilled,
  interceptorAuthOnError
)

export const upstreamIot = axios.create({
  baseURL: config.iot.baseURL,
  headers: {
    'x-api-key': config.iot.key
  }
})

upstreamIot.interceptors.request.use(
  interceptorAuthOnFulfilled,
  interceptorAuthOnError
)

export const upstreamAlert = axios.create({
  baseURL: config.alert.baseURL,
  headers: {
    'x-api-key': config.alert.key
  }
})

upstreamAlert.interceptors.request.use(
  interceptorAuthOnFulfilled,
  interceptorAuthOnError
)

export const upstreamCourse = axios.create({
  baseURL: config.course.baseURL,
  headers: {
    'x-api-key': config.course.key
  }
})

upstreamCourse.interceptors.request.use(
  interceptorAuthOnFulfilled,
  interceptorAuthOnError
)

export const handleError = _handleError
