import React from 'react'
import { withRouter } from 'react-router'
import { toC } from 'react-godfather'
import { observer, PropTypes as MobxPropTypes } from 'mobx-react'
import ReactRouterPropTypes from 'react-router-prop-types'

import { StoreContext } from 'App'

const Home = ({ store, history }) => {
  if (store.user.isLoggedIn) {
    history.push('/login')
  } else {
    window.location.href = '/my-subscriptions'
  }

  return () => {
    return <div />
  }
}

Home.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  history: ReactRouterPropTypes.history.isRequired
}

const HomeContainer = observer(toC(Home, []))

export default (withRouter(props => {
  return (
    <StoreContext.Consumer>
      {value => <HomeContainer {...props} store={value} />}
    </StoreContext.Consumer>
  )
}))
