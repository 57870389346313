import React from 'react'

import RouteIf from 'Components/conditionalRoute'
import ProtectRoute from 'Components/protected'
import Editors from 'Containers/Sites/Base/Pages/Editors'
import Products from 'Containers/Sites/Base/Pages/Products'
import ProductsComponent from 'Containers/Sites/Base/Pages/MySubscriptions/base'
import ContactComponent from 'Containers/Sites/Base/Pages/Contact'
import ExternalAccountComponent from '../../../Base/Pages/ExternalAccount'

interface IStore {
  ui: {
    isLoggedIn: boolean
  }
}

export default (store: IStore): JSX.Element[] => {
  const isLoggedIn: boolean = store.ui.isLoggedIn

  return [
    <RouteIf
      failCondition={!isLoggedIn}
      failLocation='https://www.gulfportanalytics.com/contact-us/'
      external
      path='/contact'
      key='/contact'
      component={ContactComponent}
    />,
    <RouteIf
      failCondition={!isLoggedIn}
      failLocation='https://www.gulfportanalytics.com/authors/'
      external
      path='/editors'
      key='/editors'
      component={Editors}
    />,
    <RouteIf
      failCondition={!isLoggedIn}
      failLocation='https://www.gulfportanalytics.com/products/'
      external
      path='/products'
      key='/products'
      component={Products}
    />,
    <ProtectRoute
      exact
      path='/my-subscriptions'
      key='/my-subscriptions'
      component={ProductsComponent}
    />,
    <ProtectRoute exact path='/my-account' key='/my-account-new' component={ExternalAccountComponent} />,
    <ProtectRoute exact path='/account' key='/account-redirect' component={ExternalAccountComponent} />,
  ]
}
