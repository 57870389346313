import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/Array'
import { Predicate } from 'fp-ts/Predicate'
import * as E from 'fp-ts/lib/Either'
import * as D from 'io-ts/Decoder'
import * as L from 'monocle-ts/Lens'
// import { option as SO } from 'fp-ts-std'
// import * as Eq from 'fp-ts/Eq'
// import { useStableEffect } from 'fp-ts-react-stable-hooks'
// import { traceWithValue } from 'fp-ts-std/Debug'

import { getPortfolio, RetPortfolio } from 'Api/endpoints/portfolio'
import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import Spinner from 'Components/spinner'
import { html2jsx } from 'Lib/parser'
import BaseLayout from '../../Layouts/base'
import { IError } from 'Api/error'
import { IPublicationStore } from 'Stores/mst'
import type { MaybeString, Nullable } from 'Lib/Types/base'

const usePortfolio = (portfolioId: Nullable<string>, tradeGroup: string): [boolean, MaybeString] => {
  const [portfolio, setPortfolio] = useState<MaybeString>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetch = async (code: string, tg: string): Promise<void> => {
      setLoading(true)
      const res: RetPortfolio | IError = await getPortfolio(code, tg)

      setLoading(false)
      if ('value' in res) {setPortfolio(res.value)}
    }

    if (portfolioId != null) {
      void fetch(portfolioId, tradeGroup)
    }
  }, [portfolioId, tradeGroup])

  return [loading, portfolio]
}

interface ITradeGroupComponent extends RouteComponentProps<MatchParams> {
  publication: IPublicationStore
}

const TradeGroup = D.struct({
  id: D.string,
  code: D.string,
  title: D.string
})
export type ITradeGroup = D.TypeOf<typeof TradeGroup>

export const matchTradeGroupByCode = (needle: string, haystack: ITradeGroup[]): O.Option<ITradeGroup> => {
  const hasSameCode: Predicate<ITradeGroup> = (tg: ITradeGroup) => tg.code === needle

  return pipe(
    haystack,
    A.filter(hasSameCode),
    A.head
  )
}

const Haystack: D.Decoder<unknown, ITradeGroup[]> = D.array(TradeGroup)

const idLens = O.map(pipe(L.id<ITradeGroup>(), L.prop('id')).get)
const titleLens = pipe(L.id<ITradeGroup>(), L.prop('title'))

interface MatchParams {
  code: string
}

export const TradeGroupComponent = ({
  match,
  publication
}: ITradeGroupComponent): JSX.Element => {
  const { code } = match.params
  const tradeGroups = pipe(
    Haystack.decode(publication.settings.tradeGroups),
    E.getOrElse((): ITradeGroup[] => [])
  )
  // const tradeGroups: ITradeGroup[] = publication.settings.tradeGroups != null ? publication.settings.tradeGroups : []
  const matchedTradeGroup = matchTradeGroupByCode(code, tradeGroups)
  const tgId = idLens(matchedTradeGroup)
  const title = pipe(
    matchedTradeGroup,
    O.map(titleLens.get),
    O.getOrElse(() => '')
  )
  const initialContent = O.isSome(tgId) ? <div/> : (
    <main>
      <div className="portfolioContent">
        <h2>Invalid content request.</h2>
      </div>
    </main>
  )
  const targetGroupId: Nullable<string> = O.getOrElseW(() => null)(tgId)

  const [loading, portfolioRaw] = usePortfolio(targetGroupId, title)
  const [content, setContent] = useState<JSX.Element>(initialContent)

  useEffect(() => {
    if (loading) {
      setContent(<Spinner/>)
    }

    if (portfolioRaw != null) {
      setContent((
        <main>
          <div className="portfolioContent">
            {html2jsx(portfolioRaw)}
          </div>
        </main>
      ))
    }
  }, [loading, portfolioRaw])

  return (
    <BaseLayout>
      <TemplateSingle
        noLayout
        content={content}
        extraClass="__portfolio"
        isPortfolio
      />
    </BaseLayout>
  )
}

export default withRouter(TradeGroupComponent)
