import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'

import { Bookmark } from 'Stores/mst/Models/bookmark'

import { IBookmark, IBookmarkStore } from '../index'

type optionalKeys = 'parsedDate' | 'favoriteCreatedAt' | 'favoriteParsedDate'
interface ILocalBookmark extends Omit<IBookmark, optionalKeys> {
  parsedDate?: Date
  favoriteCreatedAt?: string
  favoriteParsedDate?: Date
}

const bookmark = (self: IBookmarkStore): IBookmarkStore => ({
  createObject (item: ILocalBookmark): IBookmark {
    if (item.createdAt != null) {
      item.parsedDate = toDate(parseISO(item.createdAt))
    }

    if (item.favoriteCreatedAt != null) {
      item.favoriteParsedDate = toDate(parseISO(item.favoriteCreatedAt))
    }

    return Bookmark.create(item)
  },

  pushItem (item, skipCache = false) {
    const existingItem = self.items.valueOf()
      .filter((a) => a._id === item._id)[0]

    if (existingItem == null) {
      self.items.push(item)

      return
    }

    if (skipCache) {
      existingItem.update(item)
    }
  },

  pushItems (items) {
    items.forEach((item) => this.pushItem(item))
  }
})

export default bookmark
