import {create, IHydrateResult} from 'mobx-persist'
import {types} from 'mobx-state-tree'
import { configure } from 'mobx'

import Ui from './ui'
import User from './user'
import Site from './site'
import Portfolio from './portfolio'
import {
  articleStore,
  siteCodeStore,
  categoryStore,
  newsletterTypeStore,
  pubCodeStore,
  authorStore,
  publicationStore,
  userPublicationStore,
  realtimeStore,
  bookmarkStore,
  notificationStore,
  courseStore
} from './mst'
import { Nullable } from '../Lib/Types/base'

configure({ enforceActions: 'never' })

const hydrate = create({ jsonify: true })

interface IRootPatch {
  _root: Nullable<Store>
}

export const rootStore = types.model('mst', {
  article: articleStore,
  publication: publicationStore,
  userPublication: userPublicationStore,
  author: authorStore,
  pubCode: pubCodeStore,
  newsletterType: newsletterTypeStore,
  category: categoryStore,
  siteCode: siteCodeStore,
  realtime: realtimeStore,
  bookmark: bookmarkStore,
  notification: notificationStore,
  course: courseStore,
})
  .volatile((): IRootPatch => ({
    _root: null
  }))
  .actions((self) => ({
    setOuterRoot (value: Nullable<Store>) {
      self._root = value
    }
  }))

export default class Store {
  public readonly mst;
  public readonly site: Site;
  public readonly userPromise: IHydrateResult<User>;
  public readonly ui: Ui;
  public readonly portfolio: Portfolio;
  public user: User;

  constructor () {
    this.site = new Site(this)
    this.userPromise = hydrate('lrg_user', new User(this))
    this.user = new User(this) // Will get replaced AFTER the hydration promise resolves
    this.ui = new Ui(this)
    this.portfolio = new Portfolio(this)

    const article = articleStore.create({
      articles: [],
      state: 'done'
    })
    const siteCode = siteCodeStore.create({
      siteCode: ['all', 'LG', 'PB', 'RE', 'BR', 'JC', 'OT', 'NP', 'WM'],
      state: 'done'
    })
    const category = categoryStore.create({
      categories: [],
      state: 'done'
    })
    const newsletterType = newsletterTypeStore.create({
      newsletterTypes: [],
      state: 'done'
    })
    const pubCode = pubCodeStore.create({
      pubCodes: [],
      state: 'done'
    })
    const author = authorStore.create({
      authors: [],
      state: 'done'
    })
    const publication = publicationStore.create({
      publications: [],
      state: 'done'
    })
    const userPublication = userPublicationStore.create({
      userPublications: [],
      state: 'done'
    })
    const realtime = realtimeStore.create({
      messages: []
    })
    const bookmark = bookmarkStore.create({
      items: []
    })
    const notification = notificationStore.create({
      notifications: []
    })
    const course = courseStore.create({
      courses: [],
      sections: [],
      lessons: []
    })

    this.mst = rootStore.create({
      article,
      publication,
      userPublication,
      author,
      pubCode,
      newsletterType,
      category,
      siteCode,
      realtime,
      bookmark,
      notification,
      course
    })

    this.mst.setOuterRoot(this)
  }
}
