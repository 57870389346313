import { getRoot } from 'mobx-state-tree'
import { includes, pluck, prop } from 'ramda'

const userPublication = (self) => ({
  push (item) {
    const itemExists = self.userPublications.valueOf().filter((a) => a._id === item._id)[0]

    if (itemExists) {
      return
    }

    self.userPublications.push(item)
  },

  pushUserPublications (items) {
    items.map((item) => this.push(item))
  },

  localUpdate () {
    const root = prop('_root', getRoot(self))
    this.pushUserPublications(root.user.userPublications.map((up) => ({
      _id: up,
      publicationId: up
    })))
  },

  containsPublication (id) {
    const userPublicationIds = pluck('publicationId', self.userPublications)

    return !!includes(id, userPublicationIds)
  },

  clear () {
    self.userPublications = []
  }
})

export default userPublication
