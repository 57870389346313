import React from 'react'
import { flatten, map } from 'ramda'
import ProtectRoute from '../../../../../Components/protected'
import Product from '../../Pages/Publication'
import ProductArchive from '../../Pages/Archive'
import PortfolioSingleComponent from '../../Pages/Portfolio/single'
import Bonus from '../../Pages/Bonus'
import DynamicPostComponent from '../../Pages/Dynamic'
import Course from '../../Pages/Course'
import TradeGroupComponent from '../../Pages/TradeGroup'

const generatePublicationRoutes = map((publication) => [
  /* Publication Page */
  <ProtectRoute
    key={`/${publication.code}`}
    exact
    publication={publication}
    path={`/${publication.code}`}
    render={(props) => (
      <Product
        {...props}
        publication={publication}
      />
    )}
  />,
  /* Publication Issues & Updates Archive */
  <ProtectRoute
    key={`/${publication.code}/archives`}
    exact
    publication={publication}
    path={`/${publication.code}/archives`}
    render={(props) => (
      <ProductArchive
        {...props}
        type='content'
        publication={publication}
      />
    )}
  />,
  /* Publication Archive Single Newsletter Type */
  <ProtectRoute
    key={`/${publication.code}/archives/:newslettertype`}
    exact
    publication={publication}
    path={`/${publication.code}/archives/:newslettertype`}
    render={(props) => (
      <ProductArchive
        {...props}
        type='content'
        publication={publication}
      />
    )}
  />,
  /* Publication Issues & Updates Archive Filtered By Year/Month */
  <ProtectRoute
    key={`/${publication.code}/archives/:year/:month`}
    exact
    publication={publication}
    path={`/${publication.code}/archives/:year/:month`}
    render={(props) => (
      <ProductArchive
        {...props}
        type='content'
        publication={publication}
      />
    )}
  />,
  /* Publication Archive Single Newsletter Type Filtered By Year/Month */
  <ProtectRoute
    key={`/${publication.code}/archives/:newslettertype/:year/:month`}
    exact
    publication={publication}
    path={`/${publication.code}/archives/:newslettertype/:year/:month`}
    render={(props) => (
      <ProductArchive
        {...props}
        type='content'
        publication={publication}
      />
    )}
  />,
  /* Publication Special Reports Archive */
  <ProtectRoute
    key={`/${publication.code}/special-reports`}
    exact
    publication={publication}
    path={`/${publication.code}/special-reports`}
    render={(props) => (
      <ProductArchive
        {...props}
        type='special-report'
        publication={publication}
      />
    )}
  />,
  /* Publication Special Reports Archive Filtered By Year/Month */
  <ProtectRoute
    key={`/${publication.code}/special-reports/:year/:month`}
    exact
    publication={publication}
    path={`/${publication.code}/special-reports/:year/:month`}
    render={(props) => (
      <ProductArchive
        {...props}
        type='special-report'
        publication={publication}
      />
    )}
  />,
  /* Portfolio */
  <ProtectRoute
    key={`/${publication.code}/portfolio`}
    exact
    publication={publication}
    path={`/${publication.code}/portfolio`}
    render={(props) => (
      <PortfolioSingleComponent
        {...props}
        publication={publication}
      />
    )}
  />,
  /* Trade Groups */
  <ProtectRoute
    key={`/${publication.code}/portfolio/:code`}
    exact
    publication={publication}
    path={`/${publication.code}/portfolio/:code`}
    render={(props) => (
      <TradeGroupComponent
        {...props}
        publication={publication}
      />
    )}
  />,
  /* Dynamic Single Issue/Update Posts */
  <ProtectRoute
    key={`/${publication.code}/:item`}
    exact
    publication={publication}
    path={`/${publication.code}/:item`}
    render={(props) => (
      <DynamicPostComponent
        {...props}
        type='content'
        publication={publication}
        redirectIfPageNotFound
      />
    )}
  />,
  /* Dynamic Single Special Report Posts */
  <ProtectRoute
    key={`/${publication.code}/special-reports/:item`}
    exact
    publication={publication}
    path={`/${publication.code}/special-reports/:item`}
    render={(props) => (
      <DynamicPostComponent
        {...props}
        type='special-report'
        publication={publication}
        redirectIfPageNotFound
      />
    )}
  />,

  /* Bonus pages */
  <ProtectRoute
    key={`/${publication.code}/bonus/:bonus`}
    exact
    publication={publication}
    path={`/${publication.code}/bonus/:bonus`}
    render={(props) => (
      <Bonus
        {...props}
        publication={publication}
      />
    )}
  />,

  /* Courses */
  <ProtectRoute
    key={`/${publication.code}/courses/:code`}
    exact
    publication={publication}
    path={`/${publication.code}/courses/:code`}
    render={(props) => (
      <Course
        {...props}
        publication={publication}
      />
    )}
  />
])

const getProductRoutes = store => {
  return flatten(generatePublicationRoutes(store.mst.publication.publications))
}

export default getProductRoutes
