import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react'
import { observable, action, makeObservable } from 'mobx'

import Link from 'Components/link'
import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import { reset } from 'Api/endpoints/auth'
import { pathOr } from 'ramda'
import Spinner from 'Components/spinner'
import { Helmet } from 'react-helmet'

@inject('store')
@observer
class Reset extends Component {
  @observable email;

  @observable isWorking = false;

  @observable showErrorMessage = false;

  @observable showSuccessMessage = false;

  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject
  };

  constructor (props) {
    super(props)

    makeObservable(this)

    this.email = pathOr('', ['decodedToken', 'customerEmail'], this.props.store.user)
  }

  @action.bound
  handleUpdateEmail (e) {
    this.email = e.target.value
    this.showErrorMessage = false
  }

  @action.bound
  async handleDoReset (e) {
    this.isWorking = true
    e.preventDefault()

    const { email } = this

    const { value } = await reset(this.props.store.ui.siteId, email)

    if (value && value.status === true) {
      this.showSuccessMessage = true
    } else {
      this.showErrorMessage = true
    }

    this.isWorking = false
  }

  render () {
    const { siteDetails } = this.props.store.site

    return (
      <BaseLayout>
        <Helmet>
          <title>Password Reset</title>
        </Helmet>
        <div className='container' id='__page_reset'>
          <div className='row'>
            <div className='col-md-12 actions'>
              <section>
                {!this.showSuccessMessage && (
                  <>
                    <h4>Password Reset</h4>

                    <form onSubmit={this.handleDoReset}>
                      <div className='form-group'>
                        <input
                          type='text'
                          value={this.email}
                          onChange={this.handleUpdateEmail}
                          placeholder='Email'
                        />
                      </div>
                      {!this.isWorking && (
                        <button
                          type='submit'
                          className='btn'
                        >
                          Reset
                        </button>
                      )}
                      {this.isWorking && (
                        <button
                          type='submit'
                          className='btn'
                          disabled
                        >
                          <Spinner />
                        </button>
                      )}
                      {this.showErrorMessage &&
                        <div className='invalid-feedback'>Something went wrong, please contact support</div>}
                      <div className='row lastLine'>
                        <div className='col-12 passReminder'>
                          <Link samepage to='/login'>
                            Go to login page
                          </Link>
                        </div>
                      </div>
                    </form>
                  </>
                )}
                {this.showSuccessMessage && (
                  <h4>Please check your email for instructions.</h4>
                )}
              </section>
            </div>
          </div>

          <div className='row smallLetters'>
            <p>
              If you need assistance, contact customer service at &nbsp;
              <a href={`mailto:${siteDetails.mail.services}`}>{siteDetails.mail.services}</a> or
              call <a href={siteDetails.phone.link}>{siteDetails.phone.value}</a>.
            </p>
          </div>
        </div>
      </BaseLayout>
    )
  }
}

export default withRouter(Reset)
