import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { computed, observable, action, makeObservable } from 'mobx'
import {
  take, split, compose, concat, join, flip, prop, head, propEq, propOr, isEmpty, path
} from 'ramda'
import qs from 'query-string'

import { html2jsx } from 'Lib/parser'
import { getArticlesSiteSearch, getArticlesSearchPublication } from 'Api/endpoints/article'
import Spinner from 'Components/spinner'
import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import Link from 'Components/link'
import { computeRelativeUrl, safeFormatToDate } from 'Lib/purefunctions'

const createSnippet = (text = '', minLen = 10) => compose(
  (flip(concat))(' …'),
  join(' '),
  take(minLen),
  split(' ')
)(text)

@inject('store')
@observer
class Search extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    location: ReactRouterPropTypes.location.isRequired,
    history: ReactRouterPropTypes.history.isRequired
  };

  @observable q;

  @observable publication;

  @observable userText = '';

  @observable pointer = 0;

  @observable isLoading;

  @observable results = [];

  @observable hasMore = false;

  @observable sortBy = 'postdate';

  @observable searchBy = 'keyword';

  @observable contentType = '';

  constructor (props) {
    super(props)

    makeObservable(this)

    this.hydrate()
  }

  componentDidUpdate (prevProps) {
    if (this.props.location.search === prevProps.location.search) {
      return
    }

    this.hydrate()
  }

  @action.bound
  hydrate () {
    const {
      q, p, so, se, ct
    } = qs.parse(this.props.location.search)

    this.q = q
    this.publication = this.props.store.mst.publication.getByCode(p)
    this.userText = this.q
    this.pointer = 0
    this.results = []
    this.sortBy = so === 'weight' ? 'weight' : 'postdate'
    this.searchBy = se === 'phrase' ? 'phrase' : 'keyword'
    if (ct === 'content') {
      this.contentType = 'content'
    } else if (ct === 'special-report') {
      this.contentType = 'special-report'
    } else {
      this.contentType = ''
    }

    if (q) {
      this.fetchMore()
    }
  }

  @action.bound
  track () {
    const { session } = this.props.store.user

    const obj = {
      session: prop('id', session),
      event: 'search',
      url: window.location.href,
      status: 1,
      scroll_depth: 100,
      publication_code: prop('code', this.publication),
      product_code: path(['settings', 'product_code'], this.publication),
      custom1: this.userText.trim(),
      custom2: (Math.floor(this.pointer / 9) + 1).toString()
    }

    this.props.store.user.analyticsAdd(obj)
  }

  @action.bound
  toggleIsLoading () {
    this.isLoading = !this.isLoading
  }

  @action.bound
  async fetchMore (len = 9) {
    this.toggleIsLoading()

    await this.fetchResults(this.pointer, len)
    this.toggleIsLoading()
  }

  @action.bound
  async fetchResults (offset = 0, limit = 9) {
    const { searchSitecode } = this.props.store.site
    const opts = {
      sitecode: searchSitecode,
      offset,
      limit,
      text: encodeURIComponent(this.q),
      sorttype: this.sortBy,
      searchtype: this.searchBy,
      type: this.contentType
    }
    let results

    if (!this.publication) {
      results = await getArticlesSiteSearch(opts)
    } else {
      opts.publication = prop('_id', this.publication)
      opts.sitecode = prop('wordpressSiteName', this.publication)
      results = await getArticlesSearchPublication(opts)
    }

    this.track()

    this.hasMore = results.length === limit
    this.pointer += results.length
    this.results = concat(this.results, results)
  }

  @action.bound
  handleOnNewSearch (e) {
    e.preventDefault()

    if (!this.userText || isEmpty(this.userText)) {
      return
    }

    const p = this.publication ? `&p=${this.publication.code}` : ''
    this.props.history.push(`/search?q=${encodeURIComponent(this.userText)}${p}&so=${this.sortBy}&se=${this.searchBy}&ct=${this.contentType}`)
  }

  @action.bound
  handleUpdateUserText (e) {
    this.userText = e.target.value
  }

  @action.bound handleOnFetchMore (e) {
    e.preventDefault()

    this.fetchMore()
  }

  @action.bound
  getProcessedLink (article) {
    if (!article.new_url) {
      return ''
    }

    const pcId = head(prop('publication', article))
    const publication = this.props.store.mst.publication.getById(pcId) || {}
    const isActive = propEq('publication_status', 'active', publication)

    if (propEq('post_type', 'special-report', article)) {
      // If this is a publication search, use that slug else grab the first publication from the article
      const publicationPart = this.publication ? this.publication.code : publication.code
      return `/${publicationPart}/special-reports/${article.slug}`
    }

    return isActive ? computeRelativeUrl(article.new_url) : article.new_url
  }

  @action.bound
  getArchiveLink (article) {
    if (!article.new_url) {
      return ''
    }

    const pcId = head(prop('publication', article))
    const publication = this.props.store.mst.publication.getById(pcId) || {}

    // If this is a publication search, default to it.
    const publicationPart = this.publication ? this.publication.code : publication.code

    if (propEq('post_type', 'special-report', article)) {
      return `/${publicationPart}/special-reports/`
    }

    const newsletterType = propOr([], 'newsletter_type', article) || ''

    return `/${publicationPart}/archives/${newsletterType}`
  }

  @action.bound
  getPublicationTitleFromArticle (r) {
    const pid = head(r.publication)
    const publication = this.props.store.mst.publication.getById(pid) || {}

    return prop('title', publication)
  }

  @action.bound
  getTypeFromArticle (r) {
    if (propEq('post_type', 'special-report', r)) {
      return 'Special Reports'
    }

    return propOr([], 'newsletter_type', r)
  }

  @computed get content () {
    const {
      isLoading, results, getPublicationTitleFromArticle, getTypeFromArticle, hasMore
    } = this
    const { withTimestamp } = this.props.store.site
    const timeFormat = withTimestamp ? 'MMM dd, yyyy hh:mm aaa' : 'MMM dd, yyyy'

    return (
      <main id='searchContentWrapper'>
        <section className='beforeResults'>
          <div className='searchWrapper'>
            <div className='searchBoxWrapper'>
              <input
                className='searchBox'
                type='text'
                value={this.userText}
                onChange={this.handleUpdateUserText}
                placeholder='Search …'
              />

              <div className='searchOptions'>
                <div className='row'>

                  <div className='col-12 col-md-12 radios'>
                    <span>Sort By:</span>
                    <label className=''>
                      <input
                        type='radio'
                        name='sortBy'
                        checked={this.sortBy === 'postdate'}
                        onChange={() => { this.sortBy = 'postdate' }}
                      /> Date
                    </label>
                    <label className=''>
                      <input
                        type='radio'
                        name='sortBy'
                        checked={this.sortBy === 'weight'}
                        onChange={() => { this.sortBy = 'weight' }}
                      /> Relevance
                    </label>
                  </div>

                  <div className='col-12 col-md-12 radios'>
                    <span>Search By:</span>
                    <label className=''>
                      <input
                        type='radio'
                        name='searchBy'
                        checked={this.searchBy === 'keyword'}
                        onChange={() => { this.searchBy = 'keyword' }}
                      /> Any Word
                    </label>
                    <label className=''>
                      <input
                        type='radio'
                        name='searchBy'
                        checked={this.searchBy === 'phrase'}
                        onChange={() => { this.searchBy = 'phrase' }}
                      /> Exact Match
                    </label>
                  </div>

                  <div className='col-12 col-md-12 radios'>
                    <span>Content Type:</span>
                    <label className=''>
                      <input
                        type='radio'
                        name='contentType'
                        checked={this.contentType === ''}
                        onChange={() => { this.contentType = '' }}
                      /> All
                    </label>
                    <label className=''>
                      <input
                        type='radio'
                        name='contentType'
                        checked={this.contentType === 'content'}
                        onChange={() => { this.contentType = 'content' }}
                      /> Issues/Updates
                    </label>
                    <label className=''>
                      <input
                        type='radio'
                        name='contentType'
                        checked={this.contentType === 'special-report'}
                        onChange={() => { this.contentType = 'special-report' }}
                      /> Special Reports
                    </label>
                  </div>

                </div>

              </div>
            </div>
            <div className='searchBtnWrapper'>
              <button className='searchBtn' onClick={this.handleOnNewSearch}>Search</button>
            </div>
          </div>
        </section>

        <section className='results'>
          {!isLoading && results.length === 0 && (
            <div>No results.</div>
          )}
          {results && results.map((r) => (
            <div className='result' key={r._id}>
              <header>
                <h4 className='articleTitle'><Link to={this.getProcessedLink(r)}>{r.title}</Link></h4>
                <div className='articleInfo'>
                  <span className='articleDate'>
                    {safeFormatToDate(r.createdAtGMT, timeFormat)}
                  </span>
                  <span className='splitter'>|</span>
                  <span className='publicationTitle'>
                    <Link to={this.getArchiveLink(r)}>
                      {!this.publication && getPublicationTitleFromArticle(r)}
                      {this.publication && this.publication.title}
                    </Link>
                  </span>
                  {getTypeFromArticle(r) && (
                    <>
                      <span className='splitter'>|</span>
                      <span className='articleType'>
                        {getTypeFromArticle(r)}
                      </span>
                    </>
                  )}
                  {r.reading_time_min && (
                    <>
                      <span className='splitter'>|</span>
                      <span className='readingTime'> {r.reading_time_min} min read</span>
                    </>
                  )}
                </div>
              </header>
              <main>
                {r.excerpt && (
                  <div>
                    {html2jsx(createSnippet(r.excerpt, 30))}
                  </div>
                )}
              </main>
              <footer>
                <h6 className='articleLink'><Link to={this.getProcessedLink(r)}>Keep Reading</Link></h6>
                <hr />
              </footer>
            </div>
          ))}
          {isLoading && <Spinner />}
        </section>
        <section className='actions'>
          {!isLoading && hasMore && (
            <button onClick={this.handleOnFetchMore}>More</button>
          )}
        </section>
      </main>
    )
  }

  render () {
    const withQ = this.q ? ` : ${this.q}` : ''

    return (
      <TemplateSingle
        title={this.publication ? `Search ${this.publication.title}` : 'Search Publications'}
        metaTitle={this.publication ? `Search ${this.publication.title}${withQ}` : `Search Publications${withQ}`}
        content={this.content}
        extraClass='__page_search'
        publicationCode={prop('code', this.publication)}
        publicationTitle={prop('title', this.publication)}
      />
    )
  }
}

export default withRouter(Search)
