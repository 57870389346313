import { decode } from 'jsonwebtoken'

import { Nullable } from './Types/base'
import { IJwtPayload } from './Types/payload'

export const decodeJwtPayload = (token: Nullable<string>): Nullable<IJwtPayload> => {
  if (token == null) {
    return null
  }

  const decodedToken = decode(token)

  if (decodedToken === null || typeof decodedToken === 'string') {
    return null
  }

  return decodedToken as IJwtPayload
}
