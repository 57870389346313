import { types } from 'mobx-state-tree'

export const Notification = types
  .model('Notification', {
    _id: types.string,
    publicationId: types.maybe(types.string),
    wpKey: types.number,
    createdAt: types.string,
    newsletterType: types.maybe(types.string), // Editorial
    newsletterTypeId: types.maybe(types.string), // Editorial
    postType: types.maybe(types.string), // Editorial
    notificationType: types.string,
    siteCode: types.string,
    slug: types.string,
    status: types.string,
    wordpressSiteName: types.maybe(types.string),
    parsedDate: types.Date,
    isRead: types.boolean,
    isSticky: types.maybe(types.boolean), // Assigned locally
    title: types.maybe(types.string),
    externalURL: types.maybe(types.string), // Promotional
    expirationDate: types.maybe(types.string), // Promotional
    actionContent: types.maybe(types.string), // Promotional
    content: types.maybe(types.string) // Promotional
  })
  .actions((self) => ({
    updateProperty (key, value) {
      self[key] = value
    }
  }))
