import { getNewsletterTypes } from '../../../Api/endpoints/article'
import { NewsletterType } from '../Models/newsletterType'

const newsletterType = (self) => ({
  push (item) {
    const itemExists = self.newsletterTypes.valueOf().filter((a) => a._id === item._id)[0]

    if (itemExists) {
      return
    }

    self.newsletterTypes.push(item)
  },

  pushNewsletterTypes (items) {
    items.map((item) => this.push(item))
  },

  async fetchAll (sitecode) {
    const data = await getNewsletterTypes(sitecode)

    try {
      const objects = data.map((item) => NewsletterType.create(item))
      self.pushNewsletterTypes(objects)
    } catch (e) {
      console.log(e)
    }
  }
})

export default newsletterType
