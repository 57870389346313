import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'

import BaseLayout from 'Containers/Sites/Base/Layouts/base'

class Plain extends Component {
  render () {
    const {
      title,
      children,
      mode
    } = this.props

    const thinView = equals(mode, 'thin')

    return (
      <BaseLayout>

        <div id='__template_plain'>
          <div className='container'>
            {thinView &&
            (
              <div className='row'>
                <div className='col-md-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2'>
                  <header className='headerWrapper'>
                    <h1>{title} plain template</h1>
                    <hr />
                  </header>

                  <main>
                    {children}
                  </main>
                </div>
              </div>
            )}
            {!thinView &&
            (
              <div>
                <header className='headerWrapper'>
                  <h1>{title}</h1>
                  <hr />
                </header>

                <main>
                  {children}
                </main>
              </div>
            )}
          </div>
        </div>
      </BaseLayout>
    )
  }
}

Plain.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  mode: PropTypes.string
}

export default Plain
