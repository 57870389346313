/* eslint-disable no-param-reassign */
import { Realtime } from 'Stores/mst/Models/realtime'
import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'

const message = (self) => ({
  createObject (item) {
    item._id = Date.now().toString()
    item.parsedDate = toDate(parseISO(item.iotDataSentOn))
    item.consumed = false

    return Realtime.create(item)
  },

  pushMessage (item, skipCache = false) {
    const existingItem = self.messages.valueOf()
      .filter((a) => a._id === item._id)[0]

    /* Guard against multiple connections adding the same thing */
    const similar = self.messages.valueOf().filter((a) => {
      const samePub = a.publication === item.publication
      const sameSlug = a.slug === item.slug
      const nearTime = (parseInt(item._id, 10) - parseInt(a._id, 10)) / 1000 < 4

      return samePub && sameSlug && nearTime
    })

    if (similar.length > 0) {
      return
    }

    if (existingItem && skipCache === false) {
      return
    }
    if (existingItem && skipCache) {
      existingItem.update(item)
      return
    }

    self.messages.push(item)
  }

})

export default message
