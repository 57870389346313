import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'

const Breadcrumbs = (props) => {
  let currentPub
  if (props.currentPublication) currentPub = props.currentPublication

  let { currentPath } = props
  let goToPath

  switch (currentPath) {
    case 'special-reports':
      currentPath = 'Special Reports'
      goToPath = 'special-reports'
      break
    case 'archives':
      currentPath = 'Issues & Updates'
      goToPath = 'archives'
      break
    default:
      currentPath = null
      if (currentPub) {
        if (props.match.path === `/${currentPub.code}/:item`) {
          currentPath = 'Issues & Updates'
          goToPath = 'archives'
        }
      }
  }

  return (
    <>
      <div className='breadcrumb-container'>
        <Link to='/my-subscriptions/'>
          My Subscriptions
        </Link>

        {currentPub && (
          <> <span className='text-muted'> / </span>
            <Link to={`/${currentPub.code}/`}>{currentPub.title}</Link>
            <span className='text-muted'> / </span>
            <Link to={`/${currentPub.code}/${goToPath}/`}>{currentPath}</Link>
          </>
        )}
      </div>
    </>
  )
}

Breadcrumbs.propTypes = {
  match: PropTypes.object,
  currentPublication: PropTypes.object,
  currentPath: PropTypes.string
}

export default withRouter(Breadcrumbs)
