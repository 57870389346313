import React from 'react'

import RouteIf from 'Components/conditionalRoute'
import ProtectRoute from 'Components/protected'
import Editors from 'Containers/Sites/Base/Pages/Editors'
import Products from 'Containers/Sites/Base/Pages/Products'
import ProductsComponent from 'Containers/Sites/Base/Pages/MySubscriptions/base'
import ContactComponent from 'Containers/Sites/Base/Pages/Contact'
import ExternalAccountComponent from '../../../Base/Pages/ExternalAccount'

export default store => {
  return [
    <RouteIf
      failCondition={!store.ui.isLoggedIn}
      failLocation='https://www.jeffclarktrader.com/contact-us/'
      external
      path='/contact'
      key='/contact'
      component={ContactComponent}
    />,
    <RouteIf
      failCondition={!store.ui.isLoggedIn}
      failLocation='https://www.jeffclarktrader.com/authors/'
      external
      path='/editors'
      key='/editors'
      component={Editors}
    />,
    <RouteIf
      failCondition={!store.ui.isLoggedIn}
      failLocation='https://www.jeffclarktrader.com/about/'
      external
      path='/products'
      key='/products'
      component={Products}
    />,
    <ProtectRoute
      exact
      path='/my-subscriptions'
      key='/my-subscriptions'
      component={ProductsComponent}
    />,
    <ProtectRoute exact path='/my-account' key='/my-account-new' component={ExternalAccountComponent} />,
    <ProtectRoute exact path='/account' key='/account-redirect' component={ExternalAccountComponent} />
  ]
}
