import React from 'react'
import { toC } from 'react-godfather'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { PropTypes as MobxPropTypes, observer } from 'mobx-react'
import ReactRouterPropTypes from 'react-router-prop-types'

import { StoreContext } from 'App'
import { adjustPageTitle } from 'Lib/realtime'
import Spinner from 'Components/spinner2'

import { loadData, tokenRefreshCheck } from '../Base/Exports/functions'
import {
  staticR, dynamic, custom, publicationRoutes, specialPortfolios
} from './Exports/routeGen'
import NotFound from '../Base/Pages/NotFound'

const minute = 60

const SiteController = ({ store, history, tick }) => {
  loadData(store)
    .then(() => {
      // Update the domain - consider making this a @computed at the store level
      store.ui.domain = store.site.site

      // Handle token refresh
      tokenRefreshCheck(store, history)
        .then(() => setInterval(() => tokenRefreshCheck(store, history), 15 * minute * 1000))
    }).then(tick)

  return async (props) => {
    const { store } = props

    if (!store.site.hasLoaded) {
      return <Spinner />
    }

    // Adjust page title based on notification updates
    adjustPageTitle(store)

    const { siteId } = store.site

    return (
      <div className='' id='outerContainer'>
        <div className='siteContainer' id={siteId}>
          <Switch>
            {custom(store)} {/* Override a static route first */}
            {staticR}
            {publicationRoutes(store)}
            {specialPortfolios(store)} {/* TODO Check if we can remove this without breaking pub/special routing */}
            {custom(store)}
            {dynamic}
            <Route key='NotFound' component={NotFound} />
          </Switch>
        </div>
      </div>
    )
  }
}

SiteController.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired
}

const SiteContainer = observer(toC(SiteController, [], { extraClass: 'siteRoot' }))

export default (withRouter(props => {
  return (
    <StoreContext.Consumer>
      {value => <SiteContainer {...props} store={value} />}
    </StoreContext.Consumer>
  )
}))
