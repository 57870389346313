import { AxiosError } from 'axios'
import {
  pickBy, compose, isNil, not
} from 'ramda'

import { upstreamUser as API, handleError, updateCacheBustValue } from '../index'
import { IArticleResponse } from './types/article'
import { ApiResponse, IdiomaticApiResponse, IStatus } from './types/base'
import {
  IBonusDataResponseRaw,
  IBonusStatus,
  IFaq,
  IFaqResponseRaw,
  ISchedule,
  ISiteContent,
  ISiteContentResponseRaw,
  ITrackValue,
  IUserData,
  IUserDataResponseRaw
} from './types/user'

async function getCampaignContent (sitecode: string): ApiResponse<IArticleResponse[]> {
  try {
    const res = await API.get(`/user/${sitecode}/campaigncontent`)

    return res.data.articles
  } catch (e) {
    return handleError(e as AxiosError)
  }
}

async function addArticleBookmark (sitecode: string, wpKey: string): ApiResponse<IStatus> {
  if (sitecode.toLowerCase() === 'all') {
    sitecode = 'lg'
  }

  try {
    const res = await API.post(`/user/${sitecode}/bookmarks`, {
      wpKey
    })

    updateCacheBustValue()

    return res.data
  } catch (e) {
    return handleError(e as AxiosError)
  }
}

async function removeArticleBookmark (sitecode: string, wpKey: string): ApiResponse<IStatus> {
  if (sitecode.toLowerCase() === 'all') {
    sitecode = 'lg'
  }

  try {
    const res = await API.delete(`/user/${sitecode}/bookmark/${wpKey}`)

    updateCacheBustValue()

    return res.data
  } catch (e) {
    return handleError(e as AxiosError)
  }
}

async function getAllUserData (sitecode: string): IdiomaticApiResponse<IUserData[]> {
  try {
    const res = await API.get<IUserDataResponseRaw>(`/user/${sitecode}/userdata/all`)

    return [res.data.userData, undefined]
  } catch (e) {
    return [null, handleError(e as AxiosError)]
  }
}

async function saveUserData (sitecode: string, key: string, value: unknown): IdiomaticApiResponse<IUserData[]> {
  try {
    const res = await API.post<IUserDataResponseRaw>(`/user/${sitecode}/userdata/${key}`, {
      props: value
    })

    return [res.data.userData, undefined]
  } catch (e) {
    return [null, handleError(e as AxiosError)]
  }
}

async function trackapp (sitecode: string, obj: ITrackValue, type = 'paid'): Promise<void> {
  const value = pickBy(compose(not, isNil))(obj)
  try {
    void API.post(`/user/${sitecode.toLowerCase()}/${type}/trackapp`, value)
  } catch (e) {
    // Ignore
  }
}

async function getFaqs (sitecode: string): ApiResponse<IFaq[]> {
  try {
    const res = await API.get<IFaqResponseRaw>(`/user/${sitecode}/faqs`)
    // console.log(res)
    return res.data.faqs
  } catch (e) {
    return handleError(e as AxiosError)
  }
}

async function getSiteContent (sitecode: string, slugPath: string): ApiResponse<ISiteContent> {
  try {
    const res = await API.get<ISiteContentResponseRaw>(`/user/${sitecode}/sitecontent/${slugPath}`)

    return res.data.sitecontent
  } catch (e) {
    return handleError(e as AxiosError)
  }
}

async function getPublicationSchedule (sitecode: string): ApiResponse<ISchedule[]> {
  try {
    const res = await API.get(`/user/${sitecode}/schedule`)

    return res.data.schedule
  } catch (e) {
    return handleError(e as AxiosError)
  }
}

async function getBonusContent (sitecode: string, publication: string): ApiResponse<IBonusDataResponseRaw> {
  try {
    const res = await API.get(`/bonus/content/${sitecode}/${publication}`)

    return res.data
  } catch (e) {
    return handleError(e as AxiosError)
  }
}

async function getBonusStatus (sitecode: string, publication: string, name: string): ApiResponse<IBonusStatus> {
  try {
    const res = await API.get(`/bonus/access/${sitecode}/${publication}/${name}`)

    return res.data
  } catch (e) {
    return handleError(e as AxiosError)
  }
}

async function saveBonusData (sitecode: string, publication: string, name: string, value: unknown): IdiomaticApiResponse<IStatus> {
  try {
    const res = await API.post(`/bonus/save/${sitecode}/${publication}/${name}`, {
      publicKey: value
    })
    console.log(res)

    return [res.data, undefined]
  } catch (e) {
    return [null, handleError(e as AxiosError)]
  }
}

export {
  getCampaignContent,
  addArticleBookmark,
  removeArticleBookmark,
  getAllUserData,
  saveUserData,
  trackapp,
  getFaqs,
  getSiteContent,
  getPublicationSchedule,
  getBonusContent,
  getBonusStatus,
  saveBonusData
}
