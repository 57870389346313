import React, { useEffect, useState } from 'react'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import uniqueString from 'Lib/unique-string'
import { isRecordBookmarked } from 'Lib/purefunctions'
import { useStore } from 'App'
import { addArticleBookmark, removeArticleBookmark } from 'Api/endpoints/user'
import { IExtendedSnippet } from '../index'
import Link from 'Components/link'

const getTypeFromArticle = (a: IExtendedSnippet): string => a.newsletter_type ?? ''

const getArchiveLink = (article: IExtendedSnippet): string => {
  const code = article.publicationObj.code
  const newsletterType = getTypeFromArticle(article)

  return `/${code}/archives/${newsletterType}`
}

interface IIssueParams {
  post: IExtendedSnippet
}

const Issue = ({ post }: IIssueParams): JSX.Element => {
  const store = useStore()
  const [isBookmarked, setIsBookmarked] = useState(false)

  const { sitecode } = store.site

  useEffect(() => {
    if (isRecordBookmarked(post)) {
      setIsBookmarked(true)
    } else {
      setIsBookmarked(false)
    }
  }, [post])

  const handleBookmarkClicked = (item): void => {
    if (isRecordBookmarked(item)) {
      store.mst.article.removeBookmarkFromRecord(item.id)
      void removeArticleBookmark(sitecode, item.wpKey)
    } else {
      store.mst.article.addBookmarkToRecord(item.id)
      void addArticleBookmark(sitecode, item.wpKey)
    }
  }

  return (
    <div className={`post pr-${post.publicationObj!.code}`} key={uniqueString()}>
      <div className="articleHeaderWrapper">
        <div className="articleTitle">
          <Link to={post.url}>
            <h4>
              <span>{post.title}</span>
            </h4>
          </Link>
        </div>
        <div className="bookmarkAction">
          {!isBookmarked && <span>Save</span>}
          {isBookmarked && <span>Saved</span>}
          <button className="bookmarkBtn" onClick={() => handleBookmarkClicked(post)}>
            {!isBookmarked && <FontAwesomeIcon icon={faBookmark} size="lg"/>}
            {isBookmarked && <FontAwesomeIcon icon={faBookmarkSolid} size="lg"/>}
          </button>
        </div>
      </div>
      <div className="articleInfo">
        <span className="articleDate">
          {post.date}
        </span>
        <span className="splitter">|</span>
        <span className="publicationTitle">
          {post.publicationObj.title}
        </span>

        <span className="splitter">|</span>
        <span className="articleType"><Link to={getArchiveLink(post)}>{getTypeFromArticle(post)}</Link></span>
        {post.reading_time_min != null && (
          <>
            <span className="splitter">|</span>
            <span className="readingTime"> {post.reading_time_min} min read</span>
          </>
        )}
      </div>
      <p>{post.snippet}</p>
      <Link to={post.url} className="keep_reading">Keep Reading...</Link>
    </div>
  )
}

export default observer(Issue)
