import React, { Component } from 'react'
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react'
import { observable, computed, action, makeObservable } from 'mobx'

import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import { getProducts } from 'Api/endpoints/article'
import Spinner from 'Components/spinner'
import { html2jsx } from 'Lib/parser'

@inject('store')
@observer
class Products extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject
  };

  @observable products;

  componentDidMount () {
    this.trackPage()
  }

  constructor (props) {
    super(props)
    makeObservable(this)
    // noinspection JSIgnoredPromiseFromCall
    this.fetchProducts()
  }

  @action.bound
  async fetchProducts () {
    const { sitecode } = this.props.store.site

    this.products = await getProducts(sitecode)
  }

  @action.bound
  trackPage () {
    const { session } = this.props.store.user

    const sessionId = session ? session.id : null

    const obj = {
      session: sessionId,
      event: 'other',
      url: window.location.href,
      status: 1,
      scroll_depth: 100
    }

    this.props.store.user.analyticsAdd(obj)
  }

  @computed get content () {
    if (!this.products) {
      return <Spinner />
    }

    return (
      <div className='productsWrapper'>
        {this.products.map((product) => (
          <section key={product.code} className='productBox'>
            <div className='container'>
              <div className='row productInfo'>
                <div className='col-12 col-sm-12 col-md-5 productName'>
                  <h4>{product.title}</h4>
                </div>
                <div className='col-12 col-sm-12 col-md-7 productDesc'>
                  <p>{html2jsx(product.description)}</p>
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>
    )
  }

  render () {
    return (
      <TemplateSingle
        title='Products'
        extraClass='__page_products'
        content={this.content}
        isProducts
      />
    )
  }
}

export default Products
