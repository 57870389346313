import React from 'react'
import parse from 'html-react-parser'
import PT from 'prop-types'
import { isEmpty } from 'ramda'

const Bio = ({ bio }) => {
  if (!bio || isEmpty(bio)) {
    return null
  }

  return (
    <div>
      <hr className='separator d-none d-xl-block' />
      <span className='d-none d-xl-block'>{parse(bio)}</span>
    </div>
  )
}

Bio.propTypes = {
  bio: PT.string
}

export default Bio
