import { makeAutoObservable } from 'mobx'
import {
  isEmpty, isNil, or, complement, not, includes
} from 'ramda'
import toDate from 'date-fns/toDate'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'
import Store from './index'

export default class Ui {
  private readonly root: Store

  constructor (rootStore: Store) {
    makeAutoObservable(this)

    this.root = rootStore
  }

  domain = ''

  isMenuSidebarVisible = false

  destinationAfterLogin

  isMenuSearchbarVisible = false

  msgQueue = []

  toggleMenuSidebar = (): void => {
    this.isMenuSidebarVisible = !this.isMenuSidebarVisible
  }

  toggleMenuSearchbar = (): void => {
    this.isMenuSearchbarVisible = !this.isMenuSearchbarVisible
  }

  shouldRedirectAfterLogin = (location: string): void => {
    this.destinationAfterLogin = location
  }

  get isLoggedIn (): boolean {
    const notEmpty = complement(or(isEmpty, isNil))

    return notEmpty(this.root.user.user)
  }

  get greeting (): string {
    const { user: userDetails } = this.root.user

    if (isEmpty(userDetails)) {
      return 'Welcome back'
    }

    const {
      customerEmail,
      customerFirstName,
      customerLastName
    }: { customerEmail?: string, customerFirstName?: string, customerLastName?: string } = userDetails

    if (customerFirstName != null && customerLastName != null) {
      return `Welcome back, ${customerLastName}`
    } else if (customerLastName == null) {
      return `Welcome back, ${customerFirstName} ${customerLastName}`
    } else if (customerEmail != null) {
      return `Welcome back, ${customerEmail}`
    } else {
      return 'Welcome back'
    }
  }

  get siteId (): string {
    return this.root.site.sitecode
  }

  get shouldDisplayPopup (): boolean {
    if (this.root.site.siteDetails.messages?.popup == null) {
      return false
    }

    const { id } = this.root.site.siteDetails.messages.popup

    if (id == null) {
      return false
    }

    return not(includes(id, this.root.user.displayPopupIds))
  }

  get shouldDisplayHeadMsg (): boolean {
    if (this.root.site.siteDetails.messages?.headMsg == null) {
      return false
    }

    const {
      until,
      enabled
    } = this.root.site.siteDetails.messages?.headMsg

    if (until == null || enabled == null || !enabled) {
      return false
    }

    return isAfter(toDate(parseISO(until)), new Date())
  }

  get shouldDisplayMySubsMsg (): boolean {
    if (this.root.site.siteDetails.messages?.mySubsMsg == null) {
      return false
    }

    const {
      until,
      enabled
    } = this.root.site.siteDetails.messages.mySubsMsg

    if (until == null || enabled == null || !enabled) {
      return false
    }

    return isAfter(toDate(parseISO(until)), new Date())
  }

  get shouldDisplayLoginMsg (): boolean {
    if (this.root.site.siteDetails.messages?.loginMsg == null) {
      return false
    }

    const {
      until,
      enabled
    } = this.root.site.siteDetails.messages?.loginMsg

    if (until == null || enabled == null || !enabled) {
      return false
    }

    return isAfter(toDate(parseISO(until)), new Date())
  }

  get shouldContactMsg (): boolean {
    if (this.root.site.siteDetails.messages?.contactMsg == null) {
      return false
    }
    const {
      until,
      enabled
    } = this.root.site.siteDetails.messages?.contactMsg

    if (until == null || enabled == null || !enabled) {
      return false
    }

    return isAfter(toDate(parseISO(until)), new Date())
  }
}
