import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  compose, defaultTo, head, isEmpty, propOr, tail
} from 'ramda'
import { Link } from 'react-router-dom'
import uniqueString from 'Lib/unique-string'
import { isNew } from 'Lib/purefunctions'

import Spinner from 'Components/spinner'
import Indicator from 'Components/newArticleIndicator'

export default class Issues extends Component {
  static propTypes = {
    issues: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      date: PropTypes.string,
      snippet: PropTypes.string,
      url: PropTypes.string,
      readingTimeMin: PropTypes.number
    })),
    publicationCode: PropTypes.string.isRequired,
    publicationTitle: PropTypes.string,
    newItems: PropTypes.array
  };

  render () {
    const {
      issues, publicationCode, publicationTitle, newItems
    } = this.props

    const getTypeFromArticle = compose(
      defaultTo(''),
      propOr([], 'newsletter_type')
    )
    const headItem = head(issues)
    const tailItems = tail(issues)
    const isInNew = isNew(newItems || [])
    const noTailItems = isEmpty(tailItems)

    return (
      <div className='subscriber-issues'>
        <div className='row itemgroup'>
          <div className='col-md-7 col-sm-7 leftside'>
            {!headItem && <Spinner />}
            {headItem &&
              (
                <>
                  <h2>
                    {isInNew(headItem.slug) && <Indicator />}
                    <Link to={headItem.url}>
                      {headItem.title}
                    </Link>
                  </h2>
                  <div className='text-muted entry-meta'>
                    <span>{headItem.date}</span>
                    {headItem.pubTitle && headItem.pubTitle !== 'Issues' && (
                      <>
                        <span className='splitter'>|</span>
                        <span className='articleType'>
                          {headItem.pubTitle}
                        </span>
                      </>
                    )}
                    {getTypeFromArticle(headItem) && (!headItem.pubTitle || headItem.pubTitle === 'Issues') && (
                      <>
                        <span className='splitter'>|</span>
                        <span className='articleType'>
                          {getTypeFromArticle(headItem)}
                        </span>
                      </>
                    )}
                    {headItem.reading_time_min && (
                      <>
                        <span className='splitter'>|</span>
                        <span> {headItem.reading_time_min} min read</span>
                      </>
                    )}
                  </div>
                  <p>{headItem.snippet}</p>
                  <hr className='d-md-none' />
                </>
              )}
          </div>

          <div className='col-md-5 col-sm-5 rightside'>
            <h6 className='title'>Recently Published</h6>
            <hr />
            {noTailItems && <p>There are no other issues or updates published at this time.</p>}
            {tailItems.map((item, index) => (
              <div className='issue' key={uniqueString()}>
                <h5>
                  {isInNew(item.slug) && <Indicator />}
                  <Link to={item.url}>
                    {item.title}
                  </Link>
                </h5>
                <div className='text-muted entry-meta'>
                  <span>{item.date}</span>
                  {item.pubTitle && item.pubTitle !== 'Issues' && (
                    <>
                      <span className='splitter'>|</span>
                      <span className='articleType'>
                        {item.pubTitle}
                      </span>
                    </>
                  )}
                  {getTypeFromArticle(item) && (!item.pubTitle || item.pubTitle === 'Issues') && (
                    <>
                      <span className='splitter'>|</span>
                      <span className='articleType'>
                        {getTypeFromArticle(item)}
                      </span>
                    </>
                  )}
                  {item.reading_time_min && (
                    <>
                      <span className='splitter'>|</span>
                      <span> {item.reading_time_min} min read</span>
                    </>
                  )}
                  {(index !== tailItems.length - 1) &&
                    <hr />}
                </div>
              </div>
            ))}
          </div>

        </div>

        <div className='actionWrapper' data-name='mySubsWalkthroughStep-3'>
          <div>
            <Link to={`/${publicationCode}`}>
              Access {publicationTitle} Homepage
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
