/* eslint-disable max-classes-per-file,react/no-unescaped-entities */
/* eslint-disable no-underscore-dangle */
import React, { Component, useEffect, useState } from 'react'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { observer as obs } from 'mobx-react-lite'
import { observable, action, makeObservable } from 'mobx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PT from 'prop-types'
import {
  filter, compose, includes, propOr
} from 'ramda'

import { html2jsx } from 'Lib/parser'
import { getFaqs } from 'Api/endpoints/user'
import { useStore } from 'App'

const HelpCenter = obs(() => {
  const store = useStore()
  const [data, setData] = useState([])
  const [questionId, setQuestionId] = useState()

  useEffect(() => {
    const { sitecode } = store.site

    if (!sitecode) {
      return
    }

    getFaqs(sitecode).then(setData)
  }, [store.site])

  const onQuestionClicked = (id) => {
    switch (id) {
      case questionId:
        setQuestionId(null)
        break
      default:
        setQuestionId(id)
        break
    }
  }

  const filterByType = (type, list) => filter(compose(includes(type), propOr([], 'type')))(list)

  const filterByGeneral = (list) => filterByType('general', list)
  const filterByTechnical = (list) => filterByType('technical', list)
  const filterByBilling = (list) => filterByType('billing', list)

  return (
    <div className='mt-5 faq__section col col-lg-9'>
      <h4>
        <strong>FAQ's</strong>
      </h4>
      <hr />
      <div className='col col-lg-10'>
        <p>
          The amount of information you get with your {/* {siteName} */} subscription can
          seem daunting. That's why, to help you get started, we've put
          together the FAQ below. It will help answer common general,
          technical, and billing questions. If you're a new reader or have any
          concerns, read the sections below.
        </p>
      </div>
      <span id='generalFaqs' style={{ position: 'absolute' }} />
      <h4 className='faq__type'>General</h4>
      <hr />
      <FaqSection
        onClick={onQuestionClicked}
        openQuestionId={questionId}
        data={filterByGeneral(data)}
      />
      <span id='technicalFaqs' />
      <h4 className='faq__type'>Technical</h4>
      <hr />
      <FaqSection
        onClick={onQuestionClicked}
        openQuestionId={questionId}
        data={filterByTechnical(data)}
      />
      <span id='billingFaqs' />
      <h4 className='faq__type'>Billing</h4>
      <hr />
      <FaqSection
        onClick={onQuestionClicked}
        openQuestionId={questionId}
        data={filterByBilling(data)}
      />
    </div>
  )
})

HelpCenter.propTypes = {
  store: MobxPropTypes.objectOrObservableObject
}

@observer
class FaqSection extends Component {
  static propTypes = {
    data: PT.array,
    onClick: PT.func.isRequired,
    openQuestionId: PT.string
  };

  render () {
    const data = this.props.data.sort((a, b) => a.code - b.code)
    return (
      <section>
        {data.map((dataDetails) => (
          <div key={dataDetails._id}>
            <Question
              data={dataDetails}
              open={this.props.openQuestionId === dataDetails._id}
              onClicked={this.props.onClick}
            />
          </div>
        ))}
      </section>
    )
  }
}

@inject('store')
@observer
class Question extends Component {
  static propTypes = {
    data: PT.object,
    onClicked: PT.func
  };

  constructor (props) {
    super(props)
    makeObservable(this)
  }

  UNSAFE_componentWillUpdate (nextprops) {
    if (this.open !== nextprops.open) {
      this.isOpen = nextprops.open
    }
  }

  @observable isOpen = false;

  @action.bound
  handleQuestionClicked () {
    this.props.onClicked(this.props.data._id)
  }

  render () {
    const { data } = this.props

    return (
      <div key={data._id} className='row'>
        <div className='col col-md-10'>
          <div className='faq'>
            <button
              type='button'
              className='faq__question text-left'
              onClick={this.handleQuestionClicked}
            >
              <div>
                {this.isOpen && (
                  <FontAwesomeIcon icon='minus' className='plus-sign' />
                )}
                {this.isOpen === false && (
                  <FontAwesomeIcon icon='plus' className='plus-sign' />
                )}
                {html2jsx(data.question)}
                {this.brand}
              </div>
            </button>

            {this.isOpen && data.answer && (
              <div className='faq__answer'>
                <p>{html2jsx(data.answer)}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default HelpCenter
