import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react'

import { useStore } from 'App'
import SubscriptionRow from 'Containers/Sites/Base/Pages/MySubscriptions/Components/SubscriptionRow'
import { isEmpty, prop } from 'ramda'

const AdditionalResources = () => {
  const store = useStore()
  const [visible, setVisible] = useState(false)

  const archivedPublications = store.mst.publication.archived.map((publication) => ({
    id: prop('_id', publication),
    publicationCode: publication.code,
    archiveUrl: publication.archive_url,
    archiveInternalUrl: publication.archive_internal_url,
    title: publication.title,
    author: store.mst.author.getTitleByCode(publication.main_author_name),
    authorImage: publication.main_author_icon,
    siteImage: publication.site_logo,
    color: publication.color
  }))

  const handleToggleVisible = () => {
    setVisible(!visible)
  }

  if (isEmpty(archivedPublications)) {
    return null
  }

  return (
    <section className='container otherProducts'>
      <header className='row'>
        <div className='col-12'>
          <div className='title'>
            <div
              className='toggler'
              onClick={handleToggleVisible}
            >
              {!visible && (
                <FontAwesomeIcon icon={faChevronDown} size='sm' />
              )}
              {visible && (
                <FontAwesomeIcon icon={faChevronUp} size='sm' />
              )}
            </div>
            <span>Additional Resources</span>
          </div>
        </div>
      </header>

      {visible && (
        <div className='row'>
          {archivedPublications.map((post) => (
            <div className='col-12' key={post.archiveUrl}>
              <SubscriptionRow {...post} />
            </div>
          ))}
        </div>
      )}
    </section>
  )
}

AdditionalResources.propTypes = {}

export default observer(AdditionalResources)
