import {
  equals, pipe, either, unless, isNil, converge, concat, filter, propEq, compose, replace,
  not, anyPass, toLower, flip, includes, last, curry, join, tail, split, take, init, isEmpty, keys, is, prop
} from 'ramda'
import format from 'date-fns/format'
import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'

// TODO Refactor
function factoryIsFunction (): (a: unknown) => boolean {
  const isPrototypeEquals = pipe(Object.getPrototypeOf, equals)

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const equalsSyncFunction = isPrototypeEquals(() => {})
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const equalsAsyncFunction = isPrototypeEquals(async () => {})

  const isSyncFunction = pipe(Object.getPrototypeOf, equalsSyncFunction)
  const isAsyncFunction = pipe(Object.getPrototypeOf, equalsAsyncFunction)

  const isFunction = either(isSyncFunction, isAsyncFunction)

  return unless(isNil, isFunction)
}

export const isFunction = factoryIsFunction()

export function innerText (html: string): string {
  const div = document.createElement('div')
  div.innerHTML = html

  return div.innerText
}

export function computeRelativeUrl (url: string | URL | null): string {
  if (url == null) {
    return ''
  }

  const obj = new URL(url)
  return `${obj.pathname}${obj.search}`
}

// @ts-expect-error TODO Refactor
export const rearrangeSticky = converge(
  concat,
  [
    filter(propEq('sticky_report', 'yes')),
    filter(
      compose(not, propEq('sticky_report', 'yes'))
    )
  ]
)

export const shouldLgm = compose(
  anyPass([
    equals('lg'),
    equals('all')
  ]),
  toLower
)

export const flippedIncludes = flip(includes)

export const withTrailingSlash = (str: string): string => {
  if (equals('/', last(str))) {
    return str
  }

  return `${str}/`
}

export const safeFormatToDate = (input: string | null, style: string): string => {
  if (input == null) {
    return ''
  }

  try {
    return format(toDate(parseISO(input)), style)
  } catch (e) {
    console.log(e)
    return ''
  }
}

export const isNew = curry((haystack, needle) => includes(needle, haystack))

export const timeout = async (ms: number): Promise<void> => await new Promise((resolve) => setTimeout(resolve, ms))

export const skip = (num: number): number[] => new Array(num)

export const getPublicationSubPathFromPathname = (pathname: string): string => compose(
  init,
  join('/'),
  tail,
  tail,
  split('/'),
  replace(/\/?$/, '/')
)(pathname)

// TODO Refactor
export const getFragment = (pathname: string): string | unknown => compose(
  last,
  take(2),
  split('/')
)(pathname)

interface ISiteList {
  id: string
  code: string
  value: unknown
}

interface ICodeObject {
  [key: string]: never
}

// TODO Refactor
export const createOrderedSiteList = (order: string[], codeObject: ICodeObject): ISiteList[] => {
  const out: ISiteList[] = []
  let keyList = order

  // Fallback for backend misconfiguration
  if (isEmpty(order)) {
    keyList = keys(codeObject).map(toString)
  }

  keyList.forEach((code: string) => {
    out.push({ id: code, code, value: codeObject[code] })
  })

  return out
}

// TODO Refactor
export const reorder = (list: Iterable<unknown> | ArrayLike<unknown>, startIndex: number, endIndex: number): unknown[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const isRecordBookmarked = compose(
  is(Object),
  prop('bookmark')
)

// Deprecated
export const capitalize = (str: string): string => str.charAt(0)
  .toUpperCase() + str.slice(1)
  .toLowerCase()
