import React, { Component } from 'react'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { action, observable, computed, runInAction, makeObservable } from 'mobx'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  isEmpty, path, pathEq, prop, none
} from 'ramda'

import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import { feedback, info } from 'Api/endpoints/auth'
import Spinner from 'Components/spinner'
import RightSideInfo from '../ContactInfo'

@inject('store')
@observer
class PageFeedback extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject
  };

  @observable firstName = '';

  @observable lastName = '';

  @observable email = '';

  @observable phone = '';

  @observable comments = '';

  @observable recaptchaValue = '';

  @observable showError = false;

  @observable showThanks = false;

  @observable isWorking = false;

  constructor (props) {
    super(props)

    makeObservable(this)

    const { token } = this.props.store.user
    const { sitecode } = this.props.store.site

    if (!token) {
      return
    }

    info(sitecode, token).then(({ value }) => {
      runInAction(() => {
        this.firstName = prop('firstName', value)
        this.lastName = prop('lastName', value)
        this.phone = prop('phoneNumber', value)
        this.email = path(['emailAddress', 'emailAddress'], value)
      })
    })
  }

  @computed get isSubmitAllowed () {
    const {
      firstName, lastName, email, recaptchaValue, comments
    } = this

    return none(isEmpty, [firstName, lastName, email, recaptchaValue, comments])
  }

  @action.bound
  updateFieldValue (name, value) {
    this[name] = value
    this.showError = false
  }

  @action.bound
  updateRecaptchaValue (e) {
    this.recaptchaValue = e
  }

  @action.bound
  async handleSubmit (e) {
    e.preventDefault()

    const { sitecode, siteDetails } = this.props.store.site
    const {
      firstName, lastName, email, phone, comments, recaptchaValue
    } = this

    if (!this.isSubmitAllowed) {
      this.showError = true
      return
    }

    this.isWorking = true

    const resp = await feedback(sitecode, {
      email,
      firstName,
      lastName,
      phone,
      message: comments,
      captcha: recaptchaValue,
      formUrl: `${window.location.hostname}${window.location.pathname}`,
      target: path(['pages', 'feedback', 'target'], siteDetails),
      targetEmail: path(['pages', 'feedback', 'mail'], siteDetails)
    })

    this.isWorking = false

    if (pathEq(['value', 'status'], true, resp)) {
      this.showThanks = true
    } else {
      window.alert('Something went wrong. Please try again.')
    }
  }

  render () {
    const { showThanks } = this
    const { captchaKey } = this.props.store.site

    return (
      <BaseLayout>
        <div id='__page_feedback' className='container'>
          <Helmet>
            <title>Feedback</title>
          </Helmet>
          <div className='row'>
            <div className='col'>
              <header className='headerWrapper'>
                <h1>Feedback</h1>
              </header>
              <main>
                <div className='row preamble'>
                  <div className='col'>
                    <p>
                      Our customer service representatives are friendly, knowledgeable, and efficient. Please feel free to
                      call or email us if you have any questions.
                    </p>
                    {!showThanks && (
                      <p>
                        For security purposes please do not include your credit card information.
                      </p>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col form-contact'>

                    {!showThanks && (
                      <div className='notice'>* required fields</div>
                    )}

                    {!showThanks && (
                      <form onSubmit={this.handleSubmit}>
                        <div className='form-group'>
                          <input
                            value={this.firstName}
                            onChange={(e) => this.updateFieldValue('firstName', e.target.value)}
                            type='text'
                            className='form-control'
                            id='firstNameInput'
                            aria-describedby='First Name'
                            placeholder='First Name *'
                          />
                        </div>

                        <div className='form-group'>
                          <input
                            value={this.lastName}
                            onChange={(e) => this.updateFieldValue('lastName', e.target.value)}
                            type='text'
                            className='form-control'
                            id='lastNameInput'
                            aria-describedby='Last Name *'
                            placeholder='Enter Last Name'
                          />
                        </div>

                        <div className='form-group'>
                          <input
                            value={this.email}
                            onChange={(e) => this.updateFieldValue('email', e.target.value)}
                            type='email'
                            className='form-control'
                            aria-describedby='email'
                            placeholder='Email *'
                            id='emailInput'
                          />
                        </div>

                        <div className='form-group'>
                          <input
                            value={this.phone}
                            onChange={(e) => this.updateFieldValue('phone', e.target.value)}
                            type='text'
                            className='form-control'
                            id='phoneInput'
                            aria-describedby='Phone'
                            placeholder='Enter Phone'
                          />
                        </div>

                        <div className='form-group'>
                          <textarea
                            value={this.comments}
                            onChange={(e) => this.updateFieldValue('comments', e.target.value)}
                            className='form-control'
                            id='commentsInput'
                            rows='3'
                            placeholder='Feedback Message *'
                          />
                        </div>
                        <div className='form-group recaptchaWrapper'>
                          <ReCAPTCHA
                            sitekey={captchaKey}
                            onChange={(v) => this.updateFieldValue('recaptchaValue', v)}
                          />
                        </div>
                        <button
                          onClick={this.handleSubmit}
                          disabled={!this.isSubmitAllowed || (this.isSubmitAllowed && this.isWorking)}
                          type='submit'
                          className='btn sendBtn'
                        >
                          {this.isWorking && <Spinner />}
                          {!this.isWorking && <span>Submit</span>}
                        </button>
                      </form>
                    )}

                    {showThanks && (
                      <div className='thanksWrapper'>
                        <div>
                          <p>Message sent! Thank you.</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <RightSideInfo />
                </div>
              </main>
            </div>
          </div>
        </div>
      </BaseLayout>
    )
  }
}

export default PageFeedback
