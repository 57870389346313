import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  head, last, splitAt
} from 'ramda'
import { Link } from 'react-router-dom'
import uniqueString from 'Lib/unique-string'

import Spinner from 'Components/spinner'

export default class Index extends Component {
  static propTypes = {
    reports: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      snippet: PropTypes.string,
      image: PropTypes.string
    }))
  };

  render () {
    const { reports } = this.props
    const allItems = splitAt(5, reports)
    const leftItems = head(allItems)
    const rightItems = last(allItems)

    return (
      <div className='subscriber-reports' data-name='pubTour-step3'>

        <div>
          <h5 className='text-muted'>Special Reports</h5>
          <hr />
        </div>

        <div className='row itemgroup'>

          <div className='col-md-7 col-sm-12 rightside'>
            {!leftItems && <Spinner />}
            {leftItems.map((item, index) => (
              <Fragment key={uniqueString()}>
                <div className='wrapper issue'>
                  <div className='image'>
                    <Link to={item.url}>
                      <img src={item.image} alt='tail item' />
                    </Link>
                  </div>
                  <div className='content'>
                    <h5>
                      <Link to={item.url}>
                        {item.title}
                      </Link>
                    </h5>
                  </div>
                </div>
                {(index !== leftItems.length - 1) &&
                  <hr />}
              </Fragment>
            ))}
            <hr className='d-md-none' />
          </div>

          <div className='col-md-5 col-sm-12 rightside'>
            {rightItems.map((item, index) => (
              <Fragment key={uniqueString()}>
                <div className='wrapper issue'>
                  <div className='image'>
                    <Link to={item.url}>
                      <img src={item.image} alt='tail item' />
                    </Link>
                  </div>
                  <div className='content'>
                    <h5>
                      <Link to={item.url}>
                        {item.title}
                      </Link>
                    </h5>
                  </div>
                </div>
                {(index !== rightItems.length - 1) &&
                  <hr />}
              </Fragment>
            ))}
          </div>

        </div>

      </div>
    )
  }
}
