import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons'

import { useStore } from 'App'
import { ILesson, ICourse, IPublicationStore } from 'Stores/mst'
import injectPlayerScript from 'Lib/injectPlayer'
import Link from 'Components/link'
import CourseOverview from './CourseOverview'
import { useCourseContext } from './index'

interface ILessonCard {
  lesson?: ILesson
  course: ICourse
  publication: IPublicationStore
}

const LessonCard = ({ lesson, course, publication }: ILessonCard): JSX.Element => {
  const {
    activeSection,
    setNextActive,
    setPrevActive,
    checkNext
  } = useCourseContext()
  const [showNext, setShowNext] = useState(false)
  const store = useStore()

  const track = (): void => {
    const { session } = store.user
    const {
      code,
      settings
    } = publication

    if (lesson == null) {
      return
    }

    const obj = {
      session: session?.id ?? '',
      event: 'lessonView',
      url: window.location.href,
      status: 1,
      scroll_depth: 100,
      publication_code: code,
      product_code: settings.product_code,
      custom1: course.title,
      custom3: lesson?.secondaryTitle
    }

    store.user.analyticsAdd(obj)
  }

  useEffect(() => {
    injectPlayerScript()

    if (activeSection === undefined) {
      return
    }

    if (lesson != null) {
      setShowNext(checkNext(activeSection, lesson))

      track()
    }
  }, [lesson, activeSection])

  if (lesson == null) {
    return <CourseOverview title={course.title} description={course.description}/>
  }

  return (
    <div id="lessonCard" className="resource-container">
      <h4>{lesson.titlePrefix}</h4>

      <div className="resource-subheader">
        <h2>{lesson.secondaryTitle}</h2>
        {lesson.downloadResourcesUrl != null && lesson.downloadResourcesUrl.trim() !== '' && (
          <Link to={lesson.downloadResourcesUrl} samepage={false}>
            <span>{lesson.downloadLabel}</span> <FontAwesomeIcon icon={faDownload}/>
          </Link>
        )}
      </div>

      {lesson.videoCode != null && (
        <div className="player" dangerouslySetInnerHTML={{ __html: lesson.videoCode }}/>
      )}

      {lesson.ctaContent !== '' && (
        <div className="ctaContent" dangerouslySetInnerHTML={{ __html: lesson.ctaContent }}/>
      )}

      <div className="lesson-summary">
        <div dangerouslySetInnerHTML={{__html: lesson.description}}/>
      </div>

      <nav className="resource-nav">
        <button className="btn btn-navlink" onClick={setPrevActive}>
          <FontAwesomeIcon icon={faArrowLeft} size="sm"/> <span>Previous</span></button>

        {showNext && (
          <button className="btn btn-navlink" onClick={setNextActive}>
            <span>Next</span> <FontAwesomeIcon icon={faArrowRight} size="sm"/>
          </button>
        )}
      </nav>
    </div>
  )
}

export default LessonCard
