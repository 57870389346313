import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { observer } from 'mobx-react'

import { useStore } from 'App'
import { secondaryLogin } from 'Api/endpoints/auth'
import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import Login from './login'

export const blockedCommunityAccounts = ['SAC0005663718']

const ExternalAccount = (): JSX.Element => {
  const store = useStore()
  const { sitecode, captchaKey, siteDetails, brandId } = store.site
  const { usernameDuringLogin, afterLogin, isBssTokenUsable, bssToken } = store.user
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(isBssTokenUsable)
  const [shouldBlock, setShouldBlock] = useState<boolean>(blockedCommunityAccounts.includes(store.user.decodedToken?.customerNumber ?? 'INVALID_ACCOUNT'))

  const externalUrl = siteDetails.myAccount?.externalUrl

  const track = (): void => {
    const { session } = store.user
    const sessionId = session?.id ?? null

    const obj = {
      session: sessionId ?? '',
      event: 'accountpage',
      url: window.location.href,
      status: 1,
      scroll_depth: 100
    }

    store.user.analyticsAdd(obj)
  }

  const redirect = (): void => {
    track()
    setTimeout(() => {
      console.log(`${externalUrl}/?brandId=${brandId}&jwtToken=${bssToken}`)
      window.location.replace(`${externalUrl}/?brandId=${brandId}&jwtToken=${bssToken}`)
    }, 1000)
  }

  const onSuccess = (v): void => {
    void afterLogin(v).then(() => {
      if (store.user.decodedToken?.customerNumber != null) {
        if (blockedCommunityAccounts.includes(store.user.decodedToken?.customerNumber)) {
          setShouldBlock(true)
          setShouldRedirect(false)

          return
        }
      }

      setShouldRedirect(true)
    })
  }

  useEffect(() => {
    if (shouldRedirect && !shouldBlock) {
      redirect()
    }
  }, [shouldRedirect, shouldBlock])

  return (
    <BaseLayout>
      <div id='__page_account_ext' className='container'>
        <Helmet>
          <title>My Account</title>
        </Helmet>

        <div className='row'>
          <div className='col'>
            <h1>My Account</h1>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            {(!shouldRedirect && !shouldBlock) && (
              <Login
                onSuccess={onSuccess}
                captchaKey={captchaKey}
                username={usernameDuringLogin}
                verify={secondaryLogin(sitecode, usernameDuringLogin)}
              />
            )}

            {(shouldRedirect && !shouldBlock) && (
              <h2>You are being redirected to your Account page, please wait.</h2>
            )}

            {(shouldBlock) && (
              <h2 style={{ color: '#8b0000'}}>This account is shared and cannot access the Account page this way.</h2>
            )}
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default observer(ExternalAccount)
