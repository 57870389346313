import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { computed, action, observable, makeObservable } from 'mobx'
import {
  faExternalLinkAlt, faChevronDown, faChevronUp, faEllipsisV
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Link from 'Components/link'
import uniqueString from 'Lib/unique-string'
import { safeFormatToDate, isNew } from 'Lib/purefunctions'
import Indicator from 'Components/newArticleIndicator'

@observer
class SubscriptionRow extends Component {
  static propTypes = {
    author: PropTypes.string,
    authorImage: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      date: PropTypes.string,
      readingTimeMin: PropTypes.number,
      slug: PropTypes.string
    })),
    publicationCode: PropTypes.string,
    archiveUrl: PropTypes.string,
    menu: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.node
    })),
    noColors: PropTypes.bool,
    extendable: PropTypes.bool,
    startExtended: PropTypes.bool,
    onExtendedToggle: PropTypes.func,
    submenu: PropTypes.bool,
    productPage: PropTypes.string,
    free: PropTypes.bool,
    newItems: PropTypes.array,
    withTimestamp: PropTypes.bool,
    draggableProvided: PropTypes.object
  };

  @observable isExtended = false;

  constructor (props) {
    super(props)

    makeObservable(this)

    if (this.props.startExtended) {
      this.isExtended = true
    }
  }

  @computed get borderColor () {
    if (this.props.noColors) {
      return '#e4e4e4'
    }

    return this.props.color
  }

  @computed get subMenu () {
    return this.props.menu.map((item, index, array) => (
      <Fragment key={uniqueString()}>
        <Link to={item.url}>{item.title}</Link>
        {array.length >= index + 2 && <span> / </span>}
      </Fragment>
    ))
  }

  @action.bound
  handleToggleContent () {
    this.isExtended = !this.isExtended
    this.props.onExtendedToggle()
  }

  render () {
    const {
      author,
      authorImage,
      title,
      links,
      publicationCode,
      archiveUrl,
      extendable,
      submenu,
      productPage,
      free,
      newItems,
      withTimestamp,
      draggableProvided
    } = this.props

    const productLink = archiveUrl || `/${publicationCode}`
    const isInNew = isNew(newItems || [])
    const timeFormat = withTimestamp ? 'EEE, MMM dd hh:mm aaa' : 'EEE, MMM dd'

    const content = (
      <>
        <div className='itemWrapper' style={{ borderBottomColor: this.borderColor }}>
          {extendable &&
          (
            <div className='h-block toggler' onClick={this.handleToggleContent}>
              {!this.isExtended && <FontAwesomeIcon icon={faChevronDown} size='sm' />}
              {this.isExtended && <FontAwesomeIcon icon={faChevronUp} size='sm' />}
            </div>
          )}
          {!extendable &&
          (
            <div className='h-block toggler'>
              <FontAwesomeIcon icon={faChevronUp} size='sm' style={{ visibility: 'hidden' }} />
            </div>
          )}
          <div className='h-block image'>
            {authorImage &&
              <img src={authorImage} alt={`Author: ${author}`} />}
            {!authorImage &&
              <div className='imageStub' />}
          </div>
          <div className='h-block person'>
            <h4>{author}</h4>
          </div>
          <div className='h-block publication'>
            <Link to={free ? productPage : productLink}>
              <h5>{title}</h5>
            </Link>
          </div>
          {!free && (
            <div className='h-block submenu'>
              {submenu && <div>{this.subMenu}</div>}
            </div>
          )}
          <div className='d-none d-sm-block h-block actions'>
            <Link to={free ? productPage : productLink}>
              <FontAwesomeIcon icon={faExternalLinkAlt} size='sm' />
            </Link>
          </div>
          {draggableProvided && (
            <div {...draggableProvided.dragHandleProps} className='h-block handler'>
              <FontAwesomeIcon icon={faEllipsisV} size='sm' />
            </div>
          )}
        </div>

        {this.isExtended &&
        (
          <table className='contentArea'>
            <thead>
              <tr>
                <th>LATEST CONTENT</th>
                <th>PUBLISHED ON</th>
              </tr>
            </thead>
            <tbody>
              {links && links.map((item) => (
                <tr key={uniqueString()}>
                  <td>
                    {isInNew(item.slug) && <Indicator />}
                    <Link to={item.link}>{item.title}</Link>
                  </td>
                  <td>
                    {safeFormatToDate(item.date, timeFormat)}
                    {item.readingTimeMin && (
                      <span className='text-muted'> • {item.readingTimeMin} min read</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </>
    )

    if (!draggableProvided) {
      return (
        <div className='subscriptionRow'>
          {content}
        </div>
      )
    }

    return (
      <div className='subscriptionRow' {...draggableProvided.draggableProps} ref={draggableProvided.innerRef}>
        {content}
      </div>
    )
  }
}

export default SubscriptionRow
