import { Link } from 'react-router-dom'
import React from 'react'
import PT from 'prop-types'

const BackNav = ({ link, title }) => {
  if (!link || !title) {
    return null
  }

  return (
    <div className='navigationWrapper'>
      <Link to={link}>
        <img
          alt='Back Arrow'
          src='//duk61ht4mw10c.cloudfront.net/shared/site/img/arrow.png'
        />
        {`Back to ${title}`}
      </Link>
    </div>
  )
}

BackNav.propTypes = {
  link: PT.string,
  title: PT.string
}

export default BackNav
