import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { computed, observable, action, makeObservable } from 'mobx'
import {
  toPairs, compose, map, head, last, prop, pluck, pathOr, forEach
} from 'ramda'

import { html2jsx } from 'Lib/parser'
import Spinner from 'Components/spinner'
import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import { getPortfolio } from 'Api/endpoints/portfolio'
import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import isAfter from 'date-fns/isAfter'
import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'

@inject('store')
@observer
class Single extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    publication: MobxPropTypes.objectOrObservableObject,
    history: ReactRouterPropTypes.history.isRequired
  };

  @computed get dropdownOptions () {
    return compose(
      map((groupPair) => ({
        label: head(groupPair),
        options: map((item) => ({
          label: prop('title', item),
          value: prop('code', item)
        }))(last(groupPair))
      })),
      toPairs
    )(this.props.store.mst.publication.portfoliosBySite)
  }

  @computed get customMessage () {
    const code = prop('code', this.props.publication)
    const { siteDetails } = this.props.store.site

    const messageData = compose(
      head,
      pluck(code),
      pathOr({}, ['messages', 'publicationMsg'])
    )(siteDetails)

    if ((messageData == null) || (messageData.until == null) || (messageData.enabled == null)) {
      return false
    }

    if (messageData.content && messageData.enabled && isAfter(toDate(parseISO(messageData.until)), new Date())) {
      return messageData.content
    }
  }

  @computed get filteredContent () {
    if (!this.portfolioRaw) {
      return null
    }

    const df = document.createDocumentFragment()
    const wrapper = document.createElement('div')

    wrapper.innerHTML = this.portfolioRaw

    compose(
      forEach((item) => df.appendChild(item)),
      prop('children')
    )(wrapper)

    const portfolios = df.querySelectorAll('#__rtp-portfolio') || []

    forEach((p) => {
      p.parentNode.removeChild(p)
    })(portfolios)

    return html2jsx(df.firstChild.innerHTML)
  }

  @computed get content () {
    return (
      <div>
        <main>
          {this.isLoading && <Spinner />}
          {!this.isLoading && (
            <div>
              {this.filteredContent}
            </div>
          )}
        </main>
      </div>
    )
  }

  @observable portfolioId;

  @observable publication;

  @observable portfolioRaw = '';

  @observable isLoading;

  @observable selectedItem;

  @action.bound
  async fetchPortfolio () {
    this.isLoading = true
    const res = await getPortfolio(this.portfolioId)
    this.isLoading = false

    this.portfolioRaw = res.value
  }

  @action.bound
  onSelectionUpdate (v) {
    this.selectedItem = v
    this.props.history.push(`/${v.value}/portfolio`)
  }

  constructor (props) {
    super(props)

    makeObservable(this)

    this.publication = this.props.publication
    this.portfolioId = this.publication.portfolio_id
    this.selectedItem = {
      label: this.publication.title,
      value: this.portfolioId
    }

    this.fetchPortfolio()
  }

  render () {
    return (
      <BaseLayout>
        <TemplateSingle
          noLayout
          message={this.customMessage}
          title={this.publication.title}
          metaTitle={`${this.publication.title} Portfolio`}
          content={this.content}
          extraClass='__portfolio'
          publicationTitle={this.props.publication.title}
          publicationCode={this.props.publication.code}
          isPortfolio
        />
      </BaseLayout>
    )
  }
}

export default withRouter(Single)
