import React, { Component } from 'react'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { computed, action, observable, autorun, makeObservable } from 'mobx'
import Spinner from 'Components/spinner'
import {
  prop, compose, join, pluck, propOr, sortBy,
  toLower, keys, map, equals, head, filter, lensProp,
  view, flip, zipObj, dissoc, objOf, flatten, uniq,
  converge, curry, not, length, isEmpty, concat,
  propEq, forEach
} from 'ramda'
import * as Sentry from '@sentry/browser'
import { toast, Slide } from 'react-toastify'
import { Helmet } from 'react-helmet'

import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import { getTopN, getLatestArticles } from 'Api/endpoints/article'
import { getFreeTopN } from 'Api/endpoints/freearticle'
import { refresh, logout, getAd } from 'Api/endpoints/auth'
import { flippedIncludes } from 'Lib/purefunctions'
import {
  LatestArticles, Message, RowView, AdditionalResources
} from './components'
import 'react-toastify/dist/ReactToastify.css'
import Masthead from '../../../Base/Components/masthead'

const sortByAuthorCaseInsensitive = sortBy(compose(toLower, prop('author')))
const flippedView = flip(view)

const getEquivByField = curry((field, item) => compose(
  head,
  filter(compose(equals(prop(field, item)), prop('id')))
))

const addNotificationForPost = (publicationName) => {
  toast.success(`New Content has just been published to ${publicationName}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 8000,
    transition: Slide
  })
}

const findInTopN = (needle, haystack) => compose(
  head,
  filter(propEq('publication', needle))
)(haystack)

@inject('store')
@observer
class Base extends Component {
  constructor (props) {
    super(props)

    makeObservable(this)

    const publication = this.props.store.mst.publication.getByCode('the-daily-cut')

    if (publication) {
      this.props.store.mst.article.fetchFree('lgf', prop('_id', publication))
    }

    this.disposer = autorun(() => {
      if (!this.props.store.mst.realtime || !this.props.store.mst.realtime.messages) {
        return
      }

      forEach((p) => {
        // eslint-disable-next-line no-shadow
        if (p.consumed || p.post_type !== 'content') {
          return
        }

        if (!this.props.store.mst.userPublication.containsPublication(p.publication)) {
          p.updateProperty('consumed', true)
          return
        }

        // Caution: If we move this outside of the forEach, it will lead to an infinite loop
        if (!this.rawPaidTopN || isEmpty(this.rawPaidTopN)) {
          return
        }

        const pub = this.props.store.mst.publication.getById(p.publication)
        if (pub && pub.title && findInTopN(p.publication, this.rawPaidTopN)) {
          this.newItems = [...this.newItems, p.slug]
          addNotificationForPost(pub.title)
        }
        p.updateProperty('consumed', true)
      }, this.props.store.mst.realtime.messages)

      this.fetchLatest()
      this.fetchPaidTopN()
    })

    this.fetchLatest()
    this.fetchPaidTopN()
    this.fetchFreeTopN()
    // noinspection JSIgnoredPromiseFromCall
    this.fetchSubAd()
  }

  componentDidMount () {
    this.track()
  }

  componentWillUnmount () {
    if (this.disposer) {
      this.disposer()
    }
  }

  @observable rawPaidTopN = [];

  @observable latest = [];

  @observable rawFreeTopN = [];

  @observable additionalResourcesIsExtended = false;

  @observable newItems = [];

  @observable disposer = null;

  @observable subAd;

  @observable displaySubAd;

  @computed
  get paidTopN () {
    return this.getTop(this.rawPaidTopN, true)
  }

  @computed
  get freeTopN () {
    return this.getTop(this.rawFreeTopN, false)
  }

  @computed get paidPublicationsBySite () {
    const { bySite } = this.props.store.mst.publication
    const getListOfSitePublications = flippedView(bySite)

    const findEquivalentOverTopN = (item) => {
      const paidEquiv = getEquivByField('_id', item)(this.paidTopN)
      const freeEquiv = getEquivByField('_id', item)(this.freeTopN)

      return paidEquiv || freeEquiv || this.createPublicationView(item, [])
    }

    const unordered = compose(
      dissoc('Other'),
      zipObj(keys(bySite)),
      map(compose(
        map(findEquivalentOverTopN),
        getListOfSitePublications,
        lensProp
      )),
      keys
    )(bySite)
    const curriedAssign = curry(Object.assign)

    const converged = converge(curriedAssign, [
      compose(objOf('Legacy Research'), propOr([], 'Legacy Research')),
      dissoc('Legacy Research')
    ])(unordered)

    return filter(compose(
      not,
      equals(0),
      length
    ))(converged)
  }

  @computed get paidPublicationsBySiteCode () {
    const { bySiteOfCode } = this.props.store.mst.publication

    const findEquivalentOverTopN = (item) => {
      const paidEquiv = getEquivByField('_id', item)(this.paidTopN)
      const freeEquiv = getEquivByField('_id', item)(this.freeTopN)

      return paidEquiv || freeEquiv || this.createPublicationView(item, [])
    }

    return compose(
      zipObj(keys(bySiteOfCode)),
      map(compose(
        map(findEquivalentOverTopN),
        flippedView(bySiteOfCode),
        lensProp
      )),
      keys
    )(bySiteOfCode)
  }

  @action
  getTop = (raw, customLink = true) => sortByAuthorCaseInsensitive(raw.map((item) => {
    const publication = this.props.store.mst.publication.getById(item.publication)
    const links = propOr([], 'top_n', item)
      .map((article) => ({
        link: customLink ? `/${publication.code}/${article.slug}` : article.new_url,
        title: article.title,
        date: article.createdAtGMT,
        readingTimeMin: article.reading_time_min,
        slug: article.slug
      }))

    return this.createPublicationView(publication, links)
  }));

  @action.bound
  async fetchSubAd () {
    const { sitecode, siteDetails: { adZones } } = this.props.store.site
    const { isLoggedIn } = this.props.store.ui
    const { userAdKeywords } = this.props.store.user

    if (isLoggedIn) {
      this.subAd = await getAd(sitecode, adZones.mySub, 'keyword', userAdKeywords)
      this.displaySubAd = this.subAd !== 'no ad' ? true : ''
    }
  }

  @action createPublicationView = (publication, links) => ({
    id: prop('_id', publication),
    publicationCode: publication.code,
    title: publication.title,
    author: this.props.store.mst.author.getTitleByCode(publication.main_author_name),
    authorImage: publication.main_author_icon,
    siteImage: publication.site_logo,
    color: publication.color,
    isArchived: publication.publication_status === 'archived',
    archiveUrl: publication.archive_url,
    archiveInternalUrl: publication.archive_internal_url,
    group: publication.wordpressSiteName,
    productPage: publication.product_page,
    productDisplayOrder: publication.product_display_order,
    links
  });

  @action.bound
  logout () {
    logout(this.props.store.ui.siteId, this.props.store.user.token).then(() => {
      window.location = '/login'
      this.props.store.user.logout()
    })
  }

  @action.bound
  async forceRefresh () {
    const { sitecode } = this.props.store.site

    const resp = await refresh(sitecode, this.props.store.user.token)
    if (resp.error) {
      Sentry.withScope((scope) => {
        scope.setTag('dbg_pos', 'Force Refresh')
        Sentry.captureMessage(resp.error)
      })
      this.logout()
      return
    }

    this.props.store.user.afterLogin(resp)

    setTimeout(() => {
      window.location.reload()
    })
  }

  @action.bound
  childPublications (publication, idProp = '_id') {
    const childPublications = this.props.store.mst.publication.getChildrenOf(prop(idProp, publication))
    const { userPublications } = this.props.store.mst.userPublication

    const userHasPub = flippedIncludes(pluck('publicationId', userPublications))

    return compose(
      filter(
        compose(
          userHasPub,
          prop('_id')
        )
      )
    )(childPublications)
  }

  @action.bound
  async fetchLatest () {
    const { userPublications } = this.props.store.mst.userPublication
    const publicationList = pluck('publicationId', userPublications)

    this.latest = (await getLatestArticles(publicationList)) || []
  }

  @action.bound
  async fetchPaidTopN () {
    const { sitecode } = this.props.store.site
    const { paidViewForMySubscriptions } = this.props.store.mst.publication

    const allChildPubs = compose(
      pluck('_id'),
      flatten,
      map(this.childPublications)
    )(paidViewForMySubscriptions)

    const allPubIds = uniq(concat(allChildPubs, pluck('_id', paidViewForMySubscriptions)))

    const pString = join(',', allPubIds)

    if (isEmpty(pString)) {
      // This should happen only when the backend ids have been re-generated - logout the user
      Sentry.withScope((scope) => {
        scope.setTag('dbg_pos', 'fetchPaidTopN 1')
        Sentry.captureMessage('Empty pString')
      })

      this.logout()
      return
    }

    try {
      const code = sitecode.toLowerCase() === 'lg' ? 'all' : sitecode
      const { refreshRequired, articles } = await getTopN(code, pString)

      this.rawPaidTopN = articles || []

      if (refreshRequired) {
        this.forceRefresh()
      }
    } catch (e) {
      // This would fail if the backend ids are not re-generated but the user tokens have been flushed - logout the user
      Sentry.withScope((scope) => {
        scope.setTag('dbg_pos', 'fetchPaidTopN 2')
        Sentry.captureMessage(e)
      })

      this.logout()
    }
  }

  @action.bound
  async fetchFreeTopN () {
    const { freeSitecode } = this.props.store.site
    const pString = compose(
      join(','),
      pluck('_id')
    )(this.props.store.mst.publication.free)

    if (pString) {
      const code = freeSitecode.toLowerCase() === 'lgf' ? 'all' : freeSitecode
      this.rawFreeTopN = (await getFreeTopN(code, pString)) || []
    }
  }

  @action.bound
  track () {
    const { session } = this.props.store.user

    const obj = {
      session: prop('id', session),
      event: 'homepage',
      url: window.location.href,
      status: 1,
      scroll_depth: 100
    }

    this.props.store.user.analyticsAdd(obj)
  }

  render () {
    const { shouldDisplayMySubsMsg } = this.props.store.ui
    const { siteDetails: { messages: { mySubsMsg } } } = this.props.store.site

    return (
      <BaseLayout>
        <Helmet>
          <title>My Subscriptions</title>
        </Helmet>
        <div id='__page_my_subscriptions'>
          <Masthead />
          {this.displaySubAd && (
            <section className='subAdContainer'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12 subAdWrapper'>
                    <a href={this.subAd.redirectURL} target='_blank' rel='noopener noreferrer'>
                      <img src={this.subAd.imageURL} alt={this.subAd.altText} width='100%' />
                    </a>
                  </div>
                </div>
              </div>
            </section>
          )}

          {shouldDisplayMySubsMsg && <Message content={mySubsMsg.content} />}

          <section className='container layout1 subscriptionsTopNWrapper'>

            <LatestArticles raw={this.latest} newItems={this.newItems} />

            <header className='row'>
              <div className='col-6'>
                <h5 className='title'>Subscriptions Dashboard</h5>
              </div>
              <div className='col-12'>
                <div className='separator' />
              </div>
            </header>

            <section>
              {this.paidTopN.length < 1 && <Spinner />}

              <RowView
                paidPublicationsBySiteCode={this.paidPublicationsBySiteCode}
                // getPubRowView={this.getPubRowView}
                rawPaidTopN={this.rawPaidTopN}
                freeTopN={this.freeTopN}
                newItems={this.newItems}
              />
            </section>
          </section>

          <AdditionalResources />
        </div>
      </BaseLayout>
    )
  }
}

Base.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  publication: MobxPropTypes.objectOrObservableObject,
  location: ReactRouterPropTypes.location.isRequired
}

export default Base
