import React, { Component } from 'react'
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react'
import { observable, computed, action, makeObservable } from 'mobx'

import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import { getEditors } from 'Api/endpoints/article'
import Spinner from 'Components/spinner'
import { html2jsx } from 'Lib/parser'

@inject('store')
@observer
class Editors extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject
  };

  @observable editors;

  @observable openDescription;

  componentDidMount () {
    this.trackPage()
  }

  constructor (props) {
    super(props)

    makeObservable(this)

    // noinspection JSIgnoredPromiseFromCall
    this.fetchEditors()
  }

  @action.bound
  async fetchEditors () {
    const { sitecode } = this.props.store.site

    this.editors = await getEditors(sitecode)
  }

  @action.bound
  onButtonClicked (code) {
    const existingCode = this.openDescription

    this.openDescription = existingCode === code ? '' : code
  }

  @action.bound
  trackPage () {
    const { session } = this.props.store.user

    const sessionId = session ? session.id : null

    const obj = {
      session: sessionId,
      event: 'other',
      url: window.location.href,
      status: 1,
      scroll_depth: 100
    }

    this.props.store.user.analyticsAdd(obj)
  }

  @computed get content () {
    if (!this.editors) {
      return <Spinner />
    }

    return (
      <div className='editorsWrapper'>
        {this.editors.map((editor) => (
          <section key={editor.code} className='editorBox'>
            <div className='container'>
              <div className='row editorInfo'>
                <div className='col-2 editorImage'>
                  <img
                    alt={editor.code}
                    src={editor.icon}
                  />
                </div>
                <div className='col-6 editorName'>
                  <h2>{editor.firstName} {editor.lastName}</h2>
                  <h6><em>{editor.title}</em></h6>
                </div>
                <div className='col-4 editorButton'>
                  <h5 className='w-100'>
                    <button
                      onClick={() => {
                        this.onButtonClicked(editor.code)
                      }}
                      className='btn btn-outline-primary dropdown-toggle w-100'
                    >
                      <strong>About {editor.firstName}</strong>
                    </button>
                  </h5>
                </div>
              </div>

              {this.openDescription === editor.code && (
                <div className='row editorDesc'>
                  <div className='col-12'>
                    <h6><em>{editor.title}</em></h6>
                    {html2jsx(editor.description)}
                  </div>
                </div>
              )}
            </div>
          </section>
        ))}
      </div>
    )
  }

  render () {
    return (
      <TemplateSingle
        title='Editors'
        extraClass='__page_editors'
        content={this.content}
        isEditors
      />
    )
  }
}

export default Editors
