import React from 'react'
import * as A from 'fp-ts/Array'
import { Link } from 'react-router-dom'

import uniqueString from 'Lib/unique-string'
import { ILink } from '../index'

interface IToggleableArchive {
  months: ILink[],
  compact: boolean,
  onToggle: () => void
}

const ToggleableMenu = ({ months, compact, onToggle }: IToggleableArchive): JSX.Element => {
  const list = compact ? A.takeLeft(5)(months) : months

  return (
    <>
      <ul>
        {list.map((month) => (
          <li key={uniqueString()}>
            {/* // TODO Refactor to font icon library */}
            <img
              src='//duk61ht4mw10c.cloudfront.net/shared/site/img/arrow.png'
              className='after-arrow'
              alt='arrow'
            />
            <Link to={month.url}>{month.title}</Link>
          </li>
        ))}
      </ul>
      {compact && months.length > 5
      && <a className="showMoreLink" onClick={onToggle}>Show More</a>}
      {!compact && <a className="showLessLink" onClick={onToggle}>Show Less</a>}
    </>
  )
}

export default ToggleableMenu
