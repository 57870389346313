import React, { useState } from 'react'
import {
  map, propOr
} from 'ramda'
import PT from 'prop-types'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useResponsive } from 'ahooks'

import { useStore } from 'App'
import { createOrderedSiteList } from 'Lib/purefunctions'
import ClickOut from 'Lib/clickOut'

import Column from './column'

const Index = ({ currentPublication }) => {
  const [menuState, setMenuState] = useState(false)
  const store = useStore()
  const responsive = useResponsive()

  const { bySiteOfCode, getSiteNameByCode } = store.mst.publication
  const siteOrder = propOr([], 'groupOrder', store.site.siteDetails)
  const orderedSiteList = createOrderedSiteList(siteOrder, bySiteOfCode)
  const activePub = currentPublication ? currentPublication.title : ''

  return (
    <div id={responsive.xl ? 'lrgDropDownMenu' : 'lrgDropDownMenuMobile'} className='dropdown'>
      <button
        tabIndex="0"
        aria-haspopup="true"
        aria-controls="publicationMenu"
        aria-expanded={menuState ? 'true' : 'false'}
        type='button'
        className='btn text-white'
        onClick={() => setMenuState(!menuState)}
      >
        <span className='dropdownLabel'> Publication: </span>
        <span className='dropdownBrands'>  {activePub || 'Select Publication'} </span>
          &nbsp;
        {menuState ? <FontAwesomeIcon icon={faChevronUp} size='sm' /> : <FontAwesomeIcon icon={faChevronDown} size='sm' />}
      </button>
      {menuState && (
        <ClickOut onClickOut={() => setMenuState(!menuState)} className='clickOut_generic'>
          <div
            aria-labelledby="publicationMenu"
            style={{ fontSize: '12px' }}
            className={menuState ? 'dropdown-menu d-inline-block lrgDropDown' : 'dropdown-menu lrgDropDown'}
          >
            <div className='dropdown-content'>
              <div className='row'>
                {map(({ id, value }) => (
                  <Column
                    key={id}
                    brand={value}
                    brandName={getSiteNameByCode(id)}
                    activePub={activePub}
                  />
                ))(orderedSiteList)}
              </div>
            </div>
          </div>
        </ClickOut>
      )}
    </div>
  )
}

Index.propTypes = {
  currentPublication: PT.object
}

export default Index
