import React from 'react'

import RouteIf from 'Components/conditionalRoute'
import ProtectRoute from 'Components/protected'
import Editors from 'Containers/Sites/Base/Pages/Editors'
import Products from 'Containers/Sites/Base/Pages/Products'
import ProductsComponent from 'Containers/Sites/Base/Pages/MySubscriptions/base'
import ContactComponent from 'Containers/Sites/Base/Pages/Contact'
import ExternalAccountComponent from 'Containers/Sites/Base/Pages/ExternalAccount'
import DynamicPostComponent from 'Containers/Sites/Base/Pages/Dynamic'

export default store => {
  return [
    <RouteIf
      failCondition={!store.ui.isLoggedIn}
      failLocation='https://brownstoneresearch.com/authors/'
      external
      path='/editors'
      key='/editors'
      component={Editors}
    />,
    <RouteIf
      failCondition={!store.ui.isLoggedIn}
      failLocation='https://www.brownstoneresearch.com/products/'
      external
      path='/products'
      key='/products'
      component={Products}
    />,
    <RouteIf
      failCondition={!store.ui.isLoggedIn}
      failLocation='https://brownstoneresearch.com/contact-us/'
      external
      path='/contact'
      key='/contact'
      component={ContactComponent}
    />,
    <RouteIf
      failCondition={!store.ui.isLoggedIn}
      failLocation='https://www.brownstoneresearch.com'
      external
      path='/new-subscriptions-for-you'
      key='/new-subscriptions-for-you'
      render={(props) => (
        <DynamicPostComponent
          {...props}
          type='page'
          redirectIfPageNotFound
          forceSlug='new-subscriptions-for-you'
        />
      )}
    />,
    <ProtectRoute
      exact
      path='/my-subscriptions'
      key='/my-subscriptions'
      component={ProductsComponent}
    />,
    <ProtectRoute exact path='/my-account' key='/my-account-new' component={ExternalAccountComponent} />,
    <ProtectRoute exact path='/account' key='/account-redirect' component={ExternalAccountComponent} />,
  ]
}
