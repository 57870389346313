import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import Link from 'Components/link'
import { ICourse, IPublicationStore } from 'Stores/mst'

interface ICourseHeader {
  publication: IPublicationStore
  course: ICourse
}

const CourseHeader = ({
  publication,
  course
}: ICourseHeader): JSX.Element => {
  const parentLink = course.type === 'course' ? `/${publication.code}` : '/my-subscriptions'

  return (
    <div className="row course-header">
      <div className="container">
        <div className="row">
          <div className="col-3 my-courses">
            <h5>My Courses</h5>
          </div>
          <div className="col-9 course-name">
            <h5>
              <span className="site-name"><Link to={parentLink}>{publication.title}</Link></span> <span
              className="header-icon"><FontAwesomeIcon
              icon={faArrowRight} size="sm"/></span> {course.title}
            </h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseHeader
