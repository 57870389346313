import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { computed, observable, action, makeObservable } from 'mobx'
import {
  toPairs, compose, map, head, last, prop, pluck, pathOr, forEach, path, isEmpty
} from 'ramda'
import Select from 'react-select'

import { html2jsx } from 'Lib/parser'
import { getFragment, getPublicationSubPathFromPathname } from 'Lib/purefunctions'
import Spinner from 'Components/spinner'
import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import { getPortfolio } from 'Api/endpoints/portfolio'
import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import isAfter from 'date-fns/isAfter'
import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'

@inject('store')
@observer
class Single extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    publication: MobxPropTypes.objectOrObservableObject,
    history: ReactRouterPropTypes.history.isRequired
  };

  @computed get dropdownOptions () {
    return compose(
      map((groupPair) => ({
        label: head(groupPair),
        options: map((item) => ({
          label: prop('title', item),
          value: prop('code', item)
        }))(last(groupPair))
      })),
      toPairs
    )(this.props.store.mst.publication.portfoliosBySite)
  }

  @computed get customMessage () {
    const code = prop('code', this.props.publication)
    const { siteDetails } = this.props.store.site

    const messageData = compose(
      head,
      pluck(code),
      pathOr({}, ['messages', 'publicationMsg'])
    )(siteDetails)

    if ((messageData == null) || (messageData.until == null) || (messageData.enabled == null)) {
      return false
    }

    if (messageData.content && messageData.enabled && isAfter(toDate(parseISO(messageData.until)), new Date())) {
      return messageData.content
    }
  }

  @computed get filteredContent () {
    if (!this.portfolioRaw) {
      return null
    }

    const df = document.createDocumentFragment()
    const wrapper = document.createElement('div')

    wrapper.innerHTML = this.portfolioRaw

    compose(
      forEach((item) => df.appendChild(item)),
      prop('children')
    )(wrapper)
    // for (let i = 0; i < wrapper.children.length; i += 1) { df.appendChild(wrapper.children[i]); }
    const rac = df.getElementById('__rtp-allocation-strategy')

    if (rac && rac.parentNode) {
      rac.parentNode.removeChild(rac)
    }

    return html2jsx(df.firstChild.innerHTML)
  }

  @computed get content () {
    const dynamicPortfolioSectionTop = path(['settings', 'dynamicPortfolioSections', 'top'], this.props.publication)
    const showDynamicPortfolioSectionTop = (dynamicPortfolioSectionTop.enabled && !isEmpty(dynamicPortfolioSectionTop.content))
    const dynamicPortfolioSectionBottom = path(['settings', 'dynamicPortfolioSections', 'bottom'], this.props.publication)
    const showDynamicPortfolioSectionBottom = (dynamicPortfolioSectionBottom.enabled && !isEmpty(dynamicPortfolioSectionBottom.content))

    return (
      <div>
        <header className='d-print-none'>
          <h6>Select Portfolio</h6>
          <Select
            isSearchable={false}
            options={this.dropdownOptions}
            onChange={this.handleOnSelectionUpdate}
            value={this.selectedItem}
            className='portfolioDropdown'
          />
        </header>
        <main>
          {this.isLoading && <Spinner />}
          {!this.isLoading && !this.portfolioError && (
            <div className='portfolioContent'>
              {showDynamicPortfolioSectionTop && (
                <div className='dynamicPortfolioSectionTop'>
                  {html2jsx(dynamicPortfolioSectionTop.content)}
                </div>
              )}
              {this.filteredContent}
              {showDynamicPortfolioSectionBottom && (
                <div className='dynamicPortfolioSectionBottom'>
                  {html2jsx(dynamicPortfolioSectionBottom.content)}
                </div>
              )}
            </div>
          )}
          {this.portfolioError && (
            <div className='alert alert-warning'>
              One of our service providers is currently experiencing issues.
              We apologize for any inconvenience.
              You may experience an interruption with our Portfolio page until a resolution has been reached.
            </div>
          )}
        </main>
      </div>
    )
  }

  @observable portfolioId;

  @observable publication;

  @observable portfolioRaw = '';

  @observable isLoading;

  @observable selectedItem;

  @observable portfolioError = false;

  @action.bound
  async fetchPortfolio () {
    this.isLoading = true
    const res = await getPortfolio(this.portfolioId)
    this.isLoading = false

    if (res.error) {
      this.portfolioError = true
    } else {
      this.portfolioRaw = res.value
      this.trackPortfolio()
    }
  }

  @action.bound
  handleOnSelectionUpdate (v) {
    this.selectedItem = v
    this.props.history.push(`/${v.value}/portfolio`)
  }

  constructor (props) {
    super(props)

    makeObservable(this)

    this.publication = this.props.publication
    this.portfolioId = this.publication.portfolio_id
    this.selectedItem = {
      label: this.publication.title,
      value: this.portfolioId
    }

    this.fetchPortfolio()
  }

  @action.bound
  trackPortfolio () {
    const { session } = this.props.store.user
    const { pathname } = this.props.location

    const fragment = getFragment(pathname)
    const publication = this.props.store.mst.publication.getByCode(fragment)

    const sessionId = session ? session.id : null

    const publicationSubpath = publication ? getPublicationSubPathFromPathname(pathname) : null

    const obj = {
      session: sessionId,
      event: 'portfolioView',
      url: window.location.href,
      status: 1,
      scroll_depth: 100,
      publication_code: prop('code', publication),
      product_code: path(['settings', 'product_code'], publication),
      publication_subpath: publicationSubpath
    }

    this.props.store.user.analyticsAdd(obj)
  }

  render () {
    return (
      <BaseLayout>
        <TemplateSingle
          noLayout
          message={this.customMessage}
          title={this.publication.title}
          metaTitle={`${this.publication.title} Portfolio`}
          content={this.content}
          extraClass='__portfolio'
          publicationTitle={this.props.publication.title}
          publicationCode={this.props.publication.code}
          isPortfolio
        />
      </BaseLayout>
    )
  }
}

export default withRouter(Single)
