import React, { useState, useEffect } from 'react'
import Tour from 'reactour'
import { useStore } from 'App'

import { saveUserData } from 'Api/endpoints/user'

const MySubsWalkthrough = () => {
  const [openTour, setTourOpen] = useState(false)

  const store = useStore()
  const { sitecode } = store.site
  const { userSettings } = store.user

  useEffect(() => {
    const viewTourResponse = userSettings.filter((p) => p.dataType === 'walkthrough')
    if (viewTourResponse.length > 0) {
      setTourOpen(false)
    } else {
      setTourOpen(true)
    }
  }, [userSettings])

  const closeTour = () => {
    setTourOpen(false)

    saveUserData(sitecode, 'walkthrough', { mySubsWalkthrough: true })
    store.user.userSettings.push({ dataType: 'walkthrough' })
  }

  const steps = [
    {
      selector: '[data-name="mySubsWalkthroughStep-1"]',
      content: () => (<div> Under <b>My Account</b>, you will find quick access to your subscription, portfolio and account information. </div>)
    },
    {
      selector: '[data-name="mySubsWalkthroughStep-2"]',
      content: () => (
        <div> Use the <b>Breadcrumbs</b> and <b>Dropdown</b> for navigation and quick access to each publication.</div>
      )
    },
    {
      selector: '[data-name="mySubsWalkthroughStep-3"]',
      content: () => (
        <div> Use the <b>Navigation</b> tabs, to access past content, reports, any special courses, and each publication&lsquo;s portfolio page.</div>
      )
    },
    {
      selector: '[data-name="mySubsWalkthroughStep-4"]',
      content: () => (
        <div>
          The <b>Publication Preview</b> area gives you access to recently published content.
        </div>
      )
    },
    {
      selector: '[data-name="mySubsWalkthroughStep-5"]',
      content: () => (
        <div> Visit each <b>Publication&lsquo;s Homepage</b> for even more content related to your subscription.
          <br />
          <button className='k-button mt-3' onClick={closeTour}> Exit tour </button>
        </div>
      )
    }
  ]
  return (
    <>
      <Tour
        steps={steps}
        isOpen={openTour}
        showButtons
        showNavigation
        className='tour-class'
        showNavigationNumber={false}
        showNumber={false}
        rounded={3}
        showCloseButton={false}
        onRequestClose={() => closeTour()}
      />
    </>
  )
}

export { MySubsWalkthrough }
