import React from 'react'
import Modal from 'react-responsive-modal'
import PT from 'prop-types'

const MsgModal = ({
  title, content, onConfirm, onClose
}) => (
  <Modal
    open
    onClose={onClose}
    classNames={{
      overlay: 'r-modal-account-overlay',
      modal: 'r-modal-account-modal',
      closeButton: 'r-modal-account-close'
    }}
  >
    <div>
      {title && (
        <header>
          <h5>{title}</h5>
        </header>
      )}
      <main>
        {content}
      </main>
      {onConfirm && (
        <footer>
          <button className='btn btn-light' onClick={onClose}>Cancel</button>
          <button className='btn btn-success' onClick={onConfirm}>Confirm</button>
        </footer>
      )}
    </div>
  </Modal>
)

MsgModal.propTypes = {
  content: PT.node,
  title: PT.string,
  onConfirm: PT.func,
  onClose: PT.func
}

export default MsgModal
