import React from 'react'
import { Route } from 'react-router-dom'

import HomeComponent from '../../Pages/Home'
import LoginComponent from '../../Pages/Login'
import LogoutComponent from '../../Pages/Logout'
import ResetComponent from '../../Pages/Reset'
import ProtectRoute from '../../../../../Components/protected'
import ContactComponent from '../../Pages/Contact'
import FeedbackComponent from '../../Pages/Feedback'
import LegalNoticesComponent from '../../Pages/Plain/legalNotices'
import PortfolioArchiveComponent from '../../Pages/Portfolio/archive'
import AccountComponent from '../../Pages/Account'
import ExternalAccountComponent from '../../Pages/ExternalAccount'
import MyAccountManageSubsComponent from '../../Pages/AccountPanel/manage'
import SearchComponent from '../../Pages/Search'
import MyAccountComponent from '../../Pages/AccountPanel'
import FavoritesComponent from '../../Pages/Favorites'
import PubScheduleComponent from '../../Pages/PublicationSchedule'
import NotFound from '../../Pages/NotFound'
import NoAccess from '../../Pages/NoAccess'

const staticR = [
  <Route exact path='/' key='/' component={HomeComponent} />,
  <Route exact path='/login' key='/login' component={LoginComponent} />,
  <Route exact path='/logout' key='/logout' component={LogoutComponent} />,
  <Route exact path='/reset' key='/reset' component={ResetComponent} />,
  <ProtectRoute exact path='/contact' key='/contact' component={ContactComponent} />,
  <ProtectRoute exact path='/feedback' key='/feedback' component={FeedbackComponent} />,
  <Route exact path='/legal-notices' key='/legal-notices' component={LegalNoticesComponent} />,
  <ProtectRoute exact path='/portfolio' key='/portfolio' component={PortfolioArchiveComponent} />,
  <ProtectRoute exact path='/account' key='/account' component={AccountComponent} />,
  <ProtectRoute exact path='/account-ext' key='/account' component={ExternalAccountComponent} />,
  <ProtectRoute
    exact path='/my-account/manage/:code/:level' key='/my-account/manage/:code/:level'
    component={MyAccountManageSubsComponent}
  />,
  <ProtectRoute exact path='/feedback' key='/feedback' component={FeedbackComponent} />,
  <ProtectRoute exact path='/search' key='/search' component={SearchComponent} />,
  <ProtectRoute exact path='/my-account' key='/my-account' component={MyAccountComponent} />,
  <ProtectRoute exact path='/bookmarks' key='/bookmarks' component={FavoritesComponent} />,
  <ProtectRoute exact path='/publication-schedule' key='/publication-schedule' component={PubScheduleComponent} />,
  <Route exact path='/pageNotFound' key='/pageNotFound' component={NotFound} />,
  <Route exact path='/unavailable' key='/unavailable' component={NoAccess} />
]

export default staticR
