import React from 'react'

import RouteIf from 'Components/conditionalRoute'
import ProtectRoute from 'Components/protected'
import Editors from 'Containers/Sites/Base/Pages/Editors'
import Products from 'Containers/Sites/Base/Pages/Products'
import ProductsComponent from '../../Pages/MySubscriptions/base'
import ExternalAccountComponent from '../../../Base/Pages/ExternalAccount'
import ContactComponent from '../../../Base/Pages/Contact'

export default store => {
  return [
    <RouteIf
      failCondition={!store.ui.isLoggedIn}
      failLocation='https://www.legacyresearch.com/contact-us/'
      external
      exact
      path='/contact'
      key='/contact'
      component={ContactComponent}
    />,
    <RouteIf
      failCondition={!store.ui.isLoggedIn}
      failLocation='https://www.legacyresearch.com/team-members/'
      external
      exact
      path='/editors'
      key='/editors'
      component={Editors}
    />,
    <RouteIf
      failCondition={!store.ui.isLoggedIn}
      failLocation='https://www.legacyresearch.com/products/'
      external
      exact
      key='/products'
      path='/products'
      component={Products}
    />,
    <ProtectRoute path='/my-subscriptions' key='/my-subscriptions' component={ProductsComponent} />,
    <ProtectRoute exact path='/my-account' key='/my-account-new' component={ExternalAccountComponent} />,
    <ProtectRoute exact path='/account' key='/account-redirect' component={ExternalAccountComponent} />
  ]
}
