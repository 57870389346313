import { Predicate } from 'fp-ts/Predicate'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { pipe, constant } from 'fp-ts/lib/function'

import { IAuthor, IAuthorStore } from '../index'
import { Maybe } from 'Lib/Types/base'

const author = (self: IAuthorStore): IAuthorStore => ({
    getById (id): Maybe<IAuthor> {
      const matchesId: Predicate<IAuthor> = (a) => a._id === id

      return self.authors.filter(matchesId)[0]
    },

    getByCode (code): IAuthor[] {
      const matchesCode: Predicate<IAuthor> = (a) => a.code === code

      const authors: IAuthor[] = self.authors

      return authors.filter(matchesCode)
    },

    getTitleByCode (code: string): string {
      const list: IAuthor[] = self.getByCode(code)

      return pipe(
        list,
        A.head,
        O.map((a: IAuthor) => {
          return a.title ?? ''
        }),
        O.getOrElse(constant(''))
      )
    }
  }
)

export default author
