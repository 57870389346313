import React from 'react'
import PT from 'prop-types'
import { html2jsx } from 'Lib/parser'

const Message = ({ content }) => (
  <aside className='container'>
    <div className='row mySubsMsgContainer'>
      <div className='col-12 mySubsMsgWrapper'>
        <p>{html2jsx(content)}</p>
      </div>
    </div>
  </aside>
)

Message.propTypes = {
  content: PT.string
}

export default Message
