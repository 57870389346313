import React from 'react'
import PT from 'prop-types'
import { isEmpty } from 'ramda'

const Ad = ({ ad }) => {
  if (!ad || isEmpty(ad)) {
    return null
  }

  return (
    <div className='sidebarAdContainer d-none d-xl-block mt-5'>
      <a href={ad.redirectURL} target='_blank' rel='noopener noreferrer'>
        <img src={ad.imageURL} alt={ad.altText} width='100%' />
      </a>
    </div>
  )
}

Ad.propTypes = {
  ad: PT.shape({
    imageURL: PT.string,
    redirectURL: PT.string,
    altText: PT.string
  })
}

export default Ad
