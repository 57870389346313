import React, { useState, useEffect } from 'react'
import Switch from 'react-switch'
import PT from 'prop-types'

const Toggler = ({ publication, onChange }) => {
  const [status, setStatus] = useState(publication.selected)

  useEffect(() => {
    onChange(publication.publicationCode, status)
  }, [status, publication, onChange])

  const handleChange = () => {
    setStatus(!status)
  }

  return (
    <Switch onChange={handleChange} checked={status} width={42} height={21} />
  )
}

const publicationShape = PT.shape({
  selected: PT.bool,
  name: PT.string,
  publicationCode: PT.string,
  bundledPublications: PT.arrayOf(PT.shape({
    publicationCode: PT.string
  }))
})

Toggler.propTypes = {
  publication: publicationShape.isRequired,
  onChange: PT.func.isRequired
}

export default Toggler
