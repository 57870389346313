import React, { useState, useEffect } from 'react'
import PT from 'prop-types'

import { useStore } from 'App'

import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import { getSiteContent } from 'Api/endpoints/user'
import Spinner from 'Components/spinner'
import { html2jsx } from 'Lib/parser'
import { isEmpty, equals, cond, always } from 'ramda'

const type2title = cond([
  [equals('billing'), always('Billing Faq')],
  [equals('privacypolicy'), always('Privacy Policy')],
  [equals('termsofuse'), always('Terms of Use')],
  [equals('dmcapolicy'), always('DMCA Policy')]
])

const DynamicPages = ({ type }) => {
  const [siteContent, setSiteContent] = useState([])
  const [loaded, setLoaded] = useState(false)

  const store = useStore()
  const { sitecode } = store.site

  useEffect(() => {
    const fetchSiteContent = async () => {
      const data = await getSiteContent(sitecode, type)
      if (isEmpty(data) || isEmpty(data.content)) {
        setSiteContent(<p>No content found, please reload the page</p>)
      } else {
        setSiteContent(html2jsx(data.content))
      }
      setLoaded(true)
    }
    fetchSiteContent()
  }, [type, sitecode])

  const title = type2title(type)

  useEffect(() => {
    if (!title) {
      return
    }

    const { session } = store.user
    const sessionId = session ? session.id : null

    const obj = {
      session: sessionId,
      event: 'other',
      url: window.location.href,
      status: 1,
      scroll_depth: 100
    }

    store.user.analyticsAdd(obj)
  }, [title])

  return (
    <TemplateSingle
      title={title}
      extraClass='__page_siteContent'
      content={loaded ? <div>{siteContent}</div> : <Spinner />}
    />
  )
}

DynamicPages.propTypes = {
  type: PT.string
}

export default DynamicPages
