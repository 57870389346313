import React, { useRef, useEffect } from 'react'
import PT from 'prop-types'
// import { toC } from 'react-godfather'

const ClickOut = ({
  onClickOut,
  onUnmount
}) => {
  let wrapper

  const onSelfClick = (e) => {
    e.clickOutTarget = wrapper
  }

  const onAnyClick = (e) => {
    e.stopPropagation()

    if (e.clickOutTarget !== wrapper) {
      setTimeout(() => {
        onClickOut && onClickOut(e)
      }, 0)
    }
  }

  onUnmount(() => {
    if (wrapper) {
      wrapper.removeEventListener('click', onSelfClick)
    }

    if (typeof window !== 'undefined' && window.document) {
      window.document.removeEventListener('click', onAnyClick)
    }
  })

  return ({
    className = '_clickOut',
    children,
    container = 'div'
  }) => {
    // eslint-disable-next-line react/no-children-prop
    return React.createElement(container, {
      ref: (wrapper2) => {
        if (!wrapper) {
          if (typeof window !== 'undefined' && window.document) {
            wrapper2.addEventListener('click', onSelfClick)
            window.document.addEventListener('click', onAnyClick)
          }

          wrapper = wrapper2
        }
      },
      children,
      className
    })
  }
}

ClickOut.propTypes = {
  onClickOut: PT.func,
  className: PT.string,
  container: PT.string
}

// export default toC(ClickOut, [], { stopPropagation: true })

const ClickOutAlt = ({
  className = '_clickOut',
  children,
  onClickOut = () => {}
}) => {
  const wrapper = useRef(null)

  const onSelfClick = (e) => {
    e.clickOutTarget = wrapper
  }

  const onAnyClick = (e) => {
    e.stopPropagation()

    if (e.clickOutTarget !== wrapper) {
      setTimeout(() => {
        onClickOut && onClickOut(e)
      }, 0)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && wrapper) {
      if (wrapper.current) {
        wrapper.current.addEventListener('click', onSelfClick)
      }

      document.addEventListener('click', onAnyClick)
    }

    return () => {
      if (wrapper && wrapper.current) {
        wrapper.current.removeEventListener('click', onSelfClick)
      }

      if (typeof window !== 'undefined') {
        window.document.removeEventListener('click', onAnyClick)
      }
    }
  }, [wrapper])

  return (
    <div className={className} ref={wrapper}>
      {children}
    </div>
  )
}

ClickOutAlt.propTypes = {
  onClickOut: PT.func,
  className: PT.string,
  container: PT.string
}

export default ClickOutAlt
