export default function handleFragment () {
  setTimeout(() => {
    const fragId = window.location.hash.substr(1)

    const el = document.getElementsByName(fragId)[0]
    if (el) {
      el.scrollIntoView()
    }
  }, 2000)
}
