import { flatten } from 'ramda'

import { getAuthors } from 'Api/endpoints/article'
import { getFreeAuthors } from 'Api/endpoints/freearticle'
import { Author } from 'Stores/mst/Models/author'

const author = (self) => ({
  push (item) {
    const itemExists = self.authors.valueOf().filter((a) => a._id === item._id)[0]

    if (itemExists) {
      return
    }

    self.authors.push(item)
  },

  pushPublications (items) {
    items.map((item) => this.push(item))
  },

  getById (id) {
    // console.log(`getById ${id}`);
    // console.log(self.authors.valueOf().filter(a => a._id === id));
    return self.authors.valueOf().filter((a) => a._id === id)[0]
  },

  async fetchAll (sitecode, freeSitecode) {
    try {
      const promises = freeSitecode ? [getAuthors(sitecode), getFreeAuthors(freeSitecode)] : [getAuthors(sitecode)]
      const data = flatten(await Promise.all(promises))
      const objects = data.map((item) => Author.create(item))
      self.pushPublications(objects)
    } catch (e) {
      console.log(e)
    }
  }
})

export default author
