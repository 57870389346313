/* eslint-disable @typescript-eslint/no-unused-vars */
/* This is an issue with the linter not recognizing the use within the decorator */
import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { inject, observer } from 'mobx-react'
import { computed, makeObservable } from 'mobx'

import Maintenance from 'Containers/Sites/Base/Pages/Maintenance'
import Lg from 'Containers/Sites/Legacyresearch/exports'
import Pb from 'Containers/Sites/Palmbeachgroup/exports'
import Re from 'Containers/Sites/Rogueeconomics/exports'
import Br from 'Containers/Sites/Brownstone/exports'
import Jc from 'Containers/Sites/Jeffclarktrader/exports'
import Ot from 'Containers/Sites/Opportunistictrader/exports'
import Np from 'Containers/Sites/Newparadigmresearch/exports'
import Wm from 'Containers/Sites/Widemoatresearch/exports'
import Gp from 'Containers/Sites/Gulfportanalytics/exports'
import Notification from 'Components/notifications'

import Store from 'Stores'
import { adjustPageTitle } from 'Lib/realtime'
import { getFragment } from 'Lib/purefunctions'

const IoTWrapper = ({ children }): JSX.Element => {
  return (
      <Notification>
        {children}
      </Notification>
  )
}

interface RootProps extends RouteComponentProps {
  store: Store
}

// eslint-disable-next-line
@(withRouter as any)  // Workaround for TS issue until FC refactor
@inject('store')
@observer
class Root extends Component<RootProps> {
  constructor (props: RootProps) {
    super(props)
    makeObservable(this)
  }

  componentDidMount (): void {
    this.onRouteChanged()
  }

  componentDidUpdate (prevProps: RootProps): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.onRouteChanged()
    }
  }

  onRouteChanged (): void {
    this.props.store.user.adjustSession()

    setTimeout(() => {
      adjustPageTitle(this.props.store)

      setTimeout(() => {
        adjustPageTitle(this.props.store)

        setTimeout(() => {
          adjustPageTitle(this.props.store)
        }, 1000)
      }, 500)
    }, 500)

    if (globalThis.dataLayer == null) {
      return
    }

    setTimeout(() => {
      const title = document.getElementsByTagName('title')[0].innerHTML

      globalThis.dataLayer.push({
        event: 'VirtualPageview',
        virtualPageURL: this.props.location.pathname,
        virtualPageTitle: title
      })
    }, 3000)
  }

  @computed get publication (): unknown {
    const fragment = getFragment(this.props.location.pathname)

    return this.props.store.mst.publication.getByCode(fragment)
  }

  render (): JSX.Element {
    const { sitecode, isMaintenance } = this.props.store.site

    if (isMaintenance) {
      return <Maintenance />
    }

    switch (sitecode) {
      case 'LG':
        return <IoTWrapper><Lg /></IoTWrapper>
      case 'PB':
        return <IoTWrapper><Pb /></IoTWrapper>
      case 'RE':
        return <IoTWrapper><Re /></IoTWrapper>
      case 'BR':
        return <IoTWrapper><Br /></IoTWrapper>
      case 'JC':
        return <IoTWrapper><Jc /></IoTWrapper>
      case 'OT':
        return <IoTWrapper><Ot /></IoTWrapper>
      case 'NP':
        return <IoTWrapper><Np /></IoTWrapper>
      case 'WM':
        return <IoTWrapper><Wm /></IoTWrapper>
      case 'GP':
        return <IoTWrapper><Gp /></IoTWrapper>
      default:
        return <div />
    }
  }
}

export default Root
