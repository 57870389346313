import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import {
  map, propOr, addIndex
} from 'ramda'
import { observer } from 'mobx-react-lite'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { useStore } from 'App'
import { createOrderedSiteList, reorder } from 'Lib/purefunctions'
import PubRowViewList from './pubRowViewList'

const mapIndexed = addIndex(map)

const RowView = ({
  rawPaidTopN, freeTopN, newItems, paidPublicationsBySiteCode
}) => {
  const store = useStore()

  const [customizableOrder, setCustomizableOrder] = useState([])

  useEffect(() => {
    const siteOrder = propOr([], 'groupOrder', store.site.siteDetails)
    const orderedSiteList = createOrderedSiteList(siteOrder, paidPublicationsBySiteCode)
    setCustomizableOrder(orderedSiteList.filter((item) => item.value))
  }, [store.site.siteDetails, paidPublicationsBySiteCode])

  const onDragEnd = (result) => {
    const { destination, source } = result

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    setCustomizableOrder(reorder(customizableOrder, source.index, destination.index))
  }

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId='main'>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className='row'
          >
            {mapIndexed(({ id, value, code }, idx) => (
              <Draggable
                draggableId={id}
                index={idx}
                key={id}
                isDragDisabled
              >
                {(draggableProvided) => (
                  <div
                    className='col-12 productSiteWrapper mode-list'
                    {...draggableProvided.draggableProps}
                    ref={draggableProvided.innerRef}
                  >
                    <header {...draggableProvided.dragHandleProps}>
                      <img
                        src={store.mst.publication.getSiteLogoByPubCode(code)}
                        className='groupLogo'
                        alt='group'
                      />
                      <h5>{store.site.getSiteNameByCode(code)}</h5>
                    </header>
                    <main>
                      <PubRowViewList value={value} rawPaidTopN={rawPaidTopN} freeTopN={freeTopN} newItems={newItems} />
                    </main>

                  </div>
                )}
              </Draggable>
            ))(customizableOrder)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

RowView.propTypes = {
  paidPublicationsBySiteCode: PT.object,
  rawPaidTopN: PT.array,
  freeTopN: PT.array,
  newItems: PT.array
}

export default observer(RowView)
