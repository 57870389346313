/* eslint-disable no-undef */
import React, {
  useContext, createContext, useEffect, useState
} from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'mobx-react'
import * as Sentry from '@sentry/browser'
import ReactNotification from 'react-notifications-component'
import { ToastContainer } from 'react-toastify'
import {
  compose, any, equals, map, test, is
} from 'ramda'

import 'Lib/compat'
import ScrollBackToTop from './Components/scrollBackToTop'
import ScrollToTop from './Components/scrollToTop'

import Store from './Stores'
import Controller from './Containers/Controller'
import './App.css'
import { Nullable } from './Lib/Types/base'

// eslint-disable-next-line camelcase,@typescript-eslint/strict-boolean-expressions
if (!window.app_dev) {
  Sentry.init({
    dsn: 'https://61ce53a7c45840ddb706bd7d71df1dec@sentry.io/1445664',
    beforeSend (event, hint) {
      event.extra = event.extra ?? {}

      const error = hint.originalException

      if (error == null) {
        return event
      }

      // Assign enumerable error properties to extras
      try {
        if (hint.originalException == null) {
          return event
        }

        const keys = Object.keys(hint.originalException)

        if (keys.length > 0) {
          event.extra.errorProperties = {}
          keys.forEach((key) => {
            // @ts-expect-error Nonsense
            event.extra.errorProperties[key] = hint.originalException?.[key]
          })
        }
      } catch (e) {
        console.warn('[sentry] Failed to assign enumerable error properties to extras', e)
      }

      if (!(error instanceof Error)) {
        return event
      }

      // Filter out chunk messages and reload
      if (error.message.match(/Loading chunk/i) != null) {
        window.location.href = '/maintenance.html'

        return null
      }

      // Ignore specific error messages
      let exclusionMessageList: string[] = []
      try {
        const dynamicListContent: string[] = JSON.parse('__PLACEHOLDER__').map(atob)

        if (is(Array, dynamicListContent)) {
          exclusionMessageList = dynamicListContent
        }
      } catch (e) {
        // Ignore
      }

      const shouldIgnore = compose(
        any(equals(true)),
        map((fragment: string) => test(new RegExp(fragment, 'i'), error.message))
      )(exclusionMessageList)

      if (shouldIgnore) {
        return null
      }

      return event
    }
  })

  Sentry.configureScope((scope) => {
    scope.setTag('environment', window.app_env)
    scope.setTag('siteCode', window.siteCode)
  })
}

const store = new Store()

export const StoreContext = createContext(store)

export const useStore = (): Store => useContext(StoreContext)

const usePersistentStore = (storeObj): Nullable<Store> => {
  const [s, setS] = useState<Nullable<Store>>(null)

  useEffect(() => {
    const hydration = async (): Promise<void> => {
      const { userPromise } = storeObj

      store.user = await userPromise

      setS(storeObj)
    }

    void hydration()
  }, [storeObj])

  return s
}

const App = (): JSX.Element => {
  const persistentStore = usePersistentStore(store)

  if (persistentStore == null) {
    return <div />
  }

  return (
    <Provider store={persistentStore}>
      <Router>
        <ScrollToTop>
          <div className='App'>
            <ToastContainer />
            <ReactNotification />
            <StoreContext.Provider value={persistentStore}>
              {/* @ts-expect-error Controller needs to be refactored with classes */}
              <Controller />
            </StoreContext.Provider>
            <ScrollBackToTop />
          </div>
        </ScrollToTop>
      </Router>
    </Provider>
  )
}

export default App

window.addEventListener('storage', (event) => {
  if (event.key === 'logged_out') {
    if (store.user.isLoggedIn) {
      store.user.logout()
    }
  }
}, false)
