import React, { Component } from 'react'
import { action, makeObservable } from 'mobx'
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react'

import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import Spinner from 'Components/spinner'
import { logout } from 'Api/endpoints/auth'

@inject('store')
@observer
class Logout extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject
  };

  constructor (props) {
    super(props)

    makeObservable(this)

    this.logout()
    this.props.store.user.logout()
  }

  @action.bound
  logout () {
    logout(this.props.store.ui.siteId, this.props.store.user.token).then(() => {
      this.props.store.user.logout()
      window.location = '/login'
    })
  }

  render () {
    return (
      <BaseLayout>
        <div className='container' id='__page_logout'>
          <div className='row'>
            <div className='col-md-12'>
              <section>
                <h4>Logging out... <Spinner /></h4>
              </section>
            </div>
          </div>
        </div>
      </BaseLayout>
    )
  }
}

export default Logout
