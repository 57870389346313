/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { inject, observer } from 'mobx-react'
import { observable, action, makeObservable } from 'mobx'
import Modal from 'react-responsive-modal'
import classnames from 'classnames'

import { html2jsx } from 'Lib/parser'
import uniqueString from 'Lib/unique-string'
import injectPlayerScript from 'Lib/injectPlayer'
import Link from 'Components/link'
import { getBonusContent } from 'Api/endpoints/user'

library.add(fas, far)

@inject('store')
@observer
class Header extends Component {
  @observable welcomeModalIsOpen = false

  @observable guideModalIsOpen = false

  @observable authorModalIsOpen = false

  @observable modalIsOpen = false

  @observable modalContent

  @observable bonusButtons = []

  static propTypes = {
    title: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string
    })),
    submenu: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      faClass: PropTypes.string,
      content: PropTypes.string
    })),
    // noBackTo: PropTypes.bool,
    isChild: PropTypes.bool,
    // color: PropTypes.string,
    publicationCode: PropTypes.string,
    alternativeMode: PropTypes.bool,
    publication: PropTypes.shape({
      code: PropTypes.string
    }),
    sitecode: PropTypes.string
  }

  constructor (props) {
    super(props)
    makeObservable(this)

    this.getBonusInfo()
  }

  @action.bound
  toggleModal (index) {
    this.modalContent = this.props.submenu[index].content
    this.modalIsOpen = !this.modalIsOpen
    injectPlayerScript()
  }

  @action.bound
  async getBonusInfo () {
    const {
      publication,
      sitecode
    } = this.props

    if (!publication || !sitecode) {
      return
    }

    const data = await getBonusContent(sitecode, publication.code)

    if (data.bonus && data.bonus.length > 0) {
      this.bonusButtons = data.bonus.map(entry => ({
        title: entry.button.title,
        description: entry.button.description,
        dest: entry.name
      }))
    }
  }

  render () {
    const {
      title,
      links,
      isChild,
      alternativeMode,
      publicationCode
    } = this.props

    const showBackArrow = !isChild

    return (
      <>
        {/* <PublicationTour /> */}
        {!isChild && (
          <div className={classnames('subscriber-heading',
            { isAlternativeMode: alternativeMode })}
          >
            <div className='row'>
              <div className='col-lg-6'>
                {!alternativeMode && (
                  <h1>{title}</h1>
                )}
                {alternativeMode && (
                  <h1><Link to={`/${publicationCode}`}>{title}</Link></h1>
                )}
              </div>
              <div className='col-lg-6'>
                <div className='subscriber-heading-list'>
                  {links.map((item) => <p key={uniqueString()}><Link to={item.url}>{item.title}</Link></p>)}
                </div>
              </div>
              <div className='col-lg-12'>
                <div className='borderline' />
              </div>
            </div>

            {!alternativeMode && (
              <div className='row subscriber-links'>
                <div className='col-lg-4 navigationWrapper'>
                  {showBackArrow && (
                    <Link to='/my-subscriptions'>
                      <img
                        alt='Back Arrow'
                        src='//duk61ht4mw10c.cloudfront.net/shared/site/img/arrow.png'
                      />
                      Back To My Subscriptions
                    </Link>
                  )}
                </div>

                <div className='col-lg-8 d-md-block'>
                  <ul className='subscriber-links-list'>
                    {this.props.submenu.map((item, index) => (
                      <li key={`subscriber-link=${item.title}`}>
                        {/* eslint-disable-next-line */}
                        <a onClick={() => this.toggleModal(index)}>
                          <FontAwesomeIcon icon={item.faClass} />
                          <span>{item.title}</span>
                        </a>
                      </li>
                    ))}
                    {this.bonusButtons.map(({ title, dest }) => (
                      <li key={`bonus-${title}`}>
                        <Link to={`/${this.props.publication.code}/bonus/${dest}`}>
                          <span>{title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
        <Modal
          open={this.modalIsOpen}
          onClose={() => this.modalIsOpen = !this.modalIsOpen}
          classNames={{
            overlay: 'r-modal-product-overlay',
            modal: 'r-modal-product-modal',
            closeButton: 'r-modal-product-close'
          }}
          focusTrapped={false}
        >
          <div>
            <div>
              {html2jsx(this.modalContent)}
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

export default Header
