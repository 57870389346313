import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { head, tail } from 'ramda'
import { Link } from 'react-router-dom'
import uniqueString from 'Lib/unique-string'

import Spinner from 'Components/spinner'

export default class Index extends Component {
  static propTypes = {
    reports: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      snippet: PropTypes.string,
      image: PropTypes.string
    })),
    publicationCode: PropTypes.string.isRequired
  };

  render () {
    const { reports, publicationCode } = this.props
    const headItem = head(reports)
    const tailItems = tail(reports)

    return (
      <div className='subscriber-reports' data-name='pubTour-step3'>
        <div>
          <h5 className='text-muted'>Special Reports</h5>
          <hr />
        </div>

        <div className='row itemgroup'>
          <div className='col-md-7 col-sm-12 leftside'>
            {!headItem && <Spinner />}
            {headItem &&
              (
                <>
                  <div className='wrapper'>
                    <div className='image'>
                      <img src={headItem.image} alt='head item' />
                    </div>
                    <div className='content'>
                      <h2>
                        <Link to={headItem.url}>
                          {headItem.title}
                        </Link>
                      </h2>
                      <p>{headItem.snippet}</p>
                    </div>
                  </div>
                </>
              )}
            <hr className='d-md-none' />
          </div>

          <div className='col-md-5 col-sm-12 rightside'>
            {tailItems.map((item, index) => (
              <Fragment key={uniqueString()}>
                <div className='wrapper issue'>
                  <div className='image'>
                    <Link to={item.url}>
                      <img src={item.image} alt='tail item' />
                    </Link>
                  </div>
                  <div className='content'>
                    <h5>
                      <Link to={item.url}>
                        {item.title}
                      </Link>
                    </h5>
                  </div>
                </div>
                {(index !== tailItems.length - 1) &&
                  <hr />}
              </Fragment>
            ))}
          </div>

        </div>

        <div className='actionWrapper'>
          <div>
            <Link to={`/${publicationCode}/special-reports`}>
              View All Special Reports
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
