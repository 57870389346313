import React from 'react'
import { Route } from 'react-router-dom'

import ProtectRoute from 'Components/protected'
import DynamicPostComponent from '../../Pages/Dynamic'
import DynamicPages from '../../Pages/DynamicPages'
import TrackRecordComponent from '../../Pages/TrackRecord/trackRecord'

export default [
  <ProtectRoute
    exact
    path='/legacy-investment-summit-2018'
    key='/legacy-investment-summit-2018'
    render={(props) => (
      <DynamicPostComponent
        {...props}
        type='page'
        paidPage
        forceSlug='legacy-investment-summit-2018'
        redirectIfPageNotFound
      />
    )}
  />,

  <Route
    exact
    path='/billing'
    key='/billing'
    render={(props) => (
      <DynamicPages
        {...props}
        type='billing'
      />
    )}
  />,

  <Route
    exact
    path='/privacy-policy'
    key='/privacy-policy'
    render={(props) => (
      <DynamicPages
        {...props}
        type='privacypolicy'
      />
    )}
  />,

  <Route
    exact
    path='/dmca-policy'
    key='/dmca-policy'
    render={(props) => (
      <DynamicPages
        {...props}
        type='dmcapolicy'
      />
    )}
  />,

  <Route
    exact
    path='/terms-of-use'
    key='/terms-of-use'
    render={(props) => (
      <DynamicPages
        {...props}
        type='termsofuse'
      />
    )}
  />,

  <ProtectRoute
    exact
    path='/track-record/:portfolioId' key='/track-record/:portfolioId'
    component={TrackRecordComponent}
  />,

  <Route
    exact
    path='/:slug'
    key='/:slug'
    render={(props) => (
      <DynamicPostComponent {...props} type='page' redirectIfPageNotFound />
    )}
  />,

  <Route
    exact
    path='/:posttype/:slug'
    key='/:posttype/:slug'
    render={(props) => (
      <DynamicPostComponent {...props} type='custom' redirectIfPageNotFound />
    )}
  />
]
