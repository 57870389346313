// import axlog from 'axios-debug-log';

import { upstreamEletter as API } from '../index'

async function getFreePublications (sitecode, type) {
  try {
    const withType = type ? `?type=${type}` : ''
    const res = await API.get(`/eletters/${sitecode}/eletterpublications${withType}`)

    return res.data.publications
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function getFreeAuthors (sitecode) {
  try {
    const res = await API.get(`/eletters/${sitecode}/authors`)

    return res.data.authors
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function getFreeArticlesArchive (sitecode, pubcode, offset = 0, limit = 10) {
  if (!(sitecode && pubcode)) {
    return {
      error: 'Invalid parameters'
    }
  }

  try {
    const path = `/eletters/${sitecode}/elettersarchive/${pubcode}/${offset}/${limit}?fields=min&type=post`
    const res = await API.get(path)

    return res.data.eletters
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function getFreeTopN (sitecode, publication, filters = [], n = 3) {
  let filter = ''
  if (filters.length > 0) {
    filter = `wordpressId,eq,${filters.join(',')}`
  }

  try {
    const path = `/eletters/${sitecode}/eletterstopn/${n}?publication=${publication}&filter=${filter}&fields=min`
    const res = await API.get(path)

    return res.data.eletters
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

export {
  getFreePublications,
  getFreeAuthors,
  getFreeArticlesArchive,
  getFreeTopN
}
