import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { autorun, computed, observable, makeObservable } from 'mobx'
import {
  prop, take, path, map, compose, pluck, pathOr, head, forEach
} from 'ramda'
import { rearrangeSticky, skip } from 'Lib/purefunctions'
import { toast, Slide } from 'react-toastify'

import { html2jsx } from 'Lib/parser'
import Header from './Components/Header'
import Issues from './Components/Issues'
import SectionalIssues from './Components/SectionalIssues'
import Reports from './Components/Reports'
import SectionalReports from './Components/SectionalReports'
import Message from '../../Components/Message'
import 'react-toastify/dist/ReactToastify.css'
import isAfter from 'date-fns/isAfter'
import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'

const addNotificationForPost = () => {
  toast.success('New Content has just been published.', {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 8000,
    transition: Slide
  })
}

@inject('store')
@observer
class SectionalView extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    publication: MobxPropTypes.objectOrObservableObject,
    isChild: PropTypes.bool
  };

  @observable newItems = [];

  @observable disposer = null;

  constructor (props) {
    super(props)

    makeObservable(this)

    const id = path(['publication', '_id'], props)
    const sitecode = path(['publication', 'wordpressSiteName'], props)

    this.disposer = autorun(() => {
      forEach((p) => {
        if (p.consumed || p.post_type !== 'content') {
          return
        }

        const pId = path(['publication', '_id'], props)

        if (!this.props.store.mst.userPublication.containsPublication(p.publication)) {
          p.updateProperty('consumed', true)
          return
        }

        if (pId === p.publication) {
          this.newItems = [...this.newItems, p.slug]
          addNotificationForPost()

          this.props.store.mst.article.fetch(sitecode, id, 'issues')
        }

        p.updateProperty('consumed', true)
      }, this.props.store.mst.realtime.messages)
    })

    this.props.store.mst.article.fetch(sitecode, id, 'issues', ...skip(4), this.props.store.user.refreshSite)
    this.props.store.mst.article.fetch(sitecode, id, 'reports')
  }

  componentWillUnmount () {
    if (this.disposer) {
      this.disposer()
    }
  }

  @computed get issues () {
    const code = prop('code', this.props.publication)

    return take(10, this.props.store.mst.article.getIssues(code, null, true, ['media']))
      .map((item) => ({
        url: `/${code}/${item.slug}`,
        readingTimeMin: item.reading_time_min,
        ...item
      }))
  }

  @computed get reports () {
    const code = prop('code', this.props.publication)

    return compose(
      map((item) => ({
        url: `/${code}/special-reports/${item.slug}`,
        ...item
      })),
      take(10),
      rearrangeSticky
    )(this.props.store.mst.article.getReports(code, null, true))
  }

  @computed get customMessage () {
    const code = prop('code', this.props.publication)
    const { siteDetails } = this.props.store.site

    const messageData = compose(
      head,
      pluck(code),
      pathOr({}, ['messages', 'publicationMsg'])
    )(siteDetails)

    if ((messageData == null) || (messageData.until == null) || (messageData.enabled == null)) {
      return false
    }

    if (messageData.content && messageData.enabled && isAfter(toDate(parseISO(messageData.until)), new Date())) {
      return messageData.content
    }
  }

  @computed get dynamicFinalComponents () {
    const { publication, isChild } = this.props

    const defaultOrder = ['message', 'header', 'issues', 'reports', 'resources']
    const productOrderFromConfig = path(['settings', 'product-stack'], this.props.publication)

    const order = productOrderFromConfig || defaultOrder

    const componentMap = {
      message: <Message content={this.customMessage} />,
      header: <Header
        title={publication.title}
        links={publication.menuItems}
        submenu={publication.submenuItemsWithContent}
        isChild={isChild}
        color={publication.color}
              />,
      issues: <Issues
        title={publication.title}
        issues={this.issues}
        publicationCode={publication.code}
        isChild={isChild}
        newItems={this.newItems}
              />,
      sectionalIssues: <SectionalIssues
        title={publication.title}
        issues={this.issues}
        publicationCode={publication.code}
        isChild={isChild}
        newItems={this.newItems}
                       />,
      reports: this.reports.length > 0 && (
        <Reports
          reports={this.reports}
          publicationCode={publication.code}
        />
      ),
      sectionalReports: this.reports.length > 0 && (
        <SectionalReports
          reports={this.reports}
          publicationCode={publication.code}
        />
      ),
      resources: publication.pub_resources && (
        <div className='pubResourcesWrapper'>
          <div>
            {html2jsx(publication.pub_resources)}
          </div>
        </div>
      )
    }

    return (
      <>
        {order.map((str) => <Fragment key={str}>{componentMap[str]}</Fragment>)}
      </>
    )
  }

  render () {
    const { publication } = this.props

    return (
      <div className={`singlePublicationViewWrapper pr-${publication.code} sectional`}>
        {this.dynamicFinalComponents}
      </div>
    )
  }
}

export default SectionalView
