import { differenceInSeconds } from 'date-fns'

import { refresh } from 'Api/endpoints/auth'

const minute = 60
const hour = 60 * minute
const day = 24 * hour

export default async (store, history) => {
  const { sitecode } = store.site
  const { isLoggedIn } = store.ui
  const { decodedToken } = store.user

  if (!isLoggedIn) {
    return
  }

  const exp = parseFloat(decodedToken.exp) * 1000
  const iat = parseFloat(decodedToken.iat) * 1000
  const expDiff = differenceInSeconds(new Date(exp), new Date())
  const iatDiff = differenceInSeconds(new Date(), new Date(iat))

  if (expDiff <= 0) {
    history.push('/login')
    return
  }

  if (iatDiff < day) {
    return
  }

  const resp = await refresh(sitecode, store.user.token)
  if (resp.error) {
    history.push('/logout')

    return
  }

  store.user.afterLogin(resp)
}
