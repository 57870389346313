import { types, getParent } from 'mobx-state-tree'

export const NewsletterType = types
  .model('NewsletterType', {
    _id: types.identifier,
    wordpressId: types.number,
    code: types.string,
    title: types.string,
    description: types.string,
    wordpressSiteName: types.string
  })
  .actions((self) => ({
    remove () {
      getParent(self).remove(self)
    }
  }))
