import { getRoot } from 'mobx-state-tree'
import * as TE from 'fp-ts/lib/TaskEither'
import * as E from 'fp-ts/lib/Either'
import * as IO from 'fp-ts/lib/IO'
import { pipe } from 'fp-ts/lib/function'

import { Course as CourseModel, Lesson as LessonModel, Section as SectionModel } from 'Stores/mst/Models/course'
import {
  ICourseStore,
  ICourse,
  ILesson,
  ISection,
  ISectionSnapshotIn,
  ILessonSnapshotIn,
  ICourseSnapshotIn
} from 'Stores/mst'
import { getCourses, getSections, ICourseAPI, ISectionAPI, ILessonAPI } from 'Api/endpoints/course'
import { IError } from 'Api/error'

const course = (self: ICourseStore): ICourseStore => ({
  createCourse (input: ICourseAPI): ICourse {
    const courseInput: ICourseSnapshotIn = {
      id: input._id,
      type: input.type,
      title: input.title,
      code: input.code,
      description: input.description,
      publications: input.publications,
      sectionIds: input.sections.map(item => item._id)
    }

    return CourseModel.create(courseInput)
  },

  createLesson (input: ILessonAPI): ILesson {
    const lessonInput: ILessonSnapshotIn = {
      id: input._id,
      title: input.title,
      ctaContent: input.ctaContent,
      slug: input.slug,
      description: input.content,
      displayOrder: input.displayOrder,
      titlePrefix: input.title_prefix,
      secondaryTitle: input.lesson_title,
      videoCode: input.lesson_video_code,
      videoDuration: input.lesson_video_duration,
      downloadLabel: input.download_label,
      downloadResourcesUrl: input.download_resources_url
    }

    return LessonModel.create(lessonInput)
  },

  createSection (input: ISectionAPI): ISection {
    const sectionInput: ISectionSnapshotIn = {
      id: input._id,
      title: input.title,
      code: input.code,
      label: input.label,
      displayOrder: input.displayOrder,
      ctaContent: input.ctaContent,
      isLocked: input.isLocked,
    }

    return SectionModel.create(sectionInput)
  },

  pushCourse (item: ICourse, skipCache = false): void {
    const existingItem = self.courses?.filter((a) => a.id === item.id)[0]

    if (existingItem == null) {
      self.courses?.push(item)

      return
    }

    if (skipCache) {
      existingItem.update(item)
    }
  },

  pushSection (item: ISection, skipCache = false): void {
    const existingItem = self.sections?.filter((a) => a.id === item.id)[0]

    if (existingItem == null) {
      self.sections?.push(item)

      return
    }

    if (skipCache) {
      existingItem.update(item)
    }
  },

  pushLesson (item: ILesson, skipCache = false): void {
    const existingItem = self.lessons?.filter((a) => a.id === item.id)[0]

    if (existingItem == null) {
      self.lessons?.push(item)

      return
    }

    if (skipCache) {
      existingItem.update(item)
    }
  },

  upsertCourse (item: ICourseAPI, skipCache = false): void {
    const obj = this.createCourse(item)

    this.pushCourse(obj, skipCache)
  },

  upsertCourses (courses: ICourseAPI[], skipCache = false): IO.IO<void> {
    return () => {
      courses.forEach(item => this.upsertCourse(item, skipCache))
    }
  },

  upsertSection (item: ISectionAPI, skipCache = false): void {
    const obj = this.createSection(item)

    self.pushSection(obj, skipCache)
  },

  upsertLesson (item: ILessonAPI, skipCache = false): void {
    const obj = this.createLesson(item)

    self.pushLesson(obj, skipCache)
  },

  fetchCourses (): TE.TaskEither<IError, ICourseAPI[]> {
    // @ts-expect-error Extended store
    const { sitecode } = getRoot(self)._root.site

    return pipe(
      getCourses(sitecode),
      TE.chainIOK(this.upsertCourses)
    )
  },

  // TODO Maybe refactor with fp-ts
  async fetchCourseSections (course: ICourseStore): Promise<void> {
    // @ts-expect-error Extended store
    const { sitecode } = getRoot(self)._root.site
    const sectionIds = course.sectionIds

    const sectionsResp = await getSections(sitecode, sectionIds)()

    const displaySorter = (a: ICourseStore, b: ICourseStore): number => {
      return a.displayOrder - b.displayOrder
    }

    if (E.isLeft(sectionsResp)) {
      return
    }

    const sectionsRaw = sectionsResp.right

    const sectionList: ICourseStore[] = []
    sectionsRaw.forEach(sectionRaw => {
      // Create the lessons
      const lessonList: ICourseStore[] = []
      sectionRaw.lessons.forEach(lessonRaw => {
        self.upsertLesson(lessonRaw)
        const obj: ILesson | undefined = self.lessons?.find(item => item.id === lessonRaw._id)
        if (obj !== undefined) {
          lessonList.push(obj)
        }
      })

      // Create the section
      self.upsertSection(sectionRaw)
      // Get a ref from the store array
      const section = self.sections?.find(item => item.id === sectionRaw._id)
      // Push that
      if (section !== undefined) {
        sectionList.push(section)

        // Order the lessons list
        lessonList.sort(displaySorter)
        // Update the section with the lessons
        section.updateProperty('lessons', lessonList)
      }
    })

    sectionList.sort(displaySorter)

    // Update the course
    course.updateProperty('sections', sectionList)
  }
})

export default course
