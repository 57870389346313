import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { action, computed, observable, runInAction, autorun, makeObservable } from 'mobx'
import {
  prop, take, path, assoc, compose, map, forEach
} from 'ramda'
import { Link } from 'react-router-dom'
import Spinner from 'Components/spinner'
import { isNew } from 'Lib/purefunctions'

import { html2jsx } from 'Lib/parser'
import Indicator from 'Components/newArticleIndicator'
import Header from './Components/Header'

@inject('store')
@observer
class FullContentView extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    publication: MobxPropTypes.objectOrObservableObject,
    isChild: PropTypes.bool
  };

  @observable pointer = 0;

  @observable isLoading = false;

  @observable hasMore = false;

  @observable newItems = [];

  @observable disposer = null;

  constructor (props) {
    super(props)

    makeObservable(this)

    const id = path(['publication', '_id'], props)
    const sitecode = path(['publication', 'wordpressSiteName'], props)

    this.disposer = autorun(() => {
      forEach((p) => {
        if (p.consumed || p.post_type !== 'content') {
          return
        }

        const pId = path(['publication', '_id'], props)

        if (!this.props.store.mst.userPublication.containsPublication(p.publication)) {
          p.updateProperty('consumed', true)
          return
        }

        if (pId === p.publication) {
          this.newItems = [...this.newItems, p.slug]

          this.props.store.mst.article.fetch(sitecode, id, 'issues')
        }

        p.updateProperty('consumed', true)
      }, this.props.store.mst.realtime.messages)

      this.fetchArticles(0, 10)
    })

    this.hydrate()
  }

  componentWillUnmount () {
    if (this.disposer) {
      this.disposer()
    }
  }

  @action.bound
  toggleIsLoading () {
    this.isLoading = !this.isLoading
  }

  @action.bound
  hydrate () {
    this.fetchMore()
  }

  @action.bound
  async fetchMore () {
    this.toggleIsLoading()
    await this.fetchArticles(this.pointer, 10)
    this.toggleIsLoading()
  }

  @action.bound
  async fetchArticles (start = 0, len = 10) {
    const id = prop('_id', this.props.publication)
    const sitecode = path(['publication', 'wordpressSiteName'], this.props)
    const setHasMore = (value) => runInAction(() => {
      this.hasMore = value
    })

    const count = await this.props.store.mst.article.fetch(sitecode, id, 'issues', start, len, null, 'normal')

    this.props.store.mst.article.fetch(sitecode, id, 'issues', start + 10, len, null, 'normal')
      .then((count2) => {
        setHasMore(count2 > 0)
      })
    this.pointer += count

    return count
  }

  @computed get issues () {
    const code = prop('code', this.props.publication)

    const addUrl = (v) => assoc('url', `/${code}/${v.slug}`, v)
    const addPublicationObj = (v) => assoc('publicationObj', this.props.publication, v)

    const transformWithLimit = compose(
      take(this.pointer),
      map(addUrl),
      map(addPublicationObj)
    )

    return transformWithLimit(this.props.store.mst.article.getIssues(code))
  }

  render () {
    const { publication, isChild } = this.props
    const {
      issues, isLoading, hasMore, fetchMore
    } = this

    const isInNew = isNew(this.newItems || [])

    return (
      <div className={`singlePublicationViewWrapper pr-${publication.code}`}>
        <Header
          title={publication.title}
          links={publication.menuItems}
          submenu={publication.submenuItemsWithContent}
          isChild={isChild}
        />
        <main className='col-sm-12 col-xs-12 col-md-8 fullContentPosts'>
          {issues.map((post) => (
            <div className='post' key={prop('_id', post)}>
              <h4>{isInNew(post.slug) && <Indicator />} <Link to={post.url}>{post.title}</Link></h4>
              <h6>{post.date}</h6>
              <div>
                {html2jsx(post.content)}
              </div>
            </div>
          ))}
          {isLoading &&
            <Spinner />}
          {hasMore && !isLoading &&
            <button onClick={fetchMore} className='btn_load_more'>Load More...</button>}
        </main>
      </div>
    )
  }
}

export default FullContentView
