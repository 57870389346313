import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { computed, action, makeObservable } from 'mobx'
import {
  prop, compose, pluck, filter, path
} from 'ramda'
import { Helmet } from 'react-helmet'

import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import { flippedIncludes } from 'Lib/purefunctions'
import classnames from 'classnames'
import SingleView from './singleView'
import FullContentView from './fullContentView'
import SectionalView from './sectionalView'
import Masthead from '../../Components/masthead'

@inject('store')
@observer
class Product extends Component {
  constructor (props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount () {
    this.track()
  }

  @computed get hasChildPublications () {
    const childPublications = this.props.store.mst.publication.getChildrenOf(prop('_id', this.props.publication))

    return childPublications.length > 0
  }

  @computed get validatedChildPublications () {
    const { publication } = this.props
    const childPublications = this.props.store.mst.publication.getChildrenOf(prop('_id', publication))
    const { userPublications } = this.props.store.mst.userPublication

    const userHasPub = flippedIncludes(pluck('publicationId', userPublications))

    return filter(
      compose(
        userHasPub,
        prop('_id')
      )
    )(childPublications)
  }

  @action.bound
  track () {
    const { session } = this.props.store.user
    const { publication } = this.props

    const obj = {
      session: prop('id', session),
      event: 'productpage',
      publication_code: prop('code', publication),
      product_code: path(['settings', 'product_code'], publication),
      url: window.location.href,
      status: 1,
      scroll_depth: 100
    }

    this.props.store.user.analyticsAdd(obj)
  }

  render () {
    const { publication, store: { site: sitecode } } = this.props
    const propName = sitecode === 'LG' ? 'legacy-product-component' : 'affiliate-product-component'
    const componentName = path(['settings', propName], publication)
    let ItemView

    switch (componentName) {
      case 'default':
        ItemView = SingleView
        break
      case 'fullContent':
        ItemView = FullContentView
        break
      case 'sectional':
        ItemView = SectionalView
        break
      default:
        ItemView = SingleView
    }

    if (!this.hasChildPublications) {
      return (
        <BaseLayout>
          <Helmet>
            <title>{publication.title}</title>
          </Helmet>
          <Masthead />
          <div className='subscriptionDashboard container' id='__subscription_product'>
            <ItemView
              publication={publication}
            />
          </div>
        </BaseLayout>
      )
    }

    return (
      <BaseLayout>
        <Masthead />
        <div
          className={classnames('subscriptionDashboard', 'container',
            { withChildProducts: this.validatedChildPublications.length > 0 })}
          id='__subscription_product'
        >
          <Helmet>
            <title>{publication.title}</title>
          </Helmet>
          <div className='subscriptionDashboard container'>
            <ItemView
              publication={publication}
            />
            {this.validatedChildPublications.map((childPublication) => {
              const componentNameChild = path(['settings', propName], publication)
              let ItemViewChild

              switch (componentNameChild) {
                case 'default':
                  ItemViewChild = SingleView
                  break
                case 'sectional':
                  ItemViewChild = SectionalView
                  break
                default:
                  ItemView = SingleView
              }

              return (

                <ItemViewChild
                  key={prop('_id', childPublication)}
                  publication={childPublication}
                  isChild
                />
              )
            })}
          </div>
        </div>
      </BaseLayout>
    )
  }
}

Product.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  publication: MobxPropTypes.objectOrObservableObject,
  location: ReactRouterPropTypes.location.isRequired
}

export default Product
