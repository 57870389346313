import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { action, observable, toJS, makeObservable } from 'mobx'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'

import { getSelectData, validateSelectData, updateSelectData } from 'Api/endpoints/account'
import { refresh } from 'Api/endpoints/auth'
import SelectManager from 'Containers/Sites/Base/Pages/AccountPanel/selectManager'
import PanelTemplate from 'Containers/Sites/Base/Templates/accountPanel'

@inject('store')
@observer
class Manage extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    match: ReactRouterPropTypes.match.isRequired
    // history: ReactRouterPropTypes.history.isRequired,
  };

  @observable bundle = {};

  constructor (props) {
    super(props)
    makeObservable(this)
  }

  @action.bound
  async getBundle () {
    const { sitecode } = this.props.store.site
    const { match: { params: { code, level } } } = this.props

    const { value } = await getSelectData(sitecode, code, level)

    this.bundle = value
  }

  @action.bound
  async refresh () {
    const { sitecode } = this.props.store.site
    const resp = await refresh(sitecode, this.props.store.user.token)

    this.props.store.user.afterLogin(resp)
  }

  render () {
    const { sitecode } = this.props.store.site

    const validate = validateSelectData(sitecode)
    const update = updateSelectData(sitecode)

    return (
      <PanelTemplate
        metaTitle='Account / Select'
        title='Manage Choice Subscriptions'
        afterAuth={this.getBundle}
      >
        <div className='container'>
          <SelectManager bundle={toJS(this.bundle)} validate={validate} update={update} refresh={this.refresh} />
        </div>
      </PanelTemplate>
    )
  }
}

export default Manage
