/* eslint-disable camelcase */
import { getParent, types } from 'mobx-state-tree'
import { compose, curry, head, isEmpty, last, map, match, pluck, replace } from 'ramda'

import { IPublicationStore } from '../index'

export interface IInfo {
  url: string
  title: string
}

interface IPubSubMenu {
  pub_sub_menu_item: string
  pub_sub_menu_content: string
}

export interface ISubmenuItemsExtended extends IInfo {
  faClass?: string
  content?: string
}

const stringToNode = curry((string) => {
  const node = document.createElement('aside')
  node.innerHTML = string

  return node
})

const extractLinkDataFromNode = (node: HTMLElement): IInfo[] => {
  const getRelativeUrlIfLocal = (absolute): string => {
    const obj = new URL(absolute)

    if (window.location.hostname !== obj.hostname) {
      return absolute
    }

    return obj.pathname
  }
  const getInfo = (item): IInfo => ({ url: getRelativeUrlIfLocal(item.href), title: item.text })

  const items = [...node.querySelectorAll('a')]

  return map(getInfo)(items)
}

export const Publication = types
  .model('Publication', {
    _id: types.identifier,
    wordpressId: types.integer,
    code: types.string,
    title: types.string,
    description: types.string,
    wordpressSiteName: types.string,
    portfolio_id: types.optional(types.string, ''),
    publication_status: types.optional(types.string, ''),
    publication_type: types.optional(types.string, ''),
    publication_home_page_layout: types.optional(types.string, ''),
    product_page: types.optional(types.string, ''),
    product_display_order: types.maybe(types.number),
    main_author_name: types.optional(types.string, ''),
    main_author_icon: types.optional(types.string, ''),
    site_logo: types.optional(types.string, ''),
    show_on_subscription_page: types.optional(types.string, ''),
    show_collapsed: types.optional(types.string, ''),
    color: types.optional(types.string, ''),
    pub_main_menu: types.maybe(types.array(types.frozen())),
    pub_sub_menu: types.maybe(types.array(types.frozen())),
    pub_resources: types.optional(types.string, ''),
    archive_url: types.optional(types.string, ''),
    archive_internal_url: types.optional(types.boolean, false),
    has_children: types.optional(types.string, ''),
    parent_publication: types.optional(types.string, ''),
    settings: types.optional(types.frozen(), {})
  })
  .views((self) => ({
    get menuItems (): IInfo[] {
      const { code, portfolio_id: portfolioId, pub_main_menu: pubMainMenu } = self
      const defaultMenu = [
        { url: `/${code}/archives`, title: 'Issues & Updates' }
      ]

      if (!isEmpty(portfolioId)) {
        defaultMenu.push({ url: `/${code}/portfolio`, title: 'Portfolio' })
      }

      if (pubMainMenu == null) {
        return defaultMenu
      }

      const menu = compose(
        extractLinkDataFromNode,
        stringToNode,
        pluck('pub_menu_item'),
      )(pubMainMenu)

      if (menu == null || menu.length === 0) {
        return defaultMenu
      }

      return menu
    },
    get submenuItemsWithContent () {
      const items: IPubSubMenu[] = self.pub_sub_menu ?? []

      return map((item: IPubSubMenu) => {
        const { pub_sub_menu_item: pubSubMenuItem, pub_sub_menu_content: pubSubMenuContent } = item

        const node = stringToNode(pubSubMenuItem)
        const out: ISubmenuItemsExtended | undefined = head(extractLinkDataFromNode(node)) ?? { url: '', title: ''}

        out.faClass = compose(
          replace(/-o$/, ''), // FontAwesome 4 -> 5
          last,
          match(/fa-(.+?)"/)
        )(pubSubMenuItem)

        out.content = pubSubMenuContent

        return out
      })(items)
    }
  }))
  .actions((self) => ({
    add (item) {
      const { publications } = getParent<IPublicationStore>(self)
      const itemExists = publications.filter((a) => a._id === item._id)[0]

      if (itemExists != null) {
        return
      }

      publications.push(item)
    },
    remove () {
      getParent<IPublicationStore>(self).remove(self)
    }
  }))
