import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  compose, defaultTo, head, propOr, tail
} from 'ramda'
import { Link } from 'react-router-dom'
import uniqueString from 'Lib/unique-string'
import { isNew } from 'Lib/purefunctions'
import classnames from 'classnames'

import Spinner from 'Components/spinner'
import Indicator from 'Components/newArticleIndicator'

export default class Issues extends Component {
  static propTypes = {
    title: PropTypes.string,
    issues: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      date: PropTypes.string,
      snippet: PropTypes.string,
      url: PropTypes.string,
      readingTimeMin: PropTypes.number
    })),
    publicationCode: PropTypes.string.isRequired,
    publicationTitle: PropTypes.string,
    isChild: PropTypes.bool,
    alternativeMode: PropTypes.bool,
    newItems: PropTypes.array
  };

  render () {
    const {
      title,
      issues,
      publicationCode,
      isChild,
      alternativeMode,
      publicationTitle,
      newItems
    } = this.props

    const getTypeFromArticle = compose(
      defaultTo(''),
      propOr([], 'newsletter_type')
    )
    const headItem = head(issues)
    const tailItems = tail(issues)
    const isInNew = isNew(newItems || [])

    return (
      <div
        data-name='pubTour-step2'
        className={classnames('subscriber-issues',
          { isChildPublication: isChild, isAlternativeMode: alternativeMode })}
      >
        {!alternativeMode && !isChild && (
          <div>
            <h5 className='text-muted'>Issues & Updates</h5>
            <hr />
          </div>
        )}
        {!alternativeMode && isChild && (
          <div className='title'>
            <h4>{title}</h4>
            <hr />
          </div>
        )}

        <div className='row itemgroup'>
          <div className='col-md-7 col-sm-7 leftside'>
            {!headItem && <Spinner />}
            {headItem &&
              (
                <>
                  <h2>
                    {isInNew(headItem.slug) && <Indicator />}
                    <Link to={headItem.url}>
                      {headItem.title}
                    </Link>
                  </h2>
                  <div className='text-muted entry-meta'>
                    <span>{headItem.date}</span>
                    {getTypeFromArticle(headItem) && !isChild && (
                      <>
                        <span className='splitter'>|</span>
                        <span className='articleType'>
                          {getTypeFromArticle(headItem)}
                        </span>
                      </>
                    )}
                    {headItem.readingTimeMin && (
                      <>
                        <span className='splitter'>|</span>
                        <span> {headItem.readingTimeMin} min read</span>
                      </>
                    )}
                  </div>
                  <p>{headItem.snippet}</p>
                  <hr className='d-md-none' />
                </>
              )}
          </div>

          <div className='col-md-5 col-sm-5 rightside'>
            {alternativeMode && !isChild && (
              <>
                <h6 className='title'>Recently Published</h6>
                <hr />
              </>
            )}
            {tailItems.map((item, index) => (
              <div className='issue' key={uniqueString()}>
                <h5>
                  {isInNew(item.slug) && <Indicator />}
                  <Link to={item.url}>
                    {item.title}
                  </Link>
                </h5>
                <div className='entry-meta text-muted'>
                  <span>{item.date}</span>
                  {getTypeFromArticle(item) && !isChild && (
                    <>
                      <span className='splitter'>|</span>
                      <span className='articleType'>
                        {getTypeFromArticle(item)}
                      </span>
                    </>
                  )}
                  {item.readingTimeMin && (
                    <>
                      <span className='splitter'>|</span>
                      <span> {item.readingTimeMin} min read</span>
                    </>
                  )}
                  {(index !== tailItems.length - 1) &&
                    <hr />}
                </div>
              </div>
            ))}
          </div>

        </div>

        <div className='actionWrapper'>
          <div>
            {!alternativeMode && (
              <Link to={`/${publicationCode}/archives`}>
                View All Issues & Updates
              </Link>
            )}
            {alternativeMode && !isChild && (
              <Link to={`/${publicationCode}`}>
                Access {publicationTitle}
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}
