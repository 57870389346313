import React from 'react'
import classnames from 'classnames'

import uniqueString from 'Lib/unique-string'
import { IExtendedSnippet } from '../index'
import Link from 'Components/link'

interface IReportParams {
  post: IExtendedSnippet
}

const Report = ({ post }: IReportParams): JSX.Element => (
  <div className={classnames('post', 'report', { sticky: post.sticky_report === 'yes' })} key={uniqueString()}>
    <div className='image'>
      <img src={post.image} alt='post' />
    </div>
    <div className='content'>
      <Link to={post.url}>
        <h4>{post.title}</h4>
      </Link>
      <p>{post.snippet}</p>
      <Link to={post.url} className='keep_reading'>View Report</Link>
    </div>
  </div>
)

export default Report
