import React, { useState } from 'react'
import Spinner from 'Components/spinner'
import PT from 'prop-types'

const AddressEditor = ({
  initialValues, onSubmit, isLoading, onCancel
}) => {
  const [firstName, setFirstName] = useState(initialValues.firstName)
  const [lastName, setLastName] = useState(initialValues.lastName)
  const [phoneNumber, setPhoneNumber] = useState(initialValues.phoneNumber)
  const [street] = useState(initialValues.street)
  const [state] = useState(initialValues.state)
  const [city] = useState(initialValues.city)
  const [postalCode] = useState(initialValues.postalCode)
  const [country] = useState(initialValues.country)
  const [errorMsg, setErrorMsg] = useState('')

  const submit = async () => {
    // cannot edit these || !street || !state || !city || !postalCode || !country
    if (!firstName || !lastName || !phoneNumber) {
      setErrorMsg('Please fill all fields')

      return
    }

    setErrorMsg(null)

    await onSubmit({
      firstName,
      lastName,
      phoneNumber,
      street,
      state,
      city,
      postalCode,
      country
    })
  }

  return (
    <form className='addressEditor' onSubmit={submit}>
      <h5>NAME</h5>

      <div className='form-group'>
        <input
          type='text'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder='First Name'
        />
      </div>

      <div className='form-group'>
        <input
          type='text'
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder='Last Name'
        />
      </div>

      {/* <h5>STREET ADDRESS</h5>

      <div className="form-group">
        <textarea
          value={street}
          onChange={e => setStreet(e.target.value)}
          placeholder="Address"
        />
      </div>

      <input
        type="text"
        value={city}
        onChange={e => setCity(e.target.value)}
        placeholder="City"
      />

      <input
        type="text"
        value={state}
        onChange={e => setState(e.target.value)}
        placeholder="State"
      />

      <input
        type="text"
        value={postalCode}
        onChange={e => setPostalCode(e.target.value)}
        placeholder="Postal Code"
      />

      <input
        type="text"
        value={country}
        onChange={e => setCountry(e.target.value)}
        placeholder="Country"
      /> */}

      <h5>PHONE NUMBER</h5>

      <div className='form-group'>
        <input
          type='text'
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder='Phone'
        />
      </div>

      {errorMsg && (
        <div className='form-group'>
          <div className='error'>{errorMsg}</div>
        </div>
      )}

      <button type='button' className='btn btn-success' onClick={submit} disabled={isLoading}>
        {!isLoading && <span>Save</span>}
        {isLoading && <Spinner />}
      </button>

      <button type='button' className='btn btn-light' onClick={onCancel} disabled={isLoading}>
        <span>Cancel</span>
      </button>
    </form>
  )
}

AddressEditor.propTypes = {
  initialValues: PT.shape({
    firstName: PT.string,
    lastName: PT.string,
    phoneNumber: PT.string,
    street: PT.string,
    state: PT.string,
    city: PT.string,
    postalCode: PT.string,
    country: PT.string
  }),
  onSubmit: PT.func,
  onCancel: PT.func,
  isLoading: PT.bool
}

export default AddressEditor
