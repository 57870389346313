import React, { Component } from 'react'
// import PropTypes from 'prop-types';

import PlainTemplate from 'Containers/Sites/Base/Templates/plain'

export default class LegalNotices extends Component {
  static propTypes = {};

  render () {
    return (
      <PlainTemplate
        title='Legal Notices'
        mode='thin'
      >
        <div id='__page_legal_notices'>
          <p>Legacy Research Group LLC (Legacy Research) is a publishing company and the indicators, strategies, reports,
            articles and all other features of our products are provided for informational and educational purposes only and
            should not be construed as personalized investment advice. Our recommendations and analysis are based on SEC
            filings, current events, interviews, corporate press releases, and what we’ve learned as financial journalists.
            It may contain errors and you shouldn’t make any investment decision based solely on what you read here. It’s
            your money and your responsibility.
          </p>
          <p>Readers should be aware that trading stocks and all other financial instruments involves risk. Past performance
            is no guarantee of future results, and we make no representation that any customer will or is likely to achieve
            similar results.
          </p>
          <p>Our testimonials are the words of real subscribers received in real letters, emails, and other feedback who
            have not been paid for their testimonials. Testimonials are printed under aliases to protect privacy, and edited
            for length. Their claims have not been independently verified or audited for accuracy. We do not know how much
            money was risked, what portion of their total portfolio was allocated, or how long they owned the security. We
            do not claim that the results experienced by such subscribers are typical and you will likely have different
            results.
          </p>
          <p>Any performance results of our recommendations prepared by Legacy Research are not based on actual trading of
            securities but are instead based on a hypothetical trading account. Hypothetical performance results have many
            inherent limitations. Your actual results may vary.<br />
            Legacy Research expressly forbids its writers from having a financial interest in any security they recommend
            to our subscribers. And all Legacy Research (and affiliated companies), employees, and agents must wait 24
            hours after an initial trade recommendation is published on the Internet, or 72 hours after a direct mail
            publication is sent, before acting on that recommendation.
          </p>
        </div>
      </PlainTemplate>
    )
  }
}
