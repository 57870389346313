import React, { Component } from 'react'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { action, observable, computed, runInAction, makeObservable } from 'mobx'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'
import { path, pathEq, prop } from 'ramda'
import { Formik, Form, Field, useFormikContext, useField } from 'formik'
import * as Yup from 'yup'

import { html2jsx } from 'Lib/parser'
import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import { info, contact } from 'Api/endpoints/auth'
import Spinner from 'Components/spinner'
import ContactInfo from '../ContactInfo'
import HelpCenter from '../HelpCenter'
import Masthead from '../../Components/masthead'

const CaptchaFormField = (props) => {
  const {
    setFieldValue
  } = useFormikContext()
  const [, meta] = useField(props)

  return (
    <>
      <div className="form-group recaptchaWrapper">
        <ReCAPTCHA
          sitekey={props.captchaKey}
          onChange={(v) => {
            setFieldValue('recaptchaValue', v)
          }}
        />
      </div>
      {meta.touched && meta.error && (
        <div className="error">{meta.error}</div>
      )}
    </>
  )
}

@inject('store')
@observer
class PageContact extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject
  }

  @observable firstName = ''

  @observable lastName = ''

  @observable email = ''

  @observable phone = ''

  @observable comments = ''

  @observable recaptchaValue = ''

  @observable showError = false

  @observable showThanks = false

  @observable isWorking = false

  @observable infoHasLoaded = false

  componentDidMount () {
    this.trackPage()
  }

  @computed get isSubmitAllowed () {
    const {
      firstName,
      lastName,
      email,
      recaptchaValue,
      comments
    } = this

    return !(!firstName || !lastName || !email || !recaptchaValue || !comments)
  }

  constructor (props) {
    super(props)

    makeObservable(this)

    const { token } = this.props.store.user
    const { sitecode } = this.props.store.site

    if (!token) {
      return
    }

    info(sitecode, token)
      .then(({ value }) => {
        runInAction(() => {
          this.firstName = prop('firstName', value)
          this.lastName = prop('lastName', value)
          this.phone = prop('phoneNumber', value)
          this.email = path(['emailAddress'], value)
          this.infoHasLoaded = true
        })
      })
  }

  @action.bound
  updateFieldValue (name, value) {
    this[name] = value
    this.showError = false
  }

  @action.bound
  updateRecaptchaValue (e) {
    this.recaptchaValue = e
  }

  @action.bound
  async handleSubmit (values) {
    const {
      sitecode,
      siteDetails
    } = this.props.store.site
    const {
      firstName,
      lastName,
      email,
      phone,
      feedback,
      recaptchaValue
    } = values

    this.isWorking = true

    const resp = await contact(sitecode, {
      email,
      firstName,
      lastName,
      phone,
      message: feedback,
      captcha: recaptchaValue,
      formUrl: `${window.location.hostname}${window.location.pathname}`,
      target: path(['pages', 'contact', 'target'], siteDetails),
      targetEmail: path(['pages', 'contact', 'mail'], siteDetails)
    })

    this.isWorking = false

    if (pathEq(['value', 'status'], true, resp)) {
      this.trackSubmit(path(['pages', 'contact', 'mail'], siteDetails))
      this.showThanks = true
    } else {
      window.alert('Something went wrong. Please try again.')
    }
  }

  @action.bound
  trackPage () {
    const { session } = this.props.store.user

    const sessionId = session ? session.id : null

    const obj = {
      session: sessionId,
      event: 'other',
      url: window.location.href,
      status: 1,
      scroll_depth: 100
    }

    this.props.store.user.analyticsAdd(obj)
  }

  @action.bound
  trackSubmit (email) {
    const { session } = this.props.store.user

    const sessionId = session ? session.id : null

    const obj = {
      session: sessionId,
      event: 'contactSent',
      email_address: email,
      url: window.location.href,
      status: 1,
      scroll_depth: 100
    }

    this.props.store.user.analyticsAdd(obj)
  }

  render () {
    const {
      captchaKey,
      sitecode
    } = this.props.store.site
    const { shouldContactMsg } = this.props.store.ui
    const { siteDetails: { messages: { contactMsg } } } = this.props.store.site
    const shouldDisplayForm = sitecode !== 'PB'

    return (
      <BaseLayout>

        <Masthead/>

        <div id="__page_contact" className="">
          <Helmet>
            <title>Contact Us</title>
          </Helmet>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <header className="headerWrapper">
                  {shouldContactMsg && (
                    <aside className="contactMessage">
                      <div className="alert alert-warning text-center">
                        <p className="mb-0">
                          {html2jsx(contactMsg.content)}
                        </p>
                      </div>
                    </aside>
                  )}
                  <h1>Contact Help</h1>
                </header>
              </div>
            </div>
          </div>

          <main className="container">
            <div className="row preamble">
              <div className="col-12">
                <p>
                  Our customer service representatives are friendly, knowledgeable, and efficient. Please feel free
                  to call or email us if you have any questions.
                </p>
                {!this.showThanks && (
                  <p>
                    For security purposes please do not include your credit card information.
                  </p>
                )}
              </div>
            </div>

            <div className="row">
              {shouldDisplayForm && (
                <div className="col-lg-6 new-form-contact">
                  {!this.infoHasLoaded && (<Spinner/>)}

                  {this.infoHasLoaded && !this.showThanks && (
                    <Formik
                      initialValues={{
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email,
                        phone: this.phone,
                        comments: '',
                        recaptchaValue: this.recaptchaValue
                      }}
                      onSubmit={this.handleSubmit}
                      validationSchema={Yup.object({
                        firstName: Yup.string()
                          .required('First name is required'),
                        lastName: Yup.string()
                          .required('Last name is required'),
                        email: Yup.string()
                          .required('Email address is required'),
                        phone: Yup.string()
                          .required('Phone number is required'),
                        feedback: Yup.string()
                          .required('Feedback is required'),
                        recaptchaValue: Yup.string()
                          .nullable()
                          .required('Captcha is required')
                      })}
                    >
                      <Form>
                        <div className="row">
                          <div className="col-12">
                            <label htmlFor="firstName" className="">First Name *</label>
                          </div>

                          <div className="col-12">
                            <Field name="firstName">
                              {({
                                field,
                                meta
                              }) => (
                                <>
                                  <div className="fieldWrapper">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="" {...field}
                                      aria-required="true"
                                      aria-invalid={!!(meta.touched && meta.error)}
                                      aria-describedby={meta.error}
                                    />
                                  </div>
                                  {meta.touched && meta.error && (
                                    <div className="error">{meta.error}</div>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>

                          <div className="col-12">
                            <label htmlFor="lastName" className="">Last Name *</label>
                          </div>

                          <div className="col-12">
                            <Field name="lastName">
                              {({
                                field,
                                meta
                              }) => (
                                <>
                                  <div className="fieldWrapper">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="" {...field}
                                      aria-required="true"
                                      aria-invalid={!!(meta.touched && meta.error)}
                                      aria-describedby={meta.error}
                                    />
                                  </div>
                                  {meta.touched && meta.error && (
                                    <div className="error">{meta.error}</div>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>

                          <div className="col-12">
                            <label htmlFor="email" className="">Email *</label>
                          </div>

                          <div className="col-12">
                            <Field name="email">
                              {({
                                field,
                                meta
                              }) => (
                                <>
                                  <div className="fieldWrapper">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="" {...field}
                                      aria-required="true"
                                      aria-invalid={!!(meta.touched && meta.error)}
                                      aria-describedby={meta.error}
                                    />
                                  </div>
                                  {meta.touched && meta.error && (
                                    <div className="error">{meta.error}</div>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>

                          <div className="col-12">
                            <label htmlFor="phone" className="">Phone *</label>
                          </div>

                          <div className="col-12">
                            <Field name="phone">
                              {({
                                field,
                                meta
                              }) => (
                                <>
                                  <div className="fieldWrapper">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="" {...field}
                                      aria-required="true"
                                      aria-invalid={!!(meta.touched && meta.error)}
                                      aria-describedby={meta.error}
                                    />
                                  </div>
                                  {meta.touched && meta.error && (
                                    <div className="error">{meta.error}</div>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>

                          <div className="col-12">
                            <label htmlFor="feedback" className="">Feedback *</label>
                          </div>

                          <div className="col-12">
                            <Field name="feedback">
                              {({
                                field,
                                meta
                              }) => (
                                <>
                                  <div className="fieldWrapper">
                                  <textarea
                                    className="form-control"
                                    placeholder="" {...field}
                                    aria-required="true"
                                    aria-invalid={!!(meta.error)}
                                    aria-describedby={meta.error}
                                  />
                                  </div>
                                  {meta.error && (
                                    <div className="error">{meta.error}</div>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>

                          <div className="col-12">
                            <CaptchaFormField name="recaptchaValue" captchaKey={captchaKey}/>
                          </div>

                          {this.isWorking && (
                            <div className="statusMessage">
                              <p>Submitting...</p>
                              <Spinner/>
                            </div>
                          )}

                          {!this.isWorking && !this.showThanks && (
                            <div className="actions">
                              <button type="submit" className="btn btn-secondary edit-button">Submit</button>
                            </div>
                          )}
                        </div>
                      </Form>
                    </Formik>
                  )}

                  {this.showThanks && (
                    <div className="thanksWrapper">
                      <div>
                        <p>Message sent! Thank you.</p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {shouldDisplayForm && (
                <div className="col-lg-6">
                  <ContactInfo/>
                </div>
              )}

              {!shouldDisplayForm && (
                <div className="col-lg-12">
                  <ContactInfo/>
                </div>
              )}
            </div>
          </main>

          <footer className="container">
            <div className="row">
              <div className="col-12">
                <HelpCenter/>
              </div>
            </div>
          </footer>

        </div>

      </BaseLayout>
    )
  }
}

export default PageContact
