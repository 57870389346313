import {
  mapObjIndexed, values, join, compose
} from 'ramda'
import { AxiosError } from 'axios'
import * as E from 'fp-ts/lib/Either'
import * as T from 'fp-ts/lib/Task'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

import { upstreamArticle as API, handleError } from '../index'
import { IError } from '../error'
import { INewsletterTypeResponse, INewsletterTypeResponseRaw } from './types/newsletterType'
import { IAuthorResponse, IAuthorResponseResponseRaw } from './types/author'
import { IPubcodeResponse, IPubcodeResponseRaw } from './types/pubcode'
import { IPublicationResponse, IPublicationResponseRaw } from './types/publication'
import {
  DefaultSearchValues,
  IArticleCountsParams,
  IArticleCountsResponse,
  IArticleCountsResponseRaw,
  IArticleResponse,
  IArticleResponseRaw,
  IArticlesArchiveParams,
  IArticlesByYearMonthParams,
  IArticlesPublicationSearchParams,
  IArticlesSiteSearchParams,
  IArticleTopNResponseRaw,
  IEditorResponse,
  IEditorResponseRaw,
  IProductResponse,
  IProductResponseRaw,
} from './types/article'
import type {
  IGetTopNParams
} from './types/article'
import { ApiResponse } from './types/base'

export function getNewsletterTypesTask (sitecode: string): TE.TaskEither<IError, INewsletterTypeResponse[]> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<INewsletterTypeResponseRaw>(`/articles/${sitecode}/newslettertypes`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.newslettertypes),
  )
}

export const getNewsletterTypes = async (sitecode: string): ApiResponse<INewsletterTypeResponse[]> => {
  return await pipe(
    getNewsletterTypesTask(sitecode),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getAuthorsTask (sitecode: string): TE.TaskEither<IError, IAuthorResponse[]> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<IAuthorResponseResponseRaw>(`/articles/${sitecode}/authors`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.authors),
  )
}

export const getAuthors = async (sitecode: string): ApiResponse<IAuthorResponse[]> => {
  return await pipe(
    getAuthorsTask(sitecode),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

/*
export const getAuthors = async (sitecode: string): Promise<IAuthorResponse[]|IError> => {
  const toResp = pipe(
    getAuthorsTask(sitecode),
  )

  const resp = await toResp()

  return E.getOrElseW((e: IError) => e)(resp)
} */

export function getPubCodesTask (sitecode: string): TE.TaskEither<IError, IPubcodeResponse[]> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<IPubcodeResponseRaw>(`/articles/${sitecode}/pubcodes`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.pubCodes),
  )
}

export const getPubCodes = async (sitecode: string): ApiResponse<IPubcodeResponse[]> => {
  return await pipe(
    getPubCodesTask(sitecode),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getPaidPublicationsTask (sitecode: string, type = ''): TE.TaskEither<IError, IPublicationResponse[]> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<IPublicationResponseRaw>(`/articles/${sitecode}/v2/paidpublications?type=${type}`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.publications),
  )
}

export const getPaidPublications = async (sitecode: string, type = ''): ApiResponse<IPublicationResponse[]> => {
  return await pipe(
    getPaidPublicationsTask(sitecode, type),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getPublicationsTask (sitecode: string, type = ''): TE.TaskEither<IError, IPublicationResponse[]> {
  const archivedType = sitecode === 'all' ? 'lgmarchived' : 'archived'

  return pipe(
    TE.Do,
    TE.apS('activePaid', getPaidPublicationsTask(sitecode, type)),
    TE.apS('archivedPaid', getPaidPublicationsTask(sitecode, archivedType)),
    TE.map(({
      activePaid,
      archivedPaid
    }) => ([...activePaid, ...archivedPaid]))
  )
}

export const getPublications = async (sitecode: string, type = ''): ApiResponse<IPublicationResponse[]> => {
  return await pipe(
    getPaidPublicationsTask(sitecode, type),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getArticlesByAuthorTask (sitecode: string, authorId: string): TE.TaskEither<IError, IArticleResponse[]> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponseRaw>(`/articles/${sitecode}/articlesbyauthor/${authorId}`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.articles),
  )
}

export const getArticlesByAuthor = async (sitecode: string, authorId: string): ApiResponse<IArticleResponse[]> => {
  return await pipe(
    getArticlesByAuthorTask(sitecode, authorId),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getArticlesByPubcodeTask (sitecode: string, code: string): TE.TaskEither<IError, IArticleResponse[]> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponseRaw>(`/articles/${sitecode}/articlesbypubcode/${code}`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.articles),
  )
}

export const getArticlesByPubcode = async (sitecode: string, code: string): ApiResponse<IArticleResponse[]> => {
  return await pipe(
    getArticlesByPubcodeTask(sitecode, code),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getArticleTask (sitecode: string, id: string): TE.TaskEither<IError, IArticleResponse> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponse>(`/articles/${sitecode}/articlebyid/${id}`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data),
  )
}

export const getArticle = async (sitecode: string, code: string): ApiResponse<IArticleResponse> => {
  return await pipe(
    getArticleTask(sitecode, code),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getArticlesArchiveTask (params: IArticlesArchiveParams): TE.TaskEither<IError, IArticleResponse[]> {
  const {
    sitecode,
    publicationCode,
    postType,
    newsletterType,
    offset = 0,
    limit = 10,
    fieldType = 'min',
    cb
  } = params
  const withNewsletterType = newsletterType != null ? `&newsletter-type=${newsletterType}` : ''
  const base = `/articles/${sitecode}/articlesarchive/${publicationCode}/${offset}/${limit}`
  const qp = `type=${postType}&fields=${fieldType}${withNewsletterType}`
  const path = `${base}?${qp}`

  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponseRaw>(path),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => {
      if (cb != null && typeof cb === 'function' && resp.data.refreshRequired === true) {
        void cb()
      }

      return resp.data.articles
    }),
  )
}

export function getArticleBySlugTask (sitecode: string, publicationId: string, slug: string): TE.TaskEither<IError, IArticleResponse> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponse>(`/articles/${sitecode}/articlebyslug/${publicationId}/${slug}`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data),
  )
}

export const getArticleBySlug = async (sitecode: string, publicationId: string, slug: string): ApiResponse<IArticleResponse> => {
  return await pipe(
    getArticleBySlugTask(sitecode, publicationId, slug),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getPagePostTypeBySlugTask (sitecode: string, postType: string, slug: string): TE.TaskEither<IError, IArticleResponse> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponse>(`/articles/${sitecode}/articleposttypebypageslug/${postType}/${slug}`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data),
  )
}

export const getPagePostTypeBySlug = async (sitecode: string, postType: string, slug: string): ApiResponse<IArticleResponse> => {
  return await pipe(
    getPagePostTypeBySlugTask(sitecode, postType, slug),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getPageBySlugTask (sitecode: string, slug: string): TE.TaskEither<IError, IArticleResponse> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponse>(`/articles/${sitecode}/pagebyslug/${encodeURI(slug)}`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data),
  )
}

export const getPageBySlug = async (sitecode: string, slug: string): ApiResponse<IArticleResponse> => {
  return await pipe(
    getPageBySlugTask(sitecode, slug),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getPaidPageBySlugTask (sitecode: string, slug: string): TE.TaskEither<IError, IArticleResponse> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponse>(`/articles/${sitecode}/paidpagebyslug/${encodeURI(slug)}`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data),
  )
}

export const getPaidPageBySlug = async (sitecode: string, slug: string): ApiResponse<IArticleResponse> => {
  return await pipe(
    getPaidPageBySlugTask(sitecode, slug),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getTopNTask (...params: IGetTopNParams): TE.TaskEither<IError, IArticleTopNResponseRaw> {
  const [sitecode, publication, filters = [], n = 3, othercodes = ''] = params
  let filter = ''
  if (filters.length > 0) {
    filter = `wordpressId,eq,${filters.join(',')}`
  }

  const path = `/articles/${sitecode}/articlestopn/${n}?publication=${publication}&filter=${filter}&fields=min&othercodes=${othercodes}`

  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleTopNResponseRaw>(path),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data),
  )
}

export const getTopN = async (...params: IGetTopNParams): ApiResponse<IArticleTopNResponseRaw> => {
  return await pipe(
    getTopNTask(...params),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getArticleCountsTask (...params: IArticleCountsParams): TE.TaskEither<IError, IArticleCountsResponse[]> {
  const [sitecode, publication, type, newsletterType] = params

  const withNewsletterType = newsletterType != null ? `&newsletter-type=${newsletterType}` : ''
  const base = `/articles/${sitecode}/articlesbyyearmonthcounts/${publication}`
  const qp = `type=${type}${withNewsletterType}`
  const path = `${base}?${qp}`

  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleCountsResponseRaw>(path),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.articles),
  )
}

export const getArticleCounts = async (...params: IArticleCountsParams): ApiResponse<IArticleCountsResponse[]> => {
  return await pipe(
    getArticleCountsTask(...params),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getArticlesByYearMonthTask (params: IArticlesByYearMonthParams): TE.TaskEither<IError, IArticleResponse[]> {
  const { sitecode, publication, type, year, month, newsletterType } = params
  const withNewsletterType = newsletterType != null ? `&newsletter-type=${newsletterType}` : ''
  const base = `/articles/${sitecode}/articlesbyyearmonth/${publication}/${year}/${month}`
  const qp = `type=${type}&fields=min${withNewsletterType}`
  const path = `${base}?${qp}`

  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponseRaw>(path),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.articles),
  )
}

export const getArticlesByYearMonth = async (params: IArticlesByYearMonthParams): ApiResponse<IArticleResponse[]> => {
  return await pipe(
    getArticlesByYearMonthTask(params),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getArticlesSiteSearchTask (params: IArticlesSiteSearchParams): TE.TaskEither<IError, IArticleResponse[]> {
  const defaults: DefaultSearchValues = {
    sitecode: 'ALL',
    sorttype: 'postdate',
    searchtype: 'keyword',
    type: '',
    offset: 0,
    limit: 10,
  }
  const {
    limit,
    offset,
    searchtype,
    sitecode,
    sorttype,
    text,
    type
  }: Required<IArticlesSiteSearchParams> = { ...defaults, ...params }
  const base = `articles/${sitecode}/articlessitesearch/${offset}/${limit}`
  const qp = {
    fields: 'min',
    sorttype,
    searchtype,
    type,
    text
  }
  const qpString = compose(
    join('&'),
    values,
    mapObjIndexed((v, k) => `${k}=${v}`)
  )(qp)
  const path = `${base}?${qpString}`

  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponseRaw>(path),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.articles),
  )
}

export const getArticlesSiteSearch = async (params: IArticlesSiteSearchParams): ApiResponse<IArticleResponse[]> => {
  return await pipe(
    getArticlesSiteSearchTask(params),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getArticlesSearchPublicationTask (params: IArticlesPublicationSearchParams): TE.TaskEither<IError, IArticleResponse[]> {
  const defaults: DefaultSearchValues = {
    sitecode: 'ALL',
    sorttype: 'postdate',
    searchtype: 'keyword',
    type: '',
    offset: 0,
    limit: 10,
  }
  const {
    limit,
    offset,
    searchtype,
    publication,
    sitecode,
    sorttype,
    text,
    type
  }: Required<IArticlesPublicationSearchParams> = { ...defaults, ...params }
  const base = `articles/${sitecode}/articlespublicationsearch/${publication}/${offset}/${limit}`
  const qp = {
    fields: 'min',
    sorttype,
    searchtype,
    type,
    text
  }
  const qpString = compose(
    join('&'),
    values,
    mapObjIndexed((v, k) => `${k}=${v}`)
  )(qp)
  const path = `${base}?${qpString}`

  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponseRaw>(path),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.articles),
  )
}

export const getArticlesSearchPublication = async (params: IArticlesPublicationSearchParams): ApiResponse<IArticleResponse[]> => {
  return await pipe(
    getArticlesSearchPublicationTask(params),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getEditorsTask (sitecode: string): TE.TaskEither<IError, IEditorResponse[]> {
  return pipe(
    TE.tryCatch(
      async () => await API.get<IEditorResponseRaw>(`/articles/${sitecode}/editors`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.editors),
  )
}

export const getEditors = async (sitecode: string): ApiResponse<IEditorResponse[]> => {
  return await pipe(
    getEditorsTask(sitecode),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getProductsTask (sitecode: string): TE.TaskEither<IError, IProductResponse[]> {
  const code = sitecode === 'LG' ? 'ALL' : sitecode

  return pipe(
    TE.tryCatch(
      async () => await API.get<IProductResponseRaw>(`/articles/${code}/products`),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.products),
  )
}

export const getProducts = async (sitecode: string): ApiResponse<IProductResponse[]> => {
  return await pipe(
    getProductsTask(sitecode),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getLatestArticlesTask (publications = [], sitecode = 'all', numberOfDays = 14): TE.TaskEither<IError, IArticleResponse[]> {
  const publication = join(',', publications)
  const path = `/articles/${sitecode}/articlesdays/${numberOfDays}?publication=${publication}`

  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponseRaw>(path),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.articles),
  )
}

export const getLatestArticles = async (publications = [], sitecode = 'all', numberOfDays = 14): ApiResponse<IArticleResponse[]> => {
  return await pipe(
    getLatestArticlesTask(publications, sitecode, numberOfDays),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}

export function getBookmarkedArticlesTask (sitecode: string, offset = 0, limit = 100): TE.TaskEither<IError, IArticleResponse[]> {
  if (sitecode.toLowerCase() === 'lg') {
    sitecode = 'all'
  }

  const path = `/articles/${sitecode}/bookmarks/${offset}/${limit}`

  return pipe(
    TE.tryCatch(
      async () => await API.get<IArticleResponseRaw>(path),
      (e) => handleError(e as AxiosError)
    ),
    TE.map((resp) => resp.data.articles),
  )
}

export const getBookmarkedArticles = async (sitecode: string, offset = 0, limit = 100): ApiResponse<IArticleResponse[]> => {
  return await pipe(
    getBookmarkedArticlesTask(sitecode, offset, limit),
    T.map(E.getOrElseW((e: IError) => e))
  )()
}
