import React, { Component } from 'react'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'

import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import { Helmet } from 'react-helmet'

@inject('store')
@observer
class PageMaintenance extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject
  };

  render () {
    const { sitecode, siteId } = this.props.store.site
    const { content } = this.props.store.site.siteDetails.messages.maintenance

    return (
      <div className='' id='outerContainer'>
        <Helmet>
          <link rel='shortcut icon' href={`/favicon-${sitecode}.png`} />
        </Helmet>
        <div className='siteContainer' id={siteId}>
          <TemplateSingle
            title='Site Maintenance'
            content={(
              <section className='siteMaintenance'>
                <p>
                  {content}
                </p>
              </section>
            )}
          />
        </div>
      </div>
    )
  }
}

export default PageMaintenance
