export default store => {
  const promises = [store.site.fetchSettings()]
  const { sitecode } = store.site

  if (store.mst.publication.publications.length < 1) {
    if (sitecode.toLowerCase() === 'lg') {
      /* Especially for LG, we will use all */
      promises.push(store.mst.publication.fetchAll('all', 'all'))
    } else {
      promises.push(store.mst.publication.fetchAll(sitecode))
    }
  }
  if (store.mst.author.authors.length < 1) {
    if (sitecode.toLowerCase() === 'lg') {
      promises.push(store.mst.author.fetchAll('all'))
    } else {
      promises.push(store.mst.author.fetchAll(sitecode))
    }
  }
  if (store.mst.newsletterType.newsletterTypes.length < 1) {
    if (sitecode.toLowerCase() === 'lg') {
      promises.push(store.mst.newsletterType.fetchAll('all'))
    } else {
      promises.push(store.mst.newsletterType.fetchAll(sitecode))
    }
  }

  return Promise.all(promises)
    .then(() => {
      store.site.hasLoaded = true
      store.mst.userPublication.localUpdate()
    })
}
