import React, { Component } from 'react'
import PT from 'prop-types'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import {
  take,
  compose,
  head,
  pathOr,
  pluck,
  pathEq,
  propEq,
  prop,
  uniq,
  append,
  filter,
  flatten,
  map,
  sortBy,
  reverse,
  find,
  equals,
  ifElse,
  assoc, path
} from 'ramda'
import { computed, makeObservable } from 'mobx'

import { rearrangeSticky } from 'Lib/purefunctions'
import Header from 'Containers/Sites/Base/Pages/MySubscriptions/Components/header'
import IssuesAndReports from 'Containers/Sites/Base/Pages/MySubscriptions/Components/issuesAndReports'
import Link from 'Components/link'
import Message from '../../Components/Message'
import isAfter from 'date-fns/isAfter'
import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'

@inject('store')
@observer
class DualView extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    publication: MobxPropTypes.objectOrObservableObject,
    topN: PT.array,
    childPublications: PT.array,
    campaignContent: PT.array,
    newItems: PT.array
  };

  constructor (props) {
    super(props)

    makeObservable(this)

    const id = path(['publication', '_id'], props)
    const sitecode = path(['publication', 'wordpressSiteName'], props)

    this.props.store.mst.article.fetch(sitecode, id, 'reports')
  }

  @computed get issues () {
    const { topN } = this.props
    if (!topN || topN.length === 0) {
      return []
    }

    const { getFormattedIssuesFromTopNByPublication } = this.props.store.mst.article

    const pubs = uniq(append(this.props.publication, this.props.childPublications))
    const allIssues = compose(
      flatten,
      map(getFormattedIssuesFromTopNByPublication(topN))
    )(pubs)
    const parentId = prop('_id', this.props.publication)

    if (allIssues.length > 4) {
      return compose(
        map(ifElse(
          compose(
            equals(parentId),
            prop('publication')
          ),
          assoc('pubTitle', 'Issues'),
          (item) => {
            try {
              return { ...item, pubTitle: find(propEq('_id', item.publication), this.props.childPublications).title }
            } catch (e) {
              console.log(e)
              return item
            }
          }
        )),
        take(4),
        reverse,
        sortBy(prop('createdAtGMT'))
      )(allIssues)
    }

    return allIssues
  }

  @computed get reports () {
    const code = prop('code', this.props.publication)

    return compose(
      map((item) => ({
        url: `/${code}/special-reports/${item.slug}`,
        ...item
      })),
      take(3),
      rearrangeSticky
    )(this.props.store.mst.article.getReports(code, null, true))
  }

  @computed get customMessage () {
    const code = prop('code', this.props.publication)
    const { siteDetails } = this.props.store.site

    const messageData = compose(
      head,
      pluck(code),
      pathOr({}, ['messages', 'publicationMsg'])
    )(siteDetails)

    if ((messageData == null) || (messageData.until == null) || (messageData.enabled == null)) {
      return false
    }

    if (messageData.content && messageData.enabled && isAfter(toDate(parseISO(messageData.until)), new Date())) {
      return messageData.content
    }
  }

  @computed get campaignData () {
    const code = prop('code', this.props.publication)
    const campaigns = this.props.campaignContent

    return filter(pathEq(['code'], code), campaigns)
  }

  render () {
    const { publication } = this.props
    const archiveUrl = prop('archive_url', publication)
    const archiveInternalUrl = prop('archive_internal_url', publication)
    const showCollapsed = propEq('show_collapsed', 'yes', publication)

    if (archiveInternalUrl && archiveUrl) {
      return (
        <div className={`container singlePublicationViewForDashboardWrapper isArchived pr-${publication.code}`}>
          <div className='subscriber-heading'>
            <div className='row archiveRow'>
              <div className='col-lg-6'>
                <h1 className='title'>{publication.title}</h1>
              </div>
              <div className='col-lg-6'>
                <div className='action'>
                  <Link to={archiveUrl}>Access {publication.title}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (archiveUrl) {
      return (
        <div className={`container singlePublicationViewForDashboardWrapper isArchived pr-${publication.code}`}>
          <div className='subscriber-heading'>
            <div className='row archiveRow'>
              <div className='col-lg-6'>
                <h1 className='title'>{publication.title}</h1>
              </div>
              <div className='col-lg-6'>
                <div className='action'>
                  <Link to={archiveUrl}>Visit Site</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (showCollapsed) {
      return (
        <div className={`container singlePublicationViewForDashboardWrapper isCollapsed pr-${publication.code}`}>
          <Header
            title={publication.title}
            links={publication.menuItems}
            submenu={[]}
            isChild
            color={publication.color}
            publicationCode={publication.code}
            newItems={this.props.newItems}
          />
          <Message content={this.customMessage} />
        </div>
      )
    }

    return (
      <div className={`container singlePublicationViewForDashboardWrapper pr-${publication.code}`} data-name='mySubsWalkthroughStep-4'>
        <Header
          title={publication.title}
          links={publication.menuItems}
          submenu={[]}
          color={publication.color}
          publicationCode={publication.code}
          isChild
          newItems={this.props.newItems}
        />
        <Message content={this.customMessage} />
        <IssuesAndReports
          issues={this.issues}
          reports={this.reports}
          publicationCode={publication.code}
          publicationTitle={publication.title}
          campaignData={this.campaignData}
          newItems={this.props.newItems}
        />
      </div>
    )
  }
}

export default DualView
