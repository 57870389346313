import { getRoot } from 'mobx-state-tree'
import { isToday, isYesterday, parseISO } from 'date-fns'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { constant, pipe } from 'fp-ts/lib/function'
import { predicate as SP } from 'fp-ts-std'
import { type Predicate } from 'fp-ts/Predicate'
import * as N from 'fp-ts/number'
import { contramap } from 'fp-ts/Ord'

import { type INotificationStore, type INotification, type IPublication, IArticle } from '../index'
import { type rootStore } from '../../index'

export type NotificationType = 'editorial' | 'promotional'

export interface IFormattedPromotionalNotification {
  type: NotificationType
  id: string
  title: string
  isRead: boolean
  createdAt: Date
  content: string
  externalUrl?: string
  actionContent?: string
}

export interface IFormattedEditorialNotification {
  type: NotificationType
  id: string
  title: string
  documentType: string
  publicationName: string
  link: string
  archiveLink: string
  isRead: boolean
  createdAt: Date
}

export interface IFormattedNotification {
  type: NotificationType
  notification: IFormattedEditorialNotification | IFormattedPromotionalNotification
}

const isTodayN: Predicate<INotification> = (n) => isToday(parseISO(n.createdAt))
const isYesterdayN: Predicate<INotification> = (n) => isYesterday(parseISO(n.createdAt))
const isOlderN = SP.nonePass([isTodayN, isYesterdayN])
const isRead: Predicate<INotification> = (n) => !n.isRead

const ordByDate = pipe(
  N.Ord,
  contramap((n: IFormattedNotification) => n.notification.createdAt.getTime())
)

const inferDocumentType = (n: INotification): string => {
  if (n.postType == null) { // It's not an editorial
    return ''
  }

  if (n.postType === 'content') {
    return n.newsletterType ?? ''
  }

  if (n.postType === 'special-report') {
    return 'reports'
  }

  console.warn('Inference failed')
  console.log(n)

  return ''
}

const buildArchiveLink = (n: INotification, p: IPublication): string => {
  if (n.postType === 'special-report') {
    return `/${p.code}/special-reports/`
  }

  return `/${p.code}/archives/${n.newsletterType}`
}

const buildLink = (n: INotification, p: IPublication): string => {
  if (n.postType === 'special-report') {
    return `/${p.code}/special-reports/${n.slug}`
  }

  return `/${p.code}/${n.slug}`
}

const formatPromotionalNotification = (item: INotification): O.Option<IFormattedNotification> => {
  if (item.notificationType !== 'promotional') {
    return O.none
  }

  const formattedNotification: IFormattedPromotionalNotification = {
    actionContent: item.actionContent,
    content: item.content ?? '',
    createdAt: parseISO(item.createdAt),
    externalUrl: item.externalURL,
    id: item._id,
    isRead: item.isRead,
    title: item.title ?? '',
    type: 'promotional'
  }

  return O.some({
    type: 'promotional',
    notification: formattedNotification
  })
}

const message = (self: INotificationStore): INotificationStore => ({
  find (id) {
    const matchesId: Predicate<INotification> = (n) => n._id === id

    return self.notifications.find(matchesId)
  },

  // Unread + Sticky
  get counter () {
    // TODO Add sticky into account when re-enabling non-editorial entries
    return self.notifications.filter(isRead).length
  },

  format (item: INotification): O.Option<IFormattedNotification> {
    const {
      publication,
      article
    } = getRoot<typeof rootStore>(self)

    if (item.notificationType === 'promotional') {
      return formatPromotionalNotification(item)
    }

    const publicationObj = publication.getById(item.publicationId) as unknown as IPublication

    if (publicationObj == null) {
      return O.none
    }

    const maybeTitle = O.fromNullable(item.title)
    // In case we have it in the store, but probably not
    const articleObj = article.getBySlugAndPublicationId(item.slug, item.publicationId) as unknown as O.Option<IArticle>
    const maybeArticleTitle = pipe(
      articleObj,
      O.map((a) => a.title)
    )

    const extractedTitle = pipe(
      [maybeTitle, maybeArticleTitle],
      A.compact,
      A.head,
      O.getOrElse(constant('Placeholder title'))
    )

    const formattedNotification: IFormattedEditorialNotification = {
      id: item._id,
      title: extractedTitle,
      documentType: inferDocumentType(item),
      publicationName: publicationObj.title != null ? publicationObj.title : '',
      link: buildLink(item, publicationObj),
      archiveLink: buildArchiveLink(item, publicationObj),
      isRead: item.isRead,
      createdAt: parseISO(item.createdAt),
      type: 'editorial'
    }

    return O.some({
      type: 'editorial',
      notification: formattedNotification
    })
  },

  buildTimeline (notificationList: INotification[]): IFormattedNotification[] {
    const formattedNotifications: Array<O.Option<IFormattedNotification>> = pipe(
      notificationList,
      A.map(this.format)
    )
    return pipe(
      formattedNotifications,
      A.compact,
      A.sortBy([ordByDate]),
      A.reverse
    )
  },

  get timelineToday () {
    // For some insane reason, calling this.buildTimeline inside the pipe does not work
    return this.buildTimeline(
      pipe(
        self.notifications,
        A.filter(isTodayN)
      )
    )

    // return pipe(
    //   self.notifications,
    //   A.filter(isTodayN),
    //   A.map(this.format),
    //   A.compact,
    //   A.sortBy([ordByDate])
    // )
  },

  get timelineYesterday () {
    return this.buildTimeline(pipe(
      self.notifications,
      A.filter(isYesterdayN)
    ))

    // return pipe(
    //   self.notifications,
    //   A.filter(isYesterdayN),
    //   this.buildTimeline
    // )
  },

  get timelineOlder () {
    return this.buildTimeline(pipe(
      self.notifications,
      A.filter(isOlderN)
    ))

    // return pipe(
    //   self.notifications,
    //   A.filter(isOlderN),
    //   this.buildTimeline
    // )
  },

  get timeline () {
    return this.buildTimeline(self.notifications)
  },

  articleNotificationStatus (slug, pubId) {
    const matchesHeuristic: Predicate<INotification> = (n) => n.slug === slug && n.publicationId === pubId

    return pipe(
      self.notifications,
      A.filter(matchesHeuristic),
      A.head,
      O.map((n) => !n.isRead),
      O.getOrElse(constant(false))
    )
  },

  findBySlugAndPubId (slug, pubId) {
    const matchesHeuristic: Predicate<INotification> = (n) => n.slug === slug && n.publicationId === pubId

    return pipe(
      self.notifications,
      A.filter(matchesHeuristic),
      A.head
    )
  }
})

export default message
