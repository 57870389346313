import { types, getParent } from 'mobx-state-tree'

import { IAuthorStore } from '../index'

export const Author = types
  .model('Author', {
    _id: types.identifier,
    wordpressId: types.integer,
    code: types.string,
    title: types.string,
    wordpressSiteName: types.string,
    bio: types.optional(types.string, ''),
    avatar: types.optional(types.string, '')
  })
  .actions((self) => ({
    add (item) {
      const { authors } = getParent<IAuthorStore>(self)
      const itemExists = authors.filter((a) => a._id === item._id)[0]

      if (itemExists != null) {
        return
      }

      authors.push(item)
    },
    remove () {
      getParent<IAuthorStore>(self).remove(self)
    }
  }))
