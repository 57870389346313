import React from 'react'
import PT from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'
import { observer } from 'mobx-react'

import { useStore } from 'App'
import uniqueString from 'Lib/unique-string'
import {
  append,
  compose, curry,
  dissoc, equals,
  filter,
  flatten, flip, head, includes,
  isEmpty,
  map,
  pick,
  pluck,
  prop,
  reverse,
  sortBy,
  take,
  uniq
} from 'ramda'
import { flippedIncludes } from 'Lib/purefunctions'
import { isNilOrEmpty } from 'ramda-adjunct'
import SubscriptionRow from 'Containers/Sites/Base/Pages/MySubscriptions/Components/SubscriptionRow'

const getEquivByField = curry((field, item) => compose(
  head,
  filter(compose(equals(prop(field, item)), prop('id')))
))

const useChildPublications = (publication, idProp = '_id') => {
  const store = useStore()
  const childPublications = store.mst.publication.getChildrenOf(prop(idProp, publication))
  const { userPublications } = store.mst.userPublication

  const userHasPub = flippedIncludes(pluck('publicationId', userPublications))

  return compose(
    filter(
      compose(
        userHasPub,
        prop('_id')
      )
    )
  )(childPublications)
}

const PubRowView = ({
  publication, rawPaidTopN, freeTopN, newItems, idx
}) => {
  const store = useStore()
  const { withTimestamp } = store.site
  const childPublications = useChildPublications(publication, 'id')

  const { getFormattedIssuesFromTopNByPublication } = store.mst.article

  const startsExtended = () => {
    if (publication.group === 'LG') {
      return true
    }

    if (includes(publication.publicationCode, store.user.mySubsDefaultOpen)) {
      return true
    }

    return false
  }

  const getPublicationMenuById = (id) => {
    if (!id) {
      return []
    }

    const pub = store.mst.publication.getById(id)

    const allowedTitles = [
      'Issues & Updates',
      'Special Reports',
      'Portfolio'
    ]

    return filter(compose(
      (flip(includes))(allowedTitles),
      prop('title')
    ))(pub.menuItems)
  }

  if (!publication) {
    return <div key={uniqueString()} />
  }

  let { links } = publication
  dissoc('links', publication)

  if (!isEmpty(childPublications)) {
    const pubs = uniq(append(
      { _id: publication.id, code: publication.publicationCode },
      map(pick(['_id', 'code']), childPublications)
    ))

    const allItems = compose(
      flatten,
      map(getFormattedIssuesFromTopNByPublication(rawPaidTopN))
    )(pubs)

    links = compose(
      map((article) => {
        const publicationObj = store.mst.publication.getById(article.publication)
        return {
          link: `/${publicationObj.code}/${article.slug}`,
          title: article.title,
          date: article.createdAtGMT,
          readingTimeMin: article.reading_time_min
        }
      }),
      take(3),
      reverse,
      sortBy(prop('createdAtGMT'))
    )(allItems)
  }

  if (isNilOrEmpty(publication.archiveUrl)) {
    return (
      <Draggable
        draggableId={publication.publicationCode}
        index={idx}
        key={publication.publicationCode}
        type='PUB'
        // isDragDisabled
      >
        {(draggableProvided) => (
          <SubscriptionRow
            {...publication}
            key={publication.publicationCode}
            links={links}
            menu={getPublicationMenuById(prop('id', publication))}
            extendable
            startExtended={startsExtended()}
            onExtendedToggle={() => store.user.toggleCodeInMySubsDefaultOpen(publication.publicationCode)}
            submenu
            free={!!getEquivByField('id', publication)(freeTopN)}
            newItems={newItems}
            withTimestamp={withTimestamp}
            draggableProvided={draggableProvided}
          />
        )}
      </Draggable>
    )
  }

  return (
    <SubscriptionRow
      key={uniqueString()}
      {...publication}
      withTimestamp={withTimestamp}
    />
  )
}

PubRowView.propTypes = {
  publication: PT.object,
  rawPaidTopN: PT.array,
  freeTopN: PT.array,
  newItems: PT.array,
  idx: PT.number
}

export default observer(PubRowView)
