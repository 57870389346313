// This will render only when menuFocus is false-y.
import React from 'react'
import { html2jsx } from 'Lib/parser'

interface ICourseOverview {
  title: string
  description: string
}

const CourseOverview = ({ title, description } : ICourseOverview): JSX.Element => {
  return (
    <div className='course-overview'>
      <h2>{title}</h2>
      <p>
        {html2jsx(description)}
      </p>
    </div>
  )
}

export default CourseOverview
