import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { action, observable, makeObservable } from 'mobx'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { propOr, prop } from 'ramda'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'

import { subscription } from 'Api/endpoints/account'
import { refresh } from 'Api/endpoints/auth'
import ProductLister from 'Containers/Sites/Base/Pages/AccountPanel/productLister'
import PanelTemplate from 'Containers/Sites/Base/Templates/accountPanel'

@inject('store')
@observer
class Index extends Component {
  @observable pageData = []

  @observable isLoading = false

  constructor (props) {
    super(props)
    makeObservable(this)
  }

  @action.bound
  async getPageData () {
    const { sitecode } = this.props.store.site

    this.track()
    this.isLoading = true
    const { value } = await subscription(sitecode)
    this.isLoading = false

    this.pageData = propOr([], 'accountDetails', value)
  }

  fillState () {
    const { userPaid } = this.props.store.mst.publication
    const newArray = []

    userPaid.map((item) => {
      if (item.wordpressSiteName === 'BP') {
        newArray.push(item)
        console.log(newArray)
      }
      return item
    })
  }

  // refresh button with tooltip implementation - js
  @action.bound
  async handleRefresh () {
    const { sitecode } = this.props.store.site
    const resp = await refresh(sitecode, this.props.store.user.token)

    this.props.store.user.afterLogin(resp)
    window.location.reload()
  }

  @action.bound
  track () {
    const { session } = this.props.store.user

    const obj = {
      session: prop('id', session),
      event: 'accountpage',
      url: window.location.href,
      status: 1,
      scroll_depth: 100,
      device: 'browser'
    }

    this.props.store.user.analyticsAdd(obj)
  }

  render () {
    const { sitecode, getSiteNameByCode } = this.props.store.site
    // to get the site full name dynamically per sitecode
    const siteName = getSiteNameByCode(sitecode)

    const { userPaid } = this.props.store.mst.publication

    return (
      <PanelTemplate
        metaTitle='Account / Subscriptions'
        title='My Subscriptions'
        afterAuth={this.getPageData}
      >
        <header>
          <h1>My Account</h1>
          <hr />
          <p>
            <span>
              <span>Your current {siteName} subscriptions are listed below.</span>
              <Tooltip
                placement='top'
                overlay='New Subscription not listed here?  Click to refresh'
              >
                <button
                  onClick={this.handleRefresh}
                  className='k-button ml-3'
                >
                  Refresh Subscriptions
                </button>
              </Tooltip>
            </span>
          </p>
        </header>

        <div className='container mt-5'>
          <table id='subscriptions'>
            <thead>
              <tr>
                <th>Subscriptions</th>
                <th>Status</th>
              </tr>
            </thead>
            <ProductLister
              details={this.pageData}
              isLoading={this.isLoading}
              userPaid={userPaid}
            />
          </table>
        </div>
      </PanelTemplate>
    )
  }
}

Index.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  location: ReactRouterPropTypes.location
}

export default Index
