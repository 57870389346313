import React from 'react'

import { html2jsx } from 'Lib/parser'

interface IMessageParams {
  content: string | boolean | null | undefined
}

const Message = ({ content }: IMessageParams): JSX.Element => {
  if (content == null || content === false) {
    return <div />
  }

  return (
    <aside className='publicationMessage'>
      <div className='alert alert-warning text-center'>
        <p className='mb-0'>
          {html2jsx(content)}
        </p>
      </div>
    </aside>
  )
}

export default Message
