import { types, getParent } from 'mobx-state-tree'

import { IBookmarkStore } from '../index'

export const Bookmark = types
  .model('Bookmark', {
    _id: types.optional(types.string, ''),
    wordpressSiteName: types.optional(types.string, ''),
    slug: types.optional(types.string, ''),
    title: types.optional(types.string, ''),
    post_type: types.optional(types.string, ''),
    publication: types.optional(types.string, ''), // Not an array here!!
    newslettertype: types.optional(types.string, ''),
    newsletter_type: types.optional(types.string, ''),
    createdAt: types.optional(types.string, ''),
    parsedDate: types.maybe(types.Date),
    favoriteCreatedAt: types.optional(types.string, ''),
    favoriteParsedDate: types.maybe(types.Date),
    reading_time_min: types.maybe(types.number)
  })
  .actions((self) => ({
    remove () {
      getParent<IBookmarkStore>(self).remove(self)
    },
    updateProperty (key, value) {
      self[key] = value
    }
  }))
