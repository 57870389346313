import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { computed, action, observable, autorun, makeObservable } from 'mobx'
import {
  prop, compose, join, pluck, sortWith, ascend, equals, flatten, path,
  isEmpty, not, filter, converge, concat, anyPass, isNil, map, mapObjIndexed, uniq, forEach, propEq, head
} from 'ramda'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/browser'
import { toast, Slide } from 'react-toastify'

import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import { getTopN } from 'Api/endpoints/article'
import { getCampaignContent } from 'Api/endpoints/user'
import { logout, getAd } from 'Api/endpoints/auth'
import { flippedIncludes } from 'Lib/purefunctions'
import { html2jsx } from 'Lib/parser'
import Link from 'Components/link'
import { MySubsWalkthrough } from 'Components/walkthroughs/mySubsWalkthrough'
import SingleView from './singleView'
import DualView from './dualView'
import 'react-toastify/dist/ReactToastify.css'
import Masthead from '../../Components/masthead'

const addNotificationForPost = (publicationName) => {
  toast.success(`New Content has just been published to ${publicationName}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 8000,
    transition: Slide
  })
}

const findInTopN = (needle, haystack) => compose(
  head,
  filter(propEq('publication', needle))
)(haystack)

@inject('store')
@observer
class Base extends Component {
  @observable topN = [];

  @observable newItems = [];

  @observable disposer = null;

  @observable subAd;

  @observable displaySubAd;

  @observable campaignContent = [];

  constructor (props) {
    super(props)

    makeObservable(this)

    this.disposer = autorun(() => {
      if (!this.props.store.mst.realtime || !this.props.store.mst.realtime.messages) {
        return
      }

      forEach((p) => {
        const publication = this.props.store.mst.publication.getById(p.publication)

        if (!this.props.store.mst.userPublication.containsPublication(p.publication)) {
          p.updateProperty('consumed', true)
          return
        }

        if (p.consumed || p.post_type !== 'content') {
          return
        }

        // Caution: If we move this outside of the forEach, it will lead to an infinite loop
        if (!this.topN || isEmpty(this.topN)) {
          return
        }

        if (publication && publication.title && findInTopN(p.publication, this.topN)) {
          this.addToNewItems(p.slug)
          addNotificationForPost(publication.title)
        }

        p.updateProperty('consumed', true)
      }, this.props.store.mst.realtime.messages)

      this.fetchPaidTopN()
    })

    this.fetchPaidTopN()
    // noinspection JSIgnoredPromiseFromCall
    this.fetchSubAd()
    this.fetchCampaignContent()
  }

  componentDidMount () {
    this.track()
  }

  componentWillUnmount () {
    if (this.disposer) {
      this.disposer()
    }
  }

  @action.bound
  addToNewItems (slug) {
    this.newItems = [...this.newItems, slug]
  }

  @action.bound
  logout () {
    logout(this.props.store.ui.siteId, this.props.store.user.token).then(() => {
      window.location = '/login'
      this.props.store.user.logout()
    })
  }

  @action.bound
  async fetchSubAd () {
    const { sitecode, siteDetails: { adZones } } = this.props.store.site
    const { isLoggedIn } = this.props.store.ui
    const { userAdKeywords } = this.props.store.user

    if (isLoggedIn) {
      this.subAd = await getAd(sitecode, adZones.mySub, 'keyword', userAdKeywords)
      this.displaySubAd = this.subAd !== 'no ad' ? true : ''
    }
  }

  @action.bound
  async fetchPaidTopN () {
    const { sitecode, siteDetails: { sharedPublications } } = this.props.store.site
    const { paidViewForMySubscriptions } = this.props.store.mst.publication

    const allChildPubs = compose(
      pluck('_id'),
      flatten,
      map(this.childPublications)
    )(paidViewForMySubscriptions)

    const allPubIds = uniq(concat(allChildPubs, pluck('_id', paidViewForMySubscriptions)))

    const pString = join(',', allPubIds)
    if (isEmpty(pString)) {
      // This should happen only when the backend ids have been re-generated - logout the user
      Sentry.withScope((scope) => {
        scope.setTag('dbg_pos', 'fetchPaidTopN 1')
        Sentry.captureMessage('Empty pString')
      })

      this.logout()
      return
    }

    let otherCodes = ''
    if (!isNil(sharedPublications)) {
      mapObjIndexed((sharedPublication, sharedSiteCode) => {
        otherCodes = (otherCodes === '') ? otherCodes + sharedSiteCode : `${otherCodes},${sharedSiteCode}`
      }, sharedPublications)
    }
    try {
      // noinspection UnnecessaryLocalVariableJS
      const { refreshRequired, articles } = await getTopN(sitecode, pString, [], 4, otherCodes)

      this.topN = articles

      if (refreshRequired) {
        // this.forceRefresh();
        this.props.store.user.refreshSite()
      }
    } catch (e) {
      // This would fail if the backend ids are not re-generated but the user tokens have been flushed - logout the user
      Sentry.withScope((scope) => {
        scope.setTag('dbg_pos', 'fetchPaidTopN 2')
        Sentry.captureMessage(e)
      })

      this.logout()
    }
  }

  @action.bound
  async fetchCampaignContent () {
    const { sitecode } = this.props.store.site

    try {
      this.campaignContent = await getCampaignContent(sitecode)
    } catch (e) {
      console.log(e)
    }
  }

  @action.bound
  track () {
    const { session } = this.props.store.user

    const obj = {
      session: prop('id', session),
      event: 'homepage',
      url: window.location.href,
      status: 1,
      scroll_depth: 100
    }

    this.props.store.user.analyticsAdd(obj)
  }

  @action.bound
  childPublications (publication) {
    const childPublications = this.props.store.mst.publication.getChildrenOf(prop('_id', publication))
    const { userPublications } = this.props.store.mst.userPublication

    const userHasPub = flippedIncludes(pluck('publicationId', userPublications))

    return compose(
      filter(
        compose(
          userHasPub,
          prop('_id')
        )
      )
    )(childPublications)
  }

  @computed get sortedProducts () {
    const { sitecode } = this.props.store.site
    const { paidViewForMySubscriptions } = this.props.store.mst.publication

    const sortByNum = sortWith([ascend(prop('product_display_order'))])
    const takeUnordered = filter(compose(
      isEmpty,
      prop('product_display_order')
    ))
    const takeOrdered = filter(compose(
      not,
      isEmpty,
      prop('product_display_order')
    ))
    const takeShared = filter(compose(
      not,
      propEq('wordpressSiteName', sitecode)
    ))
    const takeNotShared = filter(propEq('wordpressSiteName', sitecode))

    return converge(concat, [
      compose(
        sortByNum,
        takeOrdered,
        takeNotShared
      ),
      compose(
        sortByNum,
        takeShared
      ),
      takeUnordered
    ])(paidViewForMySubscriptions)
  }

  @computed get otherProducts () {
    const { sitecode } = this.props.store.site
    const { userPublications } = this.props.store.mst.userPublication
    const isValidProductPage = compose(
      not,
      anyPass([isEmpty, isNil, equals('none')]),
      prop('product_page')
    )
    const userPubIds = pluck('publicationId', userPublications)
    const withoutSpecials = filter(isValidProductPage)(this.props.store.mst.publication.paid)
    const withoutSpecialsAndShared = filter(
      compose(
        flippedIncludes(sitecode),
        prop('wordpressSiteName')
      )
    )(withoutSpecials)

    return filter(
      compose(
        not,
        flippedIncludes(userPubIds),
        prop('_id')
      )
    )(withoutSpecialsAndShared)
  }

  render () {
    const { shouldDisplayMySubsMsg } = this.props.store.ui
    const { messages: { mySubsMsg } } = this.props.store.site.siteDetails

    return (
      <BaseLayout>
        {this.props.store.user.userSettingsSet && <MySubsWalkthrough className='d-none d-lg-block' />}
        <Helmet>
          <title>My Subscriptions</title>
        </Helmet>

        <div id='__page_my_subscriptions'>
          <Masthead />

          {this.displaySubAd && (
            <section className='subAdContainer'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12 subAdWrapper'>
                    <a href={this.subAd.redirectURL} target='_blank' rel='noopener noreferrer'>
                      <img src={this.subAd.imageURL} alt={this.subAd.altText} width='100%' />
                    </a>
                  </div>
                </div>
              </div>
            </section>
          )}

          {shouldDisplayMySubsMsg && (
            <aside className='container'>
              <div className='row mySubsMsgContainer'>
                <div className='col-12 mySubsMsgWrapper'>
                  <p>{html2jsx(mySubsMsg.content)}</p>
                </div>
              </div>
            </aside>
          )}

          {this.sortedProducts.map((publication) => {
            const componentName = path(['settings', 'affiliate-my-sub-component'], publication)
            let ItemView

            // TODO Adjust accordingly once we actually have other components
            if (!componentName || componentName === 'issues') {
              ItemView = SingleView
            } else if (componentName === 'default') {
              ItemView = DualView
            }

            return (
              <ItemView
                key={prop('_id', publication)}
                publication={publication}
                topN={this.topN}
                childPublications={this.childPublications(publication)}
                campaignContent={this.campaignContent}
                newItems={this.newItems}
              />
            )
          })}

          {this.otherProducts.length > 0 && (
            <section className='container otherProducts'>
              <header className='row'>
                <div className='col-12'>
                  <h5>Products to Consider</h5>
                </div>
              </header>
              {this.otherProducts.map((publication) => (
                <div
                  className={`row otherProduct pr-${publication.code}`}
                  key={publication.code}
                >
                  <div className='col-lg-6'>
                    <h2 className='title'>{publication.title}</h2>
                  </div>
                  <div className='col-lg-6'>
                    <div className='action'>
                      <Link to={publication.product_page}>Learn More</Link>
                    </div>
                  </div>
                </div>
              ))}
            </section>
          )}
        </div>
      </BaseLayout>
    )
  }
}

Base.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  publication: MobxPropTypes.objectOrObservableObject,
  location: ReactRouterPropTypes.location.isRequired
}

export default Base
