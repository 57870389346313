import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react'
import isAfter from 'date-fns/isAfter'
import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'
import { Predicate } from 'fp-ts/Predicate'
import * as ROA from 'fp-ts/ReadonlyArray'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as TE from 'fp-ts/TaskEither'
import * as IO from 'fp-ts/lib/IO'
import { constant, pipe } from 'fp-ts/lib/function'
import { contramap } from 'fp-ts/Ord'
import * as S from 'fp-ts/string'
import { Helmet } from 'react-helmet'

import { useStore } from 'App'
import { IArticle, IPublicationStore } from 'Stores/mst'
import { getArticleCounts, getArticlesArchiveTask, getArticlesByYearMonthTask } from 'Api/endpoints/article'
import BaseLayout from 'Containers/Sites/Base/Layouts/base'
import Masthead from '../../Components/masthead'
import { Maybe, Nullable } from 'Lib/Types/base'
import {
  IArticleCountsResponse,
  IArticlesArchiveParams,
  IArticlesByYearMonthParams
} from 'Api/endpoints/types/article'
import { IPublicationMsgArea } from 'Stores/site'
import { ISnippet } from 'Stores/mst/Views/article'
import { IError } from 'Api/error'
import { html2jsx } from 'Lib/parser'
import ToggleableMenu from './Components/togglableMenu'
import Issue from './Components/issue'
import Report from './Components/report'
import Spinner from 'Components/spinner'
import { getAd } from 'Api/endpoints/auth'

export interface IExtendedSnippet extends ISnippet {
  url: string
  publicationObj: IPublicationStore
}

export interface ILink {
  title: string
  url: string
}

interface IAd {
  redirectURL: string
  imageURL: string
  altText: string
}

const convertMonthNumber = (number): string => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November',
    'December'
  ]

  return months[number - 1] ?? ''
}

const snippetToPublication = (p: IPublicationStore) => (s: ISnippet): IExtendedSnippet => ({
  url: '',
  publicationObj: p, ...s
})

const sortByDate = pipe(
  S.Ord,
  contramap((s: IExtendedSnippet) => s.createdAtGMT)
)

interface MatchParams {
  newslettertype?: string
  year?: string
  month?: string
}

interface IArchiveComponentParams extends RouteComponentProps<MatchParams> {
  publication: IPublicationStore
  type: 'content' | 'special-report'
}

const ArchiveComponent = (props: IArchiveComponentParams): JSX.Element => {
  const store = useStore()
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [pointer, setPointer] = useState(0)
  const [newsletterTitle, setNewsletterTitle] = useState<Nullable<string>>(null)
  const [rawCounts, setRawCounts] = useState<IArticleCountsResponse[]>([])
  const [articles, setArticles] = useState<IArticle[]>([])
  const [isArchiveMenuExpanded, setIsArchiveMenuExpanded] = useState<boolean>(false)
  const [subAd, setSubAd] = useState<IAd|string>()

  const {
    type,
    match: {
      params: {
        month,
        year,
        newslettertype: newsletterType
      }
    },
    publication,
    history
  } = props

  const handleToggle = (): void => {
    setIsArchiveMenuExpanded(!isArchiveMenuExpanded)
  }

  const resetState = (): void => {
    setLoading(false)
    setHasMore(false)
    setPointer(0)
    setArticles([])
  }

  const pushArticles = (list: IArticle[]): IO.IO<void> => {
    return () => {
      setArticles((prev) => A.concat(list)(prev))
    }
  }

  const updateFlags = (count: number): IO.IO<void> => {
    return () => {
      if (count > 10) {
        setHasMore(true)
      } else {
        setHasMore(false)
      }

      setPointer(pointer + 10)
    }
  }

  const track = (): void => {
    const { session } = store.user
    const { publication } = props

    const {
      code,
      settings
    } = publication

    const obj = {
      session: session?.id ?? '',
      event: 'archivepage',
      publication_code: code,
      product_code: settings.product_code,
      url: window.location.href,
      status: 1,
      scroll_depth: 100
    }

    store.user.analyticsAdd(obj)
  }

  const loadData = async (start): Promise<void> => {
    // Query for 11 articles
    // If 11 -> setHasMore(true)
    // Push 10 articles
    // Update pointer

    setLoading(true)

    if (month == null) {
      await pipe(
        fetchContent(start, 11),
        TE.chainFirstIOK((data) => updateFlags(data.length)),
        TE.map(A.takeLeft(10)),
        TE.chainFirstIOK(pushArticles),
      )()
    } else {
      await pipe(
        fetchContent(0, 100), // offsets are not used since the endpoint does not support them
        TE.chainFirstIOK((data) => updateFlags(data.length)),
        TE.chainFirstIOK(pushArticles),
      )()
    }

    setLoading(false)
  }

  const fetchContent = (start = 0, len = 11): TE.TaskEither<IError, IArticle[]> => {
    const { publication } = props
    const id = publication._id
    const sitecode = publication.wordpressSiteName

    if (month != null && year != null) {
      const args: IArticlesByYearMonthParams = {
        sitecode,
        year,
        month,
        newsletterType,
        publication: id,
        type
      }

      return pipe(
        args,
        getArticlesByYearMonthTask,
        TE.map((data) => {
          console.log(data)
          return data.map(store.mst.article.createAndPush)
        })
      )
    }

    const args: IArticlesArchiveParams = {
      sitecode,
      publicationCode: id,
      postType: type,
      newsletterType,
      offset: start,
      limit: len,
      fieldType: 'min',
      cb: store.user.refreshSite
    }

    return pipe(
      args,
      getArticlesArchiveTask,
      TE.map((data) => {
        return data.map(store.mst.article.createAndPush)
      })
    )
  }

  const fetchArticleCounts = async (type): Promise<void> => {
    const { publication } = props
    const id = publication._id
    const sitecode = publication.wordpressSiteName

    const resp = await getArticleCounts(sitecode, id, type, newsletterType)

    if (Array.isArray(resp)) {
      setRawCounts(resp)
    }
  }

  const hydrate = (): void => {
    if (newsletterType != null) {
      const title = store.mst.newsletterType.getTitleByCode(newsletterType)
      if (title != null) {
        setNewsletterTitle(title)
      } else {
        history.push('/pageNotFound')
        return
      }
    }

    void loadData(pointer)

    void fetchArticleCounts(type)
  }

  const generateIssueMonthLinks = (): ILink[] => {
    const { publication } = props
    const { code } = publication
    if (rawCounts.length === 0) {
      return []
    }

    const withNewsletterType = newsletterType != null ? `${newsletterType}/` : ''

    return rawCounts.map((item) => ({
      title: `${convertMonthNumber(item.month)} ${item.year}`,
      url: `/${code}/archives/${withNewsletterType}${item.year}/${item.month}`
    }))
  }

  const getCustomMessage = (): Maybe<string> => {
    const { publication } = props
    const { code } = publication
    const { siteDetails } = store.site

    const matchesCode: Predicate<IPublicationMsgArea> = (p) => p.code === code
    const isAcceptable: Predicate<IPublicationMsgArea> = (p) =>
      p.content != null &&
      p.enabled != null &&
      p.enabled &&
      p.until != null &&
      isAfter(toDate(parseISO(p.until)), new Date())

    return pipe(
      siteDetails?.messages?.publicationMsg ?? [],
      ROA.filter(matchesCode),
      ROA.filter(isAcceptable),
      ROA.head,
      O.map(v => v.content),
      O.getOrElseW(constant(undefined))
    )
  }

  const generateReportMonthLinks = (): ILink[] => {
    const { publication } = props
    const { code } = publication
    if (rawCounts.length === 0) {
      return []
    }

    return rawCounts.map((item) => ({
      title: `${convertMonthNumber(item.month)} ${item.year}`,
      url: `/${code}/special-reports/${item.year}/${item.month}`
    }))
  }

  const generateIssueSnippets = (): IExtendedSnippet[] => {
    const { publication } = props
    const { code } = publication

    const addUrl = (v: IExtendedSnippet): IExtendedSnippet => {
      v.url = `/${code}/${v.slug}`
      return v
    }

    if (month != null) {
      return pipe(
        articles,
        A.map((s: IArticle) => store.mst.article.toSnippet(s)), // TS Quirk
        A.map(snippetToPublication(publication)),
        A.map(addUrl),
        A.sortBy([sortByDate]),
        A.reverse
      )
    }

    return pipe(
      articles,
      A.takeLeft(pointer),
      A.map((s: IArticle) => store.mst.article.toSnippet(s)), // TS Quirk
      A.map(snippetToPublication(publication)),
      A.map(addUrl),
      A.sortBy([sortByDate]),
      A.reverse
    )
  }

  const generateReportSnippets = (): IExtendedSnippet[] => {
    const { publication } = props
    const { code } = publication

    const isSticky: Predicate<ISnippet> = (s) => s.sticky_report === 'yes'
    const addUrl = (v: IExtendedSnippet): IExtendedSnippet => {
      v.url = `/${code}/special-reports/${v.slug}`
      return v
    }

    // TODO Check if we want reports sorted by creation date
    return pipe(
      articles,
      A.map((s: IArticle) => store.mst.article.toSnippet(s)), // TS Quirk
      A.map(snippetToPublication(publication)),
      A.map(addUrl),
      A.sortBy([sortByDate]),
      A.reverse,
      A.partition(isSticky),
      (s) => A.concat(s.left)(s.right),
      A.takeLeft(pointer)
    )
  }

  const fetchAd = async (): Promise<void> => {
    const { sitecode, siteDetails } = store.site
    const { adZones } = siteDetails
    const { isLoggedIn } = store.ui
    const { userAdKeywords } = store.user

    if (isLoggedIn) {
      const ad = await getAd(sitecode, adZones?.mySub ?? '', 'keyword', userAdKeywords)
      setSubAd(ad)
    }
  }

  useEffect(() => {
    void fetchAd()
  }, [])

  useEffect(() => {
    setRawCounts([])
    setNewsletterTitle(null)
  }, [newsletterType])

  useEffect(() => {
    resetState()
    track()
    hydrate()
  }, [newsletterType, month, year])

  let title = ''
  let metaTitle = ''
  let mode = ''
  let snippets: IExtendedSnippet[] = []
  let monthLinks: ILink[] = []
  const message = getCustomMessage()

  switch (type) {
    case 'content':
      title = (newsletterType != null && newsletterTitle != null) ? newsletterTitle : 'Issues & Updates'
      metaTitle = `${publication.title} - ${title}`
      mode = 'issues'
      snippets = generateIssueSnippets()
      monthLinks = generateIssueMonthLinks()
      break
    case 'special-report':
      title = 'Special Reports'
      metaTitle = `${publication.title} - Special Reports`
      mode = 'reports'
      snippets = generateReportSnippets()
      monthLinks = generateReportMonthLinks()
      break
  }

  return (
    <BaseLayout>
      <div className="__template_subscription_product_archive">
        <Masthead/>

        {subAd != null && typeof subAd !== 'string' && (
          <section className='subAdContainer'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 subAdWrapper'>
                  <a href={subAd.redirectURL} target='_blank' rel='noopener noreferrer'>
                    <img src={subAd.imageURL} alt={subAd.altText} width='100%' />
                  </a>
                </div>
              </div>
            </div>
          </section>
        )}

        <div className='container'>
          {message != null &&
            (
              <aside className='publicationMessage'>
                <div className='alert alert-warning text-center'>
                  <p className='mb-0'>{html2jsx(message)}</p>
                </div>
              </aside>
            )}
          <Helmet>
            <title>{metaTitle ?? title}</title>
          </Helmet>
          <header className='mt-3'>
            <h1>{title}</h1>
            <hr />
          </header>

          <main>
            <div className='row'>
              <div className='col-sm-12 col-xs-12 col-md-4 months'>
                <h4>Archive</h4>
                <ToggleableMenu
                  months={monthLinks}
                  compact={!isArchiveMenuExpanded}
                  onToggle={handleToggle}
                />
              </div>

              <div className='col-sm-12 col-xs-12 col-md-8 posts'>
                {mode === 'issues' &&
                  snippets.map((post, index, all) => <Issue key={post.slug} post={post} />)}
                {mode === 'reports' &&
                  snippets.map((post, index, all) => <Report key={post.slug} post={post} />)}
                {loading &&
                  <Spinner />}
                {month == null && hasMore && !loading &&
                  <button onClick={async (): Promise<void> => await loadData(pointer)} className='btn_load_more'>Load More...</button>}
              </div>
            </div>
          </main>

        </div>
      </div>
    </BaseLayout>
  )
}

export default withRouter(observer(ArchiveComponent))
