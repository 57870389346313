import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faLock } from '@fortawesome/free-solid-svg-icons'

import { ISection } from 'Stores/mst'
import AccordionItem from './AccordionItem'
import { useCourseContext } from './index'

interface IAccordion {
  section: ISection
}

const Accordion = ({ section }: IAccordion): JSX.Element => {
  const { activeSection } = useCourseContext()
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (activeSection == null) {
      setIsActive(false)

      return
    }

    if (activeSection.id === section.id) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [activeSection])

  return (
    <div className="course-dropdown">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className="title">{section.label}</div>

        <div className="rightSide">
          {section.isLocked && (<FontAwesomeIcon icon={faLock} size="sm"/>)}
          {isActive ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>}
        </div>
      </div>
      {isActive && section.lessons?.map((lesson) => <AccordionItem key={lesson.id} section={section} lesson={lesson}/>)}
    </div>
  )
}

export default Accordion
