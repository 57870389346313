import React, { useState } from 'react'
import PT from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react-lite'

import { useStore } from 'App'
import { addArticleBookmark, removeArticleBookmark } from 'Api/endpoints/user'
import { isRecordBookmarked } from 'Lib/purefunctions'
import Link from 'Components/link'

const print = () => {
  window.print()

  return false
}

const ContentHeader = ({ title, mediaLink, article }) => {
  const store = useStore()
  const [isBookmarked, setIsBookmarked] = useState(isRecordBookmarked(article))

  const { sitecode } = store.site

  const handleBookmarkClicked = (item) => {
    if (isBookmarked) {
      setIsBookmarked(false)
      store.mst.article.removeBookmarkFromRecord(item.id)
      removeArticleBookmark(sitecode, item.wpKey).catch()
    } else {
      setIsBookmarked(true)
      store.mst.article.addBookmarkToRecord(item.id)
      addArticleBookmark(sitecode, item.wpKey).catch()
    }
  }

  return (
    <div className='headerWrapper'>
      <div className='row col-md-9 col-sm-12'><h1>{title}</h1></div>

      <div className='d-none d-md-block col-3 text-nowrap print text-right row'>
        {mediaLink && (
          <button className='k-button ml-2 mt-2 text-dark'>
            <Link to={mediaLink} target='_blank' className='no-format text-dark' download>Download
              <FontAwesomeIcon icon='file-alt' className='d-none d-lg-inline ml-2 text-dark' />
            </Link>
          </button>
        )}
        <button className='k-button ml-2 mt-2 text-dark' rel='noopener noreferrer' onClick={print}> Print
          <FontAwesomeIcon icon='print' className='d-none d-lg-block ml-2 text-dark' />
        </button>
        <button className='k-button ml-2 mt-2 text-dark' onClick={() => handleBookmarkClicked(article)}> Save
          {!isBookmarked && <FontAwesomeIcon icon={faBookmark} style={{ marginLeft: '5px' }} />}
          {isBookmarked && <FontAwesomeIcon icon={faBookmarkSolid} style={{ marginLeft: '5px' }} />}
        </button>
      </div>

      <hr className='separator' />
    </div>
  )
}

ContentHeader.propTypes = {
  title: PT.string,
  mediaLink: PT.string
}

export default observer(ContentHeader)
