import React from 'react'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router'
import {
  compose, filter, last, pluck, prop, split, take
} from 'ramda'

import { useStore } from 'App'

import DropDownMenu from './mastheadComponents/dropDownMenu'
import Breadcrumbs from './mastheadComponents/breadcrumbs'
import { flippedIncludes } from '../../../../Lib/purefunctions'
import PublicationDropdown from '../../Legacyresearch/Components/publicationDropdown'

const Masthead = (props) => {
  const store = useStore()

  const { location: { pathname } } = props
  const fragment = compose(
    last,
    take(2),
    split('/')
  )(pathname)

  const fragment2 = compose(
    last,
    take(3),
    split('/')
  )(pathname)

  let currentPublication
  if (store.mst.publication) {
    currentPublication = store.mst.publication.getByCode(fragment)
  }

  const { userPublications } = store.mst.userPublication

  const userPubIds = pluck('publicationId', userPublications)
  const availablePublications = filter(
    compose(
      flippedIncludes(userPubIds),
      prop('_id')
    )
  )(store.mst.publication.paid)

  return (
    <>
      <div id='masthead' className='text-white d-print-none' data-name='mySubsWalkthroughStep-2'>
        <div className='container'>
          <div className='mastheadContainer'>
            <Breadcrumbs
              currentPublication={currentPublication}
              currentPath={fragment2}
              match={props.match}
            />
            {availablePublications.length !== 1 && store.site.sitecode !== 'LG' && (
              <DropDownMenu
                availablePublications={availablePublications}
                currentPublication={currentPublication}
              />
            )}

            {availablePublications.length !== 1 && store.site.sitecode === 'LG' && (
              <PublicationDropdown
                currentPublication={currentPublication}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

Masthead.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
}

export default withRouter(Masthead)
