export default function domProcPortfolio (raw) {
  const elem = document.createElement('div')
  elem.innerHTML = raw

  Array.prototype.forEach.call(elem.querySelectorAll('table'), (table) => {
    const titles = []

    // Map doesn't work with querySelectorAll
    Array.prototype.forEach.call(table.querySelectorAll('th'), (th) => {
      titles.push(th.textContent)
    })

    Array.prototype.forEach.call(table.querySelectorAll('tr'), (tr) => {
      let tdIdx = 0

      Array.prototype.forEach.call(tr.querySelectorAll('td'), (td) => {
        td.setAttribute('data-title', titles[tdIdx])
        tdIdx += 1
      })
    })
  })

  return elem
}
