/* eslint-disable @typescript-eslint/no-unused-vars */
/* This is an issue with the linter not recognizing the use within the decorator */

import { observable, action, computed, makeObservable } from 'mobx'
import { persist } from 'mobx-persist'
import {
  append,
  assoc,
  compose,
  equals,
  filter,
  find,
  flip,
  head,
  identity,
  includes,
  isEmpty,
  join,
  map,
  memoizeWith,
  not,
  omit,
  pathOr,
  pluck,
  prop,
  propEq,
  reject,
  split,
  takeLast,
  uniq
} from 'ramda'
import { compact } from 'ramda-adjunct'
import * as Sentry from '@sentry/browser'
import { v4 as uuid } from 'uuid'

import { refresh } from 'Api/endpoints/auth'
import { getAllUserData, saveUserData, trackapp } from 'Api/endpoints/user'
import { differenceInSeconds } from 'date-fns'
import Store from './index'
import { Maybe, Nullable } from '../Lib/Types/base'
import { IUserPublication } from './mst'
import { IJwtPayload } from '../Lib/Types/payload'
import { decodeJwtPayload } from '../Lib/payload'
import { IUserData } from '../Api/endpoints/types/user'

export interface IIoTMeta {
  endpoint: string
  timestamp: number
}

interface IBssData {
  exp: number
  token: string
  sub?: {
    snaid: string
    username: string
  }
}

interface ISession {
  id: string
  timestamp: number
}

interface ICustomerDetails {
  customerNumber: string
  customerEmail: string
  customerFirstName: string
  customerLastName: string
}

//
// interface IUserSetting {
//   _id: string
//   dataType: string
//   props: {
//     [key: string]: string
//   }
//   snaid: string
//   wordpressSiteName: string
// }

interface IMegaCode {
  code: string
  siteCode: string
}

interface IAnalytics {
  [key: string]: string | number | Date
}

// TODO Refactor out after Auth endpoint has types.
interface IAfterLogin {
  token: string
  authorization: {
    userPublications: IUserPublication[]
    userMegaCodes: IMegaCode[]
    userAdKeywords: string[]
  }
  accountToken: string
  bssToken: string
}

const flippedIncludes = flip(includes)
const memoizedDecode = memoizeWith(identity, (value: string) => decodeJwtPayload(value))
// const getCustomerDetails: (IJwtPayload) => ICustomerDetails = pick(['customerNumber', 'customerEmail', 'customerFirstName', 'customerLastName'])
const getCustomerDetails = (p:IJwtPayload): Partial<ICustomerDetails> => {
  return {
    customerNumber: p.customerNumber,
    customerEmail: p.customerEmail,
    customerFirstName: p.customerFirstName,
    customerLastName: p.customerLastName
  }
}
const getTsInSeconds = (): number => Date.now() / 1000

/** *
 * User class contain account token
 */
export default class User {
  private readonly root: Store

  constructor (rootStore: Store) {
    makeObservable(this)

    this.root = rootStore
  }

  @persist
  @observable token: Maybe<string>

  @persist
  @observable accountToken: Maybe<string>

  @persist
  @observable usernameDuringLogin: Maybe<string> // Unexpectedly, this isn't always consistent with the account email

  @persist('object')
  @observable userPublications: IUserPublication[] = []

  @persist('list')
  @observable userMegaCodes: IMegaCode[] = []

  @persist('list')
  @observable userAdKeywords: string[] = []

  @persist('list')
  @observable displayPopupIds: string[] = []

  // Deprecated
  @persist('object')
  @observable iot: Nullable<IIoTMeta> = null

  @persist('object')
  @observable notificationsIot: Nullable<IIoTMeta> = null

  @persist('list')
  @observable mySubsDefaultOpen = []

  @persist('list')
  @observable userSettings: IUserData[] = []

  @observable userSettingsSet = false

  @persist('object')
  @observable session: Nullable<ISession> = null

  @persist('object')
  @observable bssData: Nullable<IBssData> = null

  @computed get isLoggedIn (): boolean {
    return this.token != null && !isEmpty(this.token)
  }

  // TODO Refactor
  @computed get user (): Partial<ICustomerDetails> {
    return this.decodedToken == null ? {} : getCustomerDetails(this.decodedToken)
  }

  @computed get decodedToken (): Nullable<IJwtPayload> {
    if (this.token == null) {
      Sentry.configureScope((scope) => {
        scope.setTag('jti', '')
      })

      return null
    }

    const decodedToken = memoizedDecode(this.token)

    if (decodedToken == null) {
      return null
    }

    Sentry.configureScope((scope) => {
      scope.setTag('jti', decodedToken.jti)
      scope.setTag('customerNumber', decodedToken.customerNumber)
    })

    return decodedToken
  }

  @computed get decodedAccountToken (): Nullable<IJwtPayload> {
    if (this.accountToken == null) {
      return null
    }

    return memoizedDecode(this.accountToken)
  }

  @computed get tokenExistsButIsExpired (): boolean {
    if (this.decodedToken == null) {
      return false
    }

    return Date.now() > this.decodedToken.exp * 1000
  }

  @computed get hasLGM (): boolean {
    return compose(
      not,
      isEmpty,
      filter(propEq('code', 'LGM'))
    )(this.userMegaCodes)
  }

  @computed get hasPortfolios (): boolean {
    // @ts-expect-error TODO Refactor this
    const portfolioList: string[] = compose(
      compact,
      pluck('portfolio_id'),
      filter(
        compose(
          flippedIncludes(this.userPublications),
          (v) => v?._id
          // prop('_id')
        )
      ),
      map(this.root.mst.publication.getById)
    )(this.userPublications)

    return portfolioList.length > 0
  }

  // TODO Refactor
  @computed get lrgPubOrder (): unknown {
    if (this.userSettings == null) {
      return {}
    }

    const entry = find(propEq('dataType', 'lrg_pub_order'))(this.userSettings)
    // const value = prop('props', entry)
    // @ts-expect-error TODO Refactor
    const value = entry?.props

    if (value == null) {
      return {}
    }

    return value
  }

  @computed get bssToken (): Maybe<string> {
    return this.bssData?.token
  }

  @computed get isBssTokenUsable (): boolean {
    if (this.bssToken == null || this.bssData?.exp == null || this.bssToken.length < 10) {
      return false
    }

    const exp = this.bssData.exp * 1000
    const diff = differenceInSeconds(new Date(exp), new Date())

    return diff >= 3600
  }

  @action.bound
  setToken (token: Maybe<string>): void {
    this.token = token
  }

  @action.bound
  setAccountToken (token: Maybe<string>): void {
    this.accountToken = token
  }

  @action.bound
  setUsernameDuringLogin (value: Maybe<string>): void {
    this.usernameDuringLogin = value
  }

  // Login uses this
  @action.bound
  setUserPublications (list: Nullable<IUserPublication[]>): void {
    this.userPublications = list == null ? [] : list
  }

  // Login uses this
  @action.bound
  setUserMegaCodes (list: Nullable<IMegaCode[]>): void {
    this.userMegaCodes = list ?? []
  }

  @action.bound
  setUserAdKeywords (list: Nullable<string[]>): void {
    this.userAdKeywords = list ?? []
  }

  @action.bound
  setBssData (obj: Nullable<IBssData>): void {
    this.bssData = obj
  }

  @action.bound
  setUserAdCookie (): void {
    const list = this.userAdKeywords.join(',')
    const domain = compose(
      join('.'),
      takeLast(2),
      split('.')
    )(window.location.hostname)

    document.cookie = `usersubid=${list};domain=.${domain}`
  }

  @action.bound
  setIot (obj: Nullable<IIoTMeta>): void {
    this.iot = obj
  }

  @action.bound
  setNotificationIot (obj: Nullable<IIoTMeta>): void {
    this.notificationsIot = obj
  }

  @action.bound
  logout (): void {
    this.setToken(undefined)
    this.setAccountToken(undefined)
    this.setUsernameDuringLogin(undefined)
    this.setUserPublications([])
    this.setUserMegaCodes([])
    this.setUserAdKeywords([])
    this.setIot(null)
    this.setNotificationIot(null)
    this.userSettings = []
    this.root.mst.userPublication.clear()
    localStorage.removeItem('lrg_user')
    localStorage.setItem('logged_out', Math.random()
      .toString())
    this.session = null
  }

  @action.bound
  async afterLogin ({
    token,
    authorization,
    accountToken,
    bssToken
  }: IAfterLogin): Promise<void> {
    const userPublications = prop('userPublications', authorization)
    const userMegaCodes = prop('userMegaCodes', authorization)
    const userAdKeywords = prop('userAdKeywords', authorization)

    this.setToken(token)
    this.setAccountToken(accountToken)

    if (bssToken != null) {
      this.setBssData({ token: bssToken, exp: memoizedDecode(bssToken)?.exp ?? 0})
    }

    this.setUserPublications(userPublications)
    this.setUserMegaCodes(userMegaCodes)
    this.setUserAdKeywords(userAdKeywords)
    this.setUserAdCookie()
    this.root.mst.userPublication.clear()
    this.root.mst.userPublication.localUpdate()
    this.adjustSession()

    await this.getAllUserData()
    this.mySubsDefaultOpen = compose(
      pathOr([], ['props', 'codes']),
      head,
      filter(propEq('dataType', 'mysubs_default_open'))
    )(this.userSettings)

    await this.root.mst.course.fetchCourses()
  }

  @action.bound
  updatePopupState (): void {
    const id = this.root.site.siteDetails.messages?.popup?.id

    if (id == null) {
      return
    }

    this.displayPopupIds = uniq(append(id, this.displayPopupIds))
  }

  @action.bound
  async refreshSite (): Promise<void> {
    const resp = await refresh(this.root.site.sitecode, this.token)

    if (resp.error != null) {
      Sentry.withScope((scope) => {
        scope.setTag('dbg_pos', 'Force Refresh')
        Sentry.captureMessage(resp.error)
      })

      this.logout()
      return
    }

    void this.afterLogin(resp)

    setTimeout(() => {
      window.location.reload()
    })
  }

  @action.bound
  async getAllUserData (): Promise<void> {
    if (!this.isLoggedIn) {
      return
    }

    const [resp, error] = await getAllUserData(this.root.site.sitecode)
    this.userSettingsSet = true

    if (error != null) {
      return
    }

    this.userSettings = resp ?? []
  }

  @action.bound
  toggleCodeInMySubsDefaultOpen (code: string): void {
    if (!includes(code, this.mySubsDefaultOpen)) {
      // @ts-expect-error TODO Refactor
      this.mySubsDefaultOpen = compose(
        uniq,
        append(code)
      )(this.mySubsDefaultOpen)
    } else {
      this.mySubsDefaultOpen = reject(equals(code), this.mySubsDefaultOpen)
    }

    void saveUserData(this.root.site.sitecode, 'mysubs_default_open', {
      codes: this.mySubsDefaultOpen
    })
  }

  // TODO Refactor
  @action.bound
  updateLrgPubOrder (wpSiteName: string, list: unknown): void {
    const value = this.lrgPubOrder

    // @ts-expect-error TODO Refactor
    value[wpSiteName.toUpperCase()] = list

    void saveUserData(this.root.site.sitecode, 'lrg_pub_order', value)
      .then(async () => await this.getAllUserData())
  }

  @action.bound
  adjustSession (): void {
    if (this.session == null || isEmpty(this.session)) {
      this.session = {
        id: uuid(),
        timestamp: getTsInSeconds()
      }

      return
    }

    const ts = getTsInSeconds()
    const diffInSec = 60 * 60 * 3 // 3 hours

    if (this.session.timestamp + diffInSec < ts) {
      this.session = {
        id: uuid(),
        timestamp: getTsInSeconds()
      }
    }
  }

  // TODO Refactor
  @action.bound
  analyticsAdd (obj: IAnalytics): void {
    const disableLocalTracking = process.env.REACT_APP_LOCAL_TRACKING === 'false'
    const enabledLocalLog = process.env.REACT_APP_LOCAL_TRACKING_DEBUG === 'true'
    const {
      isDev,
      sitecode
    } = this.root.site
    const modifier = this.isLoggedIn ? 'paid' : 'free'
    let out = obj

    out.generated_at = new Date().toISOString()
      .substr(0, 19)
      .replace('T', ' ')

    if (modifier === 'free') {
      out = assoc('app_code', 'webapp', out)
      out = assoc('device', 'browser', out)
      out = assoc('app_version', window.__version, out)
    }

    // Don't pollute with local logs
   if ((isDev && disableLocalTracking)) {
     return
   }

    void trackapp(sitecode, out, modifier)

    const processOut = out
    if (window.app_env === 'live' && out.event !== 'scrollPosition') {
      let isEvent = 'IsPageView'
      switch (processOut.event) {
        case 'eletterSignUp':
          isEvent = 'IsEvent'
          break
        case 'optin':
          isEvent = 'IsEvent'
          processOut.cid_form = processOut?.custom2
          processOut.qs_form = processOut?.custom3
          processOut.source_form = processOut?.custom1
          break
        case 'reportDownload':
          isEvent = 'IsEvent'
          processOut.post_title = processOut?.custom1
          break
        case 'search':
          processOut.search_phrase = processOut?.custom1
          processOut.page_num = processOut?.custom2
          break
        case 'articleView':
          processOut.post_type = processOut?.custom3
          processOut.post_title = processOut?.custom1
          break
        case 'lessonView':
          processOut.post_type = 'lesson'
          processOut.publication_subpath = processOut?.custom1
          processOut.post_title = processOut?.custom3
          break
      }
      const trackData = omit(['custom1', 'custom2', 'custom3', 'scroll_depth', 'device', 'status'], processOut)
      setTimeout(() => {
        globalThis?.dataLayer.push({
          event: isEvent,
          site: 'PAID',
          snaid: this.root.user.decodedToken?.customerNumber,
          interactionData: trackData
        })
      }, 2000)
    }

    if (enabledLocalLog) {
      console.log(obj)
    }
  }
}
