import React from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'

const LinkComp = ({
  to, children, samepage = false, ...props
}) => {
  if (!to) return <span {...props}>{children}</span>

  const target = samepage ? '' : '_blank'

  if (/^https?:\/\//.test(to)) return <a href={to} {...props} target={target} rel='noopener noreferrer'>{children}</a>

  return <Link to={to} {...props}>{children}</Link>
}

LinkComp.propTypes = {
  to: PT.string,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  samepage: PT.bool
}

export default LinkComp
