import React, { useEffect, useState } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { useStore } from 'App'

import { getPortfolio } from 'Api/endpoints/portfolio'
import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import Spinner from 'Components/spinner'
import { html2jsx } from 'Lib/parser'
import BaseLayout from '../../Layouts/base'
import { find, isEmpty, isNil, path, propEq } from 'ramda'

const usePortfolio = (portfolioId) => {
  const [portfolio, setPortfolio] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const res = await getPortfolio(portfolioId)
      setLoading(false)
      setPortfolio(res.value)
    }

    // noinspection JSIgnoredPromiseFromCall
    fetch()
  }, [portfolioId])

  return [loading, portfolio]
}

const useContent = (loading, portfolio, portfolioId) => {
  const store = useStore()
  const mainPortfolioId = portfolioId.split('-')
  const publicationData = find(propEq('portfolio_id', mainPortfolioId[1]))(store.mst.publication.paid)

  const trackRecordTop = path(['settings', 'dynamicPortfolioSections', 'trackRecord', 'top'], publicationData)
  const showTrackRecordTop = (trackRecordTop.enabled && !isEmpty(trackRecordTop.content) && !isEmpty(portfolio) && !isNil(portfolio))
  const trackRecordBottom = path(['settings', 'dynamicPortfolioSections', 'trackRecord', 'bottom'], publicationData)
  const showTrackRecordBottom = (trackRecordBottom.enabled && !isEmpty(trackRecordBottom.content) && !isEmpty(portfolio) && !isNil(portfolio))

  if (loading) {
    return <Spinner />
  }

  return (
    <main>
      <div className='portfolioContent'>
        {showTrackRecordTop && (
          <div className='dynamicPortfolioSectionTop'>
            {html2jsx(trackRecordTop.content)}
          </div>
        )}
        {html2jsx(portfolio)}
        {showTrackRecordBottom && (
          <div className='dynamicPortfolioSectionBottom'>
            {html2jsx(trackRecordBottom.content)}
          </div>
        )}
      </div>
    </main>
  )
}

const TrackRecord = ({ match }) => {
  const [loading, portfolioRaw] = usePortfolio(match.params.portfolioId)
  const content = useContent(loading, portfolioRaw, match.params.portfolioId)

  const title = 'Track Record'

  return (
    <BaseLayout>
      <TemplateSingle
        noLayout
        title={title}
        content={content}
        extraClass='__portfolio'
        isPortfolio
      />
    </BaseLayout>
  )
}

TrackRecord.propTypes = {
  match: ReactRouterPropTypes.match
}

export default TrackRecord
