import { AxiosError } from 'axios'

import { upstreamIot as API, handleError } from '../index'
import { IError } from '../error'

export type RetUrl = string | IError
export interface RespUrl  {
    status: string
    url: string
}

export async function getUrl(sitecode: string): Promise<RetUrl|IError> {
  try {
      const res = await API.get<RespUrl>(`/iot/${sitecode}/url`)

      return res.data.url
  } catch (e) {
      return handleError(e as AxiosError)
  }
}
