import React, { Fragment, useEffect } from 'react'
import { observer } from 'mobx-react'
import isAfter from 'date-fns/isAfter'
import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'
import * as A from 'fp-ts/Array'
import { pipe, constant } from 'fp-ts/lib/function'
import * as ROA from 'fp-ts/ReadonlyArray'
import * as O from 'fp-ts/Option'

import { useStore } from 'App'
import { html2jsx } from 'Lib/parser'
import Header from './Components/Header'
import Issues from './Components/Issues'
import Reports from './Components/Reports'
import Message from '../../Components/Message'
import IssuesAndBulletins from './Components/IssuesAndBulletins'
import { rearrangeSticky, safeFormatToDate, skip } from 'Lib/purefunctions'
import { IArticle, IPublication } from 'Stores/mst'
import { Maybe } from 'Lib/Types/base'
import { Predicate } from 'fp-ts/Predicate'
import { IPublicationMsgArea } from 'Stores/site'
import { ISnippet } from 'Stores/mst/Views/article'

import 'react-toastify/dist/ReactToastify.css'

interface ISingleViewComponentParams {
  publication: IPublication
  isChild: boolean
}

interface ILocalSnippet extends IArticle {
  url?: string
  readingTimeMin?: number
  recommendationLine?: string
  dateByMinute?: string
}

const SingleViewComponent = ({ publication, isChild }: ISingleViewComponentParams): JSX.Element => {
  const store = useStore()
  const id = publication._id
  const sitecode = publication.wordpressSiteName
  // TODO Check variable name in Mongo, is it a typo?
  const codeFragments = publication.settings['call-fragments'] ?? {}

  const getIssues = (): ILocalSnippet[] => {
    const { code } = publication

    return pipe(
      store.mst.article.getIssues(code, null, true, ['media']),
      A.takeLeft(4),
      A.map((item: ISnippet) => ({
        url: `/${code}/${item.slug}`,
        readingTimeMin: item.reading_time_min,
        ...item
      }))
    )
  }

  const getBulletin = (): ILocalSnippet[] => {
    const { code } = publication

    return pipe(
      store.mst.article.getBulletins(code, null, true),
      A.takeLeft(6),
      A.map((item: ISnippet) => ({
        recommendationLine: item.recommendation_line,
        dateByMinute: safeFormatToDate((item.createdAtGMT), 'MM/dd/yyyy p'),
        ...item
      }))
    )
  }

  const getReports = (): ILocalSnippet[] => {
    const { code } = publication

    return pipe(
      store.mst.article.getReports(code, null, true),
      rearrangeSticky,
      A.map((item: ISnippet) => ({
        url: `/${code}/special-reports/${item.slug}`,
        ...item
      })),
      A.takeLeft(4)
    )
  }

  const getCustomMessage = (): Maybe<string> => {
    const { code } = publication
    const { siteDetails } = store.site

    const matchesCode: Predicate<IPublicationMsgArea> = (p) => p.code === code
    const isAcceptable: Predicate<IPublicationMsgArea> = (p) =>
      p.content != null &&
      p.enabled != null &&
      p.enabled &&
      p.until != null &&
      isAfter(toDate(parseISO(p.until)), new Date())

    return pipe(
      siteDetails?.messages?.publicationMsg ?? [],
      ROA.filter(matchesCode),
      ROA.filter(isAcceptable),
      ROA.head,
      O.map(v => v.content),
      O.getOrElseW(constant(undefined))
    )
  }

  const getDynamicFinalComponents = (): JSX.Element => {
    const defaultOrder = ['message', 'header', 'issues', 'reports', 'resources']
    const productOrderFromConfig: string[] | undefined = publication.settings['product-stack']

    const order = productOrderFromConfig ?? defaultOrder

    const issues = getIssues()
    const reports = getReports()
    const bulletin = getBulletin()
    const customMessage = getCustomMessage()

    const componentMap = {
      message: <Message content={customMessage} />,
      header: <Header
        title={publication.title}
        links={publication.menuItems}
        submenu={publication.submenuItemsWithContent}
        isChild={isChild}
        color={publication.color}
        publication={publication}
        sitecode={sitecode}
      />,
      issues: <Issues
        title={publication.title}
        issues={issues}
        publicationCode={publication.code}
        isChild={isChild}
      />,
      issuesAndBulletins: <IssuesAndBulletins
        title={publication.title}
        issues={issues}
        bulletin={bulletin}
        publicationCode={publication.code}
        isChild={isChild}
      />,
      reports: reports.length > 0 && (
        <Reports
          reports={reports}
          publicationCode={publication.code}
        />
      ),
      resources: publication.pub_resources != null && (
        <div className='pubResourcesWrapper'>
          <div>
            {html2jsx(publication.pub_resources)}
          </div>
        </div>
      )
    }

    return (
      <>
        {order.map((str) => <Fragment key={str}>{componentMap[str]}</Fragment>)}
      </>
    )
  }

  useEffect(() => {
    store.mst.article.fetch(sitecode, id, 'issues', ...skip(4), store.user.refreshSite)
    store.mst.article.fetch(sitecode, id, 'reports')


    if (codeFragments?.bulletin === true) {
      store.mst.article.fetch(sitecode, id, 'bulletin')
    }
  }, [])

  const dynamicFinalComponents = getDynamicFinalComponents()

  return (
    <div className={`singlePublicationViewWrapper pr-${publication.code}`}>
      {dynamicFinalComponents}
    </div>
  )
}


export default observer(SingleViewComponent)
