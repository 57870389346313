import React, { useState, useEffect, useRef, FC } from 'react'
import MQTT from 'async-mqtt'
import { startsWith } from 'ramda'

import { useStore } from 'App'
import { getUrl } from 'Api/endpoints/iot'
import { IError as ApiError } from 'Api/error'
import useInterval from 'Lib/hooks/useInterval'
import { IIoTMeta } from 'Stores/user'
import { Maybe, Nullable } from 'Lib/Types/base'

const maxIotDiff = 10 // In minutes
const isValidUrl = (url: Nullable<Maybe<string>>): boolean => url != null && startsWith('wss://', url)
const isValidIot = (iot: Nullable<IIoTMeta>): boolean => {
  if (iot == null) {
    return false
  }
  const diff = (new Date(iot.timestamp).getTime() - Date.now()) / (1000 * 60)

  return diff >= maxIotDiff
}
const isIotExpiring = (iot: Nullable<IIoTMeta>): boolean => {
  if (iot == null) {
    return false
  }
  const dateDiff = new Date(iot.timestamp).getTime() - Date.now()
  const diff = Math.floor(dateDiff / (1000 * 60))

  return diff <= maxIotDiff
}

const useFetch = (shouldFetch: string): [Maybe<string>] => {
  const store = useStore()
  const { notificationsIot } = store.user
  const initUrl = isValidIot(notificationsIot) ? notificationsIot?.endpoint : ''
  const [url, setUrl] = useState<Maybe<string>>(initUrl)

  useEffect(() => {
    const { sitecode } = store.site
    const { isLoggedIn } = store.ui

    if (sitecode == null || !isLoggedIn || shouldFetch === '') {
      return
    }

    const fetchUrl = async (): Promise<void> => {
      const ret = await getUrl(sitecode.toLowerCase())

      const url = ret as string
      const error = ret as ApiError

      if (url != null) {
        setUrl(url)
      }

      if (error != null) {
        setUrl('')
        console.error(error)
      }
    }

    void fetchUrl()
  }, [store, shouldFetch])

  return [url]
}

const useCascadingUrl = (): [Maybe<string>] => {
  const store = useStore()
  const { notificationsIot } = store.user
  const { isLoggedIn } = store.ui
  const [shouldFetch, setShouldFetch] = useState<string>(!isLoggedIn ? '' : 'true')
  const [url] = useFetch(shouldFetch)

  useEffect(() => {
    if (isLoggedIn) {
      setShouldFetch('true')
    }
  }, [isLoggedIn])

  useInterval(() => {
    // console.log('Expiration check')
    if (isIotExpiring(notificationsIot)) {
      // console.log('Updating IoT fetch flag...')
      setShouldFetch(Math.random()
        .toString())
    }
  }, 60 * 1000)

  return [url]
}

const Notifications: FC = ({ children }): JSX.Element => {
  const store = useStore()
  const [url] = useCascadingUrl()
  const clientContainer = useRef<MQTT.AsyncMqttClient | null>(null)

  const {
    sitecode,
    isBeta
  } = store.site as { sitecode: string, isBeta: boolean }

  useEffect(() => {
    if (!isValidUrl(url)) {
      return
    }

    store.mst.notification.fetchAll()

    if (clientContainer.current == null) {
      // console.log(`Connecting to ${url}`)

      clientContainer.current = MQTT.connect(url)
    }

    // clientContainer.current.on('message', (topic: string, message: string) => {
    clientContainer.current.on('message', () => {
      // console.log('Got Message!')

      // The message object over this channel is partial, so we can't update the local store but fetchAll again
      // const obj = JSON.parse(message.toString())
      // console.log(obj)
      // store.mst.notification.upsert(obj)

      store.mst.notification.fetchAll()
    })

    clientContainer.current.on('connect', async () => {
      try {
        const channel = `${isBeta ? 'dev' : 'prod'}/notification/content/${sitecode.toLowerCase()}`

        if (clientContainer.current != null && url != null) {
          store.user.setNotificationIot({
            endpoint: url,
            timestamp: Date.now() + (1000 * 60 * 60 * 24)
          })

          // console.log(`Subscribing to ${channel}`)
          await clientContainer.current.subscribe(channel)
          // console.log(`Subscribed to ${channel}`)
          console.log('Subscribed to notifications successfully.')
        }
      } catch (e) {
        // console.log('HIT ERR')
        console.warn(e)
      }
    })

    clientContainer.current.on('error', (err) => {
      // console.log('ON ERR')
      console.warn(err)
    })

  }, [url])

  return (
    <>
      {children}
    </>
  )
}

export default Notifications
