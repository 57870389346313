import { ICourse, ICourseStore, ILesson, ISection } from 'Stores/mst'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { function as FF } from 'fp-ts-std'

const { curry2 } = FF

export interface NextInCourse {
  section:ISection
  lesson: ILesson
}

export interface OptionsNextInCourse {
  section: ISection
  lesson: O.Option<ILesson>
}

const courseView = (self: ICourseStore): ICourseStore => ({
  find (id: string): ICourse | undefined {
    return self.courses?.find(course => course.id === id)
  },

  findByCode (code: string): ICourse | undefined {
    return self.courses?.find(course => course.code === code)
  },

  nextInCourse (courseId: string, sectionId: string, lessonId: string): O.Option<NextInCourse> {
    const findCourseSection = curry2(
      (_sectionId: string, c: ICourse): ISection => c.findSection(_sectionId)
    )
    const findSectionNextLessonById = curry2(
      (_lessonId: string, s: ISection): O.Option<ILesson> => {
        // console.log('A', s, s.getNextLessonById(_lessonId))
        return s.getNextLessonById(_lessonId)
      }
    )
    const findCourseNextSectionById = curry2(
      (_sectionId: string, c: ICourse): ISection => c.getNextSectionById(_sectionId)
    )
    const getFirstSectionLesson = (section: ISection): O.Option<ILesson> => {
      if (section == null || section.lessons == null || section.lessons.length === 0) {
        return O.none
      }

      return O.some(section.lessons[0])
    }

    const course: O.Option<ICourse> = O.fromNullable(this.find(courseId))

    const _s: O.Option<ISection> = pipe(course, O.map(findCourseSection(sectionId)))

    // Transpose OptionsNextInCourse to Option<NextInCourse>
    const shapeOutput = ({section, lesson}: OptionsNextInCourse): O.Option<NextInCourse> => {
      return O.match(
        () => {
          return O.none
        },
        (lessonValue) => {
          return O.some(({section, lesson: lessonValue})) as O.Option<NextInCourse>
        }
      )(lesson)
    }

    const nextInSection = pipe(
      O.Do,
      O.apS('section', pipe(course, O.map(findCourseSection(sectionId)))),
      O.apS('lesson', pipe(_s, O.map(findSectionNextLessonById(lessonId)))),
      O.chain(shapeOutput)
    )

    return O.match(
      () => {
        const maybeNextLesson = pipe(
          course,
          O.map(findCourseNextSectionById(sectionId)),
          O.map(getFirstSectionLesson)
        )

        return pipe(
          O.Do,
          O.apS('section', O.map(findCourseNextSectionById(sectionId))(course)),
          O.apS('lesson', maybeNextLesson),
          O.chain(shapeOutput)
        )
      },
      (v: NextInCourse) => {
        return O.some(v)
      }
    )(nextInSection)
  },

  prevInCourse (courseId, sectionId, lessonId): NextInCourse {
    const course = this.find(courseId)
    const currentSection = course.findSection(sectionId)


    const prevLessonInSection = currentSection.getPrevLessonById(lessonId)
    if (prevLessonInSection != null) {
      return {
        section: currentSection,
        lesson: prevLessonInSection
      }
    }

    // Move to the prev section's last lesson, if any
    const maybeNextSection = course?.getPrevSectionById(sectionId)
    const maybeNextLesson = maybeNextSection?.lessons[maybeNextSection?.lessons?.length - 1]

    return {
      section: maybeNextSection,
      lesson: maybeNextLesson
    }
  }
})

export default courseView
