import React from 'react'
import ProtectRoute from '../../../../../Components/protected'
import SpecialPortfolioComponent from '../../Pages/SpecialPortfolio/single'

// Tom Portfolio for now

export default store => {
  const publication = store.mst.publication.getByCode('tom-portfolio')

  if (!publication) {
    return null
  }

  return [
    <ProtectRoute
      key='/tom-portfolio/allocation-strategy'
      exact
      publication={publication}
      path='/tom-portfolio/allocation-strategy'
      render={(props) => (
        <SpecialPortfolioComponent
          {...props}
          publication={publication}
        />
      )}
    />
  ]
}
