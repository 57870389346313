import { AxiosError } from 'axios'
import { function as F } from 'fp-ts-std'
import { Predicate } from 'fp-ts/Predicate'

import { upstreamPortfolio as API } from '../index'
import { handleError, IError } from '../error'
import type { Maybe } from 'Lib/Types/base'
import { ApiResponse } from './types/base'

interface ValueObject {
  value: string
}

export type RetPortfolio = ValueObject | IError

export interface RespPortfolio {
  portfolioHtml: string
}

export const normalizePortfolioEndpoint = (id: string, tradeGroup: Maybe<string>): string => {
  const base = `portfolio/getportfolio/${id}`
  const hasTargetGroup: Predicate<Maybe<string>> = (s): boolean => {
    return typeof s !== 'undefined'
  }
  const withTradeGroup = (tradeGroup: Maybe<string>): string => base + `?trade-group=${tradeGroup}`
  const normalize = F.ifElse(withTradeGroup)((_: Maybe<string>): string => base)(hasTargetGroup)

  return normalize(tradeGroup)
}

// TODO Normalize response to exclude the object value wrapper
export const getPortfolio = async (id: string, tradeGroup?: string): ApiResponse<RetPortfolio> => {
  try {
    const endpoint = normalizePortfolioEndpoint(id, tradeGroup)
    const res = await API.get<RespPortfolio>(endpoint)

    return {
      value: res.data.portfolioHtml
    }
  } catch (e) {
    return handleError(e as AxiosError)
  }
}
