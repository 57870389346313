import React, { Component } from 'react'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import ReactRouterPropTypes from 'react-router-prop-types'
import qs from 'query-string'
import { computed, makeObservable } from 'mobx'
import { prop } from 'ramda'

import TemplateSingle from 'Containers/Sites/Base/Templates/single'
import Link from 'Components/link'

@inject('store')
@observer
class PageNoAccess extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    location: ReactRouterPropTypes.location.isRequired
  };

  constructor (props) {
    super(props)

    makeObservable(this)

    const { pub } = qs.parse(this.props.location.search)
    this.pub = pub
  }

  @computed get publication () {
    if (!this.pub) {
      return null
    }

    return this.props.store.mst.publication.getByCode(this.pub)
  }

  render () {
    const title = prop('title', this.publication)
    const regLink = prop('product_page', this.publication) === 'none' ? '' : prop('product_page', this.publication)

    return (
      <TemplateSingle
        title={`${title} is not available`}
        content={(
          <section className='upgradeContent'>
            <p>
              You do not currently have access to {title}.
              {regLink && (
                <span>To subscribe please click <Link to={regLink}>here</Link>.</span>
              )}
            </p>
            <p><span>Or go to <Link to='/my-subscriptions'>My Subscriptions</Link></span></p>
          </section>
        )}
      />
    )
  }
}

export default PageNoAccess
