import React from 'react'
import { withRouter } from 'react-router'
import ReactRouterPropTypes from 'react-router-prop-types'
import PT from 'prop-types'

class ScrollToTop extends React.Component {
  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render () {
    return this.props.children
  }
}

ScrollToTop.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ])
}

export default withRouter(ScrollToTop)
