/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/browser'
import { curry } from 'ramda'
import { handleError, upstreamAuth as API } from '../index'

async function login (username, password, siteid, recaptchaValue) {
  try {
    const res = await API.post(`/auth/${siteid}/login`, {
      username,
      password,
      appcode: 'webapp',
      grecaptcharesponse: recaptchaValue || '',
      appVersion: window.__version
    })

    return {
      value: res.data
    }
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTag('dbg_api', '__auth_login')
      Sentry.captureMessage(e)
    })
    return {
      error: JSON.stringify(e)
    }
  }
}

async function info (siteid, token) {
  try {
    const res = await API.get(`/auth/${siteid}/user`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function trackLogin (siteid, token) {
  try {
    const res = await API.get(`/auth/${siteid}/tracklogin`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function refresh (siteId, token) {
  try {
    const res = await API.get(`auth/${siteId}/refresh`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return res.data
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTag('dbg_api', '__auth_refresh')
      Sentry.captureMessage(e)
    })
    return {
      error: JSON.stringify(e)
    }
  }
}

async function logout (siteId, token) {
  if (!token) {
    return {
      value: ''
    }
  }

  try {
    const res = await API.get(`auth/${siteId}/logout`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function reset (siteId, email) {
  try {
    const res = await API.post(`auth/request/access/${siteId}`, { email })

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function contact (siteId, {
  email, firstName, lastName, formUrl, phone, message, captcha, target, targetEmail
}) {
  try {
    const res = await API.post(`auth/${siteId}/contactus`, {
      contactFormUrl: formUrl,
      firstName,
      lastName,
      customerEmail: email,
      phone,
      message,
      grecaptcharesponse: captcha,
      target,
      targetEmail
    })

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function cancel (siteId, {
  email, firstName, lastName, formUrl, phone, message, captcha, target, targetEmail, productToCancel
}) {
  try {
    const res = await API.post(`auth/${siteId}/cancel`, {
      contactFormUrl: formUrl,
      firstName,
      lastName,
      customerEmail: email,
      phone,
      message,
      grecaptcharesponse: captcha,
      target,
      targetEmail,
      productToCancel
    })

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function feedback (siteId, {
  email, firstName, lastName, formUrl, phone, message, captcha, target, targetEmail
}) {
  try {
    const res = await API.post(`auth/${siteId}/feedback`, {
      contactFormUrl: formUrl,
      firstName,
      lastName,
      customerEmail: email,
      phone,
      message,
      grecaptcharesponse: captcha,
      target,
      targetEmail
    })

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

const secondaryLogin = curry(async (siteid, username, password, recaptchaValue) => {
  try {
    const res = await API.post(`/auth/${siteid}/account/login`, {
      username,
      password,
      appcode: 'webapp',
      grecaptcharesponse: recaptchaValue || '',
      appVersion: window.__version
    })

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: e
    }
  }
})

async function settings (siteId) {
  try {
    const res = await API.get(`auth/${siteId}/settings`, {
      headers: {}
    })

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: JSON.stringify(e)
    }
  }
}

async function getBrokerUrl (siteid) {
  try {
    const res = await API.get(`/auth/${siteid}/ioturl`)

    return {
      value: res.data
    }
  } catch (e) {
    return {
      error: e
    }
  }
}

async function getAd (sitecode, adZone, adZoneType, kwList = []) {
  try {
    const kw = kwList.join(',')
    const path = adZoneType === 'keyword' ? `auth/${sitecode}/ad/${adZone}?kw=${kw}` : `auth/${sitecode}/ad/${adZone}`
    const res = await API.get(path)

    return res.data.adJson
  } catch (e) {
    return handleError(e)
  }
}

export {
  login,
  logout,
  refresh,
  reset,
  info,
  contact,
  feedback,
  trackLogin,
  cancel,
  secondaryLogin,
  settings,
  getBrokerUrl,
  getAd
}
