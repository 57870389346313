import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { computed, action, makeObservable } from 'mobx'
import { prop, propEq } from 'ramda'

@inject('store')
@observer
class ProtectedRoute extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    publication: MobxPropTypes.objectOrObservableObject,
    location: ReactRouterPropTypes.location.isRequired
  };

  constructor (props) {
    super(props)
    makeObservable(this)
  }

  @action.bound
  logout () {
    this.props.store.user.logout()
  }

  @computed get shouldRedirectToUnavailable () {
    if (!this.props.publication) {
      return false
    }

    const pid = prop('_id', this.props.publication)
    const isPaid = propEq('publication_type', 'paid', this.props.publication)

    return isPaid && !this.props.store.mst.userPublication.containsPublication(pid)
  }

  render () {
    const pCode = prop('code', this.props.publication)

    if (this.props.store.user.tokenExistsButIsExpired) {
      this.props.store.ui.shouldRedirectAfterLogin(this.props.location.pathname)
      this.logout()

      return (<Redirect to={{ pathname: '/login' }} />)
    }

    if (!this.props.store.ui.isLoggedIn) {
      this.props.store.ui.shouldRedirectAfterLogin(this.props.location.pathname)

      return (<Redirect to={{ pathname: '/login' }} />)
    }

    if (this.shouldRedirectToUnavailable) {
      return (<Redirect to={{ pathname: '/unavailable', search: `?pub=${pCode}` }} />)
    }

    return <Route {...this.props} />
  }
}

export default withRouter(ProtectedRoute)
