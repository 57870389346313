import React, { Component } from 'react'
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { action, observable, makeObservable } from 'mobx'

@inject('store')
@observer
class Base extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    children: PropTypes.node
  };

  @observable BaseLayout;

  constructor (props) {
    super(props)

    makeObservable(this)

    this.loadBaseLayout()
  }

  @action.bound
  async loadBaseLayout () {
    const { siteLocalPath } = this.props.store.site

    // Eslint currently dies on template literals inside dynamic imports
    // eslint-disable-next-line prefer-template
    this.BaseLayout = await import('Containers/Sites/' + siteLocalPath + '/Layouts/base')
  }

  render () {
    if (!this.BaseLayout || !this.BaseLayout.default) {
      return null
    }

    const BaseLayout = this.BaseLayout.default

    return <BaseLayout {...this.props} />
  }
}

export default Base
