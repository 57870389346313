import { flatten } from 'ramda'

import { shouldLgm } from 'Lib/purefunctions'
import { getPublications } from '../../../Api/endpoints/article'
import { getFreePublications } from '../../../Api/endpoints/freearticle'
import { Publication } from '../Models/publication'

const publication = (self) => ({
  push (item) {
    const itemExists = self.publications.valueOf().filter((a) => a._id === item._id)[0]

    if (itemExists) {
      return
    }

    self.publications.push(item)
  },

  pushPublications (items) {
    items.map((item) => this.push(item))
  },

  async fetchAll (sitecode, freeSitecode) {
    const type = shouldLgm(sitecode) ? 'lgm' : ''
    const promises = freeSitecode
      ? [getPublications(sitecode, type), getFreePublications(freeSitecode, type)]
      : [getPublications(sitecode, type)]
    const data = flatten(await Promise.all(promises))

    try {
      const objects = data.map((item) => Publication.create(item))
      self.pushPublications(objects)
    } catch (e) {
      console.log(e)
    }
  }
})

export default publication
