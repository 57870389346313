/* eslint-disable no-nested-ternary */
import {
  propEq,
  prop,
  compose,
  head,
  flip,
  includes,
  pluck,
  not,
  allPass,
  sortBy,
  toLower,
  groupBy,
  filter,
  mapObjIndexed,
  isEmpty,
  propOr,
  anyPass,
  equals,
  path,
  concat
} from 'ramda'
import { getParent, getRoot } from 'mobx-state-tree'

const flippedIncludes = flip(includes)
const sortByAuthorNameCaseInsensitive = sortBy(compose(toLower, prop('main_author_name')))

const publication = (self) => ({
  getSiteNameByCode (code) {
    const { getSiteNameByCode } = path(['_root', 'site'], getRoot(self))

    return getSiteNameByCode(code)
  },

  sortBySite (list) {
    return groupBy((item) => {
      const name = item.wordpressSiteName
      return this.getSiteNameByCode(name)
    })(list)
  },

  get paid () {
    const withRules = allPass([
      anyPass([
        propEq('publication_status', 'active'),
        propEq('publication_status', 'archived')
      ]),
      propEq('publication_type', 'paid')
    ])

    return self.publications.filter(withRules)
  },

  get nonFreeSortedBySite () {
    return this.sortBySite(this.paid)
  },

  get userPaid () {
    const { userPublication: { userPublications } } = getParent(self)
    const upIds = pluck('publicationId', userPublications)

    return filter(compose(
      flippedIncludes(upIds),
      prop('_id')
    ))(self.paid) || []
  },

  get paidViewForMySubscriptions () {
    return filter(compose(
      not,
      propEq('show_on_subscription_page', 'no')
    ))(self.userPaid)
  },

  get paidViewForLRGSubscriptions () {
    return filter(compose(
      not,
      anyPass([
        propEq('publication_status', 'archived'),
        propEq('show_on_subscription_page', 'no')
      ])
    ))(self.userPaid)
  },

  get archived () {
    const withRules = allPass([
      propEq('publication_status', 'archived')
    ])

    return self.paidViewForMySubscriptions.filter(withRules)
  },

  get paidSortedByAuthor () {
    const withRules = allPass([
      propEq('publication_status', 'active'),
      propEq('publication_type', 'paid')
    ])

    const list = self.publications.filter(withRules)

    return sortByAuthorNameCaseInsensitive(list)
  },

  get free () {
    const withRules = allPass([
      propEq('publication_status', 'active'),
      propEq('publication_type', 'free')
    ])

    return self.publications.filter(withRules)
  },

  get freeSortedByAuthor () {
    const withRules = allPass([
      propEq('publication_status', 'active'),
      propEq('publication_type', 'free')
    ])

    const list = self.publications.filter(withRules)

    return sortByAuthorNameCaseInsensitive(list)
  },

  get paidSortedBySite () { // TODO rename - paid as "user has paid for", not "subs requiring payment"
    return groupBy((item) => {
      const name = item.wordpressSiteName
      return this.getSiteNameByCode(name)
    })(this.paidViewForLRGSubscriptions)
  },

  get bySite () {
    return groupBy((item) => {
      const name = item.wordpressSiteName
      return this.getSiteNameByCode(name)
    })(concat(this.paidViewForLRGSubscriptions, this.free))
  },

  get bySiteOfCode () {
    return groupBy((item) => {
      const code = prop('wordpressSiteName', item)
      if (code.length === 3) {
        return code.substring(0, 2)
      }
      return code
    }, (concat(this.paidViewForLRGSubscriptions, this.free)))
  },

  get portfoliosBySite () {
    return mapObjIndexed((group) => group.filter((item) => !isEmpty(item.portfolio_id)))(this.paidSortedBySite)
  },

  getById (id) {
    return head(self.publications.filter(propEq('_id', id)))
  },

  getByCode (code) {
    return head(self.publications.filter(propEq('code', code)))
  },

  getByPortfolio (id) {
    return head(self.publications.filter(propEq('portfolio_id', id)))
  },

  getChildrenOf (id) {
    const item = self.getById(id)

    if (!equals(prop('has_children', item), 'yes')) {
      return []
    }

    return self.publications.filter(propEq('parent_publication', prop('code', item)))
  },

  getSiteLogoByPubCode (code) {
    return compose(
      propOr('', 'site_logo'),
      head,
      filter(propEq('wordpressSiteName', code))
    )(self.publications)
  },

  getWordpressSiteNameByCode (code) {
    return compose(
      propOr('', 'wordpressSiteName'),
      head,
      filter(propEq('code', code))
    )(self.publications)
  }
}
)

export default publication
