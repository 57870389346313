/* eslint-disable no-underscore-dangle,func-names,no-var,no-unused-vars */

const siteFavicon = document.getElementsByName('favicon')[0]
// eslint-disable-next-line no-useless-escape
const urlParts = /^(?:\w+\:\/\/)?([^\/]+)(.*)$/.exec(document.location)

let gaId = ''

// Globals
window.siteCode = ''
window.app_env = 'beta'
window.app_dev = false

// Compat function until the WP content has been sanitizer/refactored
window.__app_wp_compat = {
  webform_validate () {
    let msg = ''
    if (document.getElementById('Phone').value.replace(/[^0-9]/, '').length < 10) {
      msg += 'The phone field is required. Please enter all 10 digits, including the area code.\n'
    }

    if (msg) {
      alert(msg)
      return false
    }
    return true
  }
}

if (urlParts[1].indexOf('legacy') !== -1) {
  document.title = 'Legacy Research'
  gaId = 'GTM-M9LL542'
  window.siteCode = 'LG'

  if (siteFavicon != null) {
    siteFavicon.href = '/favicon-lg.png'
  }
}

if (urlParts[1].indexOf('palm') !== -1) {
  document.title = 'Palm Beach Group'
  window.siteCode = 'PB'
  gaId = 'GTM-56QKCVT'

  if (siteFavicon != null) {
    siteFavicon.href = '/favicon-pb.png'
  }
}

if (urlParts[1].indexOf('rogueeconomics') !== -1) {
  document.title = 'Rogue Economics'
  window.siteCode = 'RE'
  gaId = 'GTM-TBMP72L'

  if (siteFavicon != null) {
    siteFavicon.href = '/favicon-re.png'
  }
}

if (urlParts[1].indexOf('brownstone') !== -1) {
  document.title = 'Brownstone Research'
  window.siteCode = 'BR'
  gaId = 'GTM-59MPT5K'

  if (siteFavicon != null) {
    siteFavicon.href = '/favicon-br.png'
  }
}

if (urlParts[1].indexOf('jeff') !== -1) {
  document.title = 'Jeff Clark Trader'
  window.siteCode = 'JC'
  gaId = 'GTM-WHT9WWH'

  if (siteFavicon != null) {
    siteFavicon.href = '/favicon-jc.png'
  }
}

if (urlParts[1].indexOf('opportunistic') !== -1) {
  document.title = 'The Opportunistic Trader'
  window.siteCode = 'OT'
  gaId = 'GTM-PH4HMT5'

  if (siteFavicon != null) {
    siteFavicon.href = '/favicon-ot.png'
  }
}

if (urlParts[1].indexOf('newparadigm') !== -1) {
  document.title = 'New Paradigm Research'
  window.siteCode = 'NP'
  gaId = 'GTM-PQJK84P'

  if (siteFavicon != null) {
    siteFavicon.href = '/favicon-np.png'
  }
}

if (urlParts[1].indexOf('widemoatresearch') !== -1) {
  document.title = 'Wide Moat Research'
  window.siteCode = 'WM'
  gaId = 'GTM-TDRFB54'

  if (siteFavicon != null) {
    siteFavicon.href = '/favicon-wm.png'
  }
}

if (urlParts[1].indexOf('gulfportanalytics') !== -1) {
  document.title = 'Gulfport Analytics'
  window.siteCode = 'GP'
  gaId = 'GTM-WN9RPHHZ'

  if (siteFavicon != null) {
    siteFavicon.href = '/favicon-gp.png'
  }
}

if (urlParts[0].indexOf('local') !== -1) {
  window.app_dev = true
}

if (urlParts[1].indexOf('beta') === -1 && urlParts[1].indexOf('localhost') === -1) {
  window.app_env = 'live'
  window.dataLayer = window.dataLayer || [];

  (function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({
      'gtm.start':
        new Date().getTime(),
      event: 'gtm.js'
    })
    const f = d.getElementsByTagName(s)[0]
    const j = d.createElement(s)
    // eslint-disable-next-line eqeqeq
    const dl = l != 'dataLayer' ? `&l=${l}` : ''
    j.async = true
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
    f.parentNode.insertBefore(j, f)
  }(window, document, 'script', 'dataLayer', gaId))
}

// const injectStyle = url => {
//   const link = document.createElement('link')
//   link.rel = 'stylesheet'
//   link.href = url
//   document.body.appendChild(link)
// }
