const crypto = require('crypto')

const cryptoRandomString = (len) => {
  if (!Number.isFinite(len)) {
    throw new TypeError('Expected a finite number')
  }

  return crypto.randomBytes(Math.ceil(len / 2))
    .toString('hex')
    .slice(0, len)
}

export default function crypt32 () {
  return cryptoRandomString(32)
}

export function readableRandomStringMaker (length = 8) {
  return Array(length)
    .fill('a')
    .map(() => 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.charAt(Math.random() * 62))
    .join('')
}
