import { types, getParent } from 'mobx-state-tree'

import { IUserPublicationStore } from '../index'

export const UserPublication = types
  .model('UserPublication', {
    _id: types.identifier,
    publicationId: types.optional(types.string, '')
  })
  .actions((self) => ({
    add (item) {
      const { userPublications } = getParent<IUserPublicationStore>(self)
      const itemExists = userPublications.filter((a) => a._id === item._id)[0]

      if (itemExists != null) {
        return
      }

      userPublications.push(item)
    },
    remove () {
      getParent<IUserPublicationStore>(self).remove(self)
    }
  }))
