import React from 'react'
import { observer } from 'mobx-react-lite'
import PT from 'prop-types'
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  compose, propOr, defaultTo, path
} from 'ramda'
import { Link } from 'react-router-dom'

import { safeFormatToDate } from 'Lib/purefunctions'

const getTypeFromArticle = compose(
  defaultTo(''),
  propOr([], 'newsletter_type')
)

const Item = ({ item, handleBookmarkClicked }) => {
  const publicationCode = path(['publicationObj', 'code'], item)
  const linkTo = `/${publicationCode}/${item.slug}`

  return (
    <div className='favorite-item' key={linkTo}>
      <div className='titleLine' style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Link to={linkTo}>
            <h4>
              <span>{item.title}</span>
            </h4>
          </Link>
        </div>
        <div style={{ }}>
          <button className='bookmarkBtn' onClick={() => handleBookmarkClicked(item)}>
            <FontAwesomeIcon icon={faBookmarkSolid} size='lg' />
          </button>
        </div>
      </div>
      <div className='item-info'>
        <span className='item-date'>
          {safeFormatToDate(item.createdAt, 'EEE, MMM dd')}
        </span>
        <span className='splitter'>|</span>
        <span className='publicationTitle'>
          <Link to={`/${publicationCode}`}>{item.publicationObj.title}</Link>
        </span>
        {getTypeFromArticle(item) && (
          <>
            <span className='splitter'>|</span>
            <span className='item-type'>
              {getTypeFromArticle(item)}
            </span>
          </>
        )}
        {item.reading_time_min && (
          <>
            <span className='splitter'>|</span>
            <span className='readingTime'> {item.reading_time_min} min read</span>
          </>
        )}
      </div>
    </div>
  )
}

Item.propTypes = {
  item: PT.shape({
    wordpressSiteId: PT.string,
    title: PT.string,
    slug: PT.string,
    createdAt: PT.string,
    favoriteCreatedAt: PT.string,
    reading_time_min: PT.number,
    publicationObj: PT.object
  }),
  handleBookmarkClicked: PT.func.isRequired
}

export default observer(Item)
