import React from 'react'

import { html2jsx } from 'Lib/parser'

interface IMessageParams {
  html: string | boolean | null | undefined
}

const Message = ({ html }: IMessageParams): JSX.Element | null => {
  if (html == null || html === false) {
    return null
  }

  return (
    <aside className='publicationMessage'>
      <div className='mt-4 mb-3 alert alert-warning text-center'>
        <p className='mb-0'>{html2jsx(html)}</p>
      </div>
    </aside>
  )
}

export default Message
