import React from 'react'
import PT from 'prop-types'

const NewArticleIndicator = ({ label = 'New' }) => (
  <div className='newArticleIndicator'>
    {label}
  </div>
)

NewArticleIndicator.propTypes = {
  label: PT.string
}

export default NewArticleIndicator
